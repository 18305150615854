// import { decryptData, useGetListNews } from 'src/api'
import { IconBoldRight } from 'src/assets'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import BlogNewsItem from '../BlogNewsItem'
import ItemNewCol from '../ItemNewCol'
import classes from './index.module.less'

export type Props = {
  itemData: string
  listNews: any
}

const ItemCategory: React.FC<Props> = ({ itemData, listNews }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToSeeMore = () => {
    navigate(generatePath(`${itemData}`))
  }

  return (
    <div className={classes['list-category__item']}>
      <div className={classes['category-title']}>
        <p className={classes['title']}>{itemData}</p>
        <div className={classes['see-more']} onClick={navigateToSeeMore}>
          <span className={classes['text']}>{t('BLOG.SEE_MORE')}</span>
          <span className={classes['icon']}>
            <img src={IconBoldRight} alt="" />
            {/* <IconBoldRight /> */}
          </span>
        </div>
      </div>
      <div className={classes['list-news']}>
        {listNews?.data.slice(0, 2).map((item: any, index: number) => (
          <ItemNewCol key={item.newsId} itemData={item} />
        ))}
        <div className={classes['item-right']}>
          {listNews?.data.slice(2, 6).map((item: any, index: number) => (
            <BlogNewsItem
              key={item.newsId}
              itemData={item}
              reponsive={{ index: index }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ItemCategory
