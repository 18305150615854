import { Skeleton } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useGetListAddressPatient } from 'src/api/hooks/patient.query'
import { APP_URL } from 'src/config'
import { ProfileUserThemeProvider } from '../theme'
import './AddressList.module.less'
import { CreateOrUpdateAddressForm } from './components/CreateOrUpdateAddressForm'

export const UpdateAddress = () => {
  const { t } = useTranslation()
  const getListAddressRequest = useGetListAddressPatient()
  const navigate = useNavigate()

  const { id } = useParams()

  const address = useMemo(() => {
    const addresses: any[] = getListAddressRequest?.data?.data || []
    return addresses.find(item => item?.addressId === id)
  }, [getListAddressRequest?.data, id])

  useEffect(() => {
    getListAddressRequest.mutate({})
  }, [])

  if (getListAddressRequest?.isLoading) {
    return <Skeleton height={400}></Skeleton>
  }

  if (!address) {
    navigate(APP_URL.USER_PROFILE_ADDRESS_LIST)
  }

  return (
    <ProfileUserThemeProvider>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <CreateOrUpdateAddressForm address={address} />
    </ProfileUserThemeProvider>
  )
}
