import { Add, Remove } from '@mui/icons-material'
import { Box, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import { ImageProductSale } from 'src/assets'
import { IconButtonAnimate } from 'src/components/animate'
import NumericFormatCustom from 'src/components/common/NumericFormatCustom'
import Image from 'src/components/image/Image'
import useResponsive from 'src/hooks/useResponsive'
import { fCurrency, fNumber } from 'src/utils/formatNumber'
import { useCartContext } from '../../context'
import { paymentMethodDetail } from '../../types/pay'
import { SelectPaymentMethodDialog } from './SelectPaymentMethodDialog'

export const PaymentMethod = () => {
  const { t } = useTranslation()
  const { cartData, updateOrder } = useCartContext()
  const isMd = useResponsive('up', 'md')
  const [isOpenSelectDialog, setOpenSelectDialog] = useState(false)

  return (
    <Paper sx={{ p: 2 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" color="#576071">
          {t('cart.paymentMethod')}
        </Typography>
        <Typography
          variant="subtitle2"
          color="#1A9CC6"
          sx={{ cursor: 'pointer' }}
          onClick={() => setOpenSelectDialog(true)}
        >
          {t('cart.change')}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        p={1.5}
        bgcolor="#F4F8FB"
        borderRadius={1}
        mt={2}
      >
        <Image
          src={paymentMethodDetail?.[cartData?.typePay]?.icon}
          alt=""
          sx={{ width: 24 }}
        />
        <Typography variant="subtitle2" color="#576071" component="span">
          {t(paymentMethodDetail?.[cartData?.typePay]?.translateKey)}
        </Typography>
      </Stack>
      <SelectPaymentMethodDialog
        open={isOpenSelectDialog}
        onClose={() => setOpenSelectDialog(false)}
      />
    </Paper>
  )
}
