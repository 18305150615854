import React from 'react'
import classes from './Policy.module.less'
import { WrapPolicy } from './Components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

export const PolicyShip = () => {
  const { t } = useTranslation()
  return (
    <WrapPolicy>
      <Helmet>
        <title>Policy | Sophie</title>
      </Helmet>
      <div className={classes['root']}>
        <h1 className={classes['title-policy']}>
          {t('POLICY.SHIP_PAGE.TITLE')}
        </h1>
        <div className="mt-3">
          <div className={classes['terms-text']}>
            <p>{t('POLICY.SHIP_PAGE.COMMIT')}</p>
            <p> {t('POLICY.SHIP_PAGE.NOW')}</p>
            <p> {t('POLICY.SHIP_PAGE.SAVE')}</p>
          </div>
          <div className={classes['terms-text']}>
            <ul>
              <li>{t('POLICY.SHIP_PAGE.FEE')}</li>
              <li>{t('POLICY.SHIP_PAGE.FEE2')}</li>
              <li>{t('POLICY.SHIP_PAGE.FEE3')}</li>
              <li>{t('POLICY.SHIP_PAGE.NORMAL_SHIP')}</li>
              <li>{t('POLICY.SHIP_PAGE.TYPE_ONE')}</li>
              <li>{t('POLICY.SHIP_PAGE.TYPE_TWO')}</li>
              <li>{t('POLICY.SHIP_PAGE.TYPE_THREE')}</li>
              <li>{t('POLICY.SHIP_PAGE.TYPE_FOURTH')}</li>
              <li>{t('POLICY.SHIP_PAGE.BILL')}</li>
            </ul>
          </div>

          <div className={classes['terms-text']}>
            <p>{t('POLICY.SHIP_PAGE.DESC')}</p>
            <p>{t('POLICY.SHIP_PAGE.NEXT_POST')} </p>
          </div>
        </div>
      </div>
    </WrapPolicy>
  )
}

export default PolicyShip
