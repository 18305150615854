import {
  Box,
  Button,
  Divider,
  Pagination,
  Paper,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import { format } from 'date-fns'
import { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useGetOrderHistoryPatient } from 'src/api/hooks/patient.query'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import Image from 'src/components/image/Image'
import { APP_URL } from 'src/config'
import { TransportStatus } from 'src/constants/constants'
import { useCartContext } from 'src/pages/shop-online/Cart/context'
import { fNumber } from 'src/utils/formatNumber'
import { ProductRow } from './components/ProductRow'
import { TransportStatusLabel } from './components/TransportStatusLabel'
import classes from './OrderTracking.module.less'

enum TabValue {
  WaitingForVerify,
  WaitingForDelivery,
  Transporting,
  Success,
  Cancel,
}

export const OrderTracking = () => {
  const { profile } = useAuthUserContext()
  const { addManyToCart } = useCartContext()
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState(TabValue.WaitingForVerify)
  const [filter, setFilter] = useState({
    accountId: profile?.accountId,
    limit: 10,
    skip: 0,
  })
  const getOrderHistoryRequest = useGetOrderHistoryPatient()

  const orders: any[] = getOrderHistoryRequest.data?.data || []
  const total: number = getOrderHistoryRequest.data?.total || 0

  const { t } = useTranslation()

  const TABS = [
    {
      value: TabValue.WaitingForVerify,
      label: t('userProfile.orderTracking.waitingForReceive'),
    },
    {
      value: TabValue.WaitingForDelivery,
      label: t('userProfile.orderTracking.waitingForDelivery'),
    },
    {
      value: TabValue.Transporting,
      label: t('userProfile.orderTracking.transporting'),
    },
    {
      value: TabValue.Success,
      label: t('userProfile.orderTracking.success'),
    },
    {
      value: TabValue.Cancel,
      label: t('userProfile.orderTracking.cancel'),
    },
  ]

  const handleReorder = (listProduct: any[]) => {
    addManyToCart(listProduct)
    navigate(APP_URL.SHOP_ONLINE_CART)
  }

  useEffect(() => {
    if (profile?.accountId) {
      let statusTransport = [
        TransportStatus.Created,
        TransportStatus.Verified,
        TransportStatus.WaitingForDelivery,
        TransportStatus.Delivered,
        TransportStatus.Transporting,
        TransportStatus.Success,
        TransportStatus.Waiting,
        TransportStatus.Refunding,
        TransportStatus.Refunded,
        TransportStatus.Cancel,
      ]
      if (currentTab === TabValue.WaitingForVerify) {
        statusTransport = [TransportStatus.Created]
      }

      if (currentTab === TabValue.WaitingForDelivery) {
        statusTransport = [TransportStatus.Verified]
      }

      if (currentTab === TabValue.Transporting) {
        statusTransport = [
          TransportStatus.WaitingForDelivery,
          TransportStatus.Transporting,
          TransportStatus.Delivered,
          TransportStatus.Waiting,
        ]
      }

      if (currentTab === TabValue.Success) {
        statusTransport = [TransportStatus.Success]
      }

      if (currentTab === TabValue.Cancel) {
        statusTransport = [TransportStatus.Cancel]
      }

      getOrderHistoryRequest.mutate({
        ...filter,
        accountId: profile?.accountId,
        statusTransport,
      })
    }
  }, [filter, currentTab, profile?.accountId])

  return (
    <div className={classes['contact-personal']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <div>
        <Typography variant="h4" fontWeight={700}>
          {t('userProfile.orderTracking.orderTracking')}
        </Typography>
      </div>

      <Paper
        sx={{
          mt: 4,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Tabs
          value={currentTab}
          onChange={(event, newValue) => setCurrentTab(newValue)}
          allowScrollButtonsMobile
          sx={{
            p: 0,
            '.MuiTabs-flexContainer': {
              width: '100%',
            },
            '.MuiTab-root': {
              marginRight: '0 !important',
            },
            '.MuiTabs-indicator': {
              height: '3px',
              borderRadius: '10px 10px 0 0 ',
            },
            '.Mui-selected': {
              color: 'primary.main',
            },
          }}
        >
          {TABS.map(tab => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              sx={{ width: { xs: '50%', lg: '20%' }, whiteSpace: 'nowrap' }}
            />
          ))}
        </Tabs>
        <Divider sx={{ borderColor: '#f0f3f8' }} />
      </Paper>
      <Stack spacing={2} mt={2}>
        {!getOrderHistoryRequest?.isSuccess
          ? [...new Array(30)]?.map((item, i) => (
              <Skeleton height={200} key={i} />
            ))
          : null}
        {getOrderHistoryRequest?.isSuccess && !orders?.length ? (
          <Stack spacing={1} textAlign="center" alignItems="center" py={8}>
            <Image src="/assets/shop-online/paper-1.png" alt="" />
            <Typography variant="subtitle1" color="#B6BDCB">
              {t('userProfile.orderTracking.noOrder')}
            </Typography>
          </Stack>
        ) : null}
        {getOrderHistoryRequest?.isSuccess && orders?.length
          ? orders?.map((order, i) => (
              <Paper sx={{ borderRadius: '16px' }} key={order?.orderId}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Box width="100%">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6" color="primary.main">
                        {t('userProfile.orderTracking.order')}{' '}
                        {order?.orderId?.slice(0, 8)}
                        {'...'}
                      </Typography>

                      <TransportStatusLabel
                        transportStatus={order?.statusTransport}
                        display={{ xs: 'flex', lg: 'none' }}
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', lg: 'row' }}
                      sx={{ sm: { mt: '17px' }, lg: { mt: 0 } }}
                    >
                      <Typography
                        variant="body2"
                        color="#576071"
                        component="p"
                        sx={{ mr: 8 }}
                      >
                        {t('userProfile.orderTracking.orderAt')} lúc{' '}
                        {format(new Date(order?.created), 'mm:HH dd-MM-yyyy')}
                      </Typography>
                      <Typography variant="body2" color="#576071" component="p">
                        {t('userProfile.orderTracking.receiver')}:{' '}
                        {order?.accountName} -{' '}
                        {order?.phoneAccount?.replace('+84', '0')}
                      </Typography>
                    </Stack>
                  </Box>
                  <TransportStatusLabel
                    transportStatus={order?.statusTransport}
                    display={{ xs: 'none', lg: 'flex' }}
                  />
                </Stack>
                <Divider sx={{ borderColor: '#f0f3f8' }} />
                <Box px={3}>
                  {order?.listProduct?.map((item: any, i: number) => (
                    <Fragment key={item?.productId}>
                      {i !== 0 && <Divider sx={{ borderColor: '#f0f3f8' }} />}
                      <ProductRow product={item} />
                    </Fragment>
                  ))}
                </Box>
                <Stack
                  direction={{ xs: 'column-reverse', lg: 'row' }}
                  justifyContent="space-between"
                  spacing={2}
                  p={3}
                >
                  <Stack direction="row" spacing={1}>
                    {order?.statusTransport === TransportStatus.Success && (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => handleReorder(order?.listProduct)}
                        sx={{
                          backgroundColor: 'primary.lighter',
                          color: 'primary.main',
                          textTransform: 'none',
                          ':hover': {
                            backgroundColor: 'primary.lighter',
                          },
                        }}
                        fullWidth
                      >
                        {t('userProfile.orderTracking.reorder')}
                      </Button>
                    )}
                    <Link
                      className={`${classes['btn-detail']}`}
                      to={APP_URL.USER_PROFILE_ORDER_TRACKING_DETAIL?.replace(
                        ':id',
                        order?.orderId
                      )}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        sx={{
                          whiteSpace: 'nowrap',
                          backgroundColor: 'primary.lighter',
                          color: 'primary.main',
                          textTransform: 'none',
                          ':hover': {
                            backgroundColor: 'primary.lighter',
                          },
                        }}
                        fullWidth
                      >
                        {t('userProfile.orderTracking.seeOrderDetail')}
                      </Button>
                    </Link>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body2" color="#576071">
                      {t('userProfile.orderTracking.totalMoney')}
                    </Typography>
                    &nbsp;
                    <Typography variant="h6" color="#F0224F">
                      {fNumber(order?.price)}đ
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            ))
          : null}
      </Stack>
      <Box display="flex" justifyContent="center" py={6}>
        {orders?.length > 0 && (
          <Pagination
            page={filter?.skip / filter?.limit + 1}
            onChange={(e, page) => {
              page && setFilter(f => ({ ...f, skip: (page - 1) * f?.limit }))
            }}
            count={Math.ceil(total / filter?.limit)}
          />
        )}
      </Box>
    </div>
  )
}
