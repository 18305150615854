import {
  Box,
  Breadcrumbs,
  Grid,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import {
  useGetListCategoryPatient,
  useGetListProductPatient,
} from 'src/api/hooks/patient.query'
import Image from 'src/components/image/Image'
import { APP_URL } from 'src/config'
import { uuidV4Regex } from 'src/constants/regex'
import { ProductCard } from 'src/sections/user'
import {
  CategoryFilterDesktop,
  CategoryFilterSort,
  ProductPromotion,
} from './components'
import classes from './Category.module.less'
import { Helmet } from 'react-helmet-async'
import { useAuthUserContext } from 'src/auth/AuthUserContext'

export type TCategoryProps = {}

export const Category: React.FC<TCategoryProps> = ({}: TCategoryProps) => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuthUserContext()
  const categoryRequest = useGetListCategoryPatient()
  const listProductRequest = useGetListProductPatient()

  const { slug = '' } = useParams()
  const categoryId = slug.match(uuidV4Regex)?.[0]

  const category = useMemo(() => {
    const categories = categoryRequest?.data || []
    return categories?.find((item: any) => item?.categoryId === categoryId)
  }, [categoryRequest?.data, categoryId])

  const [filter, setFilter] = useState<any>({
    categoryId: categoryId,
    minPrice: '',
    maxPrice: '',
    isSale: null,
    isRelated: true,
    sortName: 'PurchasedNumber',
    sort: 'desc',
    skip: 0,
    limit: 30,
  })

  const handleUpdateFilter = (newFilter: Record<string, any>) => {
    setFilter((state: any) => ({ ...state, ...newFilter }))
  }

  const products: any[] = listProductRequest?.data?.data || []
  const total: number = listProductRequest?.data?.total || 0

  useEffect(() => {
    listProductRequest.mutate(filter)
  }, [filter])

  useEffect(() => {
    categoryRequest.mutate({})
  }, [categoryId])

  return (
    <div className={classes['category-container']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <Breadcrumbs>
        <Link color="red" to={APP_URL.SHOP_ONLINE}>
          <Typography variant="body2" color="primary">
            {t('pharmacyOnline.pharmacyOnline')}
          </Typography>
        </Link>
        <Typography color="#798395">{category?.categoryName}</Typography>
      </Breadcrumbs>
      <Typography variant="h3" fontWeight={700} mt={1}>
        {category?.categoryName}
      </Typography>
      <ProductPromotion categoryId={categoryId} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', md: 'center' }}
            spacing={2}
            mt={3}
          >
            {!listProductRequest?.isSuccess ? (
              <Skeleton width={200} height={30} />
            ) : null}
            {listProductRequest?.isSuccess && products?.length ? (
              <Typography variant="body2" color="#798395">
                {t('pharmacyOnline.searchResultFound', { number: total })}
              </Typography>
            ) : null}
            {listProductRequest?.isSuccess && !products?.length ? (
              <Typography variant="body2" color="#798395">
                {t('pharmacyOnline.noSearchResult')}
              </Typography>
            ) : null}
            <CategoryFilterSort
              category={category}
              filter={filter}
              handleUpdateFilter={handleUpdateFilter}
            />
          </Stack>
          {!listProductRequest?.isSuccess ? (
            <Grid container columns={60} spacing={2} pt={2}>
              {[...new Array(30)]?.map((item, i) => (
                <Grid item xs={30} sm={20} md={15} lg={12} key={i}>
                  <Skeleton height={200} />
                </Grid>
              ))}
            </Grid>
          ) : null}
          {listProductRequest?.isSuccess && products?.length ? (
            <Grid container columns={60} spacing={2} pt={2}>
              {products?.map((item, i) => (
                <Grid item xs={30} sm={20} md={15} lg={12} key={i}>
                  <ProductCard item={item} />
                </Grid>
              ))}
            </Grid>
          ) : null}
          {listProductRequest?.isSuccess && !products?.length ? (
            <Stack spacing={1} textAlign="center" alignItems="center" py={8}>
              <Image src="/assets/shop-online/paper-1.png" alt="" />
              <Typography variant="subtitle1" color="#B6BDCB">
                {t('pharmacyOnline.noSearchResult')}
              </Typography>
              <Typography variant="body2" color="#B6BDCB">
                {t('pharmacyOnline.pleaseTryWithOtherSearch')}
              </Typography>
            </Stack>
          ) : null}
          {listProductRequest?.isSuccess && products?.length ? (
            <Box display="flex" justifyContent="center" pt={3}>
              <Pagination
                count={Math.ceil(total / (filter?.limit || 10))}
                page={filter?.skip / filter?.limit + 1}
                onChange={(e, page) =>
                  page &&
                  handleUpdateFilter({ skip: (page - 1) * filter?.limit })
                }
              />
            </Box>
          ) : null}
        </Grid>
        <Grid item xs={12} md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
          <CategoryFilterDesktop
            category={category}
            filter={filter}
            handleUpdateFilter={handleUpdateFilter}
          />
        </Grid>
      </Grid>
      {/* <Box mt={6}>
        <ProductList title="Các sản phẩm đã xem" products={products} />
      </Box> */}
    </div>
  )
}

export default Category
