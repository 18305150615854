import { Col } from 'antd'
import { getTimeNews } from 'src/utils'
import { FORMAT_TIME } from 'src/constants'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import classes from './index.module.less'

interface ItemData {
  subject: string
  subjectType: string
  images: any
  dateTime: string
  content: string
  newsId: string
}

export type Props = {
  itemData: ItemData
}

const ItemNewCol: React.FC<Props> = ({ itemData }: Props) => {
  return (
    <Link to={`/detail-news/${itemData.newsId}`}>
      <div className={`${classes['top-new-item']} `}>
        <img src={itemData.images[0]} alt="category-item" />
        <div className={classes['item-title']}>
          <p className={classes['title']}>{itemData.subject}</p>
          <div className={classes['date']}>
            <p>
              {getTimeNews(itemData.dateTime)} -{' '}
              <strong>{itemData.subjectType}</strong>
            </p>
          </div>
          <span className={classes['content-ellipsis']}>
            <div dangerouslySetInnerHTML={{ __html: itemData?.content }}></div>
          </span>
        </div>
      </div>
    </Link>
  )
}

export default ItemNewCol
