import { Col } from 'antd'
import { getTimeNews } from 'src/utils'
import { FORMAT_TIME } from 'src/constants'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import classes from './index.module.less'

interface ItemData {
  subject: string
  subjectType: string
  images: any
  dateTime: string
  content: string
  newsId: string
}

interface Reponsive {
  lg?: number
  md?: number
  xl?: number
  xs?: number
  index?: number
}

export type Props = {
  itemData: ItemData
  reponsive?: Reponsive
  overwriteClass?: boolean
  suggestionsClass?: boolean
}

const BlogNewsItem: React.FC<Props> = ({
  itemData,
  reponsive,
  overwriteClass,
  suggestionsClass,
}: Props) => {
  return (
    <Link to={`/detail-news/${itemData.newsId}`}>
      <div
        className={`${classes['top-new-item']} ${
          overwriteClass && classes['wrapper']
        } ${suggestionsClass && classes['wrapper-small-item']}`}
      >
        <Col lg={15} className={classes['item-title']}>
          <p
            className={`${classes['title']} ${
              suggestionsClass && classes['small-title']
            }`}
          >
            {itemData.subject}
          </p>
          <div className={classes['date']}>
            <p>
              {getTimeNews(itemData.dateTime)} -{' '}
              <strong>{itemData.subjectType}</strong>
            </p>
          </div>
          <span
            className={`${classes['content-ellipsis']} ${
              overwriteClass && classes['show-evd']
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: itemData.content }}></div>
          </span>
        </Col>
        <Col order={1} lg={7}>
          <img
            className={`${overwriteClass && classes['list-news-overwrite']}`}
            src={itemData.images[0]}
            alt="category-item"
          />
        </Col>
      </div>
    </Link>
  )
}

export default BlogNewsItem
