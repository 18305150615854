export const wallets = [
  {
    value: 'Momo',
    translateKey: 'cart.momoMethod',
  },
  {
    value: 'Zalo',
    translateKey: 'cart.zaloMethod',
  },
]

export const paymentMethodDetail: Record<
  string,
  { icon: string; translateKey: string }
> = {
  COD: {
    icon: '/assets/shop-online/pay/ic-cod.svg',
    translateKey: 'cart.codMethod',
  },
  Momo: {
    icon: '/assets/shop-online/pay/ic-momo.svg',
    translateKey: 'cart.momoMethod',
  },
  Zalo: {
    icon: '/assets/shop-online/pay/ic-zalo.svg',
    translateKey: 'cart.zaloMethod',
  },
}
