import { TRoute } from 'src/@types/common'
import { APP_URL } from 'src/config'
import Category from './Category.screen'

export const CategoryRoutes: TRoute[] = [
  {
    path: APP_URL.SHOP_ONLINE_BY_CATEGORY,
    element: <Category />,
  },
]
