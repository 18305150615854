import { Close } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface INoticeHasSellOverDialogProps {
  open: boolean
  onClose: VoidFunction
}

export const NoticeHasSellOverDialog: FC<INoticeHasSellOverDialogProps> = ({
  open,
  onClose,
}: INoticeHasSellOverDialogProps) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      sx={{
        backgroundColor: '#004E7399',
      }}
    >
      <DialogTitle
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ py: 2 }}
      >
        <Typography variant="h5" color="primary.main">
          {t('cart.alertHasOverSellTitle')}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography color="#798395">
          {t('cart.alertHasOverSellDescription')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" fullWidth onClick={onClose}>
          {t('cart.understand')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
