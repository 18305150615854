import { TRoute } from 'src/@types/common'
import { APP_URL } from 'src/config'
import Cart from './Cart.screen'

export const CartRoutes: TRoute[] = [
  {
    path: APP_URL.SHOP_ONLINE_CART,
    element: <Cart />,
  },
]
