import axios, { AxiosInstance } from 'axios'
import { decryptDataResponse, logDev } from 'src/utils'
import { appConfig } from '../../../config'
import { HandleResponseError, HttpRestService } from '../../index'
import { getAuthTokenUser, getCodeHandshakeUser } from '../token'

export const InstanceAxios: AxiosInstance = axios.create({
  baseURL: appConfig.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

InstanceAxios.interceptors.request.use(config => {
  const tokenAuth = getAuthTokenUser()
  const xHandshake = getCodeHandshakeUser()
  if (config.headers) {
    if (tokenAuth != '') {
      config.headers['Authorization'] = 'Bearer ' + tokenAuth || ''
    }
    if (xHandshake != '') {
      config.headers['handshake'] = 'Basic ' + xHandshake || ''
    }
  }
  return config
})

InstanceAxios.interceptors.response.use(
  res => {
    if (typeof res.data === 'string') {
      res.data = decryptDataResponse(res)
    }

    logDev(`user ${res?.config?.url} ${res?.status}`, res?.data)

    return res?.data
  },
  error => HandleResponseError(error, { type: 'user' })
)

export const HttpServiceUser = new HttpRestService(InstanceAxios)
