import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// @mui
import { alpha } from '@mui/material/styles'
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material'
// routes
import { PATH_ADMIN, PATH_AUTH } from '../../../routes/paths'
// auth
import { useAuthContext } from '../../../auth/useAuthContext'
// components
import { CustomAvatar } from '../../../components/custom-avatar'
import { useSnackbar } from '../../../components/snackbar'
import MenuPopover from '../../../components/menu-popover'
import { IconButtonAnimate } from '../../../components/animate'
import { useLocales } from 'src/locales'
import UserProfileDialog from './ProfileDialog'
import { setAuthTokenPartner } from 'src/api/config/token'

// ----------------------------------------------------------------------

const OPTIONS = [
  //   //   {
  //   //     label: 'Home',
  //   //     linkTo: '/',
  //   //   },
  {
    label: 'Profile',
    linkTo: PATH_ADMIN.user.profile,
  },
  //   //   {
  //   //     label: 'Settings',
  //   //     linkTo: PATH_ADMIN.user.account,
  //   //   },
]

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate()
  const { translate } = useLocales()
  const [isOpenProfileDialog, setOpenProfileDialog] = useState(false)

  const OPTIONS = [
    //   {
    //     label: 'Home',
    //     linkTo: '/',
    //   },
    {
      label: translate('header.settings'),
      linkTo: PATH_ADMIN.user.profile,
    },
    //   {
    //     label: 'Settings',
    //     linkTo: PATH_ADMIN.user.account,
    //   },
  ]

  const { profile, logout } = useAuthContext()

  const { enqueueSnackbar } = useSnackbar()

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null)

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  const handleLogout = async () => {
    try {
      logout()
      handleClosePopover()
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Unable to logout!', { variant: 'error' })
    }
  }

  const handleClickItem = (path: string) => {
    handleClosePopover()
    setOpenProfileDialog(true)
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar
          src={profile?.avatar}
          alt={profile?.displayName}
          name={profile?.displayName}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 'auto', p: 0 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {profile?.firstname} {profile?.lastname}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {profile?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map(option => (
            <MenuItem
              key={option.label}
              onClick={() => handleClickItem(option.linkTo)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {translate('header.Logout')}
        </MenuItem>
      </MenuPopover>

      <UserProfileDialog
        open={isOpenProfileDialog}
        onClose={() => setOpenProfileDialog(false)}
      />
    </>
  )
}
