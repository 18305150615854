// @mui
import { Stack, Box, Typography } from '@mui/material'
// components
import Logo from '../../components/logo'
import Image from '../../components/image'
// styles
import {
  StyledRoot,
  StyledSectionBg,
  StyledSection,
  StyledContent,
} from './styles'

type Props = {
  title?: string
  illustration?: string
  children: React.ReactNode
}

export default function LoginLayout({ children, illustration }: Props) {
  return (
    <StyledRoot className="loginWrapper">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: {
              xs: 'center',
              sm: 'center',
              md: 'center',
              lg: 'space-between',
            },
            width: { xs: 'auto', md: 'auto', lg: '1128px' },
          }}
        >
          <Box>
            <Logo
              sx={{
                zIndex: 9,
                mt: { xs: 7.5, md: 2 },
                mb: 3,
                height: { xs: '48px', sm: '56px' },
              }}
            />
          </Box>
          <Box
            sx={{
              bottom: '92%',
              marginTop: '33px',
              display: {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'block',
                xl: 'block',
              },
            }}
          >
            <Typography variant="body2" color="#798395">
              © 2022 Sophie Healthcare. All rights reserved
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: { md: 'auto', lg: '1128px' },
          }}
        >
          <Box>
            <StyledSection>
              <Image
                disabledEffect
                visibleByDefault
                alt="auth"
                src={
                  illustration || '/assets/illustrations/illustration_login.png'
                }
                sx={{
                  maxWidth: 720,
                  display: { xs: 'none', sm: 'block', md: 'block' },
                }}
              />
              <StyledSectionBg />
            </StyledSection>
          </Box>
          <Box
            sx={{
              display: { sm: 'contents', lg: 'flex' },
            }}
          >
            {children && (
              <StyledContent>
                <Stack sx={{ width: 1 }}> {children} </Stack>
              </StyledContent>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: '40px',
          textAlign: 'center',
          display: {
            xs: 'block',
            sm: 'block',
            md: 'block',
            lg: 'none',
            xl: 'none',
          },
        }}
      >
        <Typography variant="body2" color="#798395">
          © 2022 Sophie Healthcare. All rights reserved
        </Typography>
      </Box>
    </StyledRoot>
  )
}
