import { ArrowRightRounded } from '@mui/icons-material'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useGetListPromotionHomepage } from 'src/api/hooks/guestDashboard.query'
import Image from 'src/components/image/Image'
import { APP_URL } from 'src/config'

import { PromotionDetailDialog } from 'src/pages/shop-online/Promotions/component/PromotionDetail'
import { useEffect, useState } from 'react'
import {
  useGetListPromotionPatient,
  useGetPromitionDetailPatient,
} from 'src/api/hooks/patient.query'
import { isImage } from 'src/utils/file'
import Slider from 'react-slick'
import classes from './Promotion.module.less'
import './Promotion.less'

export const Promotion = () => {
  const { t } = useTranslation()
  const [promotionDetail, setPromotionDetail] = useState<any>(null)

  const promotionRequest = useGetListPromotionPatient()

  const promotions: any[] = promotionRequest?.data?.data || []

  const slickSetting: any = {
    arrows: false,
    dots: false,
    navigator: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  useEffect(() => {
    promotionRequest.mutate({})
  }, [])

  return promotions?.length > 0 ? (
    <Box overflow="hidden">
      <div className={classes['promotion-container']}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" flexGrow={1}>
            {t('pharmacyOnline.promotion')}
          </Typography>
          <Link to={APP_URL.SHOP_ONLINE_PROMOTIONS}>
            <Typography
              component="span"
              color="primary.main"
              variant="body2"
              whiteSpace="nowrap"
            >
              {t('pharmacyOnline.seeAll')}
              <ArrowRightRounded fontSize="large" color="primary" />
            </Typography>
          </Link>
        </Stack>
        <div className={classes['grid-container']}>
          <Grid container spacing={2}>
            {promotions?.map((item, i) => (
              <Grid item xs={6} sm={4} lg={3} key={i}>
                <div onClick={() => setPromotionDetail(item)}>
                  {isImage(item?.banner?.[0]) ? (
                    <img
                      src={item?.banner?.[0] || ''}
                      alt=""
                      className={classes['banner']}
                    />
                  ) : (
                    <video className={classes['banner']}>
                      <source src={item?.banner?.[0]} type="video/mp4" />
                    </video>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
        <div className={classes['slider-container']}>
          <Slider
            {...slickSetting}
            className="shop-online__home-page--promotion"
          >
            {promotions.map((item, i) => (
              <div
                className={classes['slider-item']}
                onClick={() => setPromotionDetail(item)}
                key={i}
              >
                {isImage(item?.banner?.[0]) ? (
                  <img
                    src={item?.banner?.[0] || ''}
                    alt=""
                    className={classes['banner']}
                  />
                ) : (
                  <video className={classes['banner']}>
                    <source src={item?.banner?.[0]} type="video/mp4" />
                  </video>
                )}
              </div>
            ))}
          </Slider>
        </div>

        <PromotionDetailDialog
          item={promotionDetail}
          open={!!promotionDetail}
          onclose={() => setPromotionDetail(null)}
        />
      </div>
    </Box>
  ) : null
}
