import { TRoute } from 'src/@types/common'
import { APP_URL } from 'src/config'
import ShopDetail from './ShopDetail.screen'

export const ShopDetailRoutes: TRoute[] = [
  {
    path: APP_URL.SHOP_ONLINE_SHOP_DETAIL,
    element: <ShopDetail />,
  },
]
