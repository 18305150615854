import { InstanceAxios } from '../config/axios/user'

export const getListCategoryPatient = (): Promise<any> => {
  const path = '/api/patient/ShopPatient/ListCategory'
  return InstanceAxios.get(path)
}

export const getShopDetailByProductPatient = (
  productId?: any
): Promise<any> => {
  const path = '/api/patient/ShopPatient/GetShop'
  return InstanceAxios.post(path, {}, { params: { productId } })
}

export const getListPromotionPatient = (payload: any) => {
  const path = '/api/patient/shoppatient/listpromotion'
  return InstanceAxios.post(path, payload, {
    params: { skip: payload?.skip, limit: payload?.limit },
  })
}

export const getPromotionDetailPatient = (promotionId: any) => {
  const path = '/api/patient/shoppatient/detailpromotion'
  return InstanceAxios.get(path, { params: { promotionId } })
}

export const getListBannerPatient = (promotionId: any): Promise<any> => {
  const path = '/api/patient/ShopPatient/ListBanner'
  return InstanceAxios.get(path)
}

export const getListProductPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/ProductPatient/ListProduct'
  return InstanceAxios.post(path, payload, {
    params: { skip: payload?.skip, limit: payload?.limit },
  })
}

export const getSimilarProductPatient = (params?: any): Promise<any> => {
  const path = '/api/patient/ProductPatient/ListSimilarProduct'
  return InstanceAxios.post(path, {}, { params })
}

export const getDetailProductPatient = (productId?: any): Promise<any> => {
  const path = '/api/patient/ProductPatient/DetailProduct'
  return InstanceAxios.post(path, {}, { params: { productId } })
}

export const getListSpecialProduct = (payload?: any): Promise<any> => {
  const path = '/api/patient/ProductPatient/ListSpecialProduct'
  return InstanceAxios.post(path, payload, {
    params: { skip: payload?.skip, limit: payload?.limit },
  })
}
