import { Close, LogoDev } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCartContext } from '../../context'
import { RadioOverlay } from '../common/RadioOverlay'
import { fNumber } from 'src/utils/formatNumber'
import { calculateDeliveryTime, logDev } from 'src/utils'

interface IShippingUnitDialogProps {
  open: boolean
  transportListServices: any[]
  onClose: VoidFunction
}

export const ShippingUnitDialog: FC<IShippingUnitDialogProps> = ({
  open,
  transportListServices,
  onClose,
}: IShippingUnitDialogProps) => {
  const { t } = useTranslation()
  const {
    transportServiceData,
    order,
    updateTransportServiceData,
    updateOrder,
  } = useCartContext()
  const [transportServiceSelected, setTransportServiceSelected] =
    useState<any>()
  const [isLoading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = async () => {
    if (transportServiceSelected === transportServiceData?.servicE_CODE) {
      onClose()
      return
    }
    setLoading(true)
    try {
      updateTransportServiceData({
        servicE_CODE: transportServiceSelected.servicE_CODE,
        servicE_NAME: transportServiceSelected.servicE_NAME,
        servicE_PRICE: transportServiceSelected.servicE_PRICE,
        servicE_TIME: transportServiceSelected.servicE_TIME,
      })
      await updateOrder({
        transportService: transportServiceSelected.servicE_CODE,
        transportPromotionIds: [],
        promotionIds: [],
      })
      onClose()
    } catch (error) {}
    setLoading(false)
  }

  useEffect(() => {
    const handleSetTransportSelected = () => {
      transportListServices.forEach(transport => {
        if (transport?.servicE_CODE == order?.transportService) {
          setTransportServiceSelected({
            servicE_CODE: transport?.servicE_CODE,
            servicE_NAME: transport?.servicE_NAME,
            servicE_PRICE: transport?.servicE_PRICE,
            servicE_TIME: transport?.servicE_TIME,
          })
        }
      })
    }
    handleSetTransportSelected()
  }, [open, transportServiceData])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      scroll="body"
      sx={{
        backgroundColor: '#004E7399',
      }}
    >
      <DialogTitle
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ py: 2 }}
      >
        <Typography variant="h5" color="primary.main">
          {t('cart.shippingUnit')}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1} mt={1}>
          {transportListServices.map((item, i) => (
            <RadioOverlay
              checked={
                transportServiceSelected?.servicE_CODE === item?.servicE_CODE
              }
              onClick={() => setTransportServiceSelected(item)}
              key={i}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="subtitle2" color="primary.main">
                  {t(item?.servicE_NAME)}
                </Typography>
                {/* <Typography variant="body2" color="#576071">
                  -
                </Typography>
                <Typography variant="h6" color="#F0224F" align="right">
                  {fNumber(item?.servicE_PRICE || 0)}₫
                </Typography> */}
              </Stack>
              <Typography fontWeight={400} variant="body2" color="#576071">
                {calculateDeliveryTime(item?.servicE_TIME)}
              </Typography>
            </RadioOverlay>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          fullWidth
          onClick={handleSubmit}
        >
          {t('confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
