// i18n
import './locales/i18n'
import AOS from 'aos'

// scroll bar
import 'simplebar-react/dist/simplebar.min.css'

// lightbox
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

// map
import './utils/mapboxgl'
import 'mapbox-gl/dist/mapbox-gl.css'

// editor
import 'react-quill/dist/quill.snow.css'

// slick-carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
// import 'antd/lib/style/themes/default.less';
import 'aos/dist/aos.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './App.css'
import 'antd/dist/antd.min.css'
import './styles/index.less'

// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
// redux
import { store, persistor } from './redux/store'
// routes
import Router from './routes'
// theme
import ThemeProvider from './theme'
// locales
import ThemeLocalization from './locales'
// components
import { StyledChart } from './components/chart'
import SnackbarProvider from './components/snackbar'
import ScrollToTop from './components/scroll-to-top'
import { MotionLazyContainer } from './components/animate'
import { ThemeSettings, SettingsProvider } from './components/settings'
import { Toaster } from 'react-hot-toast'

import { QueryClientProvider } from 'react-query'
import { queryClient } from './api/hooks/query.client'
import { AuthProvider } from './auth/JwtContext'
import { HandshakeProvider } from './auth/HandshakeContext'
import { SnackbarUtilsConfigurator } from './utils/snackbar'
import { useEffect } from 'react'
import { AuthUserProvider } from './auth/AuthUserContext'
import { CartContextProvider } from './pages/shop-online/Cart/context/CartContext'

export default function App() {
  useEffect(() => {
    AOS.init({
      duration: 700,
      offset: 20,
    })
  })

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <BrowserRouter>
                  <HandshakeProvider>
                    <AuthProvider>
                      <AuthUserProvider>
                        <ScrollToTop />
                        <Toaster />
                        <MotionLazyContainer>
                          <ThemeProvider>
                            <ThemeSettings>
                              <ThemeLocalization>
                                <SnackbarProvider>
                                  <SnackbarUtilsConfigurator />
                                  <StyledChart />
                                  <CartContextProvider>
                                    <Router />
                                  </CartContextProvider>
                                </SnackbarProvider>
                              </ThemeLocalization>
                            </ThemeSettings>
                          </ThemeProvider>
                        </MotionLazyContainer>
                      </AuthUserProvider>
                    </AuthProvider>
                  </HandshakeProvider>
                </BrowserRouter>
              </SettingsProvider>
            </LocalizationProvider>
          </PersistGate>
        </ReduxProvider>
      </HelmetProvider>
    </QueryClientProvider>
  )
}
