import { InstanceAxios } from '../config/axios/user'
import { REST_DASHBOARD_SYS } from '../api_paths'

export const getListNews = async (payload: any, params: any): Promise<any> => {
  const route: string = REST_DASHBOARD_SYS.GET_LIST_NEWS.uri
  return InstanceAxios.post(route, payload, { params })
}

export const getListTopPartner = async (
  payload: any,
  params: any
): Promise<any> => {
  const route: string = REST_DASHBOARD_SYS.GET_TOP_PARTNER.uri
  return InstanceAxios.post(route, payload, { params })
}

export const getListPromotion = async (payload: any): Promise<any> => {
  const route: string = REST_DASHBOARD_SYS.GET_LIST_PROMOTION.uri
  return InstanceAxios.post(route, payload)
}

export const getDetailNews = async (params: any): Promise<any> => {
  const route: string = REST_DASHBOARD_SYS.GET_DETAIL_NEWS.uri
  return InstanceAxios.get(route, { params })
}

export const getListCategory = async (payload: any): Promise<any> => {
  const route: string = REST_DASHBOARD_SYS.GET_LIST_CATEGORY.uri
  return InstanceAxios.get(route, payload)
}

export const getListProductSaleOff = async (payload: any): Promise<any> => {
  const route: string = REST_DASHBOARD_SYS.GET_LIST_PRODUCT_SALE_OFF.uri
  return InstanceAxios.post(route, payload)
}

export const getListDashboardGuest = async (payload: any): Promise<any> => {
  const route: string = REST_DASHBOARD_SYS.GET_LIST_DASHBOARD_GUEST.uri
  return InstanceAxios.post(route, payload)
}
