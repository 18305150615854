import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
// utils
import localStorageAvailable from '../utils/localStorageAvailable'
//
import {
  useFacebookLogin,
  useGoogleLogin,
  useLogin,
  useLogout,
  useProfile,
} from 'src/api/hooks/admin.query'
import {
  ActionMapType,
  AuthStateType,
  AuthUserType,
  JWTContextType,
} from './types'

import {
  getAuthTokenPartner,
  getCodeHandshakePartner,
  getRefreshTokenPartner,
  removeAuthTokenPartner,
  removeRefreshTokenPartner,
  saveRefreshTokenPartner,
  setAuthTokenPartner,
} from 'src/api/config/token'

import { useNavigate } from 'react-router'
import { STORAGE_KEY } from 'src/api'
import { APP_URL } from 'src/config'
import { AccountType, Role } from 'src/constants/enum'

export enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGIN_WITH_GOOGLE = 'LOGIN_WITH_GOOGLE',
  LOGIN_WITH_FACEBOOK = 'LOGIN_WITH_FACEBOOK',
  SEND_OTP = 'SEND_OTP',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  SET_ERROR = 'SET_ERROR',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean
    profile: AuthUserType
    role: Role
    accountType: AccountType
  }
  [Types.SET_ERROR]: {
    isError: boolean
  }
  [Types.LOGIN]: {
    profile: AuthUserType
  }
  [Types.LOGIN_WITH_GOOGLE]: {
    profile: AuthUserType
  }
  [Types.LOGIN_WITH_FACEBOOK]: {
    user: AuthUserType
  }
  [Types.SEND_OTP]: {
    user: AuthUserType
  }
  [Types.REGISTER]: {
    user: AuthUserType
    profile: AuthUserType
  }
  [Types.LOGOUT]: undefined
}

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>]

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  profile: null,
  role: Role.Guest,
  accountType: AccountType.Guest,
}

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      profile: action.payload.profile,
      role: action.payload.role,
      accountType: action.payload.accountType,
    }
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      profile: action.payload.profile,
    }
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      profile: null,
    }
  }
  return state
}

export const AuthContext = createContext<JWTContextType | null>(null)

type AuthProviderProps = {
  children: React.ReactNode
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const loginRequest = useLogin()
  const googleLoginRequest = useGoogleLogin()
  const facebookLoginRequest = useFacebookLogin()
  const profileRequest = useProfile()
  const logoutRequest = useLogout()
  const navigate = useNavigate()

  const storageAvailable = localStorageAvailable()

  const initialize = useCallback(async () => {
    const tokenAuth = getAuthTokenPartner()
    var xHandshake = getCodeHandshakePartner()
    if (tokenAuth != '' && xHandshake != '') {
      await handleUpdateProfile()
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          profile: null,
          role: Role.Guest,
          accountType: AccountType.Guest,
        },
      })
    }
  }, [storageAvailable])

  useEffect(() => {
    initialize()
  }, [initialize])

  // LOGIN
  const login = useCallback(async (data: any) => {
    loginRequest.mutate({
      data,
    })
  }, [])

  const handleUpdateProfile = useCallback(async () => {
    try {
      const res: any = await profileRequest.mutateAsync()
      const data = { ...res }
      let role
      if (data?.role === Role.Pharmacist) {
        role = data?.profile?.typePharmacist
      } else {
        role = data?.role
      }

      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: true,
          profile: data?.profile,
          role: role,
          accountType: data?.role,
        },
      })
    } catch (error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          profile: null,
          role: Role.Guest,
          accountType: AccountType.Guest,
        },
      })
    }
  }, [dispatch, profileRequest])

  // LOGIN WITH FACEBOOK
  const loginWithFacebook = useCallback(async (data: any) => {
    facebookLoginRequest.mutate({
      data,
    })
  }, [facebookLoginRequest])

  // LOGIN WITH GOOGLE
  const loginWithGoogle = useCallback(async (data: any) => {
    googleLoginRequest.mutate({
      data,
    })
  }, [googleLoginRequest])

  // LOGOUT
  const logout = useCallback(async () => {
    const refreshToken = getRefreshTokenPartner()
    await logoutRequest.mutateAsync({ refreshToken })
    removeAuthTokenPartner()
    removeRefreshTokenPartner()
    navigate(APP_URL.LOGIN)
    dispatch({
      type: Types.LOGOUT,
    })
  }, [])

  useEffect(() => {
    if (loginRequest.isSuccess && loginRequest.data != null) {
      const data = loginRequest.data
      setAuthTokenPartner(data.accessToken)
      saveRefreshTokenPartner(data.refreshToken)
      handleUpdateProfile()
    }
  }, [loginRequest.isSuccess])

  useEffect(() => {
    if (facebookLoginRequest.isSuccess && facebookLoginRequest.data != null) {
      const data = facebookLoginRequest.data
      setAuthTokenPartner(data.accessToken)
      saveRefreshTokenPartner(data.refreshToken)
      handleUpdateProfile()
    }
  }, [facebookLoginRequest.isSuccess])

  useEffect(() => {
    if (googleLoginRequest.isSuccess && googleLoginRequest.data != null) {
      const data = googleLoginRequest.data
      setAuthTokenPartner(data.accessToken)
      saveRefreshTokenPartner(data.refreshToken)
      handleUpdateProfile()
    }
  }, [googleLoginRequest.isSuccess])

  useEffect(() => {
    const handleUpdateProfileIfChangeStorage = (e: StorageEvent) => {
      if (e.key === STORAGE_KEY.SESSION_TOKEN_PARTNER) {
        if (getRefreshTokenPartner() && getAuthTokenPartner()) {
          handleUpdateProfile()
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: false,
              profile: null,
              role: Role.Guest,
              accountType: AccountType.Guest,
            },
          })
          navigate(APP_URL.LOGIN)
        }
      }
    }
    window.addEventListener('storage', handleUpdateProfileIfChangeStorage)
    return () => {
      window.removeEventListener('storage', handleUpdateProfileIfChangeStorage)
    }
  }, [])

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      profile: state.profile,
      role: state.role,
      accountType: state.accountType,
      method: 'jwt',
      login,
      loginWithFacebook,
      loginWithGoogle,
      logout,
      updateProfile: handleUpdateProfile,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.profile,
      state.role,
      state.accountType,
      login,
      loginWithFacebook,
      loginWithGoogle,
      logout,
      handleUpdateProfile,
    ]
  )

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  )
}
