import { Box, Button, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { APP_URL } from 'src/config'
import LoginPopover from 'src/sections/auth/login-user/LoginUserPopover'

interface RegisterLoginButtonProps {
  onLoginSuccess?: VoidFunction
}

export const RegisterLoginButton: React.FC<RegisterLoginButtonProps> = ({
  onLoginSuccess,
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<any>(null)

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={2}
      alignItems={{ md: 'center' }}
    >
      <Link to={APP_URL.REGISTER_USER} style={{ width: '100%' }}>
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: '#E0F3F7',
            color: 'primary.main',
            whiteSpace: 'nowrap',
            ':hover': {
              backgroundColor: '#E0F3F7',
            },
          }}
        >
          Đăng ký
        </Button>
      </Link>
      <Button
        variant="contained"
        onClick={e => setAnchorEl(e.currentTarget)}
        fullWidth
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        Đăng nhập
      </Button>
      <LoginPopover
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onSuccess={() => {
          onLoginSuccess && onLoginSuccess()
          setAnchorEl(null)
        }}
      />
    </Stack>
  )
}

export default RegisterLoginButton
