export const uuidV4Regex =
  /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/g

export const phoneNumberWithoutCountryCodeRegex =
  /^(\+84|0)?(1\d{9}|3\d{8}|5\d{8}|7\d{8}|8\d{8}|9\d{8})$/

export const phoneNumberRegex =
  /^(\+84|0)(1\d{9}|3\d{8}|5\d{8}|7\d{8}|8\d{8}|9\d{8})$/

export const numberRegex = /^\d+$/
