import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ListItemText,
} from '@mui/material'
import { useState } from 'react'
import { useAuthContext } from 'src/auth/useAuthContext'
import {
  StyledIcon,
  StyledItem,
} from 'src/components/nav-section/vertical/styles'
import SvgColor from 'src/components/svg-color/SvgColor'
import { useLocales } from 'src/locales'

export default function LogoutButton() {
  const { logout } = useAuthContext()
  const { translate } = useLocales()
  const [isOpenConfirmLogoutModal, setOpenConfirmLogoutModal] = useState(false)

  return (
    <>
      <Link
        underline="none"
        sx={{ px: 2 }}
        onClick={() => setOpenConfirmLogoutModal(true)}
      >
        <StyledItem depth={1}>
          <StyledIcon>
            <SvgColor
              src={`/assets/icons/navbar/ic_logout.svg`}
              sx={{ width: 1, height: 1 }}
            />
          </StyledIcon>

          <ListItemText
            primary={`Đăng xuất`}
            primaryTypographyProps={{
              noWrap: true,
              component: 'span',
              variant: 'body2',
            }}
            secondaryTypographyProps={{
              noWrap: true,
              variant: 'caption',
            }}
          />
        </StyledItem>
      </Link>
      <Dialog
        open={isOpenConfirmLogoutModal}
        onClose={() => setOpenConfirmLogoutModal(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{translate('layout.confirmLogout')}</DialogTitle>
        <DialogContent>
          {translate('layout.confirmLogoutContent')}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpenConfirmLogoutModal(false)}
          >
            {translate('cancel')}
          </Button>
          <Button variant="contained" onClick={logout}>
            {translate('layout.logout')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
