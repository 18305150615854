import { CheckCircleRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { BroadcastChannel } from 'broadcast-channel'
import { useSnackbar } from 'notistack'
import queryString from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  useCheckMomoPayShopPatient,
  useCheckZaloPayShopPatient,
  useCreateMomoPayShopPatient,
  useCreateZaloPayShopPatient,
  useFindAddressPatient,
  useGetOrderByTransactionIdPatient,
} from 'src/api/hooks/patient.query'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import Image from 'src/components/image/Image'
import { APP_URL } from 'src/config'
import { logDev } from 'src/utils'
import { fNumber } from 'src/utils/formatNumber'
import { openInNewTab } from 'src/utils/window'
import { useCartContext } from '../Cart/context'
import { IOrder, IOrderCart } from '../Cart/types/order'
import { paymentMethodDetail } from '../Cart/types/pay'
import { RePayButton } from './components/RePayButton'
import { TransactionDetailDialog } from './components/TransactionDetailDialog'

export type TCartProps = {}

export const Cart: React.FC<TCartProps> = ({}: TCartProps) => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuthUserContext()
  const { refreshOrder } = useCartContext()
  const navigate = useNavigate()
  const getOrderByTransactionRequest = useGetOrderByTransactionIdPatient()
  const findAddressRequest = useFindAddressPatient()
  const createMomoPayRequest = useCreateMomoPayShopPatient()
  const createZaloPayRequest = useCreateZaloPayShopPatient()
  const checkMomoPayRequest = useCheckMomoPayShopPatient()
  const checkZaloPayRequest = useCheckZaloPayShopPatient()
  const [isPayed, setPayed] = useState(false)
  const [isOpenDetailDialog, setOpenDetailDialog] = useState(false)
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const query = queryString.parse(location?.search)

  const orders: IOrderCart[] = getOrderByTransactionRequest?.data?.data || []
  const products = orders?.map(item => item?.listProduct)?.flat()
  const order: IOrderCart = getOrderByTransactionRequest?.data?.data?.[0]
  const address = findAddressRequest?.data?.data
  const isSuccess = order?.typePay === 'COD' || isPayed

  const { price, transportPrice, totalPromotion } = useMemo(() => {
    let price = 0
    let transportPrice = 0
    let promotionPrice = 0
    let transportPromotionPrice = 0
    for (const order of orders) {
      price += +order?.price || 0
      transportPrice += +order?.transportPrice || 0
      promotionPrice += +order?.promotionPrice || 0
      transportPromotionPrice += +order?.transportPromotionPrice || 0
    }
    return {
      price,
      transportPrice,
      totalPromotion: promotionPrice + transportPromotionPrice,
    }
  }, [getOrderByTransactionRequest?.data])

  const handleBroadcastUpdateCart = () => {
    const cartChannel = new BroadcastChannel('update-cart')
    cartChannel.postMessage('do update')
  }

  useEffect(() => {
    const handleGetOrder = async () => {
      if (!isAuthenticated) {
        return
      }
      try {
        const res = await getOrderByTransactionRequest.mutateAsync(id)
        if (!res?.data?.length) {
          navigate('/404')
        }
      } catch (error) {
        navigate('/404')
      }
    }
    handleGetOrder()
  }, [id, isAuthenticated])

  useEffect(() => {
    if (order?.addressId && isAuthenticated) {
      findAddressRequest.mutateAsync(order?.addressId)
    } else {
      findAddressRequest.reset()
    }
  }, [order?.addressId, isAuthenticated])

  useEffect(() => {
    const handleCheckPaymentStatus = async () => {
      if (!isAuthenticated) {
        return
      }
      try {
        switch (order?.typePay) {
          case 'Momo':
            const resMomo = await checkMomoPayRequest.mutateAsync({
              transactionId: id,
              momo: query,
            })
            setPayed(resMomo?.status === 'Success')
            break

          case 'Zalo':
            const resZalo = await checkZaloPayRequest.mutateAsync({
              transactionId: id,
              zalo: query,
            })
            setPayed(resZalo?.status === 'Success')
            break

          default:
            break
        }
        handleBroadcastUpdateCart()
      } catch (error) {
        navigate('/')
        // setPayed(false)
      }
    }
    handleCheckPaymentStatus()
  }, [id, order?.typePay, isAuthenticated])

  const RenderPayCompleted = () => (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent="center"
      alignItems="center"
      bgcolor="success.lighter"
      py={2}
      textAlign={{ xs: 'center', sm: 'left' }}
    >
      <Image src="/assets/shop-online/ic-pay-success.svg" alt="" />
      <Stack spacing={1}>
        <Typography variant="h5" color="success.main">
          {t('cart.orderSuccess')}
        </Typography>
        <Typography variant="caption" color="#798395">
          {t('cart.orderSuccessSubText')}
        </Typography>
      </Stack>
    </Stack>
  )

  const RenderPayFailed = () => (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent="center"
      alignItems="center"
      bgcolor="error.lighter"
      py={2}
      textAlign={{ xs: 'center', sm: 'left' }}
    >
      <Image src="/assets/shop-online/ic-pay-failed.svg" alt="" />
      <Stack spacing={1}>
        <Typography variant="h5" color="error.main">
          {t('cart.payFailed')}
        </Typography>
        <Typography variant="caption" color="#798395">
          {t('cart.orderFailedSubText')}
        </Typography>
      </Stack>
    </Stack>
  )

  const RenderPaymentStatus = () => {
    if (order?.typePay === 'COD') {
      return <></>
    }
    if (checkMomoPayRequest?.isLoading) {
      return <></>
    }
    if (!isPayed) {
      return (
        <Typography variant="caption" fontWeight={700} color="error.main">
          {t('cart.payFailed')}
        </Typography>
      )
    }
    if (isPayed) {
      return (
        <>
          <CheckCircleRounded color="success" fontSize="small" />
          <Typography variant="caption" fontWeight={700} color="success.main">
            {t('cart.paySuccess')}
          </Typography>
        </>
      )
    }
    return <></>
  }

  if (
    !getOrderByTransactionRequest.isSuccess ||
    checkMomoPayRequest.isLoading ||
    checkZaloPayRequest.isLoading
  ) {
    return (
      <Box py={4} maxWidth="sm" mx="auto">
        <Skeleton height={500}></Skeleton>
      </Box>
    )
  }

  return (
    <Box py={4}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <Paper
        sx={{
          width: '100%',
          maxWidth: 'sm',
          mx: 'auto',
          overflow: 'hidden',
        }}
      >
        {isSuccess ? <RenderPayCompleted /> : <RenderPayFailed />}
        <Stack spacing={2} p={2}>
          <Stack spacing={1}>
            <Typography variant="subtitle2">{t('cart.shipTo')}</Typography>
            <Box p={1.5} bgcolor="#F4F8FB" borderRadius={1}>
              <Typography variant="subtitle1" color="primary.main">
                {address?.fullName} - {address?.phone}
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
                mt={1}
              >
                <Image
                  src="/assets/shop-online/ic-work-place.svg"
                  alt=""
                  sx={{ width: 18 }}
                />
                <Typography
                  variant="body2"
                  color="#576071"
                  width="calc(100% - 24px)"
                >
                  <Typography
                    variant="subtitle2"
                    color="#576071"
                    component="span"
                  >
                    {address?.typeAddress === 'Home'
                      ? t('cart.homePlace')
                      : t('cart.workPlace')}
                    :&nbsp;
                  </Typography>
                  {address?.addressAccount}, {address?.wardsName},{' '}
                  {address?.districtName}, {address?.provinceName}
                </Typography>
              </Stack>
            </Box>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="subtitle2">{t('cart.pay')}</Typography>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              p={1.5}
              bgcolor="#F4F8FB"
              borderRadius={1}
              mt={2}
            >
              <Image
                src={paymentMethodDetail?.[order?.typePay]?.icon}
                alt=""
                sx={{ width: 24 }}
              />
              <Typography variant="subtitle2" color="#576071" flexGrow={1}>
                {t(paymentMethodDetail?.[order?.typePay]?.translateKey)}
              </Typography>
              <RenderPaymentStatus />
            </Stack>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="subtitle2">{t('cart.product')}</Typography>
            <Grid container columns={10} spacing={1}>
              {products?.map((item, i) => (
                <Grid item xs={1} key={i}>
                  <Image
                    src={item?.productThumbImages?.[0]}
                    alt=""
                    width="100%"
                    sx={{
                      backgroundImage:
                        'url(/assets/shop-online/medicine-placeholder.png) !important',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="subtitle2">{t('cart.payDetail')}</Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="#798395">
                {t('cart.originalPrice')}
              </Typography>
              <Typography variant="subtitle2" color="#576071">
                {fNumber(price - transportPrice + totalPromotion)}₫
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="#798395">
                {t('cart.transportPrice')}
              </Typography>
              <Typography variant="subtitle2" color="#576071">
                {fNumber(transportPrice)}₫
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="#798395">
                {t('cart.promotion')}
              </Typography>
              <Typography variant="subtitle2" color="#576071">
                {fNumber(totalPromotion)}₫
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="#798395">
                {t('cart.total')}
              </Typography>
              <Typography variant="subtitle2" color="primary.main">
                {fNumber(price)}₫
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            {isSuccess ? (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: 'primary.lighter',
                  color: 'primary.main',
                  whiteSpace: 'nowrap',
                  ':hover': {
                    backgroundColor: 'primary.lighter',
                  },
                }}
                onClick={() => setOpenDetailDialog(true)}
              >
                {t('cart.viewDetail')}
              </Button>
            ) : (
              <Link to={APP_URL.SHOP_ONLINE} style={{ width: '100%' }}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: 'primary.lighter',
                    color: 'primary.main',
                    whiteSpace: 'nowrap',
                    ':hover': {
                      backgroundColor: 'primary.lighter',
                    },
                  }}
                >
                  {t('cart.goHomepage')}
                </Button>
              </Link>
            )}
            {isSuccess ? (
              <Link to={APP_URL.SHOP_ONLINE} style={{ width: '100%' }}>
                <Button variant="contained" fullWidth>
                  {t('cart.goHomepage')}
                </Button>
              </Link>
            ) : (
              <Link to={APP_URL.SHOP_ONLINE_CART} style={{ width: '100%' }}>
                <Button variant="contained" fullWidth sx={{ mt: 3 }}>
                  {t('cart.rePay')}
                </Button>
              </Link>
            )}
          </Stack>
        </Stack>
      </Paper>
      <TransactionDetailDialog
        open={isOpenDetailDialog}
        onClose={() => setOpenDetailDialog(false)}
        transaction={orders}
        transactionId={id || ''}
        payed={isPayed}
        address={address}
      />
    </Box>
  )
}

export default Cart
