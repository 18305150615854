import { IEnvironment } from './env'

export const appConfig: IEnvironment = {
  NODE_ENV: process.env.NODE_ENV || 'development',
  APP_ENV: process.env.REACT_APP_ENV || '',
  API_URL: process.env.REACT_APP_API_URL || '',
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID || '',
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET || '',
  PARTNER_CLIENT_ID: process.env.REACT_APP_PARTNER_CLIENT_ID || '',
  PARTNER_CLIENT_SECRET: process.env.REACT_APP_PARTNER_CLIENT_SECRET || '',
  APP_DEFAULT_AES_SECRETKEY: process.env.REACT_APP_DEFAULT_AES_SECRETKEY || '',
  APP_DEFAULT_AES_IVKEY: process.env.REACT_APP_DEFAULT_AES_IVKEY|| '',
  APP_FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID || '',
  APP_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
  APP_CLIENT_SECRET: process.env.REACT_APP_GOOGLE_CLIENT_SECRET || '',
  XFORWARDEDFOR: process.env.REACT_APP_XFORWARDEDFOR || '',
}

export const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
}
