// @mui
import { Box, Button, Stack } from '@mui/material'

import FacebookLogin from 'react-facebook-login'
import {
  GoogleOAuthProvider,
} from '@react-oauth/google'
import { useGoogleLogin } from '@react-oauth/google'

import { FacebookIcon } from '../../../components/icon/FacebookIcon'
import { GoogleIcon } from '../../../components/icon/GoogleIcon'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import { appConfig } from 'src/config/app.config'

import classes from './AuthWithSocial.module.less'
import axios from 'axios'

interface IAuthWithSocialProps {
  onClick?: VoidFunction
}

function AuthWithSocial({ onClick }: IAuthWithSocialProps) {
  const { loginWithFacebook, loginWithGoogle } = useAuthUserContext()

  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then(res => res.data)
      userInfo.accessToken = tokenResponse.access_token;
      responseGoogle(userInfo)
    },
  })

  const responseFacebook = async (response: any) => {
    await loginWithFacebook(response)
    onClick && onClick()
  }

  const responseGoogle = async (response: any) => {
    await loginWithGoogle(response)
    onClick && onClick()
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        '> *': {
          width: '100%',
        },
        button: {
          width: '100%',
        },
      }}
    >
      <FacebookLogin
        appId={appConfig.APP_FACEBOOK_APP_ID}
        fields="name,email,picture"
        callback={responseFacebook}
        cssClass={classes['facebook-custom-icon']}
        icon={<FacebookIcon />}
        textButton=""
      />
      <span className={classes['google-custom-icon']} onClick={() => login()}>
        <GoogleIcon />
      </span>
    </Stack>
  )
}

const withAuthSocial = (Component: any) => {
  function NewComponent(props: IAuthWithSocialProps) {
    return (
      <GoogleOAuthProvider clientId={appConfig.APP_CLIENT_ID}>
        <Component {...props} />
      </GoogleOAuthProvider>
    )
  }
  return NewComponent
}

export default withAuthSocial(AuthWithSocial)
