import React from 'react'
import classes from './AppointmentOnlinePhone.module.less'
import { Col, Row, Typography } from 'antd'
import {
  DoctorBackground,
  ImageAppStore,
  ImageGooglePlay,
  LightIcon,
  RadioTickIcon,
} from 'src/assets'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import {
  LINK_DOWNLOAD_APP_PATIENT_PLAY,
  LINK_DOWNLOAD_PATIENT_STORE,
} from 'src/config'

export const OnlineMedicalBookingFastPhone: React.FC<{}> = () => {
  const { t } = useTranslation()

  return (
    <div className={classes['layout-width']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <Row gutter={[0, 48]} align="middle" className={classes['banner']}>
        <Col
          data-aos="fade-up"
          className={classes['banner-left']}
          lg={12}
          md={24}
        >
          <Row align="middle" className={classes['banner-title']}>
            <div className={classes['icon-call']}>
              <img src={LightIcon} alt="" />
              {/* <LightIcon /> */}
            </div>
            <div
              className={`${classes['title']} ${classes['titleAtFast']}`}
              dangerouslySetInnerHTML={{
                __html: t('APPOINTMENT.FAST_PHONE.TITLE'),
              }}
            ></div>
          </Row>
          <Col>
            <h4 className={classes['title-sub']}>
              {t('APPOINTMENT.FAST_PHONE.FAST_CALL')}
            </h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t('APPOINTMENT.FAST_PHONE.DESC'),
              }}
            />
            <ul className={classes['list-treatment']}>
              <li className={classes['list-item']}>
                <img src={RadioTickIcon} alt="" />
                <span className={classes['text']}>
                  {t('APPOINTMENT.PHONE.CONNECT')}
                </span>
              </li>
              <li className={classes['list-item']}>
                <img src={RadioTickIcon} alt="" />
                <span className={classes['text']}>
                  {t('APPOINTMENT.FAST_PHONE.ONLINE')}
                </span>
              </li>
            </ul>
            <Row
              justify={{
                md: 'start',
                lg: 'start',
              }}
            >
              <Typography.Text className={classes['sub-subtitle']}>
                {t('GENERAL.CONSULTING')}
              </Typography.Text>
            </Row>
            <Row
              justify={{
                md: 'start',
                lg: 'start',
              }}
              className={classes['social']}
            >
              <Col>
                <a
                  rel="noreferrer"
                  href={LINK_DOWNLOAD_PATIENT_STORE}
                  target={'_blank'}
                >
                  <img
                    src={ImageAppStore}
                    alt=""
                    className={classes['img-social']}
                  />
                </a>
              </Col>
              <Col>
                <a
                  rel="noreferrer"
                  href={LINK_DOWNLOAD_APP_PATIENT_PLAY}
                  target={'_blank'}
                >
                  <img
                    src={ImageGooglePlay}
                    alt=""
                    className={classes['img-social']}
                  />
                </a>
              </Col>
            </Row>
          </Col>
        </Col>
        <Col
          data-aos="fade-up"
          lg={12}
          md={24}
          className={classes['banner-right']}
        >
          <img
            src={DoctorBackground}
            alt=""
            className={classes['image-banner']}
          />
        </Col>
      </Row>
    </div>
  )
}

export default OnlineMedicalBookingFastPhone
