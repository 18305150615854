import { Box, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import Image from 'src/components/image/Image'
import { toSlug } from 'src/utils/string'
import './Category.less'
import classes from './Category.module.less'
interface CategoryProps {
  categories: any[]
}

export const Category = ({ categories }: CategoryProps) => {
  const [slickRef, setSlickRef] = useState<Slider>()
  const { t } = useTranslation()

  const slickSetting: any = {
    ref: setSlickRef,
    arrows: false,
    dots: true,
    navigator: true,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 8,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          rows: 2,
        },
      },
    ],
  }

  return categories?.length > 0 ? (
    <div>
      <div className={'shop-online-category-slider'}>
        <Typography variant="h4" mt={2} mb={1}>
          {t('pharmacyOnline.category')}
        </Typography>
        <Slider {...slickSetting} className={classes['slider-container']}>
          {categories.map((item: any, i: number) => (
            <Link
              to={`/shop-online/category/${item?.categoryId}-${toSlug(
                item?.categoryName
              )}`}
              className={classes['slider-item']}
              key={i}
            >
              <Stack className={classes['paper']}>
                <Image
                  sx={{ width: '40%', minWidth: '48px' }}
                  src={item?.categoryIcon}
                  alt=""
                />
                <Typography variant="subtitle2" color="#576071" mt={1}>
                  {item?.categoryName}
                </Typography>
              </Stack>
            </Link>
          ))}
        </Slider>
      </div>
    </div>
  ) : null
}
