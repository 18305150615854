import './MenuItem.module.less'
import { useState } from 'react'
import classes from './MenuItem.module.less'
import { Avatar, Box, Button, Typography } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { APP_URL } from 'src/config'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import { ConfirmLogoutDialog } from 'src/layouts/shop-online/components/ConfirmLogoutDialog'

export const MenuItem = () => {
  const { profile } = useAuthUserContext()
  const location = useLocation()
  const [isOpenConfirmLogoutModal, setOpenConfirmLogoutModal] = useState(false)

  const [activeItem, setActiveItem] = useState(items[0].name)

  const HandleOnclickSideBar = (item: string) => {
    setActiveItem(item)
  }

  return (
    <div className={classes['side-bar']}>
      <div className={classes['menu-item']}>
        <div className={classes['contact']}>
          <div className={classes['contact-text']}>
            <Typography className={classes['account-name']}>
              {profile?.fullname}
            </Typography>
            <div className={classes['contact-phone']}>
              <img
                src="/assets/shop-online/Profile/call.png"
                alt="icon-phone"
              />
              <Typography className={classes['number-phone']}>
                {profile?.phoneNumber}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes['avt']}>
          <Avatar
            alt="avatar"
            src={profile?.avatar}
            className={classes['avatar']}
          />
        </div>
        <img
          src="/assets/shop-online/Profile/logo.png"
          alt="logo"
          className={classes['logo']}
        />
      </div>
      <div className={classes['list-item']}>
        {items.map(item =>
          item.action ? (
            <Button
              className={`${classes['item-category']} ${
                location.pathname?.startsWith(item.path)
                  ? classes['active']
                  : classes['']
              }`}
              onClick={() => setOpenConfirmLogoutModal(true)}
            >
              <img src={item.icon} alt="icon-item" />
              <div className={classes['item-name']}>{item.name}</div>
            </Button>
          ) : (
            <Link to={item.path}>
              <div>
                <Button
                  className={`${classes['item-category']} ${
                    location.pathname?.startsWith(item.path)
                      ? classes['active']
                      : classes['']
                  }`}
                  onClick={() => HandleOnclickSideBar(item.name)}
                >
                  <img src={item.icon} alt="icon-item" />
                  <div className={classes['item-name']}>{item.name}</div>
                </Button>
              </div>
            </Link>
          )
        )}
      </div>

      <ConfirmLogoutDialog
        open={isOpenConfirmLogoutModal}
        onClose={() => setOpenConfirmLogoutModal(false)}
      />
    </div>
  )
}

export const items = [
  {
    icon: '/assets/shop-online/Profile/solid_circle_person.png',
    name: 'Thông tin cá nhân',
    path: APP_URL.USER_PROFILE_PERSONAL_INFORMATION,
  },
  {
    icon: '/assets/shop-online/Profile/map.png',
    name: 'Danh sách địa chỉ',
    path: APP_URL.USER_PROFILE_ADDRESS_LIST,
  },
  {
    icon: '/assets/shop-online/Profile/wallet.png',
    name: 'Phương thức thanh toán',
    path: '#',
  },
  {
    icon: '/assets/shop-online/Profile/inventory.png',
    name: 'Theo dõi đơn hàng',
    path: APP_URL.USER_PROFILE_ORDER_TRACKING,
  },
  {
    icon: '/assets/shop-online/Profile/sign_in.png',
    name: 'Đăng xuất',
    path: '#',
    action: true,
  },
]
