import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Image from 'src/components/image/Image'
import Label from 'src/components/label/Label'
import { useCartContext } from '../../context'
import { paymentMethodDetail, wallets } from '../../types/pay'
import { RadioOverlay } from '../common/RadioOverlay'

interface ISelectPaymentMethodDialogProps {
  open: boolean
  onClose: VoidFunction
}

export const SelectPaymentMethodDialog: FC<ISelectPaymentMethodDialogProps> = ({
  open,
  onClose,
}: ISelectPaymentMethodDialogProps) => {
  const { t } = useTranslation()
  const { cartData, updateCartData, updateOrder } = useCartContext()
  const [typePaySelected, setTypePaySelected] = useState('')
  const [isLoading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = async () => {
    if (typePaySelected === cartData?.typePay) {
      onClose()
      return
    }
    setLoading(true)
    try {
      updateCartData({ typePay: typePaySelected })
      await updateOrder({
        typePay: typePaySelected,
        transportPromotionIds: [],
        promotionIds: [],
      })
      onClose()
    } catch (error) {}
    setLoading(false)
  }

  useEffect(() => {
    const handleSetTypePaySelected = () => {
      setTypePaySelected(cartData?.typePay)
    }
    handleSetTypePaySelected()
  }, [open, cartData])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      scroll="body"
      sx={{
        backgroundColor: '#004E7399',
      }}
    >
      <DialogTitle
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ py: 2 }}
      >
        <Typography variant="h5" color="primary.main">
          {t('cart.selectPaymentMethod')}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <RadioOverlay
          checked={typePaySelected === 'COD'}
          onClick={() => setTypePaySelected('COD')}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Image
              src={paymentMethodDetail['COD']?.icon}
              alt=""
              sx={{ width: 24 }}
            />
            <Typography variant="subtitle2" color="#576071">
              {t('cart.codMethod')}
            </Typography>
          </Stack>
        </RadioOverlay>
        <Typography variant="subtitle2" mt={2}>
          {t('cart.eWallet')}
        </Typography>
        <Stack spacing={1} mt={1}>
          {wallets.map((item, i) => (
            <RadioOverlay
              checked={typePaySelected === item?.value}
              onClick={() => setTypePaySelected(item?.value)}
              key={i}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Image
                  src={paymentMethodDetail[item?.value]?.icon}
                  alt=""
                  sx={{ width: 24 }}
                />
                <Typography variant="subtitle2" color="#576071">
                  {t(item?.translateKey)}
                </Typography>
              </Stack>
            </RadioOverlay>
          ))}
        </Stack>
        {/* <Stack
          direction="row"
          alignContent="center"
          justifyContent="space-between"
          mt={2}
        >
          <Typography variant="subtitle2">{t('cart.atmCard')}</Typography>
          <Typography
            variant="caption"
            color="#1A9CC6"
            sx={{ cursor: 'pointer' }}
          >
            {t('cart.addCard')}
          </Typography>
        </Stack>
        <Stack spacing={1} mt={1}>
          {[...new Array(2)].map((item, i) => (
            <RadioOverlay key={i}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Image
                  src="/assets/shop-online/pay/ic-vietcombank.png"
                  alt=""
                  sx={{ width: 24 }}
                />
                <Typography variant="subtitle2" color="#576071">
                  Ngân hàng Vietcombank
                </Typography>
                <Typography variant="caption" color="#798395">
                  **** 9201
                </Typography>
              </Stack>
            </RadioOverlay>
          ))}
        </Stack>
        <Stack
          direction="row"
          alignContent="center"
          justifyContent="space-between"
          mt={2}
        >
          <Typography variant="subtitle2">{t('cart.atmCard')}</Typography>
          <Typography
            variant="caption"
            color="#1A9CC6"
            sx={{ cursor: 'pointer' }}
          >
            {t('cart.addCard')}
          </Typography>
        </Stack>
        <Stack spacing={1} mt={1}>
          {[...new Array(2)].map((item, i) => (
            <RadioOverlay key={i}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Image
                  src="/assets/shop-online/pay/ic-visa.svg"
                  alt=""
                  sx={{ height: 24 }}
                />
                <Typography variant="subtitle2" color="#576071">
                  **** 9201
                </Typography>
                <Label color="error" sx={{ color: '#F0224F' }}>
                  {t('expired')}
                </Label>
              </Stack>
            </RadioOverlay>
          ))}
        </Stack> */}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          fullWidth
          onClick={handleSubmit}
        >
          {t('confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
