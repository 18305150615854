import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Image from 'src/components/image/Image'
import { APP_URL } from 'src/config'
import { logDev } from 'src/utils'
import { useCartContext } from '../../context'
import { RadioOverlay } from '../common/RadioOverlay'
import { AddAddressDialog } from './AddAddressDialog'

interface ISelectAddressDialogProps {
  open: boolean
  onClose: VoidFunction
  addresses: any[]
  handleRefreshAddressList?: VoidFunction
}

export const SelectAddressDialog: FC<ISelectAddressDialogProps> = ({
  open,
  onClose,
  addresses,
  handleRefreshAddressList,
}: ISelectAddressDialogProps) => {
  const { t } = useTranslation()
  const { order, updateOrder } = useCartContext()
  const [addressSelected, setAddressSelected] = useState<any>()
  const [isLoading, setLoading] = useState(false)
  const [isOpenAddAddressDialog, setOpenAddAddressDialog] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const {
        addressId,
        addressAccount,
        wardsName,
        districtName,
        provinceName,
      } = addressSelected
      await updateOrder({
        transportService: '',
        addressId: addressId,
        addressAccount: `${addressAccount}, ${wardsName}, ${districtName}, ${provinceName}`,
      })
      onClose()
    } catch (error) {
      logDev('Cart.SelectAddressDialog.handleSubmit', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    const handleSetAddressSelected = () => {
      if (!open) {
        return
      }
      setAddressSelected(
        addresses?.find(item => item?.addressId === order?.addressId)
      )
    }
    handleSetAddressSelected()
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      sx={{
        backgroundColor: '#004E7399',
      }}
    >
      <DialogTitle
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ py: 2 }}
      >
        <Typography variant="h5" color="primary.main">
          {t('cart.selectAddress')}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          {addresses.map((item, i) => (
            <RadioOverlay
              checked={addressSelected?.addressId === item?.addressId}
              onClick={() => setAddressSelected(item)}
              key={i}
            >
              <Typography variant="subtitle1" color="primary.main">
                {item?.fullName} - {item?.phone}
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
                mt={1}
              >
                <Image
                  src="/assets/shop-online/ic-work-place.svg"
                  alt=""
                  sx={{ width: 18 }}
                />
                <Typography
                  variant="body2"
                  color="#576071"
                  width="calc(100% - 24px)"
                >
                  <Typography
                    variant="subtitle2"
                    color="#576071"
                    component="span"
                  >
                    {item?.typeAddress === 'Home'
                      ? t('cart.homePlace')
                      : t('cart.workPlace')}
                    :&nbsp;
                  </Typography>
                  {item?.addressAccount}, {item?.wardsName},{' '}
                  {item?.districtName}, {item?.provinceName}
                </Typography>
              </Stack>
              {!!item?.isDefault && (
                <Typography variant="caption" color="primary.main" mt={2}>
                  {t('cart.defaultAddress')}
                </Typography>
              )}
            </RadioOverlay>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        {!!addresses?.length && (
          <Stack width="100%" spacing={2}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              fullWidth
              onClick={handleSubmit}
            >
              {t('confirm')}
            </LoadingButton>
            <Typography variant="body2" align="center" color="#798395">
              {t('cart.shipToOtherAddress')}&nbsp;
              <Typography
                variant="subtitle2"
                component="span"
                color="primary.main"
                sx={{ cursor: 'pointer' }}
                onClick={() => setOpenAddAddressDialog(true)}
              >
                {t('cart.addNewAddress')}
              </Typography>
            </Typography>
          </Stack>
        )}
        {!addresses?.length && (
          <Stack width="100%" spacing={4} alignItems="center">
            <Typography variant="body2" color="#798395" align="center">
              {t('cart.alertLackAddress')}
            </Typography>
            <Typography
              variant="subtitle2"
              component="span"
              align="center"
              color="primary.main"
              sx={{ cursor: 'pointer' }}
              onClick={() => setOpenAddAddressDialog(true)}
            >
              {t('cart.addNewAddress')}
            </Typography>
          </Stack>
        )}
      </DialogActions>
      <AddAddressDialog
        open={isOpenAddAddressDialog}
        onClose={() => setOpenAddAddressDialog(false)}
        onSuccess={handleRefreshAddressList}
      />
    </Dialog>
  )
}
