import React from 'react'
import { Col, Row, Typography } from 'antd'
import {
  IconBath,
  ImageAppStore,
  ImageGooglePlay,
  ImageService1,
  MassagePhysical,
  PhysicalBackground,
  PhysicalFirst,
  PhysicalSelect,
  PhysicalSelectKtv,
  RadioTickIcon,
} from 'src/assets'

import HealthServiceStep from './Components/HealthServiceStep'
import classes from './HealthServiceBaby.module.less'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import {
  LINK_DOWNLOAD_APP_PATIENT_PLAY,
  LINK_DOWNLOAD_PATIENT_STORE,
} from 'src/config'

export const HealthServicePhysical: React.FC<{}> = () => {
  const { t } = useTranslation()

  return (
    <div className={classes['layout-width']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <Row gutter={[0, 48]} align="middle" className={classes['banner']}>
        <Col
          data-aos="fade-up"
          className={classes['banner-left']}
          lg={12}
          md={24}
        >
          <Row align="middle" className={classes['banner-title']}>
            <div className={classes['icon-call']}>
              <img src={IconBath} alt="" />
              {/* <IconBath /> */}
            </div>
            <div
              className={`${classes['title']}  ${classes['titleAtFast']}`}
              dangerouslySetInnerHTML={{
                __html: t('SERVICE.PHYSICAL.TITLE'),
              }}
            ></div>
          </Row>
          <Col>
            <h4 className={classes['title-sub']}>
              {t('SERVICE.PHYSICAL.AT_HOME')}
            </h4>
            <p
              dangerouslySetInnerHTML={{ __html: t('SERVICE.PHYSICAL.DESC') }}
            />
            <ul className={classes['list-treatment']}>
              <li className={classes['list-item']}>
                <img src={RadioTickIcon} alt="" />
                <span className={classes['text']}>
                  {t('SERVICE.PHYSICAL.CONNECT')}
                </span>
              </li>
              <li className={classes['list-item']}>
                <img src={RadioTickIcon} alt="" />
                <span className={classes['text']}>{t('GENERAL.RATE')}</span>
              </li>
              <li className={classes['list-item']}>
                <img src={RadioTickIcon} alt="" />
                <span className={classes['text']}>
                  {t('GENERAL.FOLLOW_CARE')}
                </span>
              </li>
            </ul>
            <Row
              justify={{
                md: 'start',
                lg: 'start',
              }}
            >
              <Typography.Text className={classes['sub-subtitle']}>
                {t('GENERAL.WHERE_APP')}
              </Typography.Text>
            </Row>
            <Row
              justify={{
                md: 'start',
                lg: 'start',
              }}
              className={classes['social']}
            >
              <Col>
                <a
                  rel="noreferrer"
                  href={LINK_DOWNLOAD_PATIENT_STORE}
                  target={'_blank'}
                >
                  <img
                    src={ImageAppStore}
                    alt=""
                    className={classes['img-social']}
                  />
                </a>
              </Col>
              <Col>
                <a
                  rel="noreferrer"
                  href={LINK_DOWNLOAD_APP_PATIENT_PLAY}
                  target={'_blank'}
                >
                  <img
                    src={ImageGooglePlay}
                    alt=""
                    className={classes['img-social']}
                  />
                </a>
              </Col>
            </Row>
          </Col>
        </Col>
        <Col
          data-aos="fade-up"
          lg={12}
          md={24}
          className={classes['banner-right']}
        >
          <img
            src={PhysicalBackground}
            alt=""
            className={classes['image-banner']}
          />
        </Col>
      </Row>
      <RenderListAppointmentStep />
    </div>
  )
}

const RenderListAppointmentStep = () => {
  const { t } = useTranslation()

  const listHealthStep = [
    {
      number: '01',
      noteText: t('GENERAL.FIND_DOCTOR'),
      imageUrl: PhysicalFirst,
      stepTitle: t('GENERAL.INPUT'),
      imgService: ImageService1,
    },
    {
      number: '02',
      noteText: t('GENERAL.DATE_CARE'),
      imageUrl: PhysicalSelect,
      stepTitle: t('GENERAL.FIND_DOCTOR'),
      imgService: ImageService1,
    },
    {
      number: '03',
      noteText: t('GENERAL.CHECKOUT'),
      imageUrl: PhysicalSelectKtv,
      stepTitle: t('SERVICE.PHYSICAL.SERVICE'),
      imgService: ImageService1,
    },
    {
      number: '04',
      noteText: t('GENERAL.CONSULTING'),
      imageUrl: MassagePhysical,
      stepTitle: t('SERVICE.PHYSICAL.SERVICE_HOME'),
      imgService: ImageService1,
    },
  ]

  return (
    <div className={classes['apppointment-step-wrap']}>
      <Col lg={13} className={classes['wrap-title']}>
        <div
          className={classes['title']}
          dangerouslySetInnerHTML={{
            __html: t('SERVICE.PHYSICAL.PROCESS'),
          }}
        ></div>
        <Typography.Text className={classes['text']}>
          {t('SERVICE.PHYSICAL.SUB')}
        </Typography.Text>
      </Col>
      <Row
        justify={{
          md: 'space-around',
          lg: 'space-around',
          xl: 'space-between',
          xs: 'center',
        }}
        className={classes['list-appoitment-step']}
        gutter={[0, 40]}
      >
        {listHealthStep.map(item => (
          <HealthServiceStep
            key={item.number}
            number={item.number}
            noteText={item.noteText}
            imageUrl={item.imageUrl}
            stepTitle={item.stepTitle}
            imgService={item.imgService}
          />
        ))}
      </Row>
    </div>
  )
}

export default HealthServicePhysical
