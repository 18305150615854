import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
  useAddAddressPatient,
  useUpdateAddressPatient,
} from 'src/api/hooks/patient.query'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
} from 'src/components/hook-form'
import { APP_URL } from 'src/config'
import { IProvince } from 'src/constants'
import {
  districts,
  IDistrict,
  IWard,
  provinces,
  wards,
} from 'src/constants/vietnamese-administration'
import { useLocales } from 'src/locales'
import { REGEX_PHONE_NUMBER } from 'src/sections/auth/enum'
import * as Yup from 'yup'
import classes from './CreateOrUpdateAddressForm.module.less'

interface ICreateOrUpdateAddressFormProps {
  address?: any
}

export const CreateOrUpdateAddressForm = ({
  address = {},
}: ICreateOrUpdateAddressFormProps) => {
  const addAddressRequest = useAddAddressPatient()
  const updateAddressRequest = useUpdateAddressPatient()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { profile } = useAuthUserContext()

  const { translate } = useLocales()
  const { t } = useTranslation()
  const [activeType, setActiveType] = useState('')

  const [provinceOptions, setProvinceOptions] = useState<IProvince[]>([])
  const [districtOptions, setDistrictOptions] = useState<IDistrict[]>([])
  const [wardsOptions, setWardsOptions] = useState<IWard[]>([])

  const isUpdate = !!address?.addressId

  const alertFieldRequired = translate('alertSection.fieldIsRequired')
  const phoneNumberFormat = translate('alertSection.phoneNumberWrongFormat')

  const LoginSchema = Yup.object().shape({
    fullName: Yup.string().required(alertFieldRequired),
    phone: Yup.string()
      .required(alertFieldRequired)
      .matches(REGEX_PHONE_NUMBER, phoneNumberFormat),
    provinceId: Yup.string().required(alertFieldRequired),
    districtId: Yup.string().required(alertFieldRequired),
    wardsId: Yup.string().required(alertFieldRequired),
    addressAccount: Yup.string().required(alertFieldRequired),
  })

  useEffect(() => {
    setProvinceOptions(provinces)
  }, [])

  const handleChangeProvince = (value: IProvince) => {
    setDistrictOptions(
      districts?.filter(item => item.PROVINCE_ID == +value?.PROVINCE_ID)
    )
    setWardsOptions([])
    setValue('provinceId', value?.PROVINCE_ID as any, {
      shouldValidate: true,
    })
    setValue('provinceName', value?.PROVINCE_NAME)
    setValue('districtId', '')
    setValue('districtName', '')
    setValue('wardsId', '')
    setValue('wardsName', '')
  }

  const handleChangeDistrict = (value: IDistrict) => {
    setWardsOptions(
      wards?.filter(item => item.DISTRICT_ID == +value?.DISTRICT_ID)
    )
    setValue('districtId', value?.DISTRICT_ID as any, {
      shouldValidate: true,
    })
    setValue('districtName', value?.DISTRICT_NAME)
    setValue('wardsId', '')
    setValue('wardsName', '')
  }

  const handleChangeWard = (value: IWard) => {
    setValue('wardsId', value?.WARDS_ID as any, {
      shouldValidate: true,
    })
    setValue('wardsName', value?.WARDS_NAME)
  }

  const defaultValues = {
    isDefault: false,
    title: '',
    fullName: '',
    phone: '',
    addressAccount: '',
    provinceId: '',
    provinceName: '',
    districtId: '',
    districtName: '',
    wardsId: '',
    wardsName: '',
    typeAddress: 'Home',
  }

  const methods = useForm<typeof defaultValues>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isSubmitting, errors },
  } = methods

  const values = watch()

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      const body = {
        ...data,
        title: `${data?.fullName} - ${data?.phone}`,
        accountId: profile?.accountId,
        addressId: address?.addressId || '',
      }
      if (!isUpdate) {
        await addAddressRequest.mutateAsync(body)
        enqueueSnackbar('Thêm địa chỉ thành công')
      } else {
        await updateAddressRequest.mutateAsync(body)
        enqueueSnackbar('Sửa địa chỉ thành công')
      }
      navigate(APP_URL.USER_PROFILE_ADDRESS_LIST)
    } catch (error) {}
  }

  useEffect(() => {
    if (address?.addressId) {
      reset(address)
    } else {
      reset()
    }
  }, [address?.addressId])

  const addressTypeOptions = [
    {
      label: t('userProfile.address.home'),
      value: 'Home',
      icon: '/assets/shop-online/Profile/home.png',
    },
    {
      label: t('userProfile.address.organ'),
      value: 'Organ',
      icon: '/assets/shop-online/Profile/apartment.png',
    },
  ]

  return (
    <div className={classes['add-new-address']}>
      <Typography variant="h4">
        {!isUpdate
          ? t('userProfile.address.addNewAddress')
          : t('userProfile.address.updateAddress')}
      </Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} pt={4}>
          <Grid item xs={12} lg={6}>
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="#798395">
                {t('userProfile.address.contact')}
              </Typography>
              <RHFTextField
                variant="outlined"
                fullWidth
                name="fullName"
                placeholder={t('userProfile.address.inputContact')}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="#798395">
                {t('userProfile.address.phoneNumber')}
              </Typography>
              <RHFTextField
                variant="outlined"
                fullWidth
                name="phone"
                placeholder={t('userProfile.address.inputPhoneNumber')}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="#798395">
                {t('userProfile.address.province')}
              </Typography>
              <RHFAutocomplete
                name="provinceId"
                placeholder={
                  translate('userProfile.address.selectProvince') || ''
                }
                options={provinceOptions}
                getOptionLabel={(option: any) => option?.PROVINCE_NAME}
                isOptionEqualToValue={(option, value) =>
                  option?.PROVINCE_ID == value?.PROVINCE_ID
                }
                value={{
                  PROVINCE_ID: values?.provinceId || '',
                  PROVINCE_NAME: values?.provinceName || '',
                }}
                onChange={(e, value: any) => handleChangeProvince(value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="#798395">
                {t('userProfile.address.district')}
              </Typography>
              <RHFAutocomplete
                name="districtId"
                placeholder={
                  translate('userProfile.address.selectDistrict') + ' *'
                }
                options={districtOptions}
                getOptionLabel={(option: any) => option?.DISTRICT_NAME}
                isOptionEqualToValue={(option, value) =>
                  option?.DISTRICT_ID == value?.DISTRICT_ID
                }
                value={{
                  DISTRICT_ID: values?.districtId || '',
                  DISTRICT_NAME: values?.districtName || '',
                }}
                onChange={(e, value: any) => handleChangeDistrict(value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="#798395">
                {t('userProfile.address.wards')}
              </Typography>
              <RHFAutocomplete
                name="wardsId"
                placeholder={
                  translate('userProfile.address.selectWards') + ' *'
                }
                options={wardsOptions}
                getOptionLabel={(option: any) => option?.WARDS_NAME}
                isOptionEqualToValue={(option, value) =>
                  option?.WARDS_ID == value?.WARDS_ID
                }
                value={{
                  WARDS_ID: values?.wardsId || '',
                  WARDS_NAME: values?.wardsName || '',
                }}
                onChange={(e, value: any) => handleChangeWard(value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="#798395">
                {t('userProfile.address.address')}
              </Typography>
              <RHFTextField
                variant="outlined"
                fullWidth
                name="addressAccount"
                placeholder={t('userProfile.address.inputAddress')}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="#798395">
                {t('userProfile.address.addressType')}
              </Typography>

              <Stack
                direction="row"
                spacing={1}
                className={classes['address-type']}
              >
                {addressTypeOptions?.map((item, i) => (
                  <div
                    className={` ${classes['address-type-item']} ${
                      values.typeAddress === item?.value
                        ? classes['active']
                        : classes['']
                    } `}
                    onClick={() => setValue('typeAddress', item?.value)}
                  >
                    <img src={item?.icon} alt="ic-address-home" />
                    <Typography
                      variant="subtitle2"
                      ml={2}
                      color={
                        values.typeAddress === item?.value
                          ? 'primary.main'
                          : '#798395'
                      }
                    >
                      {item?.label}
                    </Typography>
                  </div>
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.isDefault}
                    onChange={(e, checked) => setValue('isDefault', checked)}
                  />
                }
                label={
                  <Typography variant="subtitle2" color="#798395">
                    {t('userProfile.address.setDefaultAddress')}
                  </Typography>
                }
              />
            </Box>
          </Grid>
        </Grid>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{
            marginTop: { xs: '16px', sm: '16px' },
          }}
        >
          {!isUpdate
            ? t('userProfile.address.saveNewAddress')
            : t('userProfile.saveChange')}
        </LoadingButton>
      </FormProvider>
    </div>
  )
}
