import { createContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  getCodeHandshakeUser,
  saveCodeHandshakeUser,
  saveCodeHandshakePartner,
} from 'src/api/config/token'
import {
  useCheckOperatingSystem,
  useGetIpClient,
  useHandShake,
} from 'src/api/hooks/system.query'
import { logDev } from 'src/utils'
import { StorageService } from '../api/config/storage'
import {
  ActionMapType,
  HandshakeContextType,
  HandshakeStateType,
} from './types'

enum Types {
  HANDSHAKE = 'HANDSHAKE',
}

type Payload = {
  [Types.HANDSHAKE]: {
    isHandshake: boolean
  }
}

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>]

// ----------------------------------------------------------------------

const initialState: HandshakeStateType = {
  isHandshake: true,
}

const reducer = (state: HandshakeStateType, action: ActionsType) => {
  if (action.type === Types.HANDSHAKE) {
    return {
      isHandshake: action.payload.isHandshake,
    }
  }
  return state
}

// ----------------------------------------------------------------------

export const HandshakeContext = createContext<HandshakeContextType | null>(null)

// ----------------------------------------------------------------------

type HandshakeProviderProps = {
  children: React.ReactNode
}

export function HandshakeProvider({ children }: HandshakeProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const navigate = useNavigate()

  const [countHandShakeFalse, setCountHandShakeFalse] = useState<number>(0)
  const [isHandShake, setHandShake] = useState(false)

  const checkSystem = useCheckOperatingSystem()
  const ipClient = useGetIpClient()
  const doHandshake = useHandShake()

  const handleGetHandshakeUser = async (responseIp: any) => {
    const handshakeRes = await doHandshake.mutateAsync(responseIp)
    saveCodeHandshakeUser(handshakeRes?.xHandshake || '')
    // logDev('TOKEN_HANDSHAKE ', handshakeRes?.xHandshake)
    StorageService.set('AES_KEY_USER', handshakeRes?.xSecretKey)
    StorageService.set('AES_IV_USER', handshakeRes?.xIVKey)
  }

  const handleGetHandshakePartner = async (responseIp: any) => {
    const handshakeRes = await doHandshake.mutateAsync({
      ...responseIp,
      type: 'partner',
    })
    saveCodeHandshakePartner(handshakeRes?.xHandshake || '')
    // logDev('TOKEN_HANDSHAKE_PARTNER ', handshakeRes?.xHandshake)
    StorageService.set('AES_KEY_PARTNER', handshakeRes?.xSecretKey)
    StorageService.set('AES_IV_PARTNER', handshakeRes?.xIVKey)
  }

  const handleHandShake = async () => {
    setHandShake(false)
    try {
      var xHandshake = getCodeHandshakeUser()
      if (!!xHandshake) {
        setHandShake(true)
        return
      }
      const checkSysRes = await checkSystem.mutateAsync()
      if (!checkSysRes?.isEnable) {
        setHandShake(true)
        return
      }
      const ipClientRes = await ipClient.mutateAsync()
      var responseIp = {
        ipClient: ipClientRes?.listIpClient?.x_Real_IP,
        dateTime: ipClientRes?.dateTime,
        timestamp: ipClientRes?.timestamp,
      }

      await Promise.all([
        handleGetHandshakeUser(responseIp),
        // handleGetHandshakePartner(responseIp),
      ])
    } catch (error) {}
    setHandShake(true)
  }

  // do HandShake
  useEffect(() => {
    handleHandShake()
  }, [])

  const memoizedValue = useMemo(
    () => ({
      isHandshake: state.isHandshake,
    }),
    [state.isHandshake]
  )

  return (
    <HandshakeContext.Provider value={memoizedValue}>
      {isHandShake ? children : ''}
    </HandshakeContext.Provider>
  )
}
