import { useMutation } from 'react-query'
import {
  onLogin,
  onFacebookLogin,
  onGoogleLogin,
  onSendOtp,
  onVerifyOtp,
  onResetPass,
  onGetProfile,
  onCheckForAll,
  onCheckAuthAccount,
  onLogout,
} from '../api_functions/admin'

export function useLogin() {
  return useMutation(onLogin)
}

export function useSendOtp() {
  return useMutation(onSendOtp)
}

export function useFacebookLogin() {
  return useMutation(onFacebookLogin)
}

export function useGoogleLogin() {
  return useMutation(onGoogleLogin)
}

export function useProfile() {
  return useMutation(onGetProfile)
}

export function useLogout() {
  return useMutation(onLogout)
}

export function useVerifyOtp() {
  return useMutation(onVerifyOtp)
}

export function useCheckAuthAccount() {
  return useMutation(onCheckAuthAccount)
}

export function useResetPass() {
  return useMutation(onResetPass)
}

export function useCheckForAll() {
  return useMutation(onCheckForAll)
}
