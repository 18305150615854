import { Helmet } from 'react-helmet-async'
// sections
import Login from '../../sections/auth/Login'
// import Login from '../../sections/auth/LoginAuth0';
import { useLocales } from 'src/locales'

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { translate } = useLocales()
  return (
    <>
      <Helmet>
        <title> {translate('Login')} | Sophie</title>
      </Helmet>

      <Login />
    </>
  )
}
