// @mui
import { styled } from '@mui/material/styles'

export const StyledRoot = styled('div')(() => ({
  position: 'relative',
  height: '100vh',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const StyledSection = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}))

export const StyledSectionBg = styled('div')(() => ({
  top: 0,
  left: 0,
  zIndex: -1,
  height: '100%',
  position: 'absolute',
  transform: 'scaleX(-1)',
}))

export const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  padding: theme.spacing(15, 2),
  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(0, 2, 2, 2),
    width: 343,
    height: 'fit-content',
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 2, 2, 2),
    width: 552,
    height: 'fit-content',
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3, 5, 4, 4),
    height: 'fit-content',
  },
  [theme.breakpoints.up('lg')]: {
    width: 552,
    height: 'fit-content',
  },
  [theme.breakpoints.up('xl')]: {
    marginLeft: 732,
    width: 552,
    height: 'fit-content',
  },
  background: '#fff',
  boxShadow: '0px 16px 48px rgba(20, 146, 187, 0.16)',
  borderRadius: '16px',
}))
