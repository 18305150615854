import { ArrowRightRounded } from '@mui/icons-material'
import { Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useResponsive from 'src/hooks/useResponsive'
import { ProductCard } from '../ProductCard'

interface ProductListProps {
  products: any[]
  title?: string
  seeMoreUrl?: string
}

export const ProductList = ({
  products,
  title,
  seeMoreUrl,
}: ProductListProps) => {
  const { t } = useTranslation()
  const isInMd = useResponsive('between', 'md', 'lg')

  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {!!title && (
          <Typography variant="h4" flexGrow={1}>
            {title}
          </Typography>
        )}
        {seeMoreUrl && (
          <Link to={seeMoreUrl}>
            <Typography component="span" color="primary.main" variant="body2">
              Xem tất cả
            </Typography>
            <ArrowRightRounded fontSize="large" color="primary" />
          </Link>
        )}
      </Stack>
      <Grid container spacing={2} pt={1} pb={10}>
        {products?.slice(0, isInMd ? 4 : 6)?.map((item, i) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={i}>
            <ProductCard item={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
