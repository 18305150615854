import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router'
import { AppFooter } from '../user/components'
import { Header } from './components'
import classes from './ShopOnlineLayout.module.less'

const ShopOnlineLayout: React.FC = () => {
  return (
    <div className={classes['shop-online']}>
      <Header />
      <div className={classes['shop-online__content']}>
        <Outlet />
      </div>
      <AppFooter />
    </div>
  )
}

export default ShopOnlineLayout
