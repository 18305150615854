import { APP_URL } from 'src/config'
import { TRoute } from 'src/@types/common'
import { lazy } from 'react'
import { Loadable } from 'src/routes/elements'
import AboutSophie from './AboutSophie.screen'
import { SophieHiring, SophieHiringDetail } from './Components'

// const AboutSophie = Loadable(lazy(() => import('./AboutSophie.screen')))
// const SophieHiring = Loadable(
//   lazy(() => import('./Components/SophieHiring/SophieHiring'))
// )
// const SophieHiringDetail = Loadable(
//   lazy(() => import('./Components/SophieHiringDetail/SophieHiringDetail'))
// )

export const AboutSophieRoutes: TRoute[] = [
  {
    path: APP_URL.ABOUT_SOPHIE,
    element: <AboutSophie />,
  },
  {
    path: APP_URL.SOPHIE_HIRING,
    element: <SophieHiring />,
  },
  {
    path: APP_URL.SOPHIE_HIRING_DETAIL,
    element: <SophieHiringDetail />,
  },
]
