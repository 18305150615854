import { Col } from 'antd'
import { ImageShoulder } from 'src/assets'
import React from 'react'
import { Link } from 'react-router-dom'
import classes from './index.module.less'

interface ItemData {
  subject: string
  subjectType: string
  images: any
  newsId: string
}

interface Reponsive {
  lg?: number
  md?: number
  xl?: number
  xs?: number
  index?: number
}

export type Props = {
  itemData: ItemData
  reponsive: Reponsive
}

const BlogItemBanner: React.FC<Props> = ({ itemData, reponsive }: Props) => {
  return (
    <Col
      lg={reponsive.lg}
      md={reponsive.md}
      xl={reponsive.xl}
      className={`${classes['top-new-item']}  ${
        classes[`item-${reponsive.index}`]
      }`}
    >
      <Link to={`/detail-news/${itemData.newsId}`}>
        <img src={itemData.images[0]} alt="category-item" />
        <div className={classes['item-content']}>
          <Col className={classes['item-title']}>
            <p className={classes['sub-title']}>{itemData.subjectType}</p>
            <p className={classes['title']}>{itemData.subject}</p>
          </Col>
        </div>
      </Link>
    </Col>
  )
}

export default BlogItemBanner
