import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { ProfileUserThemeProvider } from '../theme'
import './AddressList.module.less'
import { CreateOrUpdateAddressForm } from './components/CreateOrUpdateAddressForm'

export const CreateAddress = () => {
  const { t } = useTranslation()

  return (
    <ProfileUserThemeProvider>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <CreateOrUpdateAddressForm />
    </ProfileUserThemeProvider>
  )
}
