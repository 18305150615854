import { Box, Divider, Paper, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductDescription } from './ProductDescription'

interface ProductInfoProps {
  product: any
}

export const ProductInfo = ({ product }: ProductInfoProps) => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState('description')

  const TABS = [
    {
      value: 'description',
      label: t('productSection.productInfo'),
      component: <ProductDescription product={product} />,
    },
  ]

  return (
    <Paper>
      <Tabs
        value={currentTab}
        onChange={(event, newValue) => setCurrentTab(newValue)}
        sx={{
          px: 3,
          p: 0,
          '.MuiTabs-flexContainer': {
            width: '100%',
          },
          '.MuiTabs-indicator': {
            height: '3px',
            borderRadius: '10px 10px 0 0 ',
          },
          '.Mui-selected': {
            color: 'primary.main',
          },
        }}
      >
        {TABS.map(tab => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            sx={{ width: '100%' }}
          />
        ))}
      </Tabs>

      <Divider />

      {TABS.map(
        tab =>
          tab.value === currentTab && (
            <Box
              key={tab.value}
              sx={{
                p: 2,
              }}
            >
              {tab.component}
            </Box>
          )
      )}
    </Paper>
  )
}
