import { InstanceAxios } from '../config/axios/user'

export const createMomoPayShopPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/PayPatient/createMomoPayShop'
  return InstanceAxios.post(path, payload)
}

export const checkMomoPayShopPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/PayPatient/checkMomoPayShop'
  return InstanceAxios.post(path, payload)
}

export const createZaloPayShopPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/PayPatient/createZaloPayShop'
  return InstanceAxios.post(path, payload)
}

export const checkZaloPayShopPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/PayPatient/checkZaloPayShop'
  return InstanceAxios.post(path, payload)
}

export const createCodPayShopPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/PayPatient/codPaymentShop'
  return InstanceAxios.post(path, payload)
}
