import {
  Box,
  Button,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumericFormatCustom from 'src/components/common/NumericFormatCustom'
import { CustomSelect } from 'src/components/custom-input'
import { getPriceOptions } from 'src/pages/shop-online/common/constants'
import classes from './ProductFilter.module.less'

export type TProductFilterDesktopProps = {
  categories: any[]
  filter: any
  handleUpdateFilter: (newFilter: any) => void
}

export const ProductFilterDesktop: React.FC<TProductFilterDesktopProps> = ({
  categories,
  filter,
  handleUpdateFilter,
}: TProductFilterDesktopProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [localFilter, setLocalFilter] = useState({
    minPrice: filter?.minPrice,
    maxPrice: filter?.maxPrice,
    priceSelected: '',
  })

  const categorySelected = useMemo(() => {
    return categories?.find(item => item?.categoryId === filter?.categoryId)
  }, [filter?.categoryId, categories])

  const priceOptions = getPriceOptions(t)

  const handleUpdateLocalFilter = (newFilter: Record<string, any>) => {
    setLocalFilter((state: any) => ({ ...state, ...newFilter }))
  }

  const handleSelectPriceOption = (option: (typeof priceOptions)[0]) => {
    if (option?.label === localFilter?.priceSelected) {
      handleUpdateLocalFilter({ priceSelected: '' })
      handleUpdateFilter({ ...option, minPrice: '', maxPrice: '' })
    } else {
      handleUpdateLocalFilter({ priceSelected: option?.label })
      handleUpdateFilter({ ...option })
    }
  }

  const handleApplyPrice = () => {
    const { minPrice, maxPrice } = localFilter
    if (minPrice?.length > 0 && maxPrice?.length > 0 && +minPrice > +maxPrice) {
      return enqueueSnackbar(t('pharmacyOnline.minPriceMustLessThanMaxPrice'), {
        variant: 'error',
      })
    }
    handleUpdateFilter({
      minPrice: localFilter?.minPrice,
      maxPrice: localFilter?.maxPrice,
    })
    handleUpdateLocalFilter({ priceSelected: '' })
  }

  const minPriceValue = localFilter?.minPrice?.length
    ? +localFilter?.minPrice
    : ''
  const maxPriceValue = localFilter?.maxPrice?.length
    ? +localFilter?.maxPrice
    : ''

  return (
    <Paper sx={{ p: 2, bgcolor: 'white', mt: 3 }}>
      <Typography variant="subtitle1">
        {t('pharmacyOnline.category')}
      </Typography>
      <CustomSelect
        size="small"
        select
        fullWidth
        value={filter?.categoryId || 'All'}
        onChange={e => {
          const value = e.target.value === 'All' ? '' : e.target.value
          handleUpdateFilter({ categoryId: value, subCategoryId: '' })
        }}
        sx={{ mt: 1 }}
      >
        <MenuItem value="All">Tất cả</MenuItem>
        {categories.map((item: any, i: number) => (
          <MenuItem value={item?.categoryId} key={i}>
            {item?.categoryName}
          </MenuItem>
        ))}
      </CustomSelect>

      {filter?.categoryId && (
        <>
          <Typography variant="subtitle1" mt={2}>
            {t('pharmacyOnline.category')} {categorySelected?.categoryName}
          </Typography>
          <CustomSelect
            size="small"
            select
            fullWidth
            value={filter?.subCategoryId || 'All'}
            onChange={e => {
              const value = e.target.value === 'All' ? '' : e.target.value
              handleUpdateFilter({ subCategoryId: value })
            }}
            sx={{ mt: 1 }}
          >
            <MenuItem value="All">Tất cả</MenuItem>
            {categorySelected?.listSubCategory?.map((item: any, i: number) => (
              <MenuItem value={item?.subCategoryId} key={i}>
                {item?.subCategoryName}
              </MenuItem>
            ))}
          </CustomSelect>
        </>
      )}

      <Typography variant="subtitle1" mt={2}>
        {t('pharmacyOnline.price')}
      </Typography>
      <Box>
        <Stack spacing={1} mt={1}>
          {priceOptions?.map(({ label, ...item }, i) => (
            <div>
              <div
                className={`${classes['filter-price-chip']} ${
                  localFilter?.priceSelected === label ? classes['active'] : ''
                }`}
                key={i}
                onClick={() => handleSelectPriceOption({ label, ...item })}
              >
                {label}
              </div>
            </div>
          ))}
        </Stack>
        <Typography variant="caption" color="#576071" mt={2}>
          {t('pharmacyOnline.chooseRangePrice')}
        </Typography>

        <Stack direction="row" spacing={1}>
          <TextField
            size="small"
            placeholder="0₫"
            value={minPriceValue}
            onChange={e =>
              handleUpdateLocalFilter({
                minPrice: e.target.value,
                priceSelected: '',
              })
            }
            fullWidth
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
          />
          <TextField
            size="small"
            placeholder="0₫"
            value={maxPriceValue}
            onChange={e =>
              handleUpdateLocalFilter({
                maxPrice: e.target.value,
                priceSelected: '',
              })
            }
            fullWidth
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
          />
        </Stack>
        <Button
          variant="contained"
          fullWidth
          onClick={handleApplyPrice}
          sx={{
            backgroundColor: 'primary.lighter',
            color: 'primary.main',
            ':hover': {
              backgroundColor: 'primary.lighter',
            },
            mt: 1,
          }}
        >
          {t('pharmacyOnline.apply')}
        </Button>
      </Box>
    </Paper>
  )
}

export default ProductFilterDesktop
