export function toFileSizeReadable(num: number, fixed: number = 2) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let count = 0
  num = +num
  if (Number.isNaN(num)) {
    return '0'
  }
  while (num >= 1024) {
    num /= 1024
    count++
  }
  for (let i = 0; i < fixed; i++) {
    if (num === +num.toFixed(i)) return num.toFixed(i) + units[count]
  }
  return num.toFixed(2) + units[count]
}
