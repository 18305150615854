import { Box, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import { FC, Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useResponsive from 'src/hooks/useResponsive'
import { fNumber } from 'src/utils/formatNumber'
import { useCartContext } from '../../context'
import { ShippingUnitDialog } from './ShippingUnitDialog'
import { useGetTransportListServices } from 'src/api/hooks/patient.query'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import { calculateDeliveryTime, logDev } from 'src/utils'
import { useSnackbar } from 'src/components/snackbar'

export const ShippingUnit = () => {
  const { t } = useTranslation()
  const {
    transportServiceData,
    order,
    updateOrder,
    updateTransportServiceData,
  } = useCartContext()
  const isMd = useResponsive('up', 'md')
  const [isOpenSelectDialog, setOpenSelectDialog] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const { isAuthenticated } = useAuthUserContext()
  const getListServicesRequest = useGetTransportListServices()

  const transportListServices = getListServicesRequest?.data?.data || []

  const handleGetListTransport = async () => {
    if (!isAuthenticated) {
      return
    }
    var modelRequest = {
      addressId: order?.addressId ?? '',
      listProduct: order?.listProduct ?? [],
    }
    const res = await getListServicesRequest.mutateAsync(modelRequest)
    const selectedTransport = res?.data?.find(
      (item: any) => item?.servicE_CODE === order?.transportService
    )
    if (!selectedTransport) {
      const selectedTransport =
        res?.data?.find((item: any) => item?.isDefault) || res?.data?.[0]
      updateTransportServiceData({
        servicE_CODE: selectedTransport.servicE_CODE,
        servicE_NAME: selectedTransport.servicE_NAME,
        servicE_PRICE: selectedTransport.servicE_PRICE,
        servicE_TIME: selectedTransport.servicE_TIME,
      })
      order.transportService = selectedTransport.servicE_CODE
    } else {
      updateTransportServiceData({
        servicE_CODE: selectedTransport.servicE_CODE,
        servicE_NAME: selectedTransport.servicE_NAME,
        servicE_PRICE: selectedTransport.servicE_PRICE,
        servicE_TIME: selectedTransport.servicE_TIME,
      })
      order.transportService = selectedTransport.servicE_CODE
    }
    updateOrder({})
  }

  useEffect(() => {
    if (!!isAuthenticated) {
      if (
        order?.listProduct != null &&
        order?.listProduct.length > 0 &&
        order?.addressId !== ''
      ) {
        handleGetListTransport()
      } else {
        updateTransportServiceData({
          servicE_CODE: '',
          servicE_NAME: '',
          servicE_PRICE: 0,
          servicE_TIME: '',
        })
      }
    }
  }, [isAuthenticated, order?.addressId])

  return (
    <Paper sx={{ p: 2 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" color="#576071">
          {t('cart.shippingUnit')}
        </Typography>
        <Typography
          variant="subtitle2"
          color="#1A9CC6"
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            if (!order?.addressId) {
              enqueueSnackbar(t('cart.alertLackAddress'), { variant: 'error' })
              return
            }

            if (!order?.listProduct?.length) {
              enqueueSnackbar(t('cart.youNeedAddProductToCart'), {
                variant: 'error',
              })
              return
            }
            setOpenSelectDialog(true)
          }}
        >
          {t('cart.change')}
        </Typography>
      </Stack>
      {!!transportServiceData.servicE_CODE && (
        <Stack direction="column" alignItems="left" spacing={1} mt={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2" color="#576071">
              {t(transportServiceData?.servicE_NAME)}
            </Typography>
            {/* <Typography variant="body2" color="#576071">
              -
            </Typography>
            <Typography variant="h6" color="#F0224F">
              {fNumber(transportServiceData?.servicE_PRICE || 0)}₫
            </Typography> */}
          </Stack>
          <Typography fontWeight={400} variant="body2" color="#576071">
            {calculateDeliveryTime(transportServiceData?.servicE_TIME)}
          </Typography>
        </Stack>
      )}
      <ShippingUnitDialog
        open={isOpenSelectDialog}
        transportListServices={transportListServices}
        onClose={() => setOpenSelectDialog(false)}
      />
    </Paper>
  )
}
