import { Col, Row, Typography } from 'antd'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import { useNavigate } from 'react-router-dom'
import {
  IconChevronRight,
  ImageDoctorHuynhTrungHai,
  ImageService1,
  ImageService2,
  ImageService3,
  ImageService4,
} from 'src/assets'
import { APP_URL } from 'src/config'
import classes from './Service.module.less'

export const Service = () => {
  const { t } = useTranslation()

  return (
    <Row
      justify={{ md: 'center', lg: 'center', xl: 'space-between' }}
      className={classes['service-container']}
      gutter={[0, 30]}
    >
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={12}
        xxl={12}
        className={classes['img-doctor']}
        data-aos="fade-up"
      >
        <Row justify={'center'}>
          <LazyLoad once height={600}>
            <img src={ImageDoctorHuynhTrungHai} alt="" />
          </LazyLoad>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} data-aos="fade-up">
        <Row justify={'center'} gutter={[0, 0]}>
          <Row justify={{ xs: 'center' }}>
            <div
              className={classes['title']}
              dangerouslySetInnerHTML={{ __html: t('HOMEPAGE.SERVICE.TITLE') }}
            ></div>
          </Row>
          <RenderServiceItem
            Image={ImageService1}
            number={'01'}
            link={APP_URL.ONLINE_MEDICAL_BOOKING_PHONE}
            title={t('HOMEPAGE.SERVICE.PHONE')}
            color={'#1A9CC6'}
            subtitle={t('HOMEPAGE.SERVICE.SUB_PHONE')}
          />
          <RenderServiceItem
            Image={ImageService2}
            number={'02'}
            link={APP_URL.ONLINE_MEDICAL_BOOKING_HOME}
            title={t('HOMEPAGE.SERVICE.HOME')}
            color={'#A172D2'}
            subtitle={t('HOMEPAGE.SERVICE.SUB_HOME')}
          />
          <RenderServiceItem
            Image={ImageService3}
            number={'03'}
            link={APP_URL.ONLINE_MEDICAL_BOOKING_CLINIC}
            title={t('HOMEPAGE.SERVICE.CLINIC')}
            color={'#F44067'}
            subtitle={t('HOMEPAGE.SERVICE.SUB_CLINIC')}
          />
          <RenderServiceItem
            Image={ImageService4}
            number={'04'}
            link={APP_URL.ONLINE_MEDICAL_BOOKING_FAST_PHONE}
            title={t('HOMEPAGE.SERVICE.PHONE_FAST')}
            color={'#F5B11E'}
            subtitle={t('HOMEPAGE.SERVICE.SUB_PHONE_FAST')}
          />
        </Row>
      </Col>
    </Row>
  )
}

const RenderServiceItem = ({
  Image,
  number,
  color,
  title,
  subtitle,
  link,
}: any) => {
  const navigate = useNavigate()

  return (
    <Row
      onClick={() => navigate(link)}
      style={{
        padding: '20px 18px',
      }}
      className={clsx(classes['service'], classes['service-' + number])}
      justify={{
        xs: 'center',
        sm: 'center',
        md: 'center',
        lg: 'space-between',
      }}
      wrap={false}
    >
      <Row wrap={false} style={{ width: '85%', height: '100%' }}>
        <Col className={classes['icon']}>
          <LazyLoad once height={200}>
            <img src={Image} alt="" />
          </LazyLoad>
          <Typography.Text
            className={classes['number']}
            style={{ color: color }}
          >
            {number}
          </Typography.Text>
        </Col>
        <Col xs={16} sm={16} md={16} lg={20} xl={20} xxl={20}>
          <Row>
            <Typography.Text
              className={classes['item-title']}
              style={{ color: color }}
            >
              {title}
            </Typography.Text>
          </Row>
          <Row>
            <Typography.Text className={classes['item-subtitle']}>
              {subtitle}
            </Typography.Text>
          </Row>
        </Col>
      </Row>
      <Col span={2} className={classes['button']}>
        <img src={IconChevronRight} alt="" />
      </Col>
    </Row>
  )
}
