import { TRoute } from 'src/@types/common'
import { APP_URL } from 'src/config'
import { OrderTracking } from './OrderTracking.screen'
import { OrderTrackingDetail } from './OrderTrackingDetail.screen'

export const OrderTrackingRoutes: TRoute[] = [
  {
    path: APP_URL.USER_PROFILE_ORDER_TRACKING,
    element: <OrderTracking />,
  },
  {
    path: APP_URL.USER_PROFILE_ORDER_TRACKING_DETAIL,
    element: <OrderTrackingDetail />,
  },
]
