import { useState } from 'react'

import * as Yup from 'yup'
// form
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
// @mui
import { LoadingButton } from '@mui/lab'
import { Box, IconButton, InputAdornment, Typography } from '@mui/material'

import Iconify from 'src/components/iconify'
// components
import { useSnackbar } from 'notistack'
import { useResetPass } from 'src/api/hooks/admin.query'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import { useLocales } from 'src/locales'
import { useForgotPasswordContext } from '../context'
import { useResetPasswordPatient } from 'src/api/hooks/patient.query'

interface IAuthCreateNewPasswordProps {
  onSuccess?: VoidFunction
}

export default function AuthCreateNewPassword({
  onSuccess,
}: IAuthCreateNewPasswordProps) {
  const resetPassRequest = useResetPass()
  const resetPassPatientRequest = useResetPasswordPatient()
  const { forgotPasswordData } = useForgotPasswordContext()
  const { translate } = useLocales()
  const { enqueueSnackbar } = useSnackbar()

  const [showPassword, setShowPassword] = useState(false)
  const passwordRequired = translate(
    'createNewPasswordPage.pleaseInputNewPassword'
  )
  const passwordMin = translate('forgotPasswordPage.minPassword')
  const passwordMax = translate('forgotPasswordPage.maxPassword')
  const LoginSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(passwordRequired)
      .trim()
      .min(8, passwordMin)
      .max(20, passwordMax),
  })

  const defaultValues = {
    newPassword: '',
  }

  const methods = useForm<typeof defaultValues>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    reset,
    setError,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      const obj = {
        codeOtp: forgotPasswordData?.codeOtp,
        phoneNumber: forgotPasswordData?.phoneNumber,
        newPassword: data?.newPassword,
      }
      if (forgotPasswordData?.accountType === 'partner') {
        await resetPassRequest.mutateAsync({ data: obj })
      } else {
        await resetPassPatientRequest.mutateAsync(obj)
      }
      enqueueSnackbar(
        translate('forgotPasswordPage.alertChangePasswordSuccess')
      )
      onSuccess && onSuccess()
    } catch (error) {}
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="otpReceiver" />
      <Typography
        variant="subtitle2"
        color="#798395"
        sx={{
          marginBottom: { xs: '8px', md: 0 },
          marginTop: '8px',
        }}
      >
        {translate('createNewPasswordPage.newPassword')}
      </Typography>
      <RHFTextField
        name="newPassword"
        type={showPassword ? 'text' : 'password'}
        placeholder={translate('createNewPasswordPage.inputNewPassword') + ''}
        sx={{
          borderRadius: '8px',
          marginTop: '8px !important',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                <Iconify
                  icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Typography
        variant="body2"
        color="#798395"
        sx={{
          marginTop: '8px',
        }}
      >
        {translate('createNewPasswordPage.passwordValidation')}
      </Typography>
      <Box flexGrow={1}></Box>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{
          marginTop: '24px',
        }}
      >
        {translate('createNewPasswordPage.completed')}
      </LoadingButton>
    </FormProvider>
  )
}
