// @mui
import {
  Box,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material'
import { useLocales } from 'src/locales'
// types
import { ConfirmDialogProps } from './types'
import { CloseIcon } from '../icon/CloseIcon'

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  ...other
}: ConfirmDialogProps) {
  const { translate } = useLocales()

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle
        sx={{
          padding: '8px 16px 8px 24px ',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: '#0F8FB9',
        }}
      >
        {title}
        <IconButton
          onClick={onClose}
          sx={{
            '&:hover': {
              background: 'none',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {content && (
        <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>
      )}
      {action && (
        <DialogActions>
          {action}
          <Button variant="outlined" color="inherit" onClick={onClose}>
            {translate('cancel')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
