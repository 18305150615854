// import './AppHeader.scss'
import React from 'react'
import { Layout, Menu, MenuProps } from 'antd'
import { Link } from 'react-router-dom'
import { LogoSophie } from 'src/assets'
import classes from './Header.module.less'
import NavMenu from '../NavMenu/NavMenu'
import { Box } from '@mui/material'
import { HeaderRight } from './HeaderRight'

export type THeaderProps = {}

export const Header: React.FC<THeaderProps> = ({}: THeaderProps) => {
  return (
    <div className={classes['header-container']}>
      <div className={classes['header']}>
        <div className={classes['header__logo']}>
          <Link to="/">
            <img src={LogoSophie} alt="" />
          </Link>
        </div>
        <Box
          display={{ xs: 'none', lg: 'block' }}
          flexGrow={1}
          height="100%"
          px={3}
        >
          <NavMenu orientation="horizontal" />
        </Box>
        <HeaderRight />
      </div>
    </div>
  )
}
