import * as Yup from 'yup'
// form
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
// @mui
import { Box, Typography } from '@mui/material'
// themes
import { useTheme } from '@mui/material/styles'

// components
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { useSendOtp } from 'src/api/hooks/admin.query'
import { useCheckPhoneNumber } from 'src/api/hooks/doctorAuth.query'
import { useSendOtpPatient } from 'src/api/hooks/patient.query'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import { useLocales } from 'src/locales'
import { isEmail, isPhoneNumber } from 'src/utils/string'
import { useForgotPasswordContext } from '../context'
import { REGEX_PHONE_NUMBER } from './../../enum'

export default function AuthForgotPassword({
  hasSpaceInMobile = false,
}: {
  hasSpaceInMobile?: boolean
}) {
  const theme = useTheme()
  const sendOtpRequest = useSendOtp()
  const sendOtpPatientRequest = useSendOtpPatient()
  const { translate } = useLocales()
  const { nextStep, forgotPasswordData, updateForgotPasswordData } =
    useForgotPasswordContext()
  const checkPhoneNumber = useCheckPhoneNumber()
  const { enqueueSnackbar } = useSnackbar()

  const phoneRequiredMessage = translate('forgotPasswordPage.phone_required')
  const warningEmail = translate('forgotPasswordPage.email_warning')
  const phoneNumberFormat = translate('alertSection.phoneNumberWrongFormat')

  const LoginSchema = Yup.object().shape({
    otpReceiver: Yup.string().required(phoneRequiredMessage),
  })

  const defaultValues = {
    otpReceiver: '',
    typeOtp: 'ForgotPass',
  }

  const methods = useForm<typeof defaultValues>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    reset,
    setError,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      if (!isPhoneNumber(data?.otpReceiver) && !isEmail(data?.otpReceiver)) {
        setError('otpReceiver', {
          type: 'string',
          message: phoneNumberFormat,
        })
        return
      }
      if (isEmail(data?.otpReceiver)) {
        setError('otpReceiver', {
          type: 'string',
          message: warningEmail,
        })
        return
      }
      const phoneNumber = '+84' + data.otpReceiver?.replace(/^(0|\+84)/g, '')
      // await checkAuthAccountRequest.mutateAsync({
      //   data: { phoneNumber: phoneNumber },
      // })

      const checkRes: any = await checkPhoneNumber.mutateAsync(phoneNumber)
      if (!checkRes?.isCheck) {
        return enqueueSnackbar(
          translate('forgotPasswordPage.phoneNumberNotExist'),
          {
            variant: 'error',
          }
        )
      }

      const object = {
        typeOtp: data?.typeOtp,
        email: REGEX_PHONE_NUMBER.test(data?.otpReceiver)
          ? ''
          : data?.otpReceiver?.toLowerCase(),
        phoneNumber: phoneNumber,
      }
      if (forgotPasswordData?.accountType === 'partner') {
        await sendOtpRequest.mutateAsync({ data: object })
      } else {
        await sendOtpPatientRequest.mutateAsync(object as any)
      }

      updateForgotPasswordData({
        phoneNumber: phoneNumber,
      })

      // await onSendOtp(data)
      nextStep()
    } catch (error) {
      reset()
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box
        display="flex"
        flexDirection="column"
        height={{
          xs: hasSpaceInMobile ? 'calc(100vh - 150px)' : 'auto',
          sm: 'auto',
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {translate('forgotPasswordPage.validate_field')}
        </Typography>
        <Typography
          variant="subtitle2"
          color="#798395"
          sx={{
            marginBottom: { xs: '8px', md: 0 },
            marginTop: '8px',
          }}
        >
          {translate('phone_or_email_register')}
        </Typography>
        <RHFTextField
          variant="outlined"
          fullWidth
          name="otpReceiver"
          placeholder={translate('input_phone_or_email') + ''}
          sx={{
            borderRadius: '8px',
            marginTop: '8px !important',
            background: '#F4F6F8',
          }}
        />

        {hasSpaceInMobile && <Box flexGrow={1}></Box>}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{
            marginTop: { xs: '16px', sm: '16px' },
          }}
        >
          {translate('forgotPasswordPage.send_otp')}
        </LoadingButton>
      </Box>
    </FormProvider>
  )
}
