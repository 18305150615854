import React from 'react'
import classes from './WrapPolicy.module.less'
import { APP_URL } from 'src/config'
import { Col, Row, Typography } from 'antd'
import { IconRectangle1203 } from 'src/assets'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

export const WrapPolicy: React.FC<{ children: any }> = ({ children }) => {
  const { t } = useTranslation()

  return (
    <div className={classes['row-detail']}>
      <Grid
        container
        spacing={4}
        justifyContent={{
          lg: 'space-between',
          md: 'space-between',
          sm: 'start',
        }}
      >
        <Grid item xs={12} md={8} className={classes['list-item']}>
          {children}
        </Grid>
        <Grid item className={classes['right-content']} xs={12} md={4}>
          <div className={classes['list-category-title']}>
            <h3 className={classes['title']}>{t('POLICY.TITLE')}</h3>
            <div className={classes['list-options']}>
              <RenderLabelFooter
                link={APP_URL.POLICY_GENERAL}
                text={t('FOOTER.GENERAL')}
              />
              <RenderLabelFooter
                link={APP_URL.POLICY_SERCURITY}
                text={t('POLICY.SECURITY')}
              />
              <RenderLabelFooter
                link={APP_URL.POLICY_SHIP}
                text={t('POLICY.SHIP')}
              />
              <RenderLabelFooter
                link={APP_URL.POLICY_SENDBACK}
                text={t('POLICY.MEDICIN')}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
const RenderLabelFooter = ({ text, link = '' }: any) => {
  return (
    <Link to={`${link}`}>
      <Row justify={{ lg: 'start' }} align="middle" wrap={false}>
        <div className={classes['icon-rectangle']}>
          <img src={IconRectangle1203} alt="" />
        </div>
        <Typography.Text className={classes['option-text']}>
          {text}
        </Typography.Text>
      </Row>
    </Link>
  )
}
