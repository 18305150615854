import { InstanceAxios } from '../config/axios/user'
import { REST_DASHBOARD_SYS } from '../api_paths'

export const getTopNews = async (payload: any, params: any): Promise<any> => {
  const route: string = REST_DASHBOARD_SYS.GET_LIST_TOP_NEWS.uri

  return InstanceAxios.post(route, payload, { params })
}

export const getListCategoryNews = async (payload: any): Promise<any> => {
  const route: string = REST_DASHBOARD_SYS.GET_LIST_CATEGORY_NEWS.uri

  return InstanceAxios.get(route, payload)
}

export const getSearchNews = async (
  payload: any,
  params: any
): Promise<any> => {
  const route: string = REST_DASHBOARD_SYS.GET_SEARCH_NEWS.uri

  return InstanceAxios.post(route, payload, { params })
}
