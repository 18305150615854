import { ArrowDropDownRounded } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import Image from 'src/components/image/Image'
import { getMenu } from '../../constants'
import { ConfirmLogoutDialog } from '../ConfirmLogoutDialog'
import classes from './AccountInfo.module.less'

export const AccountInfoWithDropdownMenu = () => {
  const { t } = useTranslation()
  const { profile } = useAuthUserContext()
  const [isOpenConfirmLogoutModal, setOpenConfirmLogoutModal] = useState(false)

  const menus = getMenu(t)

  return (
    <div className={classes['account']}>
      <Stack direction="row" alignItems="center" spacing={1} width="180px">
        <Image
          src="/assets/shop-online/ic-avatar.png"
          alt=""
          sx={{ width: '40px' }}
        />
        <Stack flexGrow={1}>
          <Typography variant="caption" color="#576071">
            Tài khoản
          </Typography>
          <Typography variant="h6" color="primary.main">
            {profile?.lastname}
          </Typography>
        </Stack>
        <ArrowDropDownRounded />
      </Stack>
      <div className={classes['account-popover']}>
        <ul>
          {menus.map((item, i) => (
            <li key={i}>
              <Link to={item?.url}>
                <img src={item?.icon} alt="" />
                <p>{item?.label}</p>
              </Link>
            </li>
          ))}
          <li onClick={() => setOpenConfirmLogoutModal(true)}>
            <div>
              <img src="/assets/images/header/ic-sign-in.png" alt="" />
              <p>Đăng xuất</p>
            </div>
          </li>
        </ul>
      </div>
      <ConfirmLogoutDialog
        open={isOpenConfirmLogoutModal}
        onClose={() => setOpenConfirmLogoutModal(false)}
      />
    </div>
  )
}
