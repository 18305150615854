import { Box, IconButton, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import Slider from 'react-slick'
import Image from 'src/components/image/Image'
import Lightbox from 'src/components/lightbox/Lightbox'
import { isImage, isVideo } from 'src/utils/file'
import classes from './ImageSlider.module.less'
import './ImageSlider.less'
import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from '@mui/icons-material'

interface ImageSliderProps {
  product: any
}

export const ImageSlider = ({ product }: ImageSliderProps) => {
  const [slick, setSlick] = useState<Slider>()
  const [slickNav, setSlickNav] = useState<Slider>()
  const [currentIndex, setCurrentIndex] = useState(0)

  const [selectedImage, setSelectedImage] = useState<number>(-1)

  const images: any[] = product?.productImages || []

  let thumb = images?.find((item: string) => isImage(item))
  if (!thumb) {
    thumb = images?.[0]
  }
  const imagesLightbox: any[] = images.map(img =>
    isImage(img)
      ? { src: img + '?timestamp=' + Date.now(), description: '' }
      : {
          type: 'video',
          width: 1280,
          height: 720,
          poster: thumb + '?timestamp=' + Date.now(),
          sources: [
            {
              src: img,
              type: 'video/mp4',
            },
          ],
        }
  )

  const handleOpenLightbox = (imageUrl: string) => {
    const imageIndex = imagesLightbox.findIndex(
      image => image.src === imageUrl || image?.sources?.[0]?.src === imageUrl
    )
    setSelectedImage(imageIndex)
  }

  const handleCloseLightbox = () => {
    setSelectedImage(-1)
  }

  const slickSetting: any = {
    ref: setSlick,
    asNavFor: slickNav,
    arrows: false,
    dots: false,
    navigator: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const slickSettingNav: any = {
    ref: setSlickNav,
    asNavFor: slick,
    arrows: false,
    dots: false,
    navigator: false,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    focusOnSelect: true,
    slidesToShow: 6,
  }

  // useEffect(() => {
  //   slick?.slickGoTo(0)
  //   slickNav?.slickGoTo(0)
  // }, [images])

  useEffect(() => {
    setTimeout(() => {
      const a: NodeListOf<HTMLDivElement> = document.querySelectorAll(
        `.${classes['image-slider']} .slick-track`
      )
      for (let i = 0; i < a.length; i++) {
        const el: HTMLDivElement = a[i]
        el.style.transform = 'translate3d(0px, 0px, 0px)'
      }
    }, 0)
  }, [images])

  return (
    <div className={classes['image-slider']}>
      <Slider {...slickSetting}>
        {images.map((item, i) => (
          <div onClick={() => handleOpenLightbox(item)} key={i}>
            {isVideo(item) ? (
              <video className={classes['product-image']}>
                <source
                  src={item + '?timestamp=' + Date.now()}
                  type="video/mp4"
                />
              </video>
            ) : (
              <img
                className={classes['product-image']}
                src={item + '?timestamp=' + Date.now()}
                alt=""
              />
            )}
            {/* <Image src={item} /> */}
          </div>
        ))}
      </Slider>
      <div className={classes['slider-container']}>
        {images?.length > 5 && (
          <IconButton
            disableRipple
            className={classes['button-prev']}
            onClick={() => {
              slick?.slickPrev()
              slickNav?.slickPrev()
            }}
          >
            <ArrowBackIosNewRounded />
          </IconButton>
        )}
        <Slider {...slickSettingNav} className="product-thumb-container">
          {images.map((item, i) => (
            <div className="thumb" key={i}>
              {isVideo(item) ? (
                <video className={classes['product-image']}>
                  <source
                    src={item + '?timestamp=' + Date.now()}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  className={classes['product-image']}
                  src={item + '?timestamp=' + Date.now()}
                  alt=""
                />
              )}
            </div>
          ))}
        </Slider>
        {images?.length > 5 && (
          <IconButton
            disableRipple
            className={classes['button-next']}
            onClick={() => {
              slick?.slickNext()
              slickNav?.slickNext()
            }}
          >
            <ArrowForwardIosRounded />
          </IconButton>
        )}
      </div>
      <Lightbox
        index={selectedImage}
        slides={imagesLightbox}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
        onGetCurrentIndex={index => setCurrentIndex(index)}
      />
    </div>
  )
}
