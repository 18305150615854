import React from 'react'
import classes from './SophieHiring.module.less'
import { APP_URL } from 'src/config'
import { AboutDoctorHR } from 'src/assets'
import { Button, Col, Row, Typography } from 'antd'
import { SearchBar } from '../SearchBar'
import { generatePath, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

export type TSophieHiringProps = {}

export const SophieHiring: React.FC<
  TSophieHiringProps
> = ({}: TSophieHiringProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Row>
      <Row
        justify={{ md: 'start', lg: 'center' }}
        className={classes['about-container']}
      >
        <Col>
          <Row className={classes['about']}>
            <Row
              align={'middle'}
              justify={'space-around'}
              className={classes['part-1']}
              gutter={[0, 60]}
            >
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={11}
                xxl={11}
                data-aos="fade-right"
              >
                <img
                  src={AboutDoctorHR}
                  alt=""
                  className={classes['img-doctor-about']}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={11}
                xxl={11}
                data-aos="fade-left"
              >
                <Row gutter={[0, 30]}>
                  <Col>
                    <Row justify={{ lg: 'center', xl: 'start' }}>
                      <Typography.Text className={classes['title']}>
                        {t('SOPHIE_HIRING.PART_1.TITLE')}
                      </Typography.Text>
                    </Row>
                    <Row justify={{ lg: 'center', xl: 'start' }}>
                      <Typography.Text className={classes['subtitle']}>
                        {t('SOPHIE_HIRING.PART_1.SUBTITLE')}
                      </Typography.Text>
                    </Row>
                    <Row justify={{ lg: 'center', xl: 'start' }}>
                      <Typography.Text className={classes['paragraph']}>
                        {t('SOPHIE_HIRING.PART_1.PARAGRAPH')}
                      </Typography.Text>
                    </Row>
                  </Col>
                  <Col>
                    <Row justify={{ lg: 'center', xl: 'start' }}>
                      <Typography.Text className={classes['title']}>
                        {t('SOPHIE_HIRING.PART_1.TITLE_1')}
                      </Typography.Text>
                    </Row>
                    <Row justify={{ lg: 'center', xl: 'start' }}>
                      <Typography.Text className={classes['subtitle']}>
                        {t('SOPHIE_HIRING.PART_1.SUBTITLE_1')}
                      </Typography.Text>
                    </Row>
                    <Row justify={{ lg: 'center', xl: 'start' }}>
                      <Typography.Text className={classes['paragraph']}>
                        {t('SOPHIE_HIRING.PART_1.PARAGRAPH_1')}
                      </Typography.Text>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>

      <Row align={'middle'} justify={'center'} className={classes['part-2']}>
        <Col xs={24}>
          <Row justify={'center'}>
            <Typography.Text className={classes['title']}>
              {t('SOPHIE_HIRING.PART_2.TITLE')}
            </Typography.Text>
          </Row>
          <Row>
            <Col span={23} className={classes['search-bar']}>
              <SearchBar
                placeholder={t('SOPHIE_HIRING.PART_2.PLACEHOLDER') || ''}
              />
            </Col>
          </Row>
          <Col span={24} className={classes['item-container']}>
            {Array.from({ length: 5 }).map((item, index) => (
              <Col key={index}>
                <Row
                  justify={'space-between'}
                  align={'middle'}
                  className={classes['item']}
                  gutter={[0, 15]}
                  onClick={() =>
                    navigate(
                      generatePath(APP_URL.ABOUT_SOPHIE, {
                        id: index,
                      })
                    )
                  }
                >
                  <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                    <Row>
                      <Typography.Text className={classes['label']}>
                        {t('SOPHIE_HIRING.PART_2.LABEL')}
                      </Typography.Text>
                    </Row>
                    <Row gutter={[20, 20]}>
                      <Col xs={24} sm={24} md={24} lg={6}>
                        <Typography.Text className={classes['sublabel']}>
                          {t('SOPHIE_HIRING.PART_2.PLACE')}{' '}
                          <span>Hồ Chí Minh</span>
                        </Typography.Text>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={5}>
                        <Typography.Text className={classes['sublabel']}>
                          {t('SOPHIE_HIRING.PART_2.QUANTITY')}{' '}
                          <span>2 vị trí</span>
                        </Typography.Text>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={6}>
                        <Typography.Text className={classes['sublabel']}>
                          {t('SOPHIE_HIRING.PART_2.DATE')}{' '}
                          <span>12 Th4, 2022</span>
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
                    <Row justify={'end'}>
                      <Button type="primary" className={classes['btn']}>
                        <Typography.Text className={classes['text']}>
                          {t('SOPHIE_HIRING.PART_2.DETAIL')}
                        </Typography.Text>
                      </Button>
                    </Row>
                  </Col>
                </Row>
                {index !== 4 && (
                  <Row className={classes['border-bottom']}></Row>
                )}
              </Col>
            ))}
          </Col>
        </Col>
      </Row>
    </Row>
  )
}

export default SophieHiring
