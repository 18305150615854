import {
  CheckCircleRounded,
  StarRounded,
  ThumbUpRounded,
} from '@mui/icons-material'
import {
  Button,
  Divider,
  Grid,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetListStatusTreeTransportAccount } from 'src/api/hooks/account.query'
import {
  useCancelOrderPatient,
  useGetOrderHistoryDetailPatient,
} from 'src/api/hooks/patient.query'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import Image from 'src/components/image/Image'
import Label from 'src/components/label/Label'
import { paymentMethodDetail } from 'src/pages/shop-online/Cart/types/pay'
import { fNumber } from 'src/utils/formatNumber'
import { format } from 'date-fns'
import { ProductRow } from './components/ProductRow'
import { TransportStatusLabel } from './components/TransportStatusLabel'
import classes from './OrderTracking.module.less'
import { TransportStatus } from 'src/constants/constants'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import ConfirmDialog from 'src/components/confirm-dialog/ConfirmDialog'

export const OrderTrackingDetail = () => {
  const { profile } = useAuthUserContext()
  const { id = '' } = useParams()

  const getOrderHistoryDetailRequest = useGetOrderHistoryDetailPatient()
  const getTransportTree = useGetListStatusTreeTransportAccount()
  const cancelOrderRequest = useCancelOrderPatient()
  const { enqueueSnackbar } = useSnackbar()
  const [isOpenCancelDialog, setOpenCancelDialog] = useState(false)

  const order: any = getOrderHistoryDetailRequest.data?.data || {}
  const transportTree: any[] = getTransportTree.data?.data?.reverse() || []

  const isCanCancelOrder =
    order.typePay == 'COD' &&
    [TransportStatus.Created, TransportStatus.Verified].includes(
      order.statusTransport
    )

  const { t } = useTranslation()

  const handleCancelOrder = async () => {
    if (!isCanCancelOrder) {
      return
    }
    await cancelOrderRequest.mutateAsync({
      accountId: profile?.accountId,
      orderId: id,
    })
    enqueueSnackbar(t('userProfile.orderTracking.alertCancelOrderSuccess'))
    setOpenCancelDialog(false)
    getOrderHistoryDetailRequest.mutateAsync({
      accountId: profile?.accountId,
      orderId: id,
    })
  }

  useEffect(() => {
    if (profile?.accountId && id) {
      getOrderHistoryDetailRequest.mutate({
        accountId: profile?.accountId,
        orderId: id,
      })

      getTransportTree.mutate({
        accountId: profile?.accountId,
        orderId: id,
      })
    }
  }, [id, profile?.accountId])

  const RenderPaymentStatus = () => {
    if (order?.typePay === 'COD') {
      return <></>
    }
    if (order?.typeStatusOrder === 'Failed') {
      return (
        <Typography variant="caption" fontWeight={700} color="error.main">
          {t('cart.payFailed')}
        </Typography>
      )
    }
    if (order?.typeStatusOrder === 'Success') {
      return (
        <>
          <CheckCircleRounded color="success" fontSize="small" />
          <Typography variant="caption" fontWeight={700} color="success.main">
            {t('cart.paySuccess')}
          </Typography>
        </>
      )
    }
    return <></>
  }

  return (
    <div>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <div>
        <Typography variant="h4" fontWeight={700}>
          {t('userProfile.orderTracking.orderDetail')}: #
          {order?.orderId?.slice(0, 8)}
          {'...'}
        </Typography>
      </div>

      <Paper sx={{ mt: 4, borderRadius: '16px', p: 3 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">
            {t('userProfile.orderTracking.orderInfo')}
          </Typography>
          <TransportStatusLabel
            transportStatus={order?.statusTransport}
            display={{ xs: 'none', sm: 'flex' }}
          />
        </Stack>

        <TransportStatusLabel
          className={classes['status-transport']}
          transportStatus={order?.statusTransport}
          display={{ xs: 'inline-flex', sm: 'none' }}
        />

        <Grid container spacing={2} pt={3}>
          <Grid item xs={12} lg={6}>
            <Typography variant="body1" color="#576071">
              {t('userProfile.orderTracking.paymentInfo')}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1} mt={1}>
              <Image
                src={paymentMethodDetail?.[order?.typePay]?.icon}
                alt=""
                sx={{ width: 24 }}
              />
              <Typography variant="subtitle2" color="#576071">
                {t(paymentMethodDetail?.[order?.typePay]?.translateKey)}
              </Typography>
              <RenderPaymentStatus />
            </Stack>
            <Typography variant="body1" color="#576071" mt={3}>
              {t('userProfile.orderTracking.paymentInfo')}
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              {order?.accountName} - {order?.phoneAccount?.replace('+84', '0')}
            </Typography>
            <Typography variant="body2" color="#29313F" mt={1}>
              {order?.typeAddress === 'Home'
                ? t('cart.homePlace')
                : t('cart.workPlace')}
              :&nbsp;
              {order?.addressAccount}
            </Typography>
            <Typography variant="body1" color="#576071" mt={3}>
              {t('userProfile.orderTracking.shop')}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Image
                src="/assets/shop-online/shop-1.png"
                alt=""
                sx={{ width: '48px' }}
              />
              <Stack spacing={1}>
                <Typography variant="h6" lineHeight="1.25">
                  {order?.shopName}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1} mt={1}>
                  <Typography variant="subtitle1" color="#F5B11E">
                    4.8
                  </Typography>
                  <StarRounded color="warning" />
                  <Label
                    color="primary"
                    sx={{ borderRadius: '9999px !important' }}
                  >
                    <ThumbUpRounded sx={{ fontSize: '12px' }} />
                    &nbsp;
                    <Typography
                      variant="caption"
                      color="primary.main"
                      fontWeight={700}
                    >
                      {t('userProfile.orderTracking.reputationShop')}
                    </Typography>
                  </Label>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="body1" color="#576071">
              {t('userProfile.orderTracking.orderManage')}:
            </Typography>
            {transportTree.length ? (
              <Stepper
                activeStep={0}
                orientation="vertical"
                sx={{
                  mt: 2,
                  '.MuiStepConnector-line': {
                    borderLeftWidth: '3px',
                    borderLeftColor: '#E5E9F0',
                  },
                  '.MuiStepContent-root': {
                    borderLeftWidth: '3px',
                    borderLeftColor: '#E5E9F0',
                  },
                }}
              >
                {[...transportTree].map((step, i) => (
                  <Step expanded key={step.label}>
                    <StepLabel
                      sx={{
                        '.MuiStepIcon-text': { fill: 'transparent' },
                        '.MuiSvgIcon-root': {
                          width: '16px',
                          margin: '5.5px',
                        },
                        '.MuiStepIcon-root': {
                          color: '#E5E9F0',
                          '&.Mui-active': {
                            color: '#0F8FB9',
                            position: 'relative',
                          },
                        },
                        '.MuiStepLabel-iconContainer.Mui-active': {
                          position: 'relative',
                        },
                        '.MuiStepLabel-iconContainer.Mui-active::before': {
                          position: 'absolute',
                          top: '5.5px',
                          left: '1.5px',
                          content: '""',
                          width: '24px',
                          height: '24px',
                          backgroundColor: '#E0F3F7',
                          borderRadius: '20px',
                        },
                      }}
                    >
                      {i === 0 ? (
                        <Typography variant="subtitle1">
                          {step.title}
                        </Typography>
                      ) : (
                        <Typography variant="subtitle1" color="#B6BDCB">
                          {step.title}
                        </Typography>
                      )}
                    </StepLabel>
                    <StepContent>
                      <Typography variant="body1" color="#798395">
                        {format(new Date(step?.dateTime), 'mm:HH dd-MM:yyyy')}
                      </Typography>
                      <Typography variant="body1" color="#798395">
                        {step.description}
                      </Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            ) : (
              <Stack spacing={1} textAlign="center" alignItems="center" py={8}>
                <Image src="/assets/shop-online/paper-1.png" alt="" />
                <Typography variant="subtitle1" color="#B6BDCB">
                  {t('userProfile.orderTracking.noOrderStatus')}
                </Typography>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ mt: 4, borderRadius: '16px', p: 3 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">
            {t('userProfile.orderTracking.orderDetail')}
          </Typography>
          <Typography variant="body1">
            {t('userProfile.orderTracking.totalProducts', {
              number: order?.listProduct?.length || 0,
            })}
          </Typography>
        </Stack>
        <Box>
          {order?.listProduct?.map((item: any, i: number) => (
            <Fragment key={i}>
              <ProductRow product={item} />
              <Divider sx={{ borderColor: '#f0f3f8' }} />
            </Fragment>
          ))}
        </Box>
        <Grid container pt={3}>
          <Grid item xs={12} lg={7}>
            <Typography variant="h5">
              {t('userProfile.orderTracking.paymentDetail')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1">
                  {t('userProfile.orderTracking.initialFee')}
                </Typography>
                <Typography variant="subtitle1" color="#576071">
                  {fNumber(
                    order?.price +
                      (order?.transportPromotionPrice + order?.promotionPrice) -
                      order?.transportPrice
                  )}{' '}
                  đ
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1">
                  {t('userProfile.orderTracking.shippingFee')}
                </Typography>
                <Typography variant="subtitle1" color="#576071">
                  {fNumber(order?.transportPrice || 0)} đ
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1">
                  {t('userProfile.orderTracking.discount')}
                </Typography>
                <Typography variant="subtitle1" color="#576071">
                  {fNumber(
                    order?.transportPromotionPrice + order?.promotionPrice || 0
                  )}{' '}
                  đ
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle1" color="primary.main">
                  {t('userProfile.orderTracking.total')}
                </Typography>
                <Typography variant="subtitle1" color="error.main">
                  {fNumber(order?.price || 0)} đ
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        {isCanCancelOrder && (
          <LoadingButton
            loading={cancelOrderRequest.isLoading}
            variant="contained"
            sx={{
              whiteSpace: 'nowrap',
              backgroundColor: 'primary.lighter',
              color: 'primary.main',
              textTransform: 'none',
              ':hover': {
                backgroundColor: 'primary.lighter',
              },
              mt: 3,
            }}
            fullWidth
            onClick={() => setOpenCancelDialog(true)}
          >
            {t('userProfile.orderTracking.cancelOrder')}
          </LoadingButton>
        )}
      </Paper>
      <ConfirmDialog
        open={isOpenCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        title={t(`userProfile.orderTracking.cancelOrder`)}
        content={t(`userProfile.orderTracking.confirmCancelOrder`)}
        action={
          <LoadingButton
            variant="contained"
            loading={cancelOrderRequest.isLoading}
            color="error"
            onClick={handleCancelOrder}
          >
            {t(`yes`)}
          </LoadingButton>
        }
      />
    </div>
  )
}
