import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  useGetListDashboardGuest,
  useGetListNews, useGetTopPartner
} from 'src/api/hooks/guestDashboard.query'
import {
  useGetListCategoryPatient,
  useGetListPromotionPatient,
  useGetSListSpecialProductPatient
} from 'src/api/hooks/patient.query'
import { BackgroundOrange } from 'src/assets'
import {
  BlogHealth,
  Introduce,
  ProductCategory,
  ProductPromotionInDay,
  Promotion,
  RenderHospitalIcon,
  Service,
  ServiceOffline,
  SophieConfident,
  TopPartner
} from './components'
import classes from './HomePage.module.less'

export type THomePageProps = {}

export const HomePage: React.FC<THomePageProps> = ({}: THomePageProps) => {
  const news = useGetListNews(
    {
      version: 'v1.0',
      subjectType: 'Sức khoẻ hàng ngày',
    },
    { skip: 0, limit: 6 }
  )

  const promotionRequest = useGetListPromotionPatient()

  const partner = useGetTopPartner({
    version: 'v1.0',
  })

  const categoryRequest = useGetListCategoryPatient()

  const productSaleOff = useGetSListSpecialProductPatient()

  const dashboardGuest = useGetListDashboardGuest({
    version: 'v1.0',
  })

  const listNews = () => {
    if (news.isSuccess) {
      return news.data
    }
  }

  const listPromotion = promotionRequest?.data?.data || []

  const listPartner = () => {
    if (partner.isSuccess) {
      return partner?.data?.data
    }
  }

  const listCategory = () => {
    if (categoryRequest.isSuccess) {
      return categoryRequest?.data
    }
  }

  const listProductSaleOff = () => {
    if (productSaleOff.data) {
      return productSaleOff.data
    }
  }

  const dataDashboardGuest = () => {
    if (dashboardGuest.data) {
      return dashboardGuest.data
    }
  }

  useEffect(() => {
    promotionRequest.mutate({})
    categoryRequest.mutate({})
    productSaleOff.mutate({})
  }, [])

  return (
    <div className={classes['home-page']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <Row className={classes['introduce']}>
        <Introduce />
      </Row>
      <RenderHospitalIcon />
      <Service />
      <ServiceOffline />

      <Row
        justify={{ md: 'start', lg: 'center' }}
        className={classes['orange-background']}
        style={{
          backgroundImage: `url(${BackgroundOrange})`,
        }}
      >
        <Col span={24}>
          <SophieConfident data={dataDashboardGuest() || {}} />
        </Col>
        <TopPartner data={listPartner() || []} />
      </Row>
      <Col className={classes['product-promotion-container']}>
        <ProductCategory data={listCategory() || []} />
        <ProductPromotionInDay data={listProductSaleOff()?.data || []} />
        <Promotion data={listPromotion} />
      </Col>
      <BlogHealth data={listNews()?.data || []} />
    </div>
  )
}

export default HomePage
