import { useAuthContext } from '../../auth/useAuthContext'
import { RootState, useSelector } from '../../redux/store'
import {
  setIsOpenDialog,
  setDialogNameStore,
  setBackground,
} from '../../redux/slices/dialog'
import { trim } from 'lodash'
import { DialogNames } from './enum'
import { TypeLogin } from './enum'
import { isPhoneNumber } from 'src/utils/string'

export default function useAuthLoginForm() {
  const { login } = useAuthContext()
  const { isOpenDialog } = useSelector(
    (state: RootState) => state.dialog
  ) as any

  const handleClickOpen = () => {
    setDialogNameStore(DialogNames.FORGOT_PASSWORD)
    setIsOpenDialog(true)
    setBackground(true)
  }
  const handleLogin = async (data: any) => {
    const isNumber = Number(data?.userName)
    const isPhone = isPhoneNumber(data?.userName)
    const userName = isPhone ? '+' + isNumber : data?.userName
    const password = data?.password

    const phoneNumber = '+84' + data.userName?.replace(/^(0|84|\+84)/g, '')

    const object = {
      typeLogin: isPhone ? TypeLogin.PHONE : TypeLogin.EMAIL,
      userName: isPhone ? '' : userName.toLowerCase(),
      phoneNumber: isPhone ? phoneNumber : '',
      password: password,
    }

    await login(object)
  }

  return {
    isOpenDialog,
    handleClickOpen,
    handleLogin,
  }
}
