import { Button, Col, Layout, Menu, MenuProps, Row } from 'antd'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Outlet, useNavigate } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import {
  IconChevronDown,
  IconClose,
  IconEqual,
  ImageAppStore,
  ImageGooglePlay,
  LogoSophie,
} from 'src/assets'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import {
  APP_URL,
  LINK_DOWNLOAD_APP_DOCTOR_PLAY,
  LINK_DOWNLOAD_DOCTOR_STORE,
} from 'src/config'
import LoginDialogFullscreenMobile from 'src/sections/auth/login-user/LoginUserDialogFullscreenMobile'
import {
  AccountInfoSection,
  RegisterLoginButton,
} from '../shop-online/components'
import { AccountInfoWithDropdownMenu } from '../shop-online/components/AccountInfo'
import { AppFooter, DownloadBottom, Header } from './components'
import classes from './MainLayout.module.less'

const MainLayout: React.FC = () => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuthUserContext()
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const [isOpenLoginDialog, setOpenLoginDialog] = useState<boolean>(false)

  const indentLabelMenuHorizontal = collapsed ? { margin: -20 } : {}

  const onChangeCollapsed = (boolean: boolean) => {
    if (boolean) {
      document.body.style.overflowY = 'scroll'
    } else {
      document.body.style.overflowY = 'hidden'
    }
    setCollapsed(boolean)
  }

  const RenderMenuLabel = (label: string, useDropdown?: boolean) => {
    return (
      <Row justify={'space-between'} className="header-menu-label" wrap={false}>
        <Col className="label">{label}</Col>
        {useDropdown && (
          <Col className={classes.icon}>
            <img src={IconChevronDown} alt="" />
          </Col>
        )}
      </Row>
    )
  }

  const childStyle = {
    borderRadius: '8px',
    margin: '8px 8px 0 8px',
  }

  const downloadStyle = {}

  const menuLists = (
    classItem: string,
    isHorizontal: boolean
  ): MenuProps['items'] => {
    const layoutDownload = (
      <div style={{ display: 'flex' }} className={classes['main-download']}>
        <div>
          <a
            className={classes['item-download']}
            target={'_blank'}
            href={LINK_DOWNLOAD_DOCTOR_STORE}
            rel="noreferrer"
          >
            <img src={ImageAppStore} alt="" className={classes['img-social']} />
          </a>
        </div>
        <div>
          <a
            className={classes['item-download']}
            target={'_blank'}
            href={LINK_DOWNLOAD_APP_DOCTOR_PLAY}
            rel="noreferrer"
          >
            <img
              src={ImageGooglePlay}
              alt=""
              className={classes['img-social']}
            />
          </a>
        </div>
      </div>
    )

    return [
      {
        key: Math.random(),
        label: RenderMenuLabel(t('MENU/ABOUTSOPHIE'), isHorizontal),
        children: [
          {
            label: t('MENU/ABOUTSOPHIE/INTRODUCE'),
            key: Math.random(),
            className: 'menu-item-custom',
            style: childStyle,
            onClick: () => {
              navigate(generatePath(APP_URL.ABOUT_SOPHIE))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/ABOUTSOPHIE/HR'),
            key: Math.random(),
            className: 'menu-item-custom',
            style: childStyle,
            onClick: () => {
              navigate(generatePath(APP_URL.SOPHIE_HIRING))
              onChangeCollapsed(true)
            },
          },
        ],
        className: classes[classItem],
        onClick: () => {},
        style: indentLabelMenuHorizontal,
      },
      {
        key: Math.random(),
        label: RenderMenuLabel(t('MENU/BOOKING_ONLINE'), isHorizontal),
        children: [
          {
            label: t('MENU/BOOKING_ONLINE/PHONE'),
            style: childStyle,
            key: Math.random(),
            className: 'menu-item-custom',
            onClick: () => {
              navigate(generatePath(APP_URL.ONLINE_MEDICAL_BOOKING_PHONE))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/BOOKING_ONLINE/HOME'),
            key: Math.random(),
            style: childStyle,
            className: 'menu-item-custom',
            onClick: () => {
              navigate(generatePath(APP_URL.ONLINE_MEDICAL_BOOKING_HOME))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/BOOKING_ONLINE/CLINIC'),
            key: Math.random(),
            style: childStyle,
            className: 'menu-item-custom',
            onClick: () => {
              navigate(generatePath(APP_URL.ONLINE_MEDICAL_BOOKING_CLINIC))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/BOOKING_ONLINE/FASTPHONE'),
            key: Math.random(),
            style: childStyle,
            className: 'menu-item-custom',
            onClick: () => {
              navigate(generatePath(APP_URL.ONLINE_MEDICAL_BOOKING_FAST_PHONE))
              onChangeCollapsed(true)
            },
          },
        ],
        className: classes[classItem],
        onClick: () => {},
        style: indentLabelMenuHorizontal,
      },
      {
        key: Math.random(),
        label: RenderMenuLabel(t('MENU/SERVICE_HEALTH_AT_HOME'), isHorizontal),
        children: [
          {
            label: t('MENU/SERVICE_HEALTH_AT_HOME/BABY'),
            key: Math.random(),
            className: 'menu-item-custom',
            style: childStyle,
            onClick: () => {
              navigate(generatePath(APP_URL.HEALTH_SERVICE_BABY_BATH))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/SERVICE_HEALTH_AT_HOME/SICK'),
            key: Math.random(),
            className: 'menu-item-custom',
            style: childStyle,
            onClick: () => {
              navigate(generatePath(APP_URL.HEALTH_SERVICE_HUMAN_SICK))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/SERVICE_HEALTH_AT_HOME/BLOOD_AT_HOME'),
            key: Math.random(),
            className: 'menu-item-custom',
            style: childStyle,
            onClick: () => {
              navigate(generatePath(APP_URL.HEALTH_SERVICE_GET_BLOOD))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/SERVICE_HEALTH_AT_HOME/THERAPY'),
            key: Math.random(),
            className: 'menu-item-custom',
            style: childStyle,
            onClick: () => {
              navigate(generatePath(APP_URL.HEALTH_SERVICE_PHYSICAL_THERAPY))
              onChangeCollapsed(true)
            },
          },
        ],
        className: classes[classItem],
        onClick: () => {},
        style: indentLabelMenuHorizontal,
      },
      {
        key: Math.random(),
        label: RenderMenuLabel(t('MENU/PHARMARCY_ONLINE'), false),
        className: `${classes[classItem]} have-line`,
        onClick: () => {
          navigate(generatePath(APP_URL.SHOP_ONLINE))
          onChangeCollapsed(true)
        },
        style: indentLabelMenuHorizontal,
      },
      {
        key: Math.random(),

        label: RenderMenuLabel(t('MENU/BLOG_HEALTH'), false),
        className: `${classes[classItem]} have-line`,
        onClick: () => {
          onChangeCollapsed(true)
          navigate(generatePath(APP_URL.BLOG_HEAL_HOME))
        },
        style: indentLabelMenuHorizontal,
      },
      {
        className: `${classes[classItem]} not-background hide-at-desktop`,
        key: Math.random(),
        label: RenderMenuLabel(t('HOMEPAGE.SERVICE.DOWNLOAD'), isHorizontal),
        children: [
          {
            label: layoutDownload,
            key: Math.random(),
            style: downloadStyle,
            className: 'menu-item-custom',
          },
        ],
        style: indentLabelMenuHorizontal,
      },
    ]
  }

  const actionButtonHorizontalList: MenuProps['items'] = useMemo(
    () => [
      {
        key: Math.random(),
        label: (
          <div className={classes['item-log-sign']}>
            {isAuthenticated ? (
              <AccountInfoWithDropdownMenu />
            ) : (
              <RegisterLoginButton />
            )}
          </div>
        ),
      },
    ],
    [isAuthenticated]
  )

  const actionButtonVerticalList: MenuProps['items'] = useMemo(
    () =>
      !collapsed
        ? isAuthenticated
          ? [
              {
                key: Math.random(),
                label: <AccountInfoSection />,
                onClick: () => {
                  onChangeCollapsed(true)
                },
              },
            ]
          : [
              {
                key: Math.random(),
                label: (
                  <div className="header-vertical-btn-action">
                    <Link to={APP_URL.REGISTER_USER}>
                      <Button className="sign-up">{t('MENU/SIGN_UP')}</Button>
                    </Link>
                  </div>
                ),
                onClick: () => {
                  onChangeCollapsed(true)
                },
              },
              {
                key: Math.random(),
                label: (
                  <div className="header-vertical-btn-action">
                    <Button className="login">{t('MENU/LOGIN')}</Button>
                  </div>
                ),
                onClick: () => {
                  setOpenLoginDialog(true)
                },
              },
            ]
        : [],
    [collapsed]
  )

  const headerToggle: MenuProps['items'] = useMemo(
    () => [
      {
        key: Math.random(),
        label: (
          <div className={classes['header-toggle']}>
            <img
              src={IconEqual}
              alt=""
              onClick={() => onChangeCollapsed(false)}
            />
          </div>
        ),
      },
    ],
    []
  )

  const headerToggleButtonVerticleList = useMemo(
    () => [
      {
        key: Math.random(),
        label: (
          <div className={classes['header-logo-sophie']}>
            <div
              className={classes['logo-image']}
              onClick={() => {
                onChangeCollapsed(true)
                navigate(generatePath(APP_URL.HOME))
              }}
            >
              <img src={LogoSophie} alt="" />
              {/* <LogoSophie /> */}
            </div>
            {!collapsed && (
              <img
                src={IconClose}
                alt=""
                onClick={() => onChangeCollapsed(true)}
                className={classes['toggle-btn']}
              />
            )}
          </div>
        ),
      },
    ],
    [collapsed]
  )

  const headerToggleButtonHorizontalList = useMemo(
    () => [
      {
        key: Math.random(),
        label: <img src={LogoSophie} alt="" />,
        onClick: () => {
          onChangeCollapsed(true)
          navigate(generatePath(APP_URL.HOME))
        },
      },
    ],
    []
  )

  function onNavigateToAdmin() {
    navigate(generatePath(APP_URL.LOGIN))
  }

  const location = useLocation()

  return (
    <Layout className={classes['main-layout']}>
      <div className={classes['sider-container']}>
        <Layout>
          <Layout.Sider
            trigger={null}
            theme="light"
            collapsible
            collapsed={collapsed}
            className={classes['sider']}
            collapsedWidth={0}
            breakpoint={'sm'}
            width={'100vw'}
          >
            <div className="logo" />
            <Menu
              theme="light"
              mode="inline"
              items={[
                ...headerToggleButtonVerticleList,
                ...(menuLists('header-menu-item-vertical', false) || []),
                ...actionButtonVerticalList,
              ]}
            />
          </Layout.Sider>
        </Layout>
      </div>
      <Header />

      <section className={classes['main-container']}>
        <Outlet />
      </section>
      <AppFooter />
      <DownloadBottom />
      <LoginDialogFullscreenMobile
        open={isOpenLoginDialog}
        onClose={() => setOpenLoginDialog(false)}
        onSuccess={() => navigate('/')}
      />
    </Layout>
  )
}

export default MainLayout
