import { FilterAlt } from '@mui/icons-material'
import { MenuItem, Stack, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButtonAnimate } from 'src/components/animate'
import {
  sortOptionObj,
  sortOptions
} from 'src/pages/shop-online/common/constants'
import classes from './CategoryFilter.module.less'
import CategoryFilterMobile from './CategoryFilterMobile'

export type TCategoryFilterSortProps = {
  category: any
  filter: any
  handleUpdateFilter: (newFilter: any) => void
}

export const CategoryFilterSort: React.FC<TCategoryFilterSortProps> = ({
  category,
  filter,
  handleUpdateFilter,
}: TCategoryFilterSortProps) => {
  const { t } = useTranslation()
  const [isOpenDrawer, setOpenDrawer] = useState(false)

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: { xs: 'none', md: 'flex' } }}
      >
        {sortOptions.map((item, i) => (
          <div
            className={`${classes['filter-chip']} ${
              filter?.sortName === item?.value ? classes['active'] : ''
            }`}
            key={i}
            onClick={() => handleUpdateFilter(sortOptionObj[item?.value])}
          >
            {t(item?.translateKey)}
          </div>
        ))}
      </Stack>
      <Stack
        direction="row"
        sx={{ display: { xs: 'flex', md: 'none' }, width: '100%' }}
        spacing={2}
      >
        <TextField
          size="small"
          select
          fullWidth
          value={filter?.sortName}
          onChange={e => handleUpdateFilter(sortOptionObj[e?.target?.value])}
          sx={{
            '.MuiInputBase-root': {
              bgcolor: 'white',
            },
          }}
        >
          {sortOptions.map((item, i) => (
            <MenuItem value={item?.value} key={i}>
              {t(item?.translateKey)}
            </MenuItem>
          ))}
        </TextField>
        <IconButtonAnimate
          onClick={() => setOpenDrawer(true)}
          sx={{ bgcolor: 'white' }}
        >
          <FilterAlt sx={{ color: '#798395' }} />
        </IconButtonAnimate>
      </Stack>
      <CategoryFilterMobile
        open={isOpenDrawer}
        onClose={() => setOpenDrawer(false)}
        category={category}
        filter={filter}
        handleUpdateFilter={handleUpdateFilter}
      />
    </>
  )
}

export default CategoryFilterSort
