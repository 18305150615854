import { Col, Row, Typography } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
import { FORMAT_TIME } from 'src/constants'
import { stripHtml } from 'src/utils/string'
import classes from './BlogHealth.module.less'

export const BlogHealth = ({ data }: any) => {
  const { t } = useTranslation()

  return (
    <Row className={classes['blog-container']}>
      <Col className={classes['blog']}>
        <Col className={classes['header']} data-aos="fade-up">
          <Row justify={'center'}>
            <div
              className={classes['title']}
              dangerouslySetInnerHTML={{
                __html: t('HOMEPAGE.BLOG.TITLE'),
              }}
            ></div>
          </Row>
          <Row justify={'center'}>
            <Typography.Text className={classes['subtitle']}>
              {t('HOMEPAGE.BLOG.SUBTITLE')}
            </Typography.Text>
          </Row>
        </Col>
        <Row
          justify={'center'}
          className={classes['content']}
          gutter={[20, 20]}
        >
          {data.slice(0, 2).map((item: any, index: number) => {
            return (
              <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7} key={index}>
                <Link to={`/detail-news/${item.newsId}`}>
                  <RenderBlogItemHorizontal item={item} />
                </Link>
              </Col>
            )
          })}
          <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
            {data.slice(2, 6).map((item: any, index: number) => {
              return (
                <Link to={`/detail-news/${item.newsId}`} key={index}>
                  <RenderBlogItemVertical item={item} />
                </Link>
              )
            })}
          </Col>
        </Row>
        <Col className={classes['item-vertical']}></Col>
      </Col>
    </Row>
  )
}

export const RenderBlogItemHorizontal = ({ item }: any) => {
  return (
    <div className={classes['item-horizontal']}>
      <div className={classes['item']}>
        <LazyLoad once height={300}>
          <Row justify={'center'} className={classes['img']}>
            <img src={item.images?.[0] || ''} alt="" />
          </Row>
        </LazyLoad>
        <Row className={classes['title']}>{item.subject || ''}</Row>
        <Row wrap={false}>
          <Col className={classes['time']}>
            {moment(item.created).format(FORMAT_TIME.DATE1) || ''}
          </Col>
          <Col>&#8226;</Col>
          <Col className={classes['type']}>{item.subjectType || ''}</Col>
        </Row>
        <Row className={classes['content']}>
          {stripHtml(item.content || '')}
        </Row>
      </div>
    </div>
  )
}

export const RenderBlogItemVertical = ({ item }: any) => {
  return (
    <div className={classes['item-vertical']}>
      <Row className={classes['item']} gutter={8}>
        <Col span={16}>
          <Row className={classes['title']}>{item.subject || ''}</Row>
          <Row wrap={false}>
            <Col className={classes['time']}>
              {' '}
              {moment(item.created).format(FORMAT_TIME.DATE1) || ''}
            </Col>
            <Col>&#8226;</Col>
            <Col className={classes['type']}>{item.subjectType || ''}</Col>
          </Row>
        </Col>
        <Col span={8} className={classes['img']}>
          <LazyLoad once height={100}>
            <img src={item.images?.[0] || ''} alt="" />
          </LazyLoad>
        </Col>
      </Row>
    </div>
  )
}
