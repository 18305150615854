import { Link as RouterLink } from 'react-router-dom'
// @mui
import { styled, alpha } from '@mui/material/styles'
import { Box, Link, Typography } from '@mui/material'
// auth
import { useAuthContext } from '../../../auth/useAuthContext'
// routes
import { PATH_ADMIN } from '../../../routes/paths'
// components
import { CustomAvatar } from '../../../components/custom-avatar'
import { width } from '@mui/system'

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}))

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { profile } = useAuthContext()

  return (
    <Link
      component={RouterLink}
      to='/user/account'
      underline="none"
      color="inherit"
    >
      <StyledRoot sx={{}}>
        <CustomAvatar
          src={profile?.avatar}
          alt={profile?.displayName}
          name={profile?.displayName}
        />

        <Box
          sx={{
            ml: 2,
            minWidth: 0,
          }}
        >
          <Typography variant="subtitle2">
            {profile?.firstname} {profile?.lastname}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {profile?.typePharmacist}
          </Typography>
          {/* 
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {profile?.email}
          </Typography> */}
        </Box>
      </StyledRoot>
    </Link>
  )
}
