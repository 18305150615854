import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAuthUserContext } from 'src/auth/AuthUserContext'

interface ConfirmLogoutDialogProps {
  open: boolean
  onClose: VoidFunction
  onSuccess?: VoidFunction
}

export const ConfirmLogoutDialog = ({
  open,
  onClose,
  onSuccess,
}: ConfirmLogoutDialogProps) => {
  const { t } = useTranslation()
  const { logout } = useAuthUserContext()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)

  const handleLogout = async () => {
    setLoading(true)
    try {
      await logout()
      onClose()
      navigate('/')
      onSuccess && onSuccess()
    } catch (error) {}
    setLoading(false)
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('layout.confirmLogout')}</DialogTitle>
      <DialogContent>{t('layout.confirmLogoutContent')}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={onClose}>
          {t('cancel')}
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handleLogout}
        >
          {t('layout.logout')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
