import {
  ArrowDropDownRounded,
  Close,
  DragHandleRounded,
  MenuRounded,
  Search,
} from '@mui/icons-material'
import { InputAdornment, Popover, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { LogoSophie } from 'src/assets'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import { IconButtonAnimate } from 'src/components/animate'
import { APP_URL } from 'src/config'
import useResponsive from 'src/hooks/useResponsive'
import { AccountInfoWithDropdownMenu } from '../AccountInfo'
import { ConfirmLogoutDialog } from '../ConfirmLogoutDialog'
import RegisterLoginButton from '../RegisterLoginButton/RegisterLoginButton'
import classes from './Header.module.less'
import App from 'src/App'
import { useCartContext } from 'src/pages/shop-online/Cart/context'
import { MenuDrawer } from 'src/layouts/user/components/MenuDrawer/MenuDrawer'

export const HeaderRight: React.FC = () => {
  const { t } = useTranslation()
  const { profile, isAuthenticated } = useAuthUserContext()
  const { order } = useCartContext()
  const navigate = useNavigate()
  const isMd = useResponsive('up', 'md')
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [isOpenMenuDrawer, setOpenMenuDrawer] = useState(false)

  if (!isAuthenticated && isMd) {
    return (
      <div className={classes['header__right']}>
        <RegisterLoginButton onLoginSuccess={() => setOpenMenuDrawer(false)} />
      </div>
    )
  }

  return (
    <div className={classes['header__right']}>
      {!!isAuthenticated && (
        <Link to={APP_URL.SHOP_ONLINE_CART} className={classes['bag']}>
          <IconButtonAnimate size="small">
            <img
              className={classes['bag-icon']}
              src="/assets/shop-online/ic-bag.png"
              alt=""
            />
          </IconButtonAnimate>
          {order?.listProduct?.length > 0 && (
            <div className={classes['badge']}>{order?.listProduct?.length}</div>
          )}
        </Link>
      )}
      <div className={classes['menu-desktop']}>
        <AccountInfoWithDropdownMenu />
      </div>
      <div className={classes['menu-mobile']}>
        <IconButtonAnimate size="small" onClick={() => setOpenMenuDrawer(true)}>
          <DragHandleRounded sx={{ color: '#1A9CC6', fontSize: '32px' }} />
        </IconButtonAnimate>
      </div>
      <MenuDrawer
        open={isOpenMenuDrawer}
        onClose={() => setOpenMenuDrawer(false)}
      />
    </div>
  )
}
