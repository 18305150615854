import React from 'react'
import classes from './Policy.module.less'
import { WrapPolicy } from './Components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

export const PolicyGeneral = () => {
  const { t } = useTranslation()

  return (
    <WrapPolicy>
      <Helmet>
        <title>Policy | Sophie</title>
      </Helmet>
      <div className={classes['root']}>
        <div className={classes['terms-text']}>
          <h1 className={classes['title-policy']}>
            {t('POLICY.GENERAL.TITLE')}
          </h1>
          <h3> {t('POLICY.GENERAL.SUB_TITLE')}</h3>
          <p>
            <b>{t('POLICY.GENERAL.ACCEPT')}</b>
          </p>
          <p>{t('POLICY.GENERAL.NOTE1')}</p>
          <p>
            <b>{t('POLICY.GENERAL.NOTE2')}</b>
          </p>
          <p>{t('POLICY.GENERAL.DESC')}</p>
          <p>
            <b>{t('POLICY.GENERAL.LINK')}</b>
          </p>
          <p>{t('POLICY.GENERAL.NOTICE1')}</p>
          <p>
            <b>{t('POLICY.GENERAL.NOTICE2')}</b>
          </p>
          <p>{t('POLICY.GENERAL.COMPANY')}</p>
          <p>
            <b>{t('POLICY.GENERAL.COMPANY2')}</b>
          </p>
          <p>{t('POLICY.GENERAL.SHOPIE')}</p>
          <p>{t('POLICY.GENERAL.REJECT')}</p>
          <p>{t('POLICY.GENERAL.ACCESS')}</p>
          <p>{t('POLICY.GENERAL.LIST_NOTICE')}</p>
          <p>{t('POLICY.GENERAL.CONDITION')}</p>
          <p>
            <b>{t('POLICY.GENERAL.BRAIN')}</b>
          </p>
          <p>{t('POLICY.GENERAL.WEB')}</p>
          <p>
            <b>{t('POLICY.GENERAL.FIX')}</b>
          </p>
          <p>{t('POLICY.GENERAL.SHUTDOWN')}</p>
          <p>
            <b>{t('POLICY.GENERAL.CHECKOUT')}</b>
          </p>
          <p>{t('POLICY.GENERAL.PRODUCT')}</p>
          <p>
            <b>{t('POLICY.GENERAL.SHIP')}</b>
          </p>
          <p>{t('POLICY.GENERAL.MORE')}</p>
          <p>
            <b>{t('POLICY.GENERAL.PAY')}</b>
          </p>
          <p>{t('POLICY.GENERAL.CHANGE_CODE')}</p>
          <p>
            <b>{t('POLICY.GENERAL.PERSONAL')}</b>
          </p>
          <p>{t('POLICY.GENERAL.WATCH_MORE')} </p>
        </div>
      </div>
    </WrapPolicy>
  )
}

export default PolicyGeneral
