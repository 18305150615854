import { Col, Layout, Row, Typography } from 'antd'
import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material'
import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import { generatePath, Link } from 'react-router-dom'
import {
  IconRectangle1203,
  ImageBoCongThuong,
  ImageDMCA,
  LogoFacebook,
  LogoInstagram,
  LogoSophie,
  LogoTelegram,
  LogoTwitter,
} from 'src/assets'
import { APP_URL } from 'src/config'
import classes from './AppFooter.module.less'

export type TAppFooterProps = {}

const LINKS = {
  BCT: 'http://online.gov.vn/(X(1)S(mgkizr4vbd5qt5cadnrlupny))/Home/WebDetails/100840?AspxAutoDetectCookieSupport=1',
}

export const AppFooter: React.FC<TAppFooterProps> = ({}: TAppFooterProps) => {
  const [arrowDowns, setArrowDowns] = useState(true)
  const [isOpen, setIsOpen] = useState(true)
  const toggleDropDown = () => {
    setArrowDowns(!arrowDowns)
  }
  const openDropDown = () => {
    setIsOpen(!isOpen)
  }
  const { t } = useTranslation()
  return (
    <Layout.Footer className={classes['footer-container']}>
      <div className={classes['footer-overlay']} />
      <Row
        // align="middle"
        justify="space-around"
        className={classes['footer']}
      >
        <Col xs={24} sm={24} md={24} lg={8} className={classes['footer-item']}>
          <Row
            justify={{ lg: 'start' }}
            className={classes['footer-row-point']}
          >
            <div className={classes['footer-logo-sophie']}>
              <LazyLoad once height={50}>
                <img src={LogoSophie} alt="" />
              </LazyLoad>
            </div>
          </Row>
          <Row
            justify={{ lg: 'start' }}
            className={classes['footer-row-point']}
          >
            <Typography.Text className={clsx(classes['footer-primary-text'])}>
              {t('FOOTER.COMPANY')}
            </Typography.Text>
          </Row>
          <Row
            justify={{ lg: 'start' }}
            className={classes['footer-row-point']}
          >
            <Typography.Text className={clsx(classes['footer-text'])}>
              {t('FOOTER.CODE')}{' '}
              <strong className={classes['footer-tax-number']}>
                {t('FOOTER.PHONE')}
              </strong>
            </Typography.Text>
          </Row>
          <Typography.Text
            className={`${classes['copyright-text']} ${classes['for-desktop']}`}
          >
            {t('FOOTER.COPY_RIGHT')}
          </Typography.Text>
        </Col>
        <Col xs={24} sm={24} md={24} lg={5} className={classes['footer-item']}>
          <Row
            align={{ md: 'middle' }}
            justify={{ lg: 'start' }}
            className={classes['footer-row-point']}
          >
            <Typography.Text className={classes['footer-primary-text']}>
              {t('FOOTER.ABOUT')}
            </Typography.Text>
            <div>
              {arrowDowns === false ? (
                <ArrowDropDownRounded
                  color="primary"
                  onClick={toggleDropDown}
                />
              ) : (
                <ArrowDropUpRounded color="primary" onClick={toggleDropDown} />
              )}
            </div>
          </Row>
          {arrowDowns && (
            <div
              className={`${classes['wrap-list-info']} ${classes['show-option']}`}
            >
              <RenderLabelFooter
                link={`/${APP_URL.ABOUT_SOPHIE}`}
                text={t('FOOTER.ABOUT_SOPHIE')}
              />
              <RenderLabelFooter
                link={`/${APP_URL.ABOUT_SOPHIE}`}
                text={t('FOOTER.EXECUTIVE_BOARD')}
              />
              <RenderLabelFooter
                link={`/${APP_URL.SOPHIE_HIRING}`}
                text={t('FOOTER.HUMAN_AND_RECRUITER')}
              />
              <RenderLabelFooter
                link={APP_URL.CONTACT_SHOPIE}
                text={t('FOOTER.CONTACT')}
              />
            </div>
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={5} className={classes['footer-item']}>
          <Row
            justify={{ lg: 'start' }}
            className={classes['footer-row-point']}
          >
            <Typography.Text className={classes['footer-primary-text']}>
              {t('FOOTER.SUPPORT')}
            </Typography.Text>
            {/* <div>
              <ArrowDropDownRounded color="primary" onClick={openDropDown} />
            </div> */}
            {isOpen === false ? (
              <ArrowDropDownRounded color="primary" onClick={openDropDown} />
            ) : (
              <ArrowDropUpRounded color="primary" onClick={openDropDown} />
            )}
          </Row>
          {isOpen && (
            <div
              className={`${classes['wrap-list-info']} ${classes['show-option']}`}
            >
              <RenderLabelFooter
                link={APP_URL.POLICY_SENDBACK}
                text={t('FOOTER.PAY_BACK')}
              />
              <RenderLabelFooter
                link={APP_URL.POLICY_SHIP}
                text={t('FOOTER.RULE')}
              />
              <RenderLabelFooter
                link={APP_URL.POLICY_SERCURITY}
                text={t('FOOTER.PRIVATE')}
              />
              <RenderLabelFooter
                link={APP_URL.POLICY_GENERAL}
                text={t('FOOTER.GENERAL')}
              />
              <RenderLabelFooter
                link={APP_URL.AUTH_LOGIN}
                text={t('FOOTER.FOR_DOCTOR')}
              />
            </div>
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} className={classes['footer-item']}>
          <Row
            justify={{ lg: 'end' }}
            className={`${classes['gap-row']} ${classes['footer-row-point']}`}
            style={{ textAlign: 'right' }}
          >
            <Typography.Text>{t('FOOTER.SUPPORT_CALL')}</Typography.Text>
          </Row>
          <Row justify={{ lg: 'end' }} className={classes['footer-row-point']}>
            <Typography.Text className={classes['footer-hotline']}>
              {t('FOOTER.PHONE_RE')}
            </Typography.Text>
          </Row>
          <Row justify={{ lg: 'end' }} className={classes['footer-row-point']}>
            <Typography.Text className={classes['gap-row']}>
              {t('FOOTER.CONNECT')}
            </Typography.Text>
          </Row>
          <LazyLoad once height={30}>
            <Row
              align="middle"
              justify={{ lg: 'end' }}
              className={`classes['gap-row'] ${classes['footer-row-point']}`}
            >
              <img
                src={LogoFacebook}
                alt="logo-facebook"
                onClick={() => {}}
                className={classes['footer-logo-social']}
              />
              <img
                src={LogoInstagram}
                alt="logo-instagram"
                onClick={() => {}}
                className={classes['footer-logo-social']}
              />
              <img
                src={LogoTwitter}
                alt="logo-twitter"
                onClick={() => {}}
                className={classes['footer-logo-social']}
              />
              <img
                src={LogoTelegram}
                alt="logo-telegram"
                onClick={() => {}}
                className={classes['footer-logo-social']}
              />
              <img
                src={LogoTwitter}
                alt="logo-twitter"
                onClick={() => {}}
                className={classes['footer-logo-social']}
              />
            </Row>
          </LazyLoad>
          <LazyLoad once height={50}>
            <Row
              align="middle"
              justify={{ lg: 'end' }}
              className={`classes['list-image'] ${classes['footer-row-point']}`}
            >
              <a target="_blank" href={LINKS.BCT}>
                <img
                  src={ImageBoCongThuong}
                  alt="logo-twitter"
                  onClick={() => {}}
                  className={classes['footer-image-bocongthuong']}
                />
              </a>
              <img
                src={ImageDMCA}
                alt="logo-twitter"
                onClick={() => {}}
                className={classes['footer-image-dmca']}
              />
            </Row>
            <Typography.Text
              className={`${classes['copyright-text']} ${classes['for-mobile']}`}
            >
              {t('FOOTER.COPY_RIGHT')}
            </Typography.Text>
            <Row
              justify={{ lg: 'start' }}
              className={classes['footer-row-point']}
            ></Row>
          </LazyLoad>
        </Col>
      </Row>
    </Layout.Footer>
  )
}

const RenderLabelFooter = ({ text, link = '/' }: any) => {
  return (
    <Link to={generatePath(link)}>
      <Row
        justify={{ lg: 'start' }}
        align="middle"
        wrap={false}
        className={classes['footer-row-point']}
      >
        <div className={classes['icon-rectangle']}>
          <img src={IconRectangle1203} alt="" />
          {/* <IconRectangle1203 /> */}
        </div>
        <Typography.Text className={classes['footer-text']}>
          {text}
        </Typography.Text>
      </Row>
    </Link>
  )
}

// const DropDownUp = () => {

//   return (
//   )
// }
