export const sortOptions = [
  {
    translateKey: 'pharmacyOnline.sortBestSale',
    value: 'PurchasedNumber',
  },
  {
    translateKey: 'pharmacyOnline.sortNewest',
    value: 'Created',
  },
  {
    translateKey: 'pharmacyOnline.sortPriceLowest',
    value: 'ProductPrice',
  },
]

export const sortOptionObj: Record<string, any> = {
  PurchasedNumber: {
    sortName: 'PurchasedNumber',
    sort: 'desc',
  },
  Created: {
    sortName: 'Created',
    sort: 'desc',
  },
  ProductPrice: {
    sortName: 'ProductPrice',
    sort: 'asc',
  },
}

export const getPriceOptions = (t: Function) => [
  {
    label: t('pharmacyOnline.under') + ' 100.000₫',
    minPrice: 0,
    maxPrice: 99999,
  },
  {
    label: '100.000₫ ' + t('pharmacyOnline.to') + ' 300.000₫',
    minPrice: 100000,
    maxPrice: 300000,
  },
  {
    label: t('pharmacyOnline.above') + ' 500.000₫',
    minPrice: 500000,
    maxPrice: 0,
  },
]
