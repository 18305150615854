import { LabelColor } from 'src/components/label'
import { TransportStatus } from 'src/constants/constants'

export const transportStatusColor: Record<string, LabelColor> = {
  [TransportStatus.Created]: 'info',
  [TransportStatus.Verified]: 'info',
  [TransportStatus.WaitingForDelivery]: 'info',
  [TransportStatus.Delivered]: 'info',
  [TransportStatus.Transporting]: 'info',
  [TransportStatus.Success]: 'success',
  [TransportStatus.Waiting]: 'default',
  [TransportStatus.Refunding]: 'error',
  [TransportStatus.Refunded]: 'error',
  [TransportStatus.Cancel]: 'default',
}

export const transportStatusTextColor: Record<string, string> = {
  [TransportStatus.Created]: '#008EFF',
  [TransportStatus.Verified]: '#008EFF',
  [TransportStatus.WaitingForDelivery]: '#008EFF',
  [TransportStatus.Delivered]: '#008EFF',
  [TransportStatus.Transporting]: '#008EFF',
  [TransportStatus.Success]: '#00A57B',
  [TransportStatus.Waiting]: '#576071',
  // [TransportStatus.Refunding]: 'error',
  // [TransportStatus.Refunded]: 'error',
  [TransportStatus.Cancel]: '#576071',
}

export const getTransportStatusLabel = (t: any): Record<string, string> => ({
  [TransportStatus.Created]: t('userProfile.orderTracking.waitingForDelivery'),
  [TransportStatus.Verified]: t('userProfile.orderTracking.shipping'),
  [TransportStatus.WaitingForDelivery]: t('userProfile.orderTracking.shipping'),
  [TransportStatus.Delivered]: t('userProfile.orderTracking.shipping'),
  [TransportStatus.Transporting]: t('userProfile.orderTracking.shipping'),
  [TransportStatus.Success]: t('userProfile.orderTracking.success'),
  [TransportStatus.Waiting]: t('userProfile.orderTracking.waiting'),
  [TransportStatus.Refunding]: t('userProfile.orderTracking.refund'),
  [TransportStatus.Refunded]: t('userProfile.orderTracking.refund'),
  [TransportStatus.Cancel]: t('userProfile.orderTracking.cancel'),
})
