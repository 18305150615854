import { CaretRightOutlined } from '@ant-design/icons'
import { Col, Row, Typography as TypographyAntd } from 'antd'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import classes from './Promotion.module.less'
import { PromotionDetailDialog } from 'src/pages/shop-online/Promotions/component/PromotionDetail'
import { useEffect, useState } from 'react'
import { useGetPromitionDetailPatient } from 'src/api/hooks/patient.query'
import { Link } from 'react-router-dom'
import { APP_URL } from 'src/config'
import { Stack, Typography } from '@mui/material'

export const Promotion = ({ data }: any) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [promotionDetail, setPromotionDetail] = useState<any>({})
  const promitionDetailPatientRequest = useGetPromitionDetailPatient()
  const [promotionId, setPromotionId] = useState('')

  useEffect(() => {
    if (promotionId) {
      promitionDetailPatientRequest.mutate(promotionId)
    }
  }, [promotionId])

  useEffect(() => {
    if (promitionDetailPatientRequest.isSuccess) {
      const data = promitionDetailPatientRequest?.data?.data || {}
      setPromotionDetail(data)
    }
  }, [promitionDetailPatientRequest.isSuccess, promotionId])

  return (
    <div className={classes['promotion-container']}>
      <Stack
        width="100%"
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'center', md: 'space-between' }}
        alignItems="center"
      >
        <Typography className={classes['title']}>
          {t('HOMEPAGE.PRODUCT_CATEGORY.PROMOTION_TITLE')}
        </Typography>
        <Link to={APP_URL?.SHOP_ONLINE_PROMOTIONS}>
          <TypographyAntd.Text className={classes['see-all']}>
            {t('HOMEPAGE.PRODUCT_CATEGORY.SEE_ALL')} <CaretRightOutlined />
          </TypographyAntd.Text>
        </Link>
      </Stack>
      <Row className={classes['item-container']} gutter={[8, 16]}>
        {(data || []).map((item: any, index: number) => {
          return (
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={6}
              xl={4}
              xxl={4}
              className={classes['item']}
              key={index}
            >
              <LazyLoad once height={200}>
                <img
                  src={item?.banner?.[0] || ''}
                  alt=""
                  style={{ borderRadius: 16 }}
                  onClick={() => {
                    setIsOpen(true)
                    setPromotionId(item?.promotionId)
                  }}
                />
              </LazyLoad>
            </Col>
          )
        })}
      </Row>

      <PromotionDetailDialog
        item={promotionDetail}
        open={isOpen}
        onclose={() => setIsOpen(false)}
      />
    </div>
  )
}
