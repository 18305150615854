import { REST_DASHBOARD_SYS } from '../api_paths'
import {
  getDetailNews,
  getListCategory,
  getListDashboardGuest,
  getListNews,
  getListProductSaleOff,
  getListPromotion,
  getListTopPartner,
} from '../api_functions/guestDashboard'
import { useQueries, useQuery } from 'react-query'

export function useGetListNews(payload: any, params: any) {
  return useQuery([...Object.values(REST_DASHBOARD_SYS.GET_LIST_NEWS)], () =>
    getListNews(payload, params)
  )
}

export function useGetTopPartner(payload: any, params?: any) {
  return useQuery([...Object.values(REST_DASHBOARD_SYS.GET_TOP_PARTNER)], () =>
    getListTopPartner(payload, params)
  )
}

export function useGetListNewsParallel(
  listSubject: any,
  version: string,
  params: any
) {
  const { skip } = params
  return useQueries(
    listSubject.map((subject: string) => {
      return {
        queryKey: [
          ...Object.values(REST_DASHBOARD_SYS.GET_LIST_NEWS),
          subject,
          skip,
        ],
        queryFn: () => getListNews({ version, subjectType: subject }, params),
      }
    })
  )
}

export function useGetListPromotion(payload: any) {
  return useQuery(
    [...Object.values(REST_DASHBOARD_SYS.GET_LIST_PROMOTION)],
    () => getListPromotion(payload)
  )
}

export function useGetListPromotionHomepage(payload: any) {
  return useQuery(
    [...Object.values(REST_DASHBOARD_SYS.GET_LIST_PROMOTION_HOMEPAGE)],
    () => getListPromotion(payload)
  )
}

export function useGetDetailNews(newsId: string) {
  return useQuery(
    [...Object.values(REST_DASHBOARD_SYS.GET_LIST_PROMOTION), newsId],
    () => getDetailNews({ newsId }),
    {
      cacheTime: 0,
    }
  )
}

export function useGetListCategory(payload: any = {}) {
  return useQuery(
    [...Object.values(REST_DASHBOARD_SYS.GET_LIST_CATEGORY)],
    () => getListCategory(payload)
  )
}

export function useGetListProductSaleOff(payload: any = {}) {
  return useQuery(
    [...Object.values(REST_DASHBOARD_SYS.GET_LIST_PRODUCT_SALE_OFF)],
    () => getListProductSaleOff(payload)
  )
}

export function useGetListDashboardGuest(payload: any) {
  return useQuery(
    [...Object.values(REST_DASHBOARD_SYS.GET_LIST_DASHBOARD_GUEST)],
    () => getListDashboardGuest(payload)
  )
}
