import { Dialog, Popover } from '@mui/material'
import LoginPopoverContent from 'src/sections/auth/login-user/LoginUserContent'

interface LoginPopoverProps {
  open: any
  onClose: VoidFunction
  onSuccess?: VoidFunction
}

const LoginDialogFullscreenMobile = ({
  open,
  onClose,
  onSuccess,
}: LoginPopoverProps) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      sx={{
        '.MuiDialog-paperFullScreen': {
          margin: '0 !important',
        },
        margin: '0 !important',
      }}
    >
      <LoginPopoverContent onClose={onClose} onSuccess={onSuccess} />
    </Dialog>
  )
}

export default LoginDialogFullscreenMobile
