import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material'
import {
  Collapse,
  List,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ImageAppStore, ImageGooglePlay } from 'src/assets'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import Image from 'src/components/image/Image'
import { LINK_DOWNLOAD_APP_DOCTOR_PLAY, LINK_DOWNLOAD_DOCTOR_STORE } from 'src/config'
import classes from './MenuDrawer.module.less'

interface MenuItemProps {
  item: any
  onClose: VoidFunction
}

export const MenuItem: FC<MenuItemProps> = ({
  item,
  onClose,
}: MenuItemProps) => {
  const { t } = useTranslation()
  const { profile } = useAuthUserContext()
  const [isExpand, setExpand] = useState(false)

  if (!item?.children) {
    return (
      <Link to={item?.path}>
        <ListItemButton onClick={onClose} sx={{ px: 0, py: 1.5 }}>
          <Typography variant="subtitle1" color="#576071">
            {item?.label}
          </Typography>
        </ListItemButton>
      </Link>
    )
  }

  const RenderSubMenu = (item: any) => {
    if (item?.render === 'download') {
      return (
        <Stack direction="row" sx={{ py: 1 }}>
          <a
            className={classes['item-download']}
            target={'_blank'}
            href={LINK_DOWNLOAD_DOCTOR_STORE}
            rel="noreferrer"
          >
            <img src={ImageAppStore} alt="" className={classes['img-social']} />
          </a>
          <a
            className={classes['item-download']}
            target={'_blank'}
            href={LINK_DOWNLOAD_APP_DOCTOR_PLAY}
            rel="noreferrer"
          >
            <img
              src={ImageGooglePlay}
              alt=""
              className={classes['img-social']}
            />
          </a>
        </Stack>
      )
    }
    return (
      <Link to={item?.path}>
        <ListItemButton sx={{ pl: 2 }} onClick={onClose}>
          <Typography variant="subtitle2" color="#576071">
            {item?.label}
          </Typography>
        </ListItemButton>
      </Link>
    )
  }

  const RenderLabel = () => {
    if (item?.role === 'auth') {
      return (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          onClick={() => setExpand(!isExpand)}
          sx={{ cursor: 'pointer', px: 0, py: 1.5 }}
        >
          <Image
            src="/assets/shop-online/ic-avatar.png"
            alt=""
            sx={{ width: '40px' }}
          />
          <Stack flexGrow={1}>
            <Typography variant="caption" color="#576071">
              Tài khoản
            </Typography>
            <Typography variant="h5" color="primary.main">
              {profile?.fullname}
            </Typography>
          </Stack>
          <ArrowDropDownRounded />
        </Stack>
      )
    }
    return (
      <ListItemButton
        onClick={() => setExpand(!isExpand)}
        sx={{ px: 0, py: 1.5 }}
      >
        <Typography
          variant="subtitle1"
          color={isExpand ? 'primary.main' : '#576071'}
          flexGrow={1}
        >
          {item?.label}
        </Typography>
        {isExpand ? (
          <ArrowDropUpRounded sx={{ color: 'primary.main' }} />
        ) : (
          <ArrowDropDownRounded />
        )}
      </ListItemButton>
    )
  }

  return (
    <>
      {RenderLabel()}
      <Collapse in={isExpand} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item?.children?.map((item: any, i: number) => (
            <RenderSubMenu {...item} key={i} />
          ))}
        </List>
      </Collapse>
    </>
  )
}
