import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
  useCreateCodPayShopPatient,
  useCreateMomoPayShopPatient,
  useCreateZaloPayShopPatient,
  useSaveOrderPatient,
} from 'src/api/hooks/patient.query'
import { APP_URL } from 'src/config'
import { logDev, logJson } from 'src/utils'
import { openInNewTab } from 'src/utils/window'
import { useCartContext } from '../../context'
import { NoticeHasSellOverDialog } from './NoticeHasSellOverDialog'

export const PayButton = () => {
  const { t } = useTranslation()
  const { order, cartData, refreshOrder } = useCartContext()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const saveOrderRequest = useSaveOrderPatient()
  const createCodPayRequest = useCreateCodPayShopPatient()
  const createMomoPayRequest = useCreateMomoPayShopPatient()
  const createZaloPayRequest = useCreateZaloPayShopPatient()
  const [isOpenNoticeDialog, setOpenNoticeDialog] = useState(false)

  const handleCodPay = async (transactionId: any, order: any) => {
    try {
      const res = await createCodPayRequest.mutateAsync({
        transactionId,
        order,
      })
      if (res?.status === 'Success') {
        enqueueSnackbar(t('cart.alertOrderSuccess'))
        await refreshOrder()
        navigate(APP_URL.SHOP_ONLINE_PAY_RESULT?.replace(':id', transactionId))
      } else {
        enqueueSnackbar(res?.message, { variant: 'error' })
      }
    } catch (error) {
      logDev('Cart.PayButton.handleCodPay', error)
    }
  }

  const handleMomoPay = async (transactionId: any, order: any) => {
    try {
      const res = await createMomoPayRequest.mutateAsync({
        transactionId,
        webLink:
          location.origin +
          APP_URL.SHOP_ONLINE_PAY_RESULT?.replace(':id', transactionId),
        order,
      })
      if (res?.status === 'Success') {
        await refreshOrder()
        // enqueueSnackbar(res?.message)
        openInNewTab(res?.data?.payUrl)
      } else {
        enqueueSnackbar(res?.message, { variant: 'error' })
      }
    } catch (error) {
      logDev('Cart.PayButton.handleCodPay', error)
    }
  }

  const handleZaloPay = async (transactionId: any, order: any) => {
    try {
      const res = await createZaloPayRequest.mutateAsync({
        transactionId,
        webLink:
          location.origin +
          APP_URL.SHOP_ONLINE_PAY_RESULT?.replace(':id', transactionId),
        order,
      })
      if (res?.status === 'Success') {
        await refreshOrder()
        // enqueueSnackbar(res?.message)
        openInNewTab(res?.data?.order_url)
      } else {
        enqueueSnackbar(res?.message, { variant: 'error' })
      }
    } catch (error) {
      logDev('Cart.PayButton.handleCodPay', error)
    }
  }

  const handlePay = async () => {
    if (!order?.addressId) {
      enqueueSnackbar(t('cart.alertLackAddress'), { variant: 'error' })
      return
    }

    if (!order?.listProduct?.length) {
      enqueueSnackbar(t('cart.youNeedAddProductToCart'), { variant: 'error' })
      return
    }

    for (const product of order?.listProduct) {
      if (product?.productNumber <= 0 && !product?.sellOver) {
        setOpenNoticeDialog(true)
        return
      }
    }

    try {
      const res = await saveOrderRequest.mutateAsync({
        ...order,
        typePay: cartData?.typePay,
        transactionId: '',
      })

      const transactionId = res?.data?.transactionId
      if (!!transactionId) {
        switch (cartData?.typePay) {
          case 'COD':
            handleCodPay(transactionId, res?.data)
            break

          case 'Momo':
            handleMomoPay(transactionId, res?.data)
            break

          case 'Zalo':
            handleZaloPay(transactionId, res?.data)
            break

          default:
            break
        }
      }
    } catch (error) {}
  }

  const isLoading =
    saveOrderRequest.isLoading ||
    createCodPayRequest.isLoading ||
    createMomoPayRequest.isLoading ||
    createZaloPayRequest.isLoading

  return (
    <>
      <LoadingButton
        loading={isLoading}
        variant="contained"
        fullWidth
        onClick={handlePay}
        sx={{ mt: 3 }}
      >
        {t('cart.pay')}
      </LoadingButton>
      <NoticeHasSellOverDialog
        open={isOpenNoticeDialog}
        onClose={() => setOpenNoticeDialog(false)}
      />
    </>
  )
}
