// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { TextField, TextFieldProps, FormHelperText } from '@mui/material'

type Props = TextFieldProps & {
  name: string
  placeholder?: string
}

export default function RHFTextField({
  name,
  placeholder,
  helperText,
  ...other
}: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            {...field}
            placeholder={placeholder}
            fullWidth
            value={
              typeof field.value === 'number' && field.value === 0
                ? '0'
                : field.value
            }
            error={!!error}
            autoComplete="off"
            {...other}
          ></TextField>
          {error && (
            <FormHelperText
              color="#F0224F"
              sx={{
                marginTop: '8px !important',
              }}
            >
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </>
      )}
    />
  )
}
