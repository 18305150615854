import { REST_DASHBOARD_SYS } from '../api_paths'
import {
  getListCategoryNews,
  getSearchNews,
  getTopNews,
} from '../api_functions/blog'
import { useQuery } from 'react-query'

export function useGetLatestTopNews(payload: any, params: any) {
  return useQuery(
    [...Object.values(REST_DASHBOARD_SYS.GET_LIST_TOP_NEWS)],
    () => getTopNews(payload, params),
    {
      cacheTime: 0,
    }
  )
}

export function useGetListCategoryNews(payload: any) {
  return useQuery(
    [...Object.values(REST_DASHBOARD_SYS.GET_LIST_CATEGORY_NEWS)],
    () => getListCategoryNews(payload)
  )
}

export function useSearchNews(payload: any, params: any) {
  const { keywords } = payload
  const { skip } = params

  return useQuery(
    [...Object.values(REST_DASHBOARD_SYS.GET_SEARCH_NEWS), { keywords, skip }],
    () => getSearchNews(payload, params),
    { cacheTime: 0 }
  )
}
