import { Box, Breadcrumbs, Grid, Typography } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  useGetDetailProductPatient,
  useGetListCategoryPatient,
  useGetShopDetailByProductPatient,
  useGetSimilarProductPatient,
} from 'src/api/hooks/patient.query'
import { APP_URL } from 'src/config'
import { uuidV4Regex } from 'src/constants/regex'
import { ProductList } from 'src/sections/user/ProductList'
import { toSlug } from 'src/utils/string'
import {
  ImageSlider,
  ProductInfo,
  ProductSummary,
  ShopInfo,
} from './components'
import classes from './ProductDetail.module.less'

export type TProductDetailProps = {}

export const ProductDetail: React.FC<
  TProductDetailProps
> = ({}: TProductDetailProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { slug = '' } = useParams()
  const productId = slug.match(uuidV4Regex)?.[0] || ''

  const productRequest = useGetDetailProductPatient()
  const listCategoryRequest = useGetListCategoryPatient()
  const similarProductRequest = useGetSimilarProductPatient()
  const shopDetailRequest = useGetShopDetailByProductPatient()

  const product: any = productRequest?.data || null
  const similarProducts: any[] = similarProductRequest?.data?.data || []
  const shopDetail: any = shopDetailRequest?.data?.data || null
  const category: any = useMemo(
    () =>
      listCategoryRequest?.data?.find(
        (item: any) => item?.categoryId === productRequest?.data?.categoryId
      ),
    [listCategoryRequest?.data, productRequest?.data?.categoryId]
  )

  useEffect(() => {
    productRequest.mutate(productId)
    shopDetailRequest.mutate(productId)
    similarProductRequest.mutate({ productId })
  }, [productId])

  useEffect(() => {
    listCategoryRequest.mutate({})

    const cartChannel = new BroadcastChannel('delete-product-admin')
    cartChannel.onmessage = msg => {
      if (productId === msg.data?.data) {
        productRequest.mutate(productId)
      }
    }
  }, [])

  if (productRequest.isSuccess && !product) {
    navigate('/404')
  }

  return (
    <div className={classes['product-detail-container']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <Breadcrumbs sx={{ position: 'relative', zIndex: 1 }}>
        <Link color="red" to={APP_URL.SHOP_ONLINE}>
          <Typography variant="body2" color="primary">
            {t('pharmacyOnline.pharmacyOnline')}
          </Typography>
        </Link>
        <Link
          color="red"
          to={APP_URL.SHOP_ONLINE_BY_CATEGORY?.replace(
            ':slug',
            `${category?.categoryId}-${toSlug(category?.categoryName)}`
          )}
        >
          <Typography variant="body2" color="primary">
            {category?.categoryName}
          </Typography>
        </Link>
        <Typography color="#798395">{product?.productName}</Typography>
      </Breadcrumbs>

      <Grid container spacing={10} pt={2}>
        <Grid item xs={12} md={5}>
          <ImageSlider product={product} />
        </Grid>
        <Grid item xs={12} md={7}>
          <ProductSummary product={product} />
        </Grid>
      </Grid>
      <Grid container spacing={4} pt={4}>
        <Grid item xs={12} md={9}>
          <ProductInfo product={product} />
        </Grid>
        <Grid item xs={12} md={3}>
          <ShopInfo shop={shopDetail} product={product} />
        </Grid>
      </Grid>

      {!!similarProducts?.length && (
        <Box mt={6}>
          <ProductList
            title={t('pharmacyOnline.product.similarProduct')}
            products={similarProducts}
          />
        </Box>
      )}
    </div>
  )
}

export default ProductDetail
