import { APP_URL } from 'src/config'
import { TRoute } from 'src/@types/common'
import { Loadable } from 'src/routes/elements'
import { lazy } from 'react'
import OnlineMedicalBookingPhone from './AppointmentOnlinePhone.screen'
import OnlineMedicalBookingHome from './AppointmentOnlineHome.screen'
import OnlineMedicalBookingClinic from './AppointmentOnlineClinic'
import OnlineMedicalBookingFastPhone from './AppointmentOnlineFastPhone.screen'

// const OnlineMedicalBookingPhone = Loadable(
//   lazy(() => import('./AppointmentOnlinePhone.screen'))
// )
// const OnlineMedicalBookingHome = Loadable(
//   lazy(() => import('./AppointmentOnlineHome.screen'))
// )
// const OnlineMedicalBookingClinic = Loadable(
//   lazy(() => import('./AppointmentOnlineClinic'))
// )
// const OnlineMedicalBookingFastPhone = Loadable(
//   lazy(() => import('./AppointmentOnlineFastPhone.screen'))
// )

export const AppointmentOnlineRoutes: TRoute[] = [
  {
    path: APP_URL.ONLINE_MEDICAL_BOOKING_PHONE,
    element: <OnlineMedicalBookingPhone />,
  },
  {
    path: APP_URL.ONLINE_MEDICAL_BOOKING_HOME,
    element: <OnlineMedicalBookingHome />,
  },
  {
    path: APP_URL.ONLINE_MEDICAL_BOOKING_CLINIC,
    element: <OnlineMedicalBookingClinic />,
  },
  {
    path: APP_URL.ONLINE_MEDICAL_BOOKING_FAST_PHONE,
    element: <OnlineMedicalBookingFastPhone />,
  },
]
