import { useEffect } from 'react'
import { useLocales } from 'src/locales'
import AuthCreateNewPassword from './components/AuthCreateNewPassword'
import AuthForgotPassword from './components/AuthForgotPassword'
import AuthInputCode from './components/AuthInputCode'
import ConfirmDialog from './components/common/ConfirmDialog'
import { useForgotPasswordContext, withForgotPasswordContext } from './context'
import { ForgotPasswordStep } from './types'

export interface IForgotPasswordDialogProps {
  open: boolean
  onClose: VoidFunction
}

function ForgotPasswordDialog({ open, onClose }: IForgotPasswordDialogProps) {
  const { translate } = useLocales()
  const { step, goToStep, resetForgotPasswordData } = useForgotPasswordContext()

  let component = <></>
  let title = ''

  switch (step) {
    case ForgotPasswordStep.InputPhoneNumber:
      component = <AuthForgotPassword />
      title = translate('forgotPasswordPage.you_forgot_password')
      break
    case ForgotPasswordStep.InputOtpCode:
      component = <AuthInputCode />
      title = translate('forgotPasswordPage.input_otp')
      break
    case ForgotPasswordStep.InputNewPassword:
      component = <AuthCreateNewPassword onSuccess={onClose} />
      title = translate('forgotPasswordPage.create_new_password')
      break
    default:
      break
  }

  useEffect(() => {
    if (open) {
      goToStep(ForgotPasswordStep.InputPhoneNumber)
      resetForgotPasswordData({ accountType: 'partner' })
    }
  }, [open])

  return (
    <>
      <ConfirmDialog
        title={title}
        content={component}
        open={open}
        onClose={onClose}
        sx={{
          backgroundColor: '#004E7399',
        }}
      />
    </>
  )
}

export default withForgotPasswordContext(ForgotPasswordDialog)
