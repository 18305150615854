import md5 from 'md5'
import { TypeLogin } from 'src/sections/auth/enum'
import { generateSignatureUser, logJson } from 'src/utils'
import { InstanceAxios } from '../config/axios/user'
import { getIpClient } from './system'

interface IOtpModel {
  typeOtp: 'Registry' | 'ForgotPass' // Registry, ForgotPass
  phoneNumber: string
  email?: string
  codeOtp?: string
}

export const registerPatient = (body?: any): Promise<any> => {
  const path = '/api/patient/AuthPatient/register'
  return InstanceAxios.post(path, body)
}

export const loginPatient = (requestModel?: any): Promise<any> => {
  const path = '/api/patient/AuthPatient/login'
  const body = {
    typeLogin: requestModel?.typeLogin,
    username: '',
    phoneNumber:
      requestModel?.userName === TypeLogin.PHONE
        ? requestModel?.userName
        : requestModel?.phoneNumber,
    email: requestModel?.userName,
    password: md5(requestModel?.password),
    code2FA: '',
  }
  return InstanceAxios.post(path, body)
}

export const onFacebookLogin = async (requestModel: any): Promise<any> => {
  const path = '/api/patient/authpatient/facebooksignin'
  const body = {
    typeLogin: 'Facebook',
    access_token: requestModel?.data?.accessToken,
    profile: {
      id: requestModel?.data?.id,
      name: requestModel?.data?.name,
      email: requestModel?.data?.email,
      first_name: requestModel?.data?.name,
      last_name: requestModel?.data?.name,
      picture: requestModel?.data?.picture?.url,
    },
  }

  return InstanceAxios.post(path, body)
}

export const onGoogleLogin = async (requestModel: any): Promise<any> => {
  const path = '/api/patient/authpatient/googlesignin'
  const body = {
    typeLogin: 'Google',
    access_token: requestModel?.data?.accessToken,
    id_token: requestModel?.data?.tokenId ?? '',
    profile: {
      id: requestModel?.data?.sub,
      name: requestModel?.data?.name,
      email: requestModel?.data?.email,
      family_name: requestModel?.data?.family_name,
      given_name: requestModel?.data?.given_name,
      picture: requestModel?.data?.picture,
    },
  }

  return InstanceAxios.post(path, body)
}

export const logoutPatient = (body?: any): Promise<any> => {
  const path = '/api/patient/AuthPatient/logout'
  return InstanceAxios.post(path, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const sendOtpPatient = async (requestModel: IOtpModel): Promise<any> => {
  const path = '/api/patient/OtpPatient/sendotp'

  const body = {
    typeOtp: requestModel?.typeOtp, // Registry, ForgotPass
    phoneNumber: requestModel?.phoneNumber,
    email: requestModel?.email,
  }

  const ipClientRes = await getIpClient()
  const timestamp = ipClientRes?.timestamp
  // const timestamp = new Date().getTime()
  const [signature] = generateSignatureUser(body, timestamp)

  return InstanceAxios.post(path, JSON.stringify(btoa(JSON.stringify(body))), {
    headers: {
      'x-request-timestamp': timestamp.toString(),
      'x-signature': signature,
    } as any,
  })
}

export const verifyOtpPatient = async (requestModel: IOtpModel): Promise<any> => {
  const path = '/api/patient/OtpPatient/VerifyOtp'

  const body = {
    typeOtp: requestModel.typeOtp, // Registry, ForgotPass
    phoneNumber: requestModel.phoneNumber,
    codeOtp: requestModel.codeOtp,
  }

  const ipClientRes = await getIpClient()
  const timestamp = ipClientRes?.timestamp
  // const timestamp = new Date().getTime()
  const [signature] = generateSignatureUser(body, timestamp)

  return InstanceAxios.post(path, JSON.stringify(btoa(JSON.stringify(body))), {
    headers: {
      'x-request-timestamp': timestamp.toString(),
      'x-signature': signature,
    } as any,
  })
}

export const checkPhoneNumberPatient = (phoneNumber: string): Promise<any> => {
  const path = '/api/patient/AuthPatient/check'
  return InstanceAxios.post(
    path,
    {
      phoneNumber,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
}
