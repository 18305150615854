import { ENVIRONMENT, appConfig } from '../config'

export const logString = (key: string, value: string) => {
  if (appConfig.NODE_ENV === ENVIRONMENT.DEVELOPMENT) {
    console.log(key + ': ' + value)
  }
}

export const logJson = (key: string, value: any) => {
  if (appConfig.NODE_ENV === ENVIRONMENT.DEVELOPMENT) {
    console.log(key + ': ' + JSON.stringify(value))
  }
}

export const logDev = (...value: any[]) => {
  if (appConfig.NODE_ENV === ENVIRONMENT.DEVELOPMENT) {
    console.log(...value)
  }
}
