import { Col, Row } from 'antd'
import LazyLoad from 'react-lazyload'
import {
  IconHospital1,
  IconHospital2,
  IconHospital3,
  IconHospital4,
  IconHospital5,
  IconHospital6,
} from 'src/assets'
import classes from './HospitalIcon.module.less'

export const RenderHospitalIcon = () => {
  const RenderItemHospital = ({ Image }: any) => {
    return (
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={4}
        xxl={4}
        className={classes['item']}
      >
        <LazyLoad once height={100}>
          <img src={Image} alt="" />
        </LazyLoad>
      </Col>
    )
  }

  return (
    <Row justify="center" align={'middle'}>
      <Col>
        <Row
          align={'middle'}
          justify="center"
          className={classes['hospital-bar']}
        >
          <RenderItemHospital Image={IconHospital1} />
          <RenderItemHospital Image={IconHospital2} />
          <RenderItemHospital Image={IconHospital3} />
          <RenderItemHospital Image={IconHospital4} />
          <RenderItemHospital Image={IconHospital5} />
          <RenderItemHospital Image={IconHospital6} />
        </Row>
      </Col>
    </Row>
  )
}
