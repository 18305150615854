import { APP_URL } from 'src/config'
import { TRoute } from 'src/@types/common'
import { Loadable } from 'src/routes/elements'
import { lazy } from 'react'
import Contact from './Contact.screen'

// const Contact = Loadable(lazy(() => import('./Contact.screen')))

export const ContactSophieRoutes: TRoute[] = [
  {
    path: APP_URL.CONTACT_SHOPIE,
    element: <Contact />,
  },
]
