import { yupResolver } from '@hookform/resolvers/yup'
import {
  Close,
  VisibilityOffRounded,
  VisibilityRounded,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
import md5 from 'md5'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useChangePasswordPatient } from 'src/api/hooks/patient.query'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import { useLocales } from 'src/locales'
import ForgotPasswordUserDialog from 'src/sections/auth/forgot-password/ForgotPasswordUserDialog'
import * as Yup from 'yup'

export interface IPUpdatePassWordProps {
  open: boolean
  onClose: VoidFunction
}

export const UpdatePassWorDialog = ({
  open,
  onClose,
}: IPUpdatePassWordProps) => {
  const changePasswordRequest = useChangePasswordPatient()
  const { profile, updateProfile } = useAuthUserContext()
  const { translate } = useLocales()
  const { enqueueSnackbar } = useSnackbar()
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showNewPasswordAgain, setShowNewPasswordAgain] = useState(false)
  const [isOpenForgotPasswordDialog, setOpenForgotPasswordDialog] =
    useState(false)

  const { t } = useTranslation()

  const alertFieldRequired = translate('alertSection.fieldIsRequired')
  const passwordMin = translate('forgotPasswordPage.minPassword')
  const passwordMax = translate('forgotPasswordPage.maxPassword')

  const ChangePassword = Yup.object().shape({
    oldPassword: Yup.string().required(alertFieldRequired),
    newPassword: Yup.string()
      .required(alertFieldRequired)
      .min(8, passwordMin)
      .max(20, passwordMax),
    confirmPassword: Yup.string()
      .required(alertFieldRequired)
      .oneOf([Yup.ref('newPassword')], 'Mật khẩu chưa khớp'),
  })

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
  }

  const methods = useForm<typeof defaultValues>({
    resolver: yupResolver(ChangePassword),
    defaultValues,
  })

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      const payload = {
        oldPassword: md5(data?.oldPassword),
        newPassword: data?.newPassword,
        accountId: profile?.accountId,
      }
      await changePasswordRequest.mutateAsync(payload)
      await updateProfile()
      enqueueSnackbar(t('userProfile.personalInformation.updateSuccess'))
      onClose()
    } catch (error) {}
  }

  useEffect(() => {
    if (open) {
      reset(defaultValues)
    }
  }, [open])

  const RenderEyeOldPasswordIcon = (
    <InputAdornment position="end">
      <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
        {showCurrentPassword ? <VisibilityRounded /> : <VisibilityOffRounded />}
      </IconButton>
    </InputAdornment>
  )

  const RenderEyeNewPasswordIcon = (
    <InputAdornment position="end">
      <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
        {showNewPassword ? <VisibilityRounded /> : <VisibilityOffRounded />}
      </IconButton>
    </InputAdornment>
  )

  const RenderEyeConfirmPasswordIcon = (
    <InputAdornment position="end">
      <IconButton
        onClick={() => setShowNewPasswordAgain(!showNewPasswordAgain)}
      >
        {showNewPasswordAgain ? (
          <VisibilityRounded />
        ) : (
          <VisibilityOffRounded />
        )}
      </IconButton>
    </InputAdornment>
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          component="div"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h5" color="primary.main">
            {t('userProfile.personalInformation.changePassword')}
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="#798395">
                  {t('userProfile.personalInformation.currentPassword')}
                </Typography>
                <div onClick={() => setOpenForgotPasswordDialog(true)}>
                  <Typography
                    variant="body2"
                    color="#1a9cc6"
                    sx={{ cursor: 'pointer' }}
                  >
                    {t('userProfile.personalInformation.forgotPassword')}
                  </Typography>
                </div>
              </Stack>
              <RHFTextField
                variant="outlined"
                fullWidth
                name="oldPassword"
                type={showCurrentPassword ? 'text' : 'password'}
                placeholder={t(
                  'userProfile.personalInformation.inputCurrentPassword'
                )}
                InputProps={{
                  endAdornment: RenderEyeOldPasswordIcon,
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="#798395">
                {t('userProfile.personalInformation.newPassword')}
              </Typography>
              <RHFTextField
                variant="outlined"
                fullWidth
                type={showNewPassword ? 'text' : 'password'}
                name="newPassword"
                placeholder={t(
                  'userProfile.personalInformation.inputNewPassword'
                )}
                InputProps={{
                  endAdornment: RenderEyeNewPasswordIcon,
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="#798395">
                {t('userProfile.personalInformation.confirmPassword')}
              </Typography>

              <RHFTextField
                variant="outlined"
                fullWidth
                type={showNewPasswordAgain ? 'text' : 'password'}
                name="confirmPassword"
                placeholder={t(
                  'userProfile.personalInformation.confirmPassword'
                )}
                InputProps={{
                  endAdornment: RenderEyeConfirmPasswordIcon,
                }}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('userProfile.personalInformation.saveNewPassword')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
      <ForgotPasswordUserDialog
        open={isOpenForgotPasswordDialog}
        onClose={() => setOpenForgotPasswordDialog(false)}
      />
    </Dialog>
  )
}
