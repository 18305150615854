import React from 'react'
import classes from './Policy.module.less'
import { WrapPolicy } from './Components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

export const PolicySendBack = () => {
  const { t } = useTranslation()
  return (
    <WrapPolicy>
      <Helmet>
        <title>Policy | Sophie</title>
      </Helmet>
      <div className={classes['root']}>
        <h1 className={classes['title-policy']}>{t('POLICY.PAYBACK.TITLE')}</h1>
        <div className="mt-3">
          <div className={classes['terms-text']}>
            <h3> {t('POLICY.PAYBACK.CONDITION')}</h3>
            <p> {t('POLICY.PAYBACK.PRODUCT')}</p>
          </div>
          <div className={classes['terms-text']}>
            <h3> {t('POLICY.PAYBACK.BACK')}</h3>
            <p> {t('POLICY.PAYBACK.CUS')}</p>
            <ul>
              <li> {t('POLICY.PAYBACK.CHECKOUT')}</li>
              <li> {t('POLICY.PAYBACK.ERROR')}</li>
              <li> {t('POLICY.PAYBACK.WRONG')}</li>
              <li> {t('POLICY.PAYBACK.CLEAR')}</li>
            </ul>
          </div>
          <div className={classes['terms-text']}>
            <h3> {t('POLICY.PAYBACK.NO_CHANGE')}</h3>
            <p> {t('POLICY.PAYBACK.NO_ACCEPT')} </p>
          </div>
          <div className={classes['terms-text']}>
            <h3> {t('POLICY.PAYBACK.CONDITION_BACK')}</h3>
            <p> {t('POLICY.PAYBACK.PRODUCT_TYPE')}</p>
          </div>
          <div className={classes['terms-text']}>
            <h3> {t('POLICY.PAYBACK.FEE')}</h3>
            <p> {t('POLICY.PAYBACK.RETURN')}</p>
          </div>
          <div className={classes['terms-text']}>
            <h3> {t('POLICY.PAYBACK.NOTICE')}</h3>
            <p> {t('POLICY.PAYBACK.USER')}</p>
            <p>
              <b> {t('POLICY.PAYBACK.LOOK')}</b> {t('POLICY.PAYBACK.BANK')}
            </p>
          </div>
        </div>
      </div>
    </WrapPolicy>
  )
}

export default PolicySendBack
