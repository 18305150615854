import { DeleteRounded } from '@mui/icons-material'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  useDeleteAddressPatient,
  useGetListAddressPatient,
} from 'src/api/hooks/patient.query'
import ConfirmDialog from 'src/components/confirm-dialog/ConfirmDialog'
import Label from 'src/components/label/Label'
import { APP_URL } from 'src/config'
import './AddressList.module.less'
import classes from './AddressList.module.less'

export const AddressList = () => {
  const { t } = useTranslation()
  const [addressIdToDelete, setAddressIdToDelete] = useState('')
  const listAddressRequest = useGetListAddressPatient()
  const deleteAddressRequest = useDeleteAddressPatient()
  const { enqueueSnackbar } = useSnackbar()

  const addresses: any[] = listAddressRequest?.data?.data || []

  const handleDeleteAddress = async () => {
    try {
      await deleteAddressRequest.mutateAsync(addressIdToDelete)
      enqueueSnackbar(t('userProfile.address.deleteAddressSuccess'))
      setAddressIdToDelete('')
      listAddressRequest.mutate({})
    } catch (error) {
      enqueueSnackbar(t('userProfile.address.deleteAddressFailed'), {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    listAddressRequest.mutate({})
  }, [])

  return (
    <div className={classes['address-list']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <Typography variant="h4" fontWeight={700}>
        {t('userProfile.address.listAddress')}
      </Typography>
      {addresses.map((item, index) => (
        <div key={index} className={classes['item-address']}>
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle1">{item.fullName}</Typography>
              {!!item?.isDefault && (
                <Label color="primary">Địa chỉ mặc định</Label>
              )}
            </Stack>
            <Stack direction="row" spacing={1}>
              <img
                src="/assets/shop-online/Profile/apartment.png"
                alt="address"
              />
              <Typography variant="subtitle2" color="#576071">
                {item?.typeAddress === 'Home' ? 'Nhà riêng: ' : 'Cơ quan: '}
                {[
                  item.addressAccount,
                  item?.wardsName,
                  item?.districtName,
                  item?.provinceName,
                ].join(', ')}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <img src="/assets/shop-online/Profile/calladd.png" alt="phone" />
              <Typography className={classes['number-phone']}>
                {item.phone}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Link
              to={APP_URL.USER_PROFILE_ADDRESS_UPDATE?.replace(
                ':id',
                item?.addressId
              )}
            >
              <IconButton size="small">
                <img src="/assets/shop-online/Profile/default.png" alt="" />
              </IconButton>
            </Link>
            <div>
              <IconButton
                size="small"
                color="error"
                onClick={() => setAddressIdToDelete(item?.addressId)}
              >
                <DeleteRounded />
              </IconButton>
            </div>
          </Stack>
        </div>
      ))}
      <Link to={APP_URL.USER_PROFILE_ADDRESS_CREATE}>
        <Button variant="contained" fullWidth sx={{ mt: 2 }}>
          {t('userProfile.address.addAddress')}
        </Button>
      </Link>

      <ConfirmDialog
        open={!!addressIdToDelete}
        onClose={() => setAddressIdToDelete('')}
        title={t(`userProfile.address.deleteAddress`)}
        content={t(`userProfile.address.deleteAddressConfirm`)}
        action={
          <Button
            variant="contained"
            disabled={deleteAddressRequest.isLoading}
            color="error"
            onClick={handleDeleteAddress}
          >
            {t(`Delete`)}
          </Button>
        }
      />
    </div>
  )
}
