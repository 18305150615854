import { TRoute } from 'src/@types/common'
import { APP_URL } from 'src/config'
import { AddressList } from './AddressList.screen'
import { CreateAddress } from './CreateAddress.screen'
import { UpdateAddress } from './UpdateAddress.screen'

export const AddressListRoutes: TRoute[] = [
  {
    path: APP_URL.USER_PROFILE_ADDRESS_LIST,
    element: <AddressList />,
  },
  {
    path: APP_URL.USER_PROFILE_ADDRESS_CREATE,
    element: <CreateAddress />,
  },
  {
    path: APP_URL.USER_PROFILE_ADDRESS_UPDATE,
    element: <UpdateAddress />,
  },
]
