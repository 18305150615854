import { Tabs, TabsProps } from '@mui/material'
import { styled } from '@mui/material/styles'

interface FullWidthTabProps extends TabsProps {}

export const FullWidthTabs = styled(Tabs)<FullWidthTabProps>(
  (props: FullWidthTabProps) => ({
    '.MuiTabs-flexContainer': {
      width: '100%',
      display: 'flex',
      button: {
        maxWidth: '50%',
        margin: 0,
      },
    },
    '.MuiTabs-indicator': {
      height: '3px',
      borderRadius: '10px 10px 0 0 ',
    },
    '.Mui-selected': {
      color: 'primary.main',
    },
  })
)
