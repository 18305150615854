import { useEffect, useState } from 'react'
import * as Yup from 'yup'
// form
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
// @mui
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
// components
import FormProvider, { RHFTextField } from '../../components/hook-form'
import Iconify from '../../components/iconify'
// locales
import { useLocales } from '../../locales'
// hooks
import { StorageService } from 'src/api'
import {
  getCodeHandshakePartner,
  saveCodeHandshakePartner,
} from 'src/api/config/token'
import {
  useCheckOperatingSystem,
  useGetIpClient,
  useHandShake,
} from 'src/api/hooks/system.query'
import { logDev } from 'src/utils'
import ForgotPasswordDialog from './forgot-password/ForgotPasswordDialog'
import useAuthLoginForm from './useAuthLoginForm'

type FormValuesProps = {
  userName: string
  password: string
  afterSubmit?: string
}

export default function AuthLoginForm() {
  const { translate } = useLocales()
  const [showPassword, setShowPassword] = useState(false)
  const [isOpenForgotPasswordDialog, setOpenForgotPasswordDialog] =
    useState(false)
  const { handleClickOpen, handleLogin } = useAuthLoginForm()
  const checkSystem = useCheckOperatingSystem()
  const ipClient = useGetIpClient()
  const doHandshake = useHandShake()

  const LoginSchema = Yup.object().shape({
    userName: Yup.string().required(
      translate('loginPage.userNameRequired') + ''
    ),
    password: Yup.string().required(
      translate('loginPage.passwordRequired') + ''
    ),
  })

  const defaultValues = {
    userName: '',
    password: '',
  }

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods

  const onSubmit = (data: FormValuesProps) => {
    handleLogin(data)
  }

  const handleGetHandshakePartner = async (responseIp: any) => {
    const handshakeRes = await doHandshake.mutateAsync({
      ...responseIp,
      type: 'partner',
    })
    saveCodeHandshakePartner(handshakeRes?.xHandshake || '')
    // logDev('TOKEN_HANDSHAKE_PARTNER ', handshakeRes?.xHandshake)
    StorageService.set('AES_KEY_PARTNER', handshakeRes?.xSecretKey)
    StorageService.set('AES_IV_PARTNER', handshakeRes?.xIVKey)
  }

  const handleHandShake = async () => {
    var xHandshake = getCodeHandshakePartner()
    if (!!xHandshake) {
      return
    }
    const checkSysRes = await checkSystem.mutateAsync()
    if (!checkSysRes?.isEnable) {
      return
    }
    const ipClientRes = await ipClient.mutateAsync()
    var responseIp = {
      ipClient: ipClientRes?.listIpClient?.x_Real_IP,
      dateTime: ipClientRes?.dateTime,
      timestamp: ipClientRes?.timestamp,
    }

    await handleGetHandshakePartner(responseIp)
  }

  // do HandShake
  useEffect(() => {
    handleHandShake()
  }, [])

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Typography
            variant="subtitle2"
            color="#798395"
            sx={{
              marginBottom: { xs: '8px', md: 0 },
            }}
          >
            {translate('loginPage.phone_or_email_register')}
          </Typography>
          <RHFTextField
            name="userName"
            placeholder={translate('loginPage.input_phone_or_email') + ''}
            sx={{
              borderRadius: '8px',
              marginTop: '8px !important',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px !important',
            }}
          >
            <Typography variant="subtitle2" color="#798395">
              {translate('password')}
            </Typography>
            <Stack>
              <Button
                variant="text"
                // onClick={handleClickOpen}
                onClick={() => setOpenForgotPasswordDialog(true)}
                sx={{
                  '&:hover': {
                    background: 'none',
                  },
                }}
              >
                <Typography variant="body2" color="#1A9CC6">
                  {translate('forgot_password')}
                </Typography>
              </Button>
            </Stack>
          </Box>
          <RHFTextField
            name="password"
            placeholder={translate('loginPage.input_password') + ''}
            type={showPassword ? 'text' : 'password'}
            sx={{
              borderRadius: '8px',
              marginTop: '8px !important',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{
            bgcolor: '#0F8FB9',
            color: theme =>
              theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
            '&:hover': {
              bgcolor: '#0F8FB9',
              color: theme =>
                theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
            },
            marginTop: '24px',
          }}
        >
          {translate('login')}
        </LoadingButton>
        {/* <Typography
        variant="body1"
        color="#798395"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
          marginBottom: '16px',
        }}
      >
        {translate('loginPage.or_login_by')}
      </Typography> */}
      </FormProvider>
      <ForgotPasswordDialog
        open={isOpenForgotPasswordDialog}
        onClose={() => setOpenForgotPasswordDialog(false)}
      />
    </>
  )
}
