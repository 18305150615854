import React from 'react'
import classes from './SophieHiringDetail.module.less'
import { Col, Row, Typography } from 'antd'
import { RightNav } from '../RightNav'
import { useTranslation } from 'react-i18next'

export type TSophieHiringDetailProps = {}

export const SophieHiringDetail: React.FC<
  TSophieHiringDetailProps
> = ({}: TSophieHiringDetailProps) => {
  const { t } = useTranslation()

  return (
    <Row className={classes['hiring-detail-container']}>
      <Row className={classes['hiring-detail']} gutter={[0, 40]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
          <Row>
            <div
              className={classes['title']}
              dangerouslySetInnerHTML={{
                __html: t('SOPHIE_HIRING_DETAIL.TITLE'),
              }}
            ></div>
          </Row>
          <Row>
            <Typography.Text>
              {t('SOPHIE_HIRING_DETAIL.SUBTITLE')}
            </Typography.Text>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          <RightNav />
        </Col>
      </Row>
    </Row>
  )
}

export default SophieHiringDetail
