import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useCheckPromotionByCodePatient,
  useValidatePromotionPatient,
} from 'src/api/hooks/patient.query'
import { FORMAT_TIME } from 'src/constants'
import { logDev } from 'src/utils'
import { useCartContext } from '../../context'
import { RadioOverlay } from '../common/RadioOverlay'

interface IAddPromotionDialogProps {
  open: boolean
  onClose: VoidFunction
  transportPromotions: any[]
}

export const AddPromotionDialog: FC<IAddPromotionDialogProps> = ({
  open,
  onClose,
  transportPromotions,
}: IAddPromotionDialogProps) => {
  const { t } = useTranslation()
  const { order, updateOrder } = useCartContext()
  const { cartData, updateCartData } = useCartContext()
  const validatePromotionRequest = useValidatePromotionPatient()
  const checkPromotionByCodeRequest = useCheckPromotionByCodePatient()
  const [transportPromotionSelected, setTransportPromotionSelected] =
    useState<any>()
  const [discountPromotionSelected, setDiscountPromotionSelected] =
    useState<any>()
  const [promotionCodeSearch, setPromotionCodeSearch] = useState('')
  const [isLoading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const transportPromotionIds = []
      if (transportPromotionSelected?.transportPromotionId) {
        transportPromotionIds.push(
          transportPromotionSelected?.transportPromotionId
        )
      }
      const promotionIds = []
      if (discountPromotionSelected?.promotionId) {
        promotionIds.push(discountPromotionSelected?.promotionId)
      }
      const validateRes = await validatePromotionRequest.mutateAsync({
        listProduct: order.listProduct,
        typePay: order?.typePay || cartData?.typePay,
        transportPromotionIds: transportPromotionIds,
        promotionIds: promotionIds,
      })

      if (!validateRes?.result) {
        if (!!validateRes?.message) {
          enqueueSnackbar(validateRes?.message, { variant: 'error' })
        }
        setLoading(false)
        return
      }

      await updateOrder({ transportPromotionIds, promotionIds })

      if (!!validateRes?.result && !!validateRes?.message) {
        enqueueSnackbar(validateRes?.message)
      }

      onClose()
    } catch (error) {
      logDev('Cart.AddPromotionDialog.handleSubmit', error)
    }
    setLoading(false)
  }

  const handleApplyPromotionCode = async () => {
    if (!promotionCodeSearch) {
      return
    }
    try {
      const res = await checkPromotionByCodeRequest.mutateAsync({
        listProduct: order.listProduct,
        typePay: order?.typePay || cartData?.typePay,
        promotionCode: promotionCodeSearch,
      })
      if (!res?.result && !!res?.message) {
        enqueueSnackbar(res?.message, { variant: 'error' })
      }
      if (!!res?.result) {
        setDiscountPromotionSelected(res?.data)
        if (!!res?.message) {
          enqueueSnackbar(res?.message)
        }
      }
    } catch (error) {
      logDev('Cart.AddPromotionDialog.handleApplyPromotionCode', error)
    }
  }

  useEffect(() => {
    const handleSetDiscountPromotionSelected = () => {
      setPromotionCodeSearch('')
      if (!open || !order?.promotionIds?.[0]) {
        return
      }
      setDiscountPromotionSelected({
        promotionId: order?.promotionIds?.[0],
      })
    }
    const handleSetTransportPromotionSelected = () => {
      setPromotionCodeSearch('')
      if (!open || !order?.transportPromotionIds?.[0]) {
        return
      }
      setTransportPromotionSelected(
        transportPromotions?.find(
          item =>
            item?.transportPromotionId === order?.transportPromotionIds?.[0]
        )
      )
    }
    handleSetDiscountPromotionSelected()
    handleSetTransportPromotionSelected()
  }, [open, order])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      sx={{
        backgroundColor: '#004E7399',
      }}
    >
      <DialogTitle
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ py: 2 }}
      >
        <Typography variant="h5" color="primary.main">
          {t('cart.addPromotion')}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="#798395">
          {t('cart.enterOrSelectPromotionBelow')}:
        </Typography>
        <Stack direction="row" spacing={1}>
          <TextField
            size="small"
            value={promotionCodeSearch}
            onChange={e => setPromotionCodeSearch(e.target.value)}
            sx={{ flexGrow: 1 }}
            placeholder={t('cart.inputPromotion')}
          />
          <LoadingButton
            loading={checkPromotionByCodeRequest.isLoading}
            variant="contained"
            onClick={handleApplyPromotionCode}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {t('cart.apply')}
          </LoadingButton>
        </Stack>
        <Typography variant="subtitle2" mt={2}>
          {t('cart.transportPromotion')} ({transportPromotions?.length || 0})
        </Typography>
        <Stack spacing={1} mt={1}>
          {transportPromotions.map((item, i) => (
            <RadioOverlay
              checked={
                transportPromotionSelected?.transportPromotionId ===
                item?.transportPromotionId
              }
              onClick={() => setTransportPromotionSelected(item)}
              sx={{ p: 0, overflow: 'hidden' }}
              key={i}
            >
              <Box borderLeft="4px solid #1A9CC6" p={2}>
                <Typography variant="subtitle1" color="#576071">
                  {item?.transportPromotionName}
                </Typography>
                <Typography variant="caption" color="#798395">
                  {t('cart.exp')}:{' '}
                  {moment(item?.endDate).format(FORMAT_TIME.DATE1)}
                </Typography>
              </Box>
            </RadioOverlay>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          fullWidth
          onClick={handleSubmit}
        >
          {t('confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
