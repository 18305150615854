import { Col, Row, Typography } from 'antd'
import React from 'react'
import classes from './AppointmentStep.module.less'

function AppointmentStep({
  imageUrl,
  stepTitle,
  imgService,
  noteText,
  number,
}) {
  return (
    <Col
      data-aos="fade-up"
      lg={5}
      md={9}
      xs={20}
      className={classes['appoitment-step']}
    >
      <div className={classes['image-step']}>
        <img className={classes['appoitment-image']} src={imageUrl} alt="" />
        <div className={classes['overlay']}></div>
      </div>
      <Row align="middle" className={classes['step-title']}>
        <Col className={classes['icon']}>
          <img src={imgService} alt="" />
          <Typography.Text className={classes['number']}>
            {number}
          </Typography.Text>
        </Col>
        <Typography.Text className={classes['title']}>
          {stepTitle}
        </Typography.Text>
      </Row>
      <Typography.Text className={classes['note']}>{noteText}</Typography.Text>
    </Col>
  )
}

export default AppointmentStep
