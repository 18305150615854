import { Grid } from '@mui/material'
import { Col, Pagination, Row, Typography } from 'antd'
import React, { useEffect, useMemo, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import {
  useGetLatestTopNews,
  useGetListCategoryNews,
} from 'src/api/hooks/blog.query'
import { useGetListNewsParallel } from 'src/api/hooks/guestDashboard.query'
import { IconRectangle1203, SearchIcon } from 'src/assets'
import { Loading } from 'src/components/loading'
import useResponsive from 'src/hooks/useResponsive'
import classes from './BlogHeal.module.less'
import './BlogHeal.scss'
import BlogNewsItem from './Components/BlogNewsItem'

export type Props = {}

const DEFAULT_PAGE_SIZE = 4

export const BlogListNewsItem: React.FC<Props> = ({}: Props) => {
  const { name } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const ref: any = useRef()
  // const [searchText, setSearchText] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const isMd = useResponsive('up', 'md')

  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname, location.search])

  const skipParam: any = searchParams.get('skip') || '0'
  let payloadCategoryTitle = {
    version: 'v1.0',
    subjectType: 'Sức khoẻ hàng ngày',
  }

  const newsByCategory = useGetListNewsParallel([name] || [], 'v1.0', {
    skip: +skipParam,
    limit: DEFAULT_PAGE_SIZE,
  })

  const TopNews = useGetLatestTopNews(payloadCategoryTitle, {
    skip: 0,
    limit: 3,
  })
  const categoryNews = useGetListCategoryNews(payloadCategoryTitle)

  const listNewsByCategory = useMemo(() => {
    return !!newsByCategory && newsByCategory.map((item: any) => item.data)
  }, [newsByCategory])

  const listTopNews = useMemo(() => {
    if (TopNews.isSuccess) {
      return TopNews.data
    }
  }, [TopNews.isSuccess])

  const listCategoryNews = useMemo(() => {
    if (categoryNews.isSuccess) {
      return categoryNews.data
    }
  }, [categoryNews.isSuccess])

  const backToBlog = () => {
    navigate(generatePath('/blog-heal'))
  }

  const onSearch = () => {
    const value = ref.current.value
    if (!!value && !!value.trim()) {
      navigate({
        pathname: '/blog-heal/search',
        search: `?keywords=${value}`,
      })
    }
  }

  // const handleValue = (e: any) => {}

  const handleKeyPress = (e: any) => {
    const valueInput = e.target.value
    if (e.key === 'Enter' && !!valueInput) {
      if (!!valueInput.trim()) {
        navigate({
          pathname: '/blog-heal/search',
          search: `?keywords=${valueInput.trim()}`,
        })
      }
    }
  }

  if (newsByCategory[0].isLoading || TopNews.isLoading) {
    return <Loading />
  }

  const { skip, limit, total } = listNewsByCategory[0]

  const onChangePage = (page: number, pageSize: number) => {
    const currentPage = skip ? skip / limit + 1 : 1
    if (currentPage < page) {
      setSearchParams({ skip: (page - 1) * pageSize + '' })
    } else {
      setSearchParams({
        skip: (page === 1 ? 0 : (page - 1) * pageSize) + '',
      })
    }
  }

  const SearchBox = (
    <div className={classes['search-box']}>
      <h3 className={classes['title']}>{t('BLOG.SEARCH_TEXT')}</h3>
      <div className={classes['search-input']}>
        <input
          ref={ref}
          placeholder={`${t('BLOG.SEARCH_PLACEHOLDER')}`}
          type="text"
          onKeyUp={handleKeyPress}
        />
        <span onClick={onSearch} className={classes['search-icon']}>
          <img src={SearchIcon} alt="" />
          {/* <SearchIcon /> */}
        </span>
      </div>
    </div>
  )

  return (
    <div id="top" className={`${classes['layout-width']} root-blog`}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <div className={classes['breadcrumb']}>
        <span onClick={backToBlog}>{t('BLOG.LOCATION')}</span>
        <span className={classes['boundary']}>/</span>
        <span>{name}</span>
      </div>

      {!isMd && SearchBox}

      <h2 className={classes['list-news-title']}>{name}</h2>
      <Grid container rowSpacing={6} justifyContent='space-between'>
        <Grid
          item
          data-aos="fade-up"
          xs={12}
          md={7}
          className={classes['list-item']}
        >
          {listNewsByCategory[0]?.data.map((item: any, index: number) => (
            <BlogNewsItem
              key={item.newsId}
              itemData={item}
              reponsive={{ index: index }}
              overwriteClass={true}
            />
          ))}
          <div className="wrap-pagination">
            <Pagination
              onChange={onChangePage}
              defaultCurrent={skip ? skip / limit + 1 : 1}
              pageSize={DEFAULT_PAGE_SIZE}
              total={total}
            />
          </div>
        </Grid>
        <Grid
          item
          data-aos="fade-up"
          className={classes['right-content']}
          xs={12}
          md={4}
        >
          {isMd && SearchBox}
          <div className={classes['list-category-title']}>
            <h3 className={classes['title']}>
              {t('BLOG.LIST_CATEGORY_TITLE')}
            </h3>
            <div className={classes['list-options']}>
              {listCategoryNews?.map((item: string) => (
                <RenderLabelFooter key={item} text={item} />
              ))}
            </div>
          </div>
          <div className={classes['list-top-news']}>
            <h3 className={classes['title']}>
              {t('BLOG.LIST_TOP_NEWS_TITLE')}
            </h3>
            <div className={classes['content']}>
              {listTopNews?.data.map((item: any, index: number) => (
                <BlogNewsItem
                  suggestionsClass={true}
                  key={item.newsId}
                  itemData={item}
                />
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const RenderLabelFooter = ({ text }: any) => {
  const navigate = useNavigate()
  const goToPage = (text: string) => {
    navigate(`/blog-heal/${text}`)
  }
  return (
    <Row justify={{ lg: 'start' }} align="middle" wrap={false}>
      <div className={classes['icon-rectangle']}>
        <img src={IconRectangle1203} alt="" />
      </div>
      <Typography.Text
        onClick={() => goToPage(text)}
        className={classes['option-text']}
      >
        {text}
      </Typography.Text>
    </Row>
  )
}

export default BlogListNewsItem
