import BlogNewsItem from './Components/BlogNewsItem'
import React, { useEffect, useMemo, useRef } from 'react'
import classes from './BlogHeal.module.less'
import { APP_URL } from 'src/config'
import { Col, Row, Typography } from 'antd'
import { IconRectangle1203, SearchIcon } from 'src/assets'
import { Link } from 'react-router-dom'
import { Loading } from 'src/components/loading'
import { getTimeNews } from 'src/utils'
import { useGetDetailNews } from 'src/api/hooks/guestDashboard.query'
import {
  useGetLatestTopNews,
  useGetListCategoryNews,
} from 'src/api/hooks/blog.query'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { margin } from '@mui/system'
import { Helmet } from 'react-helmet-async'

export type Props = {}

export const BlogHeadDetail: React.FC<Props> = ({}: Props) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const ref: any = useRef()
  // const navigate = useNavigate()

  let payloadCategoryTitle = {
    version: 'v1.0',
    subjectType: 'Sức khoẻ hàng ngày',
  }

  const detail = useGetDetailNews(id || '')

  const TopNews = useGetLatestTopNews(payloadCategoryTitle, {
    skip: 0,
    limit: 4,
  })
  const categoryNews = useGetListCategoryNews(payloadCategoryTitle)

  const detailNews = useMemo(() => {
    if (detail.isSuccess) {
      return detail.data
    }
  }, [detail.isSuccess])

  const listTopNews = useMemo(() => {
    if (TopNews.isSuccess) {
      return TopNews.data
    }
  }, [TopNews.isSuccess])

  const listCategoryNews = useMemo(() => {
    if (categoryNews.isSuccess) {
      return categoryNews.data
    }
  }, [categoryNews.isSuccess])

  // const handleValue = (e: any) => {}

  const handleKeyPress = (e: any) => {
    const valueInput = e.target.value
    if (e.key === 'Enter' && !!valueInput) {
      if (!!valueInput.trim()) {
        navigate({
          pathname: '/blog-heal/search',
          search: `?keywords=${valueInput.trim()}`,
        })
      }
    }
  }

  const onSearch = () => {
    const value = ref.current.value
    if (!!value && !!value.trim()) {
      navigate({
        pathname: '/blog-heal/search',
        search: `?keywords=${value}`,
      })
    }
  }

  useEffect(() => {
    if (detail.error || TopNews.error) {
      navigate(`/${APP_URL.BLOG_HEAL_HOME}`)
    }
  }, [detail.error, TopNews.error])

  if (detail.isLoading || TopNews.isLoading) {
    return <Loading />
  }

  const { subject, images, subjectType, dateTime } = detailNews.data

  return (
    <div id="top" className={classes['row-detail']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <div className={classes['breadcrumb-detail']}>
        <Link to={`${APP_URL.BLOG_HEAL_HOME}`}>
          <span className={classes['root-link']}>{t('BLOG.LOCATION')}</span>
        </Link>
        <span className={classes['boundary']}>/</span>
        <Link to={`${APP_URL.BLOG_HEAL_HOME}/${subjectType}`}>
          <span className={classes['root-link']}>{subjectType}</span>
        </Link>
        <span className={classes['boundary']}>/</span>
        <span className={classes['normal-link']}>{subject}</span>
      </div>

      <Row
        gutter={[0, 48]}
        justify={{ lg: 'space-between', md: 'space-between', sm: 'start' }}
      >
        <Col lg={16} md={24} className={classes['list-item']}>
          <h2 className={classes['title-detail']}>{subject}</h2>
          {!!images && images.length > 0 && (
            <div className={classes['img-bg']}>
              <img style={{ borderRadius: '8px' }} alt="" src={images[0]} />
            </div>
          )}
          <div className={classes['addition-blog']}>
            <div className={classes['blog-times']}>
              <span>{getTimeNews(dateTime)}</span>
              <span> • </span>
              <Link
                to={`/${APP_URL.BLOG_HEAL_HOME}/${subjectType}`}
                className={classes['blog-category']}
              >
                {subjectType}
              </Link>
            </div>
            <div className="blog-share">
              <div
                className="fb-like"
                data-href="https://developers.facebook.com/docs/plugins/"
                data-width=""
                data-layout="standard"
                data-action="like"
                data-size="small"
                data-share="true"
              ></div>
              <div
                className="fb-save"
                data-uri="http://www.your-domain.com/your-page.html"
                data-size="small"
              ></div>
              <div>
                <a
                  href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site http://www.website.com."
                  title="Share by Email"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="28"
                    height="20"
                    viewBox="0 0 28 20"
                  >
                    <image
                      width="28"
                      height="20"
                      xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAUCAYAAACeXl35AAADBElEQVRIibWVzW5TVxSFv318rhVMYgJCgf6IglRcGlAUFOOAUEonafsW9QugvkIfJYzKuGVGBy3yCDedgEJbSgdIxP0RKQHHqWzuWR3cH9/YF2bZ0rGO7937rL3X2mdf6/V6urVxi+5Pm4xGQw7DoqhKs7lCu/0l9tXNm9rc/JnhaIikQwE0M6Io4kqrRWXmyJGv9//b53CgEhMQxzHPd3bwe/v7yOwQ4ca2NxjgpfitTlFU5ejRGpGPchYM0v34yWg0YjAYMBwOc6fJMiTwRlw4puAikMSZ999jfX2dRqOB9x6ASqWCBHFIYl+PXvP48W/c/f4uT35/gkh0K+PNuzeIJ4EzB0H8/edfnPvgLBcXF5mdncM5BxghxAz29tja2uLli10IwrsKoDc2oDcZ4EoQwTBWW1dZWVnh4YOHvNrt07q6ysLCAgie/7ND9/59tre3aV1ZxeF4+sdThGGEcsAELKWzkJQEQWJ2rs7qtWucPLnAvR9+5Ltv73B97ToGdO51iOOYG5/e4MPz5/nl0a9Ilh7jyimVlZcuBAjnjJmZGRofNzh+Yp5Op8Pt298giUuLl1j7ZI1Tp08TRRFySpZECMIVEVNNfbAc4UDPBAEYIS0+iiJOvfsOn33xOWfOnUUh8NGFC9Tn6jjnUEpQrFQ/E2HiupmBV0anHexSWUjzSNQwwDnH3LFjLF++DEqSABvnag6reKQA0vQwMcNP35ZpcvPftGrvIwzDjIO0kVwlYxosK9aPJRTFrjEl/1/tvqT37Bnm3DhrKdekaLsv/kXxKL02E4BpxtZsNku7JoSAJObn56nX6yjVJltmhnOOEMbt3+/36ff7mBkhBKxkZPoysDENlh8Sx8lUmQTMLnilUsnfZ7Fl5s1saiqYWT7GigCTPsXYjBFJOOdKAc0MV6vV8oNDCPnKgrN95pNVloEVF1CqX2a1Wg23tLREtVo9UE0RfHL/to90VnUZG9VqleXlZazX62ljY4Nut5t/WopBRZCiPsX3k88mLYoims0m7Xab/wH5x58dnSB6RAAAAABJRU5ErkJggg=="
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div
            className={classes['content-blog']}
            dangerouslySetInnerHTML={{ __html: detailNews.data.content }}
          ></div>
        </Col>
        <Col className={classes['right-content']} lg={7} md={24}>
          <div className={classes['search-box']}>
            <h3 className={classes['title']}>{t('BLOG.SEARCH_TEXT')}</h3>
            <div className={classes['search-input']}>
              <input
                ref={ref}
                placeholder={`${t('BLOG.SEARCH_PLACEHOLDER')}`}
                type="text"
                onKeyUp={handleKeyPress}
                // onChange={handleValue}
              />
              <span onClick={onSearch} className={classes['search-icon']}>
                <img src={SearchIcon} alt="" />
                {/* <SearchIcon /> */}
              </span>
            </div>
          </div>
          <div className={classes['list-category-title']}>
            <h3 className={classes['title']}>
              {t('BLOG.LIST_CATEGORY_TITLE')}
            </h3>
            <div className={classes['list-options']}>
              {listCategoryNews?.map((item: string) => (
                <RenderLabelFooter key={item} text={item} />
              ))}
            </div>
          </div>
          <div className={classes['list-top-news']}>
            <h3 className={classes['title']}>
              {t('BLOG.LIST_TOP_NEWS_TITLE')}
            </h3>
            <div className={classes['content']}>
              {listTopNews?.data.map((item: any, index: number) => (
                <BlogNewsItem
                  suggestionsClass={true}
                  key={item.newsId}
                  itemData={item}
                />
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const RenderLabelFooter = ({ text }: any) => {
  const navigate = useNavigate()
  const goToPage = (text: string) => {
    navigate(`/blog-heal/${text}`)
  }
  return (
    <Row justify={{ lg: 'start' }} align="middle" wrap={false}>
      <div className={classes['icon-rectangle']}>
        <img src={IconRectangle1203} alt="" />
      </div>
      <Typography.Text
        onClick={() => goToPage(text)}
        className={classes['option-text']}
      >
        {text}
      </Typography.Text>
    </Row>
  )
}

export default BlogHeadDetail
