import { Box, Breadcrumbs, Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import { useGetListPromotionPatient } from 'src/api/hooks/patient.query'
import { APP_URL } from 'src/config'
import classes from './Promotions.module.less'
import { PromotionDetailDialog } from './component/PromotionDetail'
import { useGetPromitionDetailPatient } from 'src/api/hooks/patient.query'
import { isImage } from 'src/utils/file'
import { Helmet } from 'react-helmet-async'

export const Promotions = () => {
  const listPromotionPatientRequest = useGetListPromotionPatient()
  const promitionDetailPatientRequest = useGetPromitionDetailPatient()

  const [items, setItems] = useState<Array<any>>([])
  const [hasMore, setHasMore] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [filter, setFilter] = useState({
    limit: 6,
    skip: 0,
  })
  const [total, setTotal] = useState(0)
  const [promotionId, setPromotionId] = useState('')
  const [promotionDetail, setPromotionDetail] = useState<any>({})

  const { t } = useTranslation()

  const fetchMoreData = () => {
    if (items.length >= total) {
      setHasMore(false)
      return
    }

    setFilter(prevFilter => ({
      skip: prevFilter.skip + 6,
      limit: prevFilter.limit,
    }))
  }

  useEffect(() => {
    listPromotionPatientRequest.mutate(filter)
  }, [filter])

  useEffect(() => {
    if (listPromotionPatientRequest.isSuccess) {
      const data: Array<any> = listPromotionPatientRequest?.data?.data || []
      const total = listPromotionPatientRequest?.data?.total || 0
      setTotal(total)
      setItems(prevItems => (filter?.skip > 0 ? [...prevItems, ...data] : data))
    }
  }, [listPromotionPatientRequest.isSuccess, filter])

  useEffect(() => {
    if (promotionId) {
      promitionDetailPatientRequest.mutate(promotionId)
    }
  }, [promotionId])

  useEffect(() => {
    if (promitionDetailPatientRequest.isSuccess) {
      const data = promitionDetailPatientRequest?.data?.data || {}
      setPromotionDetail(data)
    }
  }, [promitionDetailPatientRequest.isSuccess, promotionId])

  return (
    <div className={classes['promotions']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <Box py={2}>
        <Breadcrumbs>
          <Link color="red" to={APP_URL.SHOP_ONLINE}>
            <Typography variant="body2" color="primary">
              {t('pharmacyOnline.pharmacyOnline')}
            </Typography>
          </Link>
          <Typography color="#798395">
            {t('pharmacyOnline.promotion')}
          </Typography>
        </Breadcrumbs>
        <Typography variant="h3" fontWeight={700} mt={1}>
          {t('pharmacyOnline.promotion')}
        </Typography>
      </Box>

      {items?.length > 0 ? (
        <div className={classes['container-promotion']}>
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div className={classes['control-loading']}>
                <img
                  className={classes['loading']}
                  src="/assets/shop-online/promotions/loading.png"
                  alt="loading-promotions"
                />
              </div>
            }
            scrollableTarget="container-promotion"
            className={classes['loading-scroll']}
          >
            {items.map((item: any, index) => (
              <div className={classes['list-item']} key={index}>
                <div
                  onClick={() => {
                    setIsOpen(true)
                    setPromotionDetail(item)
                  }}
                  className={`${classes['top-new-item']} `}
                >
                  {isImage(item?.banner?.[0]) ? (
                    <img
                      src={item?.banner?.[0] || ''}
                      alt=""
                      className={classes['banner']}
                    />
                  ) : (
                    <video className={classes['banner']}>
                      <source src={item?.banner?.[0]} type="video/mp4" />
                    </video>
                  )}
                  <div className={classes['item-title']}>
                    <p className={classes['title']}>{item?.title}</p>
                    <div className={classes['date']}>
                      <img
                        src="/assets/shop-online/promotions/icondatetime.png"
                        alt="date-time-promotions"
                      />
                      <p>{format(new Date(item?.created), 'dd MMM, yyyy')}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      ) : null}

      <PromotionDetailDialog
        item={promotionDetail}
        open={isOpen}
        onclose={() => setIsOpen(false)}
      />
    </div>
  )
}

export default Promotions
