import { TRoute } from 'src/@types/common'
import { APP_URL } from 'src/config'
import PolicyGeneral from './PolicyGeneral.screen'
import PolicySecurity from './PolicySecurity.screen'
import PolicySendBack from './PolicySendBack.screen'
import PolicyShip from './PolicyShip.screen'

export const PolicyRoutes: TRoute[] = [
  {
    path: APP_URL.POLICY_GENERAL,
    element: <PolicyGeneral />,
  },
  {
    path: APP_URL.POLICY_SERCURITY,
    element: <PolicySecurity />,
  },
  {
    path: APP_URL.POLICY_SENDBACK,
    element: <PolicySendBack />,
  },
  {
    path: APP_URL.POLICY_SHIP,
    element: <PolicyShip />,
  },
]
