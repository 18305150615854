import { DragHandleRounded, MenuRounded } from '@mui/icons-material'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import { IconButtonAnimate } from 'src/components/animate'
import { APP_URL } from 'src/config'
import useResponsive from 'src/hooks/useResponsive'
import { RegisterLoginButton } from 'src/layouts/shop-online/components'
import { AccountInfoWithDropdownMenu } from 'src/layouts/shop-online/components/AccountInfo'
import { useCartContext } from 'src/pages/shop-online/Cart/context'
import { MenuDrawer } from '../MenuDrawer/MenuDrawer'
import classes from './Header.module.less'

export const HeaderRight: React.FC = () => {
  const { t } = useTranslation()
  const { profile, isAuthenticated } = useAuthUserContext()
  const { order } = useCartContext()
  const navigate = useNavigate()
  const isLg = useResponsive('up', 'lg')
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [isOpenMenuDrawer, setOpenMenuDrawer] = useState(false)

  if (!isAuthenticated && isLg) {
    return (
      <div className={classes['header__right']}>
        <RegisterLoginButton />
      </div>
    )
  }

  return (
    <div className={classes['header__right']}>
      {isAuthenticated && (
        <Link to={APP_URL.SHOP_ONLINE_CART} className={classes['bag']}>
          <IconButtonAnimate size="small">
            <img
              className={classes['bag-icon']}
              src="/assets/shop-online/ic-bag.png"
              alt=""
            />
          </IconButtonAnimate>
          {order?.listProduct?.length > 0 && (
            <div className={classes['badge']}>{order?.listProduct?.length}</div>
          )}
        </Link>
      )}
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <AccountInfoWithDropdownMenu />
      </Box>
      <IconButtonAnimate
        size="small"
        onClick={() => setOpenMenuDrawer(true)}
        sx={{ display: { lg: 'none' } }}
      >
        <DragHandleRounded sx={{ color: '#1A9CC6', fontSize: '32px' }} />
      </IconButtonAnimate>
      <MenuDrawer
        open={isOpenMenuDrawer}
        onClose={() => setOpenMenuDrawer(false)}
      />
    </div>
  )
}
