import { AddRounded } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { Row } from 'antd'
import { useSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
import { ImageProductSale } from 'src/assets'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import { TypographyLineClamp } from 'src/components/common/TypographyLineClamp'
import { useCartContext } from 'src/pages/shop-online/Cart/context'
import LoginUserDialog from 'src/sections/auth/login-user/LoginUserDialog'
import { isImage } from 'src/utils/file'
import { fNumber } from 'src/utils/formatNumber'
import { toSlug } from 'src/utils/string'
import classes from './ProductCard.module.less'

export const ProductCard = ({ item: product }: any) => {
  const { isAuthenticated } = useAuthUserContext()
  const { t } = useTranslation()
  const { order, addToCart } = useCartContext()
  const [isOpenLoginDialog, setOpenLoginDialog] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const productInCart = useMemo(
    () =>
      order?.listProduct?.find(item => item?.productId === product?.productId),
    [order, product]
  )

  const quantityInCart = productInCart?.quantity || 0
  const inStock = product?.productNumber

  const handleAddToCart = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    if (inStock < +quantityInCart + 1 && !product?.sellOver) {
      return enqueueSnackbar(
        t('cart.productOutStock', { name: product.productName }),
        { variant: 'error' }
      )
    }
    if (isAuthenticated) {
      addToCart({ ...product, quantity: 1 })
    } else {
      setOpenLoginDialog(true)
    }
  }

  let thumb = product.productImages?.find((product: string) => isImage(product))
  if (!thumb) {
    thumb = product.productImages?.[0]
  }

  return (
    <>
      <Link
        to={`/shop-online/product/${product?.productId}-${toSlug(
          product?.productName
        )}`}
        className={classes['item-product']}
        data-aos="fade-up"
      >
        <Row className={classes['overlay-sale']}>
          {product.productDiscounts !== 0 && (
            <LazyLoad once height={40}>
              <img src={ImageProductSale} alt="" />
            </LazyLoad>
          )}
        </Row>
        {product.productDiscounts !== 0 && (
          <Row className={classes['overlay-sale-text']}>
            <Typography className={classes['text']}>
              {product.productDiscounts || ''}%
            </Typography>
          </Row>
        )}
        <Row className={classes['product-image']} justify={'center'}>
          <LazyLoad once height={200}>
            {isImage(thumb) ? (
              <img
                src={thumb + '?timestamp=' + Date.now()}
                alt=""
                className={classes['img']}
              />
            ) : (
              <video className={classes['img']}>
                <source
                  src={thumb + '?timestamp=' + Date.now()}
                  type="video/mp4"
                />
              </video>
            )}
          </LazyLoad>
        </Row>
        <div style={{ padding: '8px 12px' }}>
          <Row className={classes['label']}>
            <TypographyLineClamp line={3}>
              {product.productName || ''}
            </TypographyLineClamp>
          </Row>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack height={42}>
              <Typography
                className={classes['price']}
                fontSize={product.productPrice > 9999999 ? 14 : 16}
              >
                {fNumber(product.productPrice || '')}
              </Typography>
              {product.productPrice !== product.productRealPrice && (
                <Typography className={classes['old-price']}>
                  {product.productDiscounts !== 0 &&
                    fNumber(product.productRealPrice || '')}
                </Typography>
              )}
            </Stack>
            <div
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              <Button
                disabled={inStock <= quantityInCart && !product?.sellOver}
                variant="contained"
                onClick={handleAddToCart}
                sx={{ width: 40, minWidth: 0 }}
              >
                <AddRounded />
              </Button>
            </div>
          </Stack>
        </div>
      </Link>
      <LoginUserDialog
        open={isOpenLoginDialog}
        onClose={() => setOpenLoginDialog(false)}
        // onSuccess={() => addToCart({ ...product, quantity: 1 })}
      />
    </>
  )
}
