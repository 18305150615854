import { Box, Stack, StackProps } from '@mui/material'
import { FC } from 'react'

type RadioOverlayProps = StackProps & {
  checked?: boolean
}

export const RadioOverlay: FC<RadioOverlayProps> = ({
  checked = false,
  children,
  ...props
}: RadioOverlayProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{
        cursor: 'pointer',
      }}
      border={`1px solid ${checked ? '#1A9CC6' : '#F4F8FB'}`}
      bgcolor={checked ? '#E0F3F7' : '#F4F8FB'}
      borderRadius={1}
      p={2}
      {...props}
    >
      <Box width="calc(100% - 52px)">{children}</Box>
      <Box
        sx={{
          width: 20,
          height: 20,
          borderRadius: 2,
          border: checked ? '4px solid #1A9CC6' : '1px solid #798395',
        }}
      ></Box>
    </Stack>
  )
}
