import { StarRounded, ThumbUpRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { TypographyLineClamp } from 'src/components/common/TypographyLineClamp'
import Image from 'src/components/image/Image'
import Label from 'src/components/label/Label'
import { stripHtml, toSlug } from 'src/utils/string'

interface ProductSummaryProps {
  shop: any
  product: any
}

export const ShopInfo = ({ shop, product }: ProductSummaryProps) => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState('description')

  return (
    <Paper sx={{ p: 2 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Image
          src="/assets/shop-online/shop-1.png"
          alt=""
          sx={{ width: '48px' }}
        />
        <Typography variant="h6" lineHeight="1.25">
          {shop?.shopName}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="subtitle1" color="#F5B11E">
          4.8
        </Typography>
        <StarRounded color="warning" />
        <Label color="primary">
          <ThumbUpRounded sx={{ fontSize: '12px' }} />
          &nbsp;
          <Typography variant="caption" color="primary.main" fontWeight={700}>
            Nhà thuốc uy tín
          </Typography>
        </Label>
      </Stack>
      <TypographyLineClamp variant="body2" color="#798395" mt={2} line={4}>
        {stripHtml(shop?.description)}
      </TypographyLineClamp>
      <Link
        to={`/shop-online/shop/${product?.productId}-${toSlug(shop?.shopName)}`}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'primary.lighter',
            color: 'primary.main',
            ':hover': {
              backgroundColor: 'primary.lighter',
            },
            mt: 2,
          }}
          fullWidth
        >
          Xem nhà thuốc
        </Button>
      </Link>
    </Paper>
  )
}
