import {
  Cancel,
  Search,
  StarRounded,
  ThumbUpRounded,
} from '@mui/icons-material'
import {
  Box,
  Breadcrumbs,
  Divider,
  InputAdornment,
  Paper,
  Stack,
  Tab,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useGetShopDetailByProductPatient } from 'src/api/hooks/patient.query'
import { IconButtonAnimate } from 'src/components/animate'
import { FullWidthTabs } from 'src/components/common/FullWidthTabs'
import Image from 'src/components/image/Image'
import Label from 'src/components/label/Label'
import { APP_URL } from 'src/config'
import { uuidV4Regex } from 'src/constants/regex'
import useDebounce from 'src/hooks/useDebounce'
import { ProductFilter, ShopDescription } from './components'
import classes from './ShopDetail.module.less'

export type TShopDetailProps = {}

export const ShopDetail: React.FC<
  TShopDetailProps
> = ({}: TShopDetailProps) => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState('description')
  const shopDetailRequest = useGetShopDetailByProductPatient()

  const [search, setSearch] = useState('')
  const searchDebounce = useDebounce(search, 300)

  const { slug = '' } = useParams()
  const productId = slug.match(uuidV4Regex)?.[0] || ''

  const shop: any = shopDetailRequest?.data?.data || null

  const TABS = [
    {
      value: 'description',
      label: t('productSection.productInfo'),
      component: <ProductFilter shop={shop} search={searchDebounce} />,
    },
    {
      value: 'description_',
      label: t('Hồ sơ cửa hàng'),
      component: <ShopDescription shop={shop} />,
    },
  ]

  useEffect(() => {
    shopDetailRequest.mutate(productId)
  }, [productId])

  return (
    <div className={classes['shop-detail-container']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <Breadcrumbs>
        <Link color="red" to={APP_URL.SHOP_ONLINE}>
          <Typography variant="body2" color="primary">
            {t('pharmacyOnline.pharmacyOnline')}
          </Typography>
        </Link>
        <Typography color="#798395">{shop?.shopName}</Typography>
      </Breadcrumbs>

      <Paper
        sx={{ mt: 2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      >
        <Stack p={2} direction={{ md: 'row' }} justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Image
              src="/assets/shop-online/shop-1.png"
              alt=""
              sx={{ width: '72px' }}
            />
            <Stack spacing={1}>
              <Typography variant="h4" lineHeight="1.25">
                {shop?.shopName}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="subtitle1" color="#F5B11E">
                  4.8
                </Typography>
                <StarRounded color="warning" />
                <Label color="primary">
                  <ThumbUpRounded sx={{ fontSize: '12px' }} />
                  &nbsp;
                  <Typography
                    variant="caption"
                    color="primary.main"
                    fontWeight={700}
                  >
                    Nhà thuốc uy tín
                  </Typography>
                </Label>
              </Stack>
            </Stack>
          </Stack>
          <TextField
            size="small"
            placeholder="Tìm kiếm sản phẩm tại cửa hàng"
            value={search}
            onChange={e => setSearch(e.target.value)}
            fullWidth
            sx={{ mt: 2, maxWidth: { md: '320px' } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButtonAnimate size="small">
                    <Search />
                  </IconButtonAnimate>
                </InputAdornment>
              ),
              endAdornment: search?.length ? (
                <InputAdornment position="end">
                  <IconButtonAnimate size="small" onClick={() => setSearch('')}>
                    <Cancel />
                  </IconButtonAnimate>
                </InputAdornment>
              ) : null,
            }}
          />
        </Stack>
        <FullWidthTabs
          value={currentTab}
          onChange={(event: any, newValue: any) => setCurrentTab(newValue)}
        >
          {TABS.map(tab => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              sx={{ width: '100%' }}
            />
          ))}
        </FullWidthTabs>

        <Divider />
      </Paper>
      {TABS.map(tab => tab.value === currentTab && tab.component)}

      {/* <Box mt={6}>
        <ProductList title="Các sản phẩm đã xem" products={products} />
      </Box> */}
    </div>
  )
}

export default ShopDetail
