import { Suspense, lazy, ElementType } from 'react'
// components
import LoadingScreen from '../components/loading-screen'

// ----------------------------------------------------------------------

export const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')))
export const RegisterPage = Loadable(
  lazy(() => import('../pages/auth/register'))
)
export const RegisterUserPage = Loadable(
  lazy(() => import('../pages/auth/register-user'))
)

export const ResetPasswordPage = Loadable(
  lazy(() => import('../pages/auth/ResetPasswordPage'))
)

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(
  lazy(() => import('../pages/admin/GeneralAppPage'))
)
export const ApprovePage = Loadable(
  lazy(() => import('../pages/admin/approve/ApprovePage'))
)
export const AccountPage = Loadable(
  lazy(() => import('../pages/admin/account/AccountPage'))
)
export const OrderPage = Loadable(
  lazy(() => import('../pages/admin/order/OrderPage'))
)
export const ShopPage = Loadable(
  lazy(() => import('../pages/admin/shop/ShopPage'))
)
export const ProductPage = Loadable(
  lazy(() => import('../pages/admin/product/ProductPage'))
)
export const DiscountPromotionPage = Loadable(
  lazy(() => import('../pages/admin/discount-promotion/DiscountPromotionPage'))
)
export const TransportPromotionPage = Loadable(
  lazy(
    () => import('../pages/admin/transport-promotion/TransportPromotionPage')
  )
)

export const ApproveDetailPage = Loadable(
  lazy(() => import('../pages/admin/profile/ApproveDetailPage'))
)

// ANALYSIS
export const ServicePage = Loadable(
  lazy(() => import('../pages/analysis/service/ServicePage'))
)
export const RequestPage = Loadable(
  lazy(() => import('../pages/analysis/request/RequestPage'))
)

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')))
export const Page403 = Loadable(lazy(() => import('../pages/Page403')))
export const Page404 = Loadable(lazy(() => import('../pages/Page404')))
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')))
// export const HomePage = Loadable(lazy(() => import('../pages/user/HomePage')))
export const MaintenancePage = Loadable(
  lazy(() => import('../pages/MaintenancePage'))
)

// ===================================  USER  ============================================
