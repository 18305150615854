import { yupResolver } from '@hookform/resolvers/yup'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import Iconify from 'src/components/iconify'
import useResponsive from 'src/hooks/useResponsive'
import { useLocales } from 'src/locales'
import { logDev } from 'src/utils'
import * as Yup from 'yup'
import { REGEX_PHONE_NUMBER, TypeLogin } from '../enum'
import ForgotPasswordUserDialog from '../forgot-password/ForgotPasswordUserDialog'
import AuthWithSocial from '../AuthWithSocial'

interface LoginUserDialogProps {
  open: any
  onClose?: VoidFunction
  onSuccess?: VoidFunction
}

const LoginUserDialog = ({
  open,
  onClose,
  onSuccess,
}: LoginUserDialogProps) => {
  const { login } = useAuthUserContext()
  const { translate } = useLocales()
  const [showPassword, setShowPassword] = useState(false)
  const [isOpenForgotPasswordDialog, setOpenForgotPasswordDialog] =
    useState(false)
  const isMd = useResponsive('up', 'md')

  const LoginSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required(translate('loginPage.phoneNumberRequired') + '')
      .matches(
        REGEX_PHONE_NUMBER,
        translate('loginPage.phoneNumberIsWrongFormat') + ''
      ),
    password: Yup.string().required(
      translate('loginPage.passwordRequired') + ''
    ),
  })

  const defaultValues = {
    phoneNumber: '',
    password: '',
  }

  const methods = useForm<typeof defaultValues>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      const password = data?.password

      const phoneNumber = data?.phoneNumber?.replace(/^(\+84|0)/g, '+84')

      const object = {
        typeLogin: TypeLogin.PHONE,
        phoneNumber: phoneNumber,
        password: password,
      }

      await login(object)
      onClose && onClose()
      onSuccess && onSuccess()
    } catch (error) {
      logDev('LoginPopoverContent', error)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      sx={{
        backgroundColor: '#004E7399',
        '.MuiDialog-paper': {
          maxWidth: '360px',
        },
      }}
    >
      <DialogTitle
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ py: 2 }}
      >
        <Typography variant="h5" color="primary.main">
          Đăng nhập vào tài khoản
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: 4 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Typography variant="body2" color="#798395">
              Bạn cần đăng nhập để mua hàng trên Sophie.
            </Typography>
            <Stack spacing={1}>
              <Typography
                variant="subtitle2"
                color="#798395"
                sx={{
                  marginBottom: { xs: '8px', md: 0 },
                }}
              >
                {translate('loginPage.phoneNumberRegister')}
              </Typography>
              <RHFTextField
                size="small"
                name="phoneNumber"
                placeholder={translate('loginPage.phoneNumberRegister') + ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ color: '#576071' }}>
                      <Box
                        component="img"
                        src={`/assets/icons/flags/ic_flag_vn.svg`}
                        sx={{ width: 26, height: 36 }}
                      />
                      <Typography sx={{ marginLeft: 1, marginRight: 3 }}>
                        +84
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  autocomplete: 'off',
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                }}
              >
                <Typography variant="subtitle2" color="#798395">
                  {translate('password')}
                </Typography>
                <Stack>
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => setOpenForgotPasswordDialog(true)}
                    sx={{
                      '&:hover': {
                        background: 'none',
                      },
                    }}
                  >
                    <Typography variant="body2" color="#1A9CC6">
                      {translate('forgot_password')}
                    </Typography>
                  </Button>
                </Stack>
              </Box>
              <RHFTextField
                size="small"
                name="password"
                placeholder={translate('loginPage.input_password') + ''}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {translate('login')}
            </LoadingButton>

            <Typography
              variant="body1"
              color="#798395"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
                marginBottom: '16px',
              }}
            >
              {translate('loginPage.or_login_by')}
            </Typography>
            <AuthWithSocial onClick={onClose}/>

            <Typography variant="body2" color="#798395" align="center">
              Bạn chưa có tài khoản?{' '}
              <Link to="/register" onClick={onClose}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="primary.main"
                >
                  Đăng ký ngay
                </Typography>
              </Link>
            </Typography>
          </Stack>
        </FormProvider>
      </DialogContent>
      <ForgotPasswordUserDialog
        open={isOpenForgotPasswordDialog}
        onClose={() => setOpenForgotPasswordDialog(false)}
      />
    </Dialog>
  )
}

export default LoginUserDialog
