import { Navigate, useRoutes } from 'react-router-dom'
// auth
import AuthGuard from '../auth/AuthGuard'
import GuestGuard from '../auth/GuestGuard'
// layouts
// import MainLayout from '../layouts/user';
import AdminLayout from '../layouts/admin'
import CompactLayout from '../layouts/compact'
// config
import { PATH_AFTER_LOGIN } from '../config/config-global'
//
import { APP_URL } from 'src/config'
import { Role } from 'src/constants/enum'
import { ShopOnlineLayout } from 'src/layouts/shop-online'
import { ShopOnlineProfileLayout } from 'src/layouts/shop-online-profile'
import { MainLayout } from 'src/layouts/user'
import ComingSoonPage from 'src/pages/ComingSoonPage'
import { CartRoutes as ShopOnlineCartRoutes } from 'src/pages/shop-online/Cart'
import { CategoryRoutes as ShopOnlineCategoryRoutes } from 'src/pages/shop-online/Category'
import { HomePageRoutes as HomePageShopOnlineRoutes } from 'src/pages/shop-online/HomePage'
import { PayResultRoutes as ShopOnlinePayResultRoutes } from 'src/pages/shop-online/PayResult'
import { ProductDetailRoutes as ShopOnlineProductDetailRoutes } from 'src/pages/shop-online/ProductDetail'
import { PromotionsRoutes } from 'src/pages/shop-online/Promotions'
import { SearchRoutes as ShopOnlineSearchRoutes } from 'src/pages/shop-online/Search'
import { ShopDetailRoutes as ShopOnlineShopDetailRoutes } from 'src/pages/shop-online/ShopDetail'
import { AddressListRoutes as UserProfileAddressListRoutes } from 'src/pages/user-profile/AddressList'
import { OrderTrackingRoutes as UserProfileOrderTrackingRoutes } from 'src/pages/user-profile/OrderTracking'
import { PersonalInfoRoutes as UserProfilePersonalInfoRoutes } from 'src/pages/user-profile/PersonalInfo'
import { AboutSophieRoutes } from 'src/pages/user/AboutSophie'
import { AppointmentOnlineRoutes } from 'src/pages/user/AppointmentOnline'
import { BlogHealHomeRoutes } from 'src/pages/user/BlogHeal/BlogHeal.route'
import { ContactSophieRoutes } from 'src/pages/user/Contact'
import { HealthServicePageRoutes } from 'src/pages/user/HealthService'
import { HomePageRoutes } from 'src/pages/user/HomePage'
import { PolicyRoutes } from 'src/pages/user/Policy'
import {
  AccountPage,
  ApproveDetailPage,
  ApprovePage,
  DiscountPromotionPage,
  // Admin
  GeneralAppPage,
  LoginPage,
  MaintenancePage,
  OrderPage,
  Page403,
  Page404,
  //
  Page500,
  ProductPage,
  RegisterPage,
  RegisterUserPage,
  RequestPage,
  ResetPasswordPage,
  ServicePage,
  ShopPage,
  TransportPromotionPage,
} from './elements'
import { PATH_ANALYSIS } from './paths'

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        ...HomePageRoutes,
        ...AboutSophieRoutes,
        ...HealthServicePageRoutes,
        ...AppointmentOnlineRoutes,
        ...BlogHealHomeRoutes,
        ...ContactSophieRoutes,
        ...PolicyRoutes,
        { path: APP_URL.REGISTER_USER, element: <RegisterUserPage /> },
      ],
    },
    {
      path: APP_URL.SHOP_ONLINE,
      element: <ShopOnlineLayout />,
      children: [
        ...HomePageShopOnlineRoutes,
        ...ShopOnlineSearchRoutes,
        ...ShopOnlineCategoryRoutes,
        ...ShopOnlineProductDetailRoutes,
        ...ShopOnlineShopDetailRoutes,
        ...PromotionsRoutes,
        ...ShopOnlineCartRoutes,
        ...ShopOnlinePayResultRoutes,
      ],
    },
    {
      path: APP_URL.USER_PROFILE,
      element: <ShopOnlineProfileLayout />,
      children: [
        // ...ProfileRoute,
        ...UserProfilePersonalInfoRoutes,
        ...UserProfileAddressListRoutes,
        ...UserProfileOrderTrackingRoutes,
      ],
    },

    // {
    //   path: APP_URL.SHOP_ONLINE,
    //   element: <ShopOnlineLayout />,
    //   children: [
    //     ...HomePageShopOnlineRoutes,
    //     ...ShopOnlineSearchRoutes,
    //     ...ShopOnlineCategoryRoutes,
    //     ...ShopOnlineProductDetailRoutes,
    //   ],
    // },
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
          ],
        },
      ],
    },

    // User Routes
    // {
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //   ],
    // },

    // Admin
    {
      path: 'admin',
      element: (
        <AuthGuard roles={[Role.Admin, Role.Pharmacy]}>
          <AdminLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'dashboard', element: <GeneralAppPage /> },
        {
          path: 'approve',
          element: (
            <AuthGuard roles={[Role.Admin]}>
              <ApprovePage />
            </AuthGuard>
          ),
        },

        { path: 'approve/detail', element: <ApproveDetailPage /> },
        { path: 'order', element: <OrderPage /> },
        { path: 'drugstore', element: <GeneralAppPage /> },
        { path: 'pharmacy/shop', element: <ShopPage /> },
        { path: 'pharmacy/product', element: <ProductPage /> },
        { path: 'promotion/discount', element: <DiscountPromotionPage /> },
        { path: 'promotion/transport', element: <TransportPromotionPage /> },
      ],
    },
    {
      path: 'analysis',
      element: (
        <AuthGuard roles={[Role.Analysis]}>
          <AdminLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_ANALYSIS.app} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },
        { path: 'service', element: <ServicePage /> },
        { path: 'request', element: <RequestPage /> },
      ],
    },
    {
      path: 'user',
      element: (
        <AuthGuard>
          <AdminLayout />
        </AuthGuard>
      ),
      children: [{ path: 'account', element: <AccountPage /> }],
    },

    // Compact
    {
      element: <CompactLayout />,
      children: [
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: 'coming-soon', element: <ComingSoonPage /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}
