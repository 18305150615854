import { APP_URL } from 'src/config'
import { TRoute } from 'src/@types/common'
import { Loadable } from 'src/routes/elements'
import { lazy } from 'react'
import HealthServicePhysical from './HealthServicePhysical.screen'
import HealthServiceBlood from './HealthServiceBlood.screen'
import HealthServiceBaby from './HealthServiceBaby.screen'
import HealthServiceSick from './HealthServiceSick.screen'

// const HealthServiceBaby = Loadable(
//   lazy(() => import('./HealthServiceBaby.screen'))
// )
// const HealthServiceBlood = Loadable(
//   lazy(() => import('./HealthServiceBlood.screen'))
// )
// const HealthServicePhysical = Loadable(
//   lazy(() => import('./HealthServicePhysical.screen'))
// )
// const HealthServiceSick = Loadable(
//   lazy(() => import('./HealthServiceSick.screen'))
// )

export const HealthServicePageRoutes: TRoute[] = [
  {
    path: APP_URL.HEALTH_SERVICE_PHYSICAL_THERAPY,
    element: <HealthServicePhysical />,
  },
  {
    path: APP_URL.HEALTH_SERVICE_GET_BLOOD,
    element: <HealthServiceBlood />,
  },
  {
    path: APP_URL.HEALTH_SERVICE_BABY_BATH,
    element: <HealthServiceBaby />,
  },
  {
    path: APP_URL.HEALTH_SERVICE_HUMAN_SICK,
    element: <HealthServiceSick />,
  },
]
