import { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { StorageService } from 'src/api'
import {
  useCreateOrUpdateOrderPatient,
  useGetListAddressPatient,
  useGetOrderByAccountIdPatient,
  useValidatePromotionPatient,
} from 'src/api/hooks/patient.query'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import useDebounce from 'src/hooks/useDebounce'
import {
  ICartData,
  IOrderCart,
  IProduct,
  ITransportServiceData,
} from '../types/order'
import { BroadcastChannel } from 'broadcast-channel'
import { logJson } from 'src/utils'

interface ICartContext {
  order: IOrderCart
  cartData: ICartData
  transportServiceData: ITransportServiceData
  isCartInitialized: boolean
  isOrderLoading: boolean
  updateOrder: (newValue: Partial<IOrderCart>) => void
  updateCartData: (newValue: Partial<ICartData>) => void
  updateTransportServiceData: (newValue: Partial<ITransportServiceData>) => void
  addToCart: (product: IProduct) => void
  addManyToCart: (products: IProduct[]) => void
  increaseProductAmountById: (productId: string, shopId: string) => void
  decreaseProductAmountById: (productId: string, shopId: string) => void
  setProductAmountById: (productId: string, amount: any) => void
  removeProductById: (productId: string, shopId: string) => void
  refreshOrder: () => void
}

const initialOrder: IOrderCart = {
  draftOrderId: '',
  transactionId: '',
  pharmacistId: '',
  accountId: '',
  accountName: '',
  addressId: '',
  addressAccount: '',
  listProduct: [],
  listOrder: [],
  promotionIds: [],
  transportPromotionIds: [],
  price: 0,
  promotionPrice: 0,
  transportPromotionPrice: 0,
  transportPrice: 0,
  typePay: 'COD',
  transportService: '',
}

const initialCartData: ICartData = {
  typePay: 'COD',
}

const initialTransportServiceData: ITransportServiceData = {
  servicE_CODE: '',
  servicE_NAME: '',
  servicE_PRICE: 0,
  servicE_TIME: '',
}

const initialState: ICartContext = {
  order: initialOrder,
  cartData: initialCartData,
  transportServiceData: initialTransportServiceData,
  isCartInitialized: false,
  isOrderLoading: false,
  updateOrder: () => {},
  updateCartData: () => {},
  updateTransportServiceData: () => {},
  addToCart: () => {},
  addManyToCart: () => {},
  increaseProductAmountById: () => {},
  decreaseProductAmountById: () => {},
  setProductAmountById: () => {},
  removeProductById: () => {},
  refreshOrder: () => {},
}

const CartContext = createContext<ICartContext>(initialState)

function CartContextProvider({ children }: { children: JSX.Element }) {
  const { profile, isAuthenticated } = useAuthUserContext()
  const [order, setOrder] = useState(initialOrder)
  const [cartData, setCartData] = useState(initialCartData)
  const [transportServiceData, setTransportServiceData] = useState(
    initialTransportServiceData
  )
  const canUpdateCart = useRef(false)
  const getCartRequest = useGetOrderByAccountIdPatient()
  const createOrUpdateOrderRequest = useCreateOrUpdateOrderPatient()
  const orderDebounce = useDebounce(JSON.stringify(order), 500)
  const validatePromotionRequest = useValidatePromotionPatient()

  const isCartInitialized = !!order?.draftOrderId

  const handleUpdateOrder = useCallback(
    async (newValue: Partial<IOrderCart>) => {
      canUpdateCart.current = false
      const newOrder = Object.assign(order, newValue)
      setOrder(newOrder)
      await handleCreateOrUpdateOrder(newOrder)
    },
    [order, isAuthenticated]
  )

  const handleUpdateCartData = useCallback(
    async (newValue: Partial<ICartData>) => {
      const newCartData = Object.assign(cartData, newValue)
      StorageService.set('cartData', JSON.stringify(newCartData))
      setCartData(newCartData)
    },
    [cartData, isAuthenticated]
  )

  const handleUpdateTransportServiceData = useCallback(
    async (newValue: Partial<ITransportServiceData>) => {
      const newTransportServiceData = Object.assign(
        transportServiceData,
        newValue
      )
      StorageService.set(
        'transportServiceData',
        JSON.stringify(newTransportServiceData)
      )
      setTransportServiceData(newTransportServiceData)
    },
    [transportServiceData, isAuthenticated]
  )

  const handleAddProductToCart = useCallback(
    (product: IProduct) => {
      canUpdateCart.current = true
      const productExistInCart = order.listProduct?.find(
        item => item?.productId === product?.productId
      )
      if (!!productExistInCart) {
        setOrder(order => ({
          ...order,
          listProduct: order?.listProduct?.map(item => {
            if (item?.productId === product?.productId) {
              item.quantity += product.quantity
            }
            return item
          }),
        }))
      } else {
        setOrder(order => ({
          ...order,
          listProduct: [...order?.listProduct, product],
        }))
      }
    },
    [order, isAuthenticated]
  )

  const handleAddProductsToCart = useCallback(
    (products: IProduct[]) => {
      canUpdateCart.current = true
      const listProduct = [...order.listProduct]

      for (const product of products) {
        const indexInCart = listProduct.findIndex(
          item => item?.productId === product?.productId
        )

        if (indexInCart === -1) {
          listProduct.push(product)
        } else {
          listProduct[indexInCart].quantity += product?.quantity || 0
        }
      }

      setOrder(order => ({
        ...order,
        listProduct: listProduct,
      }))
    },
    [order, isAuthenticated]
  )

  const increaseProductAmountById = useCallback(
    (productId: String, shopId: String) => {
      canUpdateCart.current = true
      setOrder({
        ...order,
        listOrder: order?.listOrder?.map(itemOrder => {
          if (itemOrder?.shopId === shopId) {
            itemOrder?.listProduct?.map(itemProduct => {
              if (itemProduct?.productId === productId) {
                itemProduct.quantity = itemProduct.quantity + 1
              }
            })
          }
          return itemOrder
        }),
        listProduct: order?.listProduct?.map(item => {
          if (item?.productId === productId) {
            item.quantity = item.quantity + 1
          }
          return item
        }),
      })
    },
    [order, isAuthenticated]
  )

  const decreaseProductAmountById = useCallback(
    (productId: String, shopId: String) => {
      canUpdateCart.current = true
      setOrder(order => ({
        ...order,
        listOrder: order?.listOrder?.map(itemOrder => {
          if (itemOrder?.shopId === shopId) {
            itemOrder?.listProduct?.map(itemProduct => {
              if (itemProduct?.productId === productId) {
                itemProduct.quantity = Math.max(0, itemProduct.quantity - 1)
              }
            })
          }
          return itemOrder
        }),
        listProduct: order?.listProduct?.map(item => {
          if (item?.productId === productId) {
            item.quantity = Math.max(0, item.quantity - 1)
          }
          return item
        }),
      }))
    },
    [order, isAuthenticated]
  )

  const setProductAmountById = useCallback(
    (productId: String, amount: any) => {
      if (typeof amount === 'number') {
        canUpdateCart.current = true
      }
      setOrder(order => ({
        ...order,
        listProduct: order?.listProduct?.map(item => {
          if (item?.productId === productId) {
            item.quantity = amount
          }
          return item
        }),
      }))
    },
    [order, isAuthenticated]
  )

  const removeProductById = useCallback(
    (productId: String, shopId: String) => {
      canUpdateCart.current = true
      var listTempOrder = order?.listOrder?.map(itemOrder => {
        if (itemOrder?.shopId === shopId) {
          itemOrder.listProduct = itemOrder?.listProduct?.filter(
            itemProd => itemProd?.productId !== productId
          )
        }
        return itemOrder
      })

      setOrder(order => ({
        ...order,
        transportService: '',
        transportPromotionIds: [],
        promotionIds: [],
        listOrder: listTempOrder,
        listProduct: order?.listProduct?.filter(
          item => item?.productId !== productId
        ),
      }))
    },
    [order, isAuthenticated]
  )

  useEffect(() => {
    const handleGetLocalCartData = () => {
      try {
        const cartDataRaw: string = StorageService.get('cartData')
        return JSON.parse(cartDataRaw)
      } catch (error) {
        return {}
      }
    }
    const handleGetCartData = async () => {
      if (!isAuthenticated || !profile?.accountId) {
        setOrder({ ...initialOrder })
        return
      }
      try {
        const res: any = await getCartRequest.mutateAsync(profile?.accountId)
        canUpdateCart.current = false
        const cartData = handleGetLocalCartData()
        setCartData(Object.assign({ typePay: 'COD' }, cartData))

        if (!!res?.result) {
          setOrder({ ...cartData, ...res?.data })
        } else {
          await createOrUpdateOrderRequest.mutateAsync({
            ...initialOrder,
            accountId: profile?.accountId,
            accountName: profile?.fullname,
            typePay: 'COD',
          })
          const res2: any = await getCartRequest.mutateAsync(profile?.accountId)
          setOrder({ ...cartData, ...res2?.data })
        }
      } catch (error) {}
    }
    handleGetCartData()

    const cartChannel = new BroadcastChannel('update-cart')
    cartChannel.onmessage = msg => handleGetCartData()
  }, [profile?.accountId])

  const handleRefreshOrder = async () => {
    const refreshOrderByTime = async (timeFail = 1) => {
      if (timeFail < 1) {
        return
      }
      try {
        const accountId = profile?.accountId
        const res: any = await getCartRequest.mutateAsync(accountId)
        canUpdateCart.current = false
        if (!res?.result) {
          await createOrUpdateOrderRequest.mutateAsync({
            ...initialOrder,
            accountId: profile?.accountId,
            accountName: profile?.fullname,
            typePay: 'COD',
          })
          const res2: any = await getCartRequest.mutateAsync(accountId)
          setOrder(res2?.data)
        } else {
          setOrder(res?.data)
        }
      } catch (error) {
        setTimeout(() => {
          refreshOrderByTime(timeFail - 1)
        }, 3000)
      }
    }
    refreshOrderByTime(3)
  }

  const handleCreateOrUpdateOrder = async (newOrder = order) => {
    try {
      if (
        newOrder?.transportPromotionIds?.length > 0 ||
        newOrder?.promotionIds?.length > 0
      ) {
        const validateRes = await validatePromotionRequest.mutateAsync({
          listProduct: order.listProduct,
          typePay: order?.typePay || cartData?.typePay,
          transportPromotionIds: newOrder?.transportPromotionIds || [],
          promotionIds: newOrder?.promotionIds || [],
        })

        if (!validateRes?.result) {
          newOrder.transportPromotionIds = []
          newOrder.promotionIds = []
        }
      }
      canUpdateCart.current = false
      const res2 = await createOrUpdateOrderRequest.mutateAsync(newOrder)
      setOrder(res2?.data)
    } catch (error) {
    } finally {
      //handleRefreshOrder()
    }
  }

  useEffect(() => {
    if (canUpdateCart.current && !!profile?.accountId) {
      handleCreateOrUpdateOrder()
    }
  }, [orderDebounce, profile?.accountId])

  const value = {
    order,
    cartData,
    transportServiceData,
    isCartInitialized,
    isOrderLoading: createOrUpdateOrderRequest.isLoading,
    updateOrder: handleUpdateOrder,
    updateCartData: handleUpdateCartData,
    updateTransportServiceData: handleUpdateTransportServiceData,
    addToCart: handleAddProductToCart,
    addManyToCart: handleAddProductsToCart,
    increaseProductAmountById,
    decreaseProductAmountById,
    setProductAmountById,
    removeProductById,
    refreshOrder: handleRefreshOrder,
  }

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

export { CartContextProvider, CartContext }
