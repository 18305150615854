import { yupResolver } from '@hookform/resolvers/yup'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useUpdateProfilePatient } from 'src/api/hooks/patient.query'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import * as Yup from 'yup'

export interface IPUpdateLastNameDialogProps {
  open: boolean
  onClose: VoidFunction
}

export const UpdateLastNameDialog = ({
  open,
  onClose,
}: IPUpdateLastNameDialogProps) => {
  const { profile, updateProfile } = useAuthUserContext()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const updateProfileRequest = useUpdateProfilePatient()

  const usernameRequiredMessage = t('updateinfouserpage.username')

  const LoginSchema = Yup.object().shape({
    lastname: Yup.string().required(usernameRequiredMessage).trim(),
  })

  const defaultValues = {
    lastname: '',
  }

  const methods = useForm<typeof defaultValues>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = methods

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      await updateProfileRequest.mutateAsync({ ...profile, ...data })
      await updateProfile()
      enqueueSnackbar(t('userProfile.personalInformation.updateSuccess'))
      onClose()
    } catch (error) {
      reset()
    }
  }

  useEffect(() => {
    if (open) {
      reset({ lastname: profile?.lastname })
    }
  }, [profile?.lastname, open])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          component="div"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h5" color="primary.main">
            {t('userProfile.personalInformation.updateInformation')}
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <Typography variant="subtitle2" color="#798395">
              {t('userProfile.personalInformation.lastName')}
            </Typography>
            <RHFTextField variant="outlined" fullWidth name="lastname" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('userProfile.saveChange')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
