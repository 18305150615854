import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { useGetSListSpecialProductPatient } from 'src/api/hooks/patient.query'
import { ProductCard } from 'src/sections/user'
import classes from './ProductPromotion.module.less'
import './ProductPromotion.less'

export const ProductPromotion = () => {
  const [slickRef, setSlickRef] = useState<Slider>()
  const { t } = useTranslation()
  const productSaleOff = useGetSListSpecialProductPatient()

  const products: any[] = productSaleOff?.data?.data || []

  const slickSetting: any = {
    ref: setSlickRef,
    arrows: false,
    dots: false,
    navigator: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  useEffect(() => {
    productSaleOff.mutateAsync({})
  }, [])

  return products?.length > 0 ? (
    <Box overflow="hidden">
      <div className="container">
        <div className={classes['product-promotion']}>
          <Typography variant="h4" mt={2} mb={1}>
            {t('pharmacyOnline.promotionProductInDay')}
          </Typography>
          <div className={classes['slider-container']}>
            <IconButton
              disableRipple
              className={classes['button-prev']}
              onClick={() => slickRef?.slickPrev()}
            >
              <ArrowBackIosNewRounded />
            </IconButton>
            <IconButton
              disableRipple
              className={classes['button-next']}
              onClick={() => slickRef?.slickNext()}
            >
              <ArrowForwardIosRounded />
            </IconButton>
            <Slider
              {...slickSetting}
              className="shop-online__home-page--product-promotion"
            >
              {products.map((item, i) => (
                <div className={classes['slider-item']} key={i}>
                  <ProductCard item={item} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </Box>
  ) : null
}
