import { useMemo } from 'react'
// @mui
import { CssBaseline } from '@mui/material'
import {
  createTheme,
  ThemeOptions,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
  useTheme,
} from '@mui/material/styles'
// components
import { useSettingsContext } from 'src/components/settings'
//
import palette from 'src/theme/palette'
import typography from 'src/theme/typography'
import shadows from 'src/theme/shadows'
import customShadows from 'src/theme/customShadows'
import componentsOverride from 'src/theme/overrides'
import GlobalStyles from 'src/theme/globalStyles'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export function ProfileUserThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettingsContext()

  const custom: ThemeOptions = {
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: 48,
            borderRadius: '8px',
            backgroundColor: 'white !important',
            '&.MuiInputBase-sizeSmall': {
              height: 'auto',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          nativeInput: {
            opacity: 1,
            border: 'none',
            color: 'transparent',
            background: 'transparent',
            top: '50%',
            bottom: 'auto',
            transform: 'translate(0, -50%)',
            paddingLeft: 12,
            '::placeholder': {
              opacity: 1,
              fontSize: '16px',
              color: '#bbb',
            },
            legend: {
              height: '11px',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          input: {
            padding: '2.5px 4px !important',
          },
        },
      },
    },
  }

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette(themeMode),
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows(themeMode),
      customShadows: customShadows(themeMode),
    }),
    [themeDirection, themeMode]
  )

  const theme = createTheme(Object.assign(themeOptions, custom))

  theme.components = Object.assign(componentsOverride(theme), custom.components)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
