import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import { ImageProductSale } from 'src/assets'
import { TypographyLineClamp } from 'src/components/common/TypographyLineClamp'
import Image from 'src/components/image/Image'
import { fNumber } from 'src/utils/formatNumber'
import classes from './TransactionDetailDialog.module.less'

interface ProductByShopProps {
  order: any
}

export const ProductByShop = ({ order }: ProductByShopProps) => {
  const { t } = useTranslation()

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">{order?.shopName}</Typography>
      {order?.listProduct?.map((item: any, i: number) => (
        <Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <div className={classes['product-image']}>
              {item?.productPrice !== item?.productRealPrice && (
                <div className={classes['overlay-sale']}>
                  <LazyLoad once height={40}>
                    <img src={ImageProductSale} alt="" />
                  </LazyLoad>
                </div>
              )}
              {item?.productPrice !== item?.productRealPrice && (
                <div className={classes['overlay-sale-text']}>
                  <Typography color="white">
                    -{100 - (item?.productPrice / item?.productRealPrice) * 100}
                    %
                  </Typography>
                </div>
              )}
              <Image
                src={item?.productThumbImages?.[0]}
                alt=""
                ratio="1/1"
                width="64px"
                sx={{
                  backgroundImage:
                    'url(/assets/shop-online/medicine-placeholder.png) !important',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '64px',
                }}
              />
            </div>
            <Stack flexGrow={1}>
              <TypographyLineClamp variant="subtitle2" color="#576071" line={2}>
                {item?.productName}
              </TypographyLineClamp>
              <Typography variant="body2" color="#798395">
                {t('cart.quantity')}: {item?.quantity}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="subtitle2" color="#576071" textAlign="right">
                {fNumber(+item?.productPrice * item?.quantity || 0)}₫
              </Typography>
              {item?.productPrice !== item?.productRealPrice && (
                <Typography
                  variant="caption"
                  component="span"
                  color="#798395"
                  textAlign="right"
                  sx={{ textDecoration: 'line-through' }}
                >
                  {fNumber(+item?.productRealPrice * item?.quantity || 0)}₫
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}
