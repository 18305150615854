import { InstanceAxios } from '../config/axios/user'

export const getListAddressPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/profilepatient/listaddress'
  return InstanceAxios.post(path, payload, {
    params: { skip: payload?.skip, limit: payload?.limit },
  })
}

export const findAddressPatient = (addressId: string): Promise<any> => {
  const path = '/api/patient/profilepatient/findaddress'
  return InstanceAxios.put(path, {}, { params: { addressId } })
}

export const addAddressPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/profilepatient/addaddress'
  return InstanceAxios.put(path, payload)
}

export const updateAddressPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/profilepatient/updateaddress'
  return InstanceAxios.post(path, payload)
}

export const deleteAddressPatient = (addressId?: any): Promise<any> => {
  const path = '/api/patient/profilepatient/deleteaddress'
  return InstanceAxios.delete(path, { params: { addressId } })
}

export const getTransportListServices = (payload?: any): Promise<any> => {
  const path = '/api/account/transport/listservices'
  return InstanceAxios.post(path, payload)
}

