import {
  Box,
  Breadcrumbs,
  Grid,
  MenuItem,
  Pagination,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useGetListProductSaleOff } from 'src/api/hooks/guestDashboard.query'
import { useGetListProductPatient } from 'src/api/hooks/patient.query'
import Image from 'src/components/image/Image'
import { APP_URL } from 'src/config'
import { ProductCard } from 'src/sections/user'
import { ProductList } from 'src/sections/user/ProductList'
import { sortOptionObj, sortOptions } from '../common/constants'
import classes from './Search.module.less'

export type TSearchProps = {}

export const Search: React.FC<TSearchProps> = ({}: TSearchProps) => {
  const { t } = useTranslation()
  const listProductSaleOff = useGetListProductSaleOff({
    version: 'v1.0',
  })
  const listProductRequest = useGetListProductPatient()
  const navigate = useNavigate()
  const location = useLocation()
  const { search = '' } = queryString.parse(location?.search)
  const [filter, setFilter] = useState<any>({
    minPrice: 0,
    maxPrice: 0,
    isSale: null,
    isRelated: true,
    sortName: 'PurchasedNumber',
    sort: 'desc',
    search: search,
    skip: 0,
    limit: 30,
  })

  const handleUpdateFilter = (newFilter: Record<string, any>) => {
    setFilter((state: any) => ({ ...state, skip: 0, ...newFilter }))
  }

  const products: any[] = listProductRequest?.data?.data || []
  const total: number = listProductRequest?.data?.total || 0

  useEffect(() => {
    if (!search) {
      navigate(APP_URL.SHOP_ONLINE)
    } else {
      handleUpdateFilter({ search })
    }
  }, [search])

  useEffect(() => {
    const handleGetListProduct = async () => {
      const res = await listProductRequest.mutateAsync(filter)
    }
    handleGetListProduct()
  }, [filter])

  return (
    <div className={classes['search-container']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <Breadcrumbs>
        <Link color="red" to={APP_URL.SHOP_ONLINE}>
          <Typography variant="body2" color="primary">
            {t('pharmacyOnline.pharmacyOnline')}
          </Typography>
        </Link>
        <Typography color="#798395">{t('pharmacyOnline.search')}</Typography>
      </Breadcrumbs>
      <Typography variant="h3" fontWeight={700} mt={1}>
        {t('pharmacyOnline.searchResultFor')}{' '}
        <Typography
          component="span"
          color="primary"
          variant="h3"
          fontWeight={700}
        >
          “{search}”
        </Typography>
      </Typography>
      <Stack
        justifyContent="space-between"
        alignItems={{ md: 'center' }}
        direction={{ xs: 'column', md: 'row' }}
        spacing={2}
        mt={3}
      >
        {!listProductRequest?.isSuccess ? (
          <Skeleton width={200} height={30} />
        ) : null}
        {listProductRequest?.isSuccess && !!products?.length ? (
          <Typography variant="body2" color="#798395">
            {t('pharmacyOnline.searchResultFound', { number: total })}
          </Typography>
        ) : null}
        {listProductRequest?.isSuccess && !products?.length ? (
          <Typography variant="body2" color="#798395">
            {t('pharmacyOnline.noSearchResult')}
          </Typography>
        ) : null}

        {listProductRequest?.isSuccess && !!products?.length ? (
          <Stack
            direction="row"
            spacing={1}
            sx={{ display: { xs: 'none', md: 'flex' } }}
          >
            {sortOptions.map((item, i) => (
              <div
                className={`${classes['filter-chip']} ${
                  filter?.sortName === item?.value ? classes['active'] : ''
                }`}
                key={i}
                onClick={() => handleUpdateFilter(sortOptionObj[item?.value])}
              >
                {t(item?.translateKey)}
              </div>
            ))}
          </Stack>
        ) : null}
        <TextField
          size="small"
          select
          fullWidth
          value={filter?.sortName}
          onChange={e => handleUpdateFilter(sortOptionObj[e?.target?.value])}
          sx={{
            '.MuiInputBase-root': {
              bgcolor: 'white',
            },
            display: { md: 'none' },
          }}
        >
          {sortOptions.map((item, i) => (
            <MenuItem value={item?.value} key={i}>
              {t(item?.translateKey)}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      {!listProductRequest?.isSuccess ? (
        <Grid container spacing={2} pt={2}>
          {[...new Array(30)]?.map((item, i) => (
            <Grid item xs={6} sm={4} md={3} lg={2} key={i}>
              <Skeleton height={200} />
            </Grid>
          ))}
        </Grid>
      ) : null}
      {listProductRequest?.isSuccess && products?.length ? (
        <Grid container spacing={2} pt={2}>
          {products?.map((item, i) => (
            <Grid item xs={6} sm={4} md={3} lg={2} key={i}>
              <ProductCard item={item} />
            </Grid>
          ))}
        </Grid>
      ) : null}
      {listProductRequest?.isSuccess && !products?.length ? (
        <Stack spacing={1} textAlign="center" alignItems="center" py={8}>
          <Image src="/assets/shop-online/paper-1.png" alt="" />
          <Typography variant="subtitle1" color="#B6BDCB">
            Không tìm thấy kết quả phù hợp
          </Typography>
          <Typography variant="body2" color="#B6BDCB">
            Vui lòng thử với từ khoá tìm kiếm khác.
          </Typography>
        </Stack>
      ) : null}
      {listProductRequest?.isSuccess && products?.length ? (
        <Box display="flex" justifyContent="center" pt={3}>
          <Pagination
            count={Math.ceil(total / (filter?.limit || 10))}
            page={filter?.skip / filter?.limit + 1}
            onChange={(e, page) =>
              page && handleUpdateFilter({ skip: (page - 1) * filter?.limit })
            }
          />
        </Box>
      ) : null}
    </div>
  )
}

export default Search
