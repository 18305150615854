import { Action, createSlice } from '@reduxjs/toolkit'
import { dispatch } from '../store'

export interface Auth {
  user: any
  isInitialized: boolean
  isAuthenticated: boolean
  isAuthenticating: boolean
  otpReceiver: string
  registerFormNameStore: number
  isSendOtpSuccess: boolean
  isVerifyOtpSuccess: boolean
  isResetPasswordSuccess: boolean
}

const initialState: Auth = {
  user: null,
  isInitialized: false,
  isAuthenticated: false,
  isAuthenticating: true,
  otpReceiver: '',
  registerFormNameStore: 1,
  isSendOtpSuccess: false,
  isVerifyOtpSuccess: false,
  isResetPasswordSuccess: false,
}

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialAuth: (state: Auth, action: Action<any>) => {
      return {
        ...state,
        isInitialized: true,
      }
    },
    updateAuthUser: (state: Auth, action: any) => {
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        isAuthenticating: false,
      }
    },
    removeAuth: state => {
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        isAuthenticating: false,
      }
    },
    setOtpReceiver: (state, action: any) => {
      return {
        ...state,
        otpReceiver: action.payload,
      }
    },
    setRegisterFormName: (state, action: any) => {
      return {
        ...state,
        registerFormNameStore: action.payload,
      }
    },
    setIsSendOtpSuccess: (state, action: any) => {
      return {
        ...state,
        isSendOtpSuccess: action.payload,
      }
    },
    setIsVerifyOtpSuccess: (state, action: any) => {
      return {
        ...state,
        isVerifyOtpSuccess: action.payload,
      }
    },
    setIsResetPassSuccess: (state, action: any) => {
      return {
        ...state,
        isResetPasswordSuccess: action.payload,
      }
    },
  },
})

export function setOtpReceiver(payload: any) {
  dispatch(AuthSlice.actions.setOtpReceiver(payload))
}

export function setRegisterFormName(payload: any) {
  dispatch(AuthSlice.actions.setRegisterFormName(payload))
}
export function setIsSendOtpSuccess(payload: any) {
  dispatch(AuthSlice.actions.setIsSendOtpSuccess(payload))
}

export function setIsVerifyOtpSuccess(payload: any) {
  dispatch(AuthSlice.actions.setIsVerifyOtpSuccess(payload))
}

export function setIsResetPassSuccess(payload: any) {
  dispatch(AuthSlice.actions.setIsResetPassSuccess(payload))
}

export const { updateAuthUser, removeAuth, initialAuth } = AuthSlice.actions

export default AuthSlice.reducer
