import { TRoute } from 'src/@types/common'
import { APP_URL } from 'src/config'
import Search from './Search.screen'

export const SearchRoutes: TRoute[] = [
  {
    path: APP_URL.SHOP_ONLINE_SEARCH,
    element: <Search />,
  },
]
