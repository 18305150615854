import { useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
// components
import LoadingScreen from '../components/loading-screen'
//
import Login from '../pages/auth/LoginPage'
import { useAuthContext } from './useAuthContext'
import { StorageService } from 'src/api/config/storage'
import { PATH_ADMIN, PATH_ANALYSIS } from 'src/routes/paths'
import { Role } from 'src/constants/enum'

// ----------------------------------------------------------------------

type AuthGuardProps = {
  roles?: Role[]
  children: React.ReactNode
}

export default function AuthGuard({ children, roles = [] }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, profile, role } = useAuthContext()

  const { pathname } = useLocation()

  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  )

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <Login />
  }

  if (roles?.length > 0 && !roles.includes(role)) {
    return <Navigate to="/404" />
  }

  if (profile != null) {
    let role = ''
    var id = ''
    var fullName = ''
    var pharmacistId = ''
    var typeRole = profile.role
    if (profile.role === Role.Pharmacist) {
      role = profile.profile.typePharmacist
      fullName = profile.profile.namePharmacist
      id = profile.profile.pharmacistId
      pharmacistId = profile.profile.pharmacistId
    } else if (profile.role === Role.Nursing) {
      role = profile.role
      fullName = profile.profile.nameNursing
    } else if (profile.role === Role.Analysis) {
      role = profile.role
      fullName = profile.profile.nameAnalysis
    } else if (profile.role === Role.Doctor) {
      role = profile.role
      fullName = profile.profile.nameDoctor
    }
    StorageService.set('DataProfile', {
      role: role,
      id: id,
      typeRole: typeRole,
      fullName: fullName,
      pharmacistId: pharmacistId,
      specialist: profile.profile?.specialist,
      avatar: profile.profile?.avatar,
    })
    switch (role) {
      case 'Admin':
        setRequestedLocation(PATH_ADMIN.general.approve)
        break
      case 'Pharmacy':
        //   case 'Advisory':
        setRequestedLocation(PATH_ADMIN.general.order)
        break
      case 'Nursing':
        //this.gotoPage('/nursing')
        break
      case 'Analysis':
        setRequestedLocation(PATH_ANALYSIS.root)
        break
      default:
    }
    if (requestedLocation && pathname !== requestedLocation) {
      setRequestedLocation(null)
      return <Navigate to={requestedLocation} />
    }
  }

  return <> {children} </>
}
