import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from '@mui/icons-material'
import { Button, Row, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { ProductCard } from 'src/sections/user'
import classes from './Promotion.module.less'

export const ProductPromotionInDay = ({ data }: any) => {
  const [slickRef, setSlickRef] = useState<Slider>()
  const { t } = useTranslation()

  const slickSetting: any = {
    ref: setSlickRef,
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    className: classes['slide-container'],
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  return (
    <Row className={classes['product-inday-container']}>
      <Row className={classes['product-inday']}>
        <Typography.Text className={classes['overlay-text']}>
          {t('HOMEPAGE.PRODUCT_CATEGORY.PROMOTION_IN_DAY')}
        </Typography.Text>
        <Button
          className={classes['overlay-left-button']}
          onClick={() => slickRef && slickRef?.slickPrev()}
        >
          <KeyboardArrowLeftRounded sx={{ color: '#798395', fontSize: 32 }} />
        </Button>
        <Button
          className={classes['overlay-right-button']}
          onClick={() => slickRef && slickRef?.slickNext()}
        >
          <KeyboardArrowRightRounded sx={{ color: '#798395', fontSize: 32 }} />
        </Button>
        <div className={classes['product-container']}>
          {data.length > 0 && (
            <Slider {...slickSetting}>
              {data?.map((item: any, index: number) => (
                <ProductCard item={item} key={index} />
              ))}
            </Slider>
          )}
        </div>
      </Row>
    </Row>
  )
}
