import { AxiosError } from 'axios'
import { put } from 'redux-saga/effects'
import { doHandShake, getIpClient } from 'src/api/api_functions/system'
import { StorageService } from '../storage'
import { saveCodeHandshakeUser, saveCodeHandshakePartner } from '../token'
import { BaseResponse } from './errors'
import { InstanceAxios as InstanceAxiosPartner } from '../axios/partner'
import { InstanceAxios as InstanceAxiosUser } from '../axios/user'

export function* HandleErrorSaga(error: BaseResponse, typeError: string) {
  yield put({
    type: typeError,
    payload: error,
  })
}

export interface IHandleResponseOptions {
  type?: 'partner' | 'user'
}

export async function doHandShakeAgain(
  axiosError: AxiosError,
  customOptions: IHandleResponseOptions = {}
): Promise<any> {
  const defaultOptions: IHandleResponseOptions = {
    type: 'user',
  }
  const options = Object.assign(defaultOptions, customOptions)
  if (!axiosError.config || !axiosError.config.headers) {
    return Promise.reject(axiosError)
  }
  if (!!axiosError.config.headers.notHandShakeAgain) {
    return Promise.reject(axiosError)
  }
  try {
    const ipClient = await getIpClient()
    var responseIp = {
      ipClient: ipClient.listIpClient.x_Real_IP,
      dateTime: ipClient.dateTime,
      timestamp: ipClient.timestamp,
    }
    const MAX_TIME_HAND_SHAKE_AGAIN = 3
    var COUNT_SHAKE_AGAIN = 0
    for (let i = 0; i < MAX_TIME_HAND_SHAKE_AGAIN; i++) {
      try {
        COUNT_SHAKE_AGAIN = COUNT_SHAKE_AGAIN + 1;
        if (options.type === 'partner') {
          const handShake = await doHandShake({
            ...responseIp,
            type: options?.type,
          })
          saveCodeHandshakePartner(handShake.xHandshake || '')
          StorageService.set('AES_KEY_PARTNER', handShake.xSecretKey)
          StorageService.set('AES_IV_PARTNER', handShake.xIVKey)
        } else {
          const handShake = await doHandShake(responseIp)
          saveCodeHandshakeUser(handShake.xHandshake || '')
          StorageService.set('AES_KEY_USER', handShake.xSecretKey)
          StorageService.set('AES_IV_USER', handShake.xIVKey)
        }
        const config = axiosError.config
        if (config.headers) {
          config.headers['notHandShakeAgain'] = true
        }
        if (options?.type === 'partner') {
          return InstanceAxiosPartner.request(config)
        } else {
          return InstanceAxiosUser.request(config)
        }
      } catch (error) {
        if (COUNT_SHAKE_AGAIN == 3) {
          saveCodeHandshakePartner('');
          saveCodeHandshakeUser('');
        }
      }
    }
  } catch (error) {
    return Promise.reject(axiosError)
  }
}
