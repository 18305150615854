import { Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
import {
  IconChevronRight,
  ImageBaby,
  ImageDoctorTalk,
  ImageInjection,
  ImageShoulder,
} from 'src/assets'
import { APP_URL } from 'src/config'
import classes from './ServiceOffline.module.less'

export const ServiceOffline = () => {
  const { t } = useTranslation()

  return (
    <Row
      justify={{ lg: 'center', xl: 'space-around' }}
      className={classes['service-offline-container']}
    >
      <Col className={classes['service-offline']}>
        <Col data-aos="fade-up">
          <Row justify={'center'}>
            <div
              className={classes['title']}
              dangerouslySetInnerHTML={{
                __html: t('HOMEPAGE.SERVICE_OFFLINE.TITLE'),
              }}
            ></div>
          </Row>
          <Row justify={'center'}>
            <div
              className={classes['subtitle']}
              dangerouslySetInnerHTML={{
                __html: t('HOMEPAGE.SERVICE_OFFLINE.SUBTITLE'),
              }}
            ></div>
          </Row>
        </Col>
        <Row
          justify={'space-between'}
          align="top"
          className={classes['item-container']}
          data-aos="fade-up"
          gutter={[20, 20]}
        >
          <RenderItemService
            link={APP_URL.HEALTH_SERVICE_BABY_BATH}
            title={t('HOMEPAGE.SERVICE_OFFLINE.BABY')}
            subtitle={t('HOMEPAGE.SERVICE_OFFLINE.SUB_BABY')}
            Image={ImageBaby}
            color={'#1A9CC6'}
          />
          <RenderItemService
            link={APP_URL.HEALTH_SERVICE_HUMAN_SICK}
            title={t('HOMEPAGE.SERVICE_OFFLINE.SICK')}
            subtitle={t('HOMEPAGE.SERVICE_OFFLINE.SUB_SICK')}
            Image={ImageDoctorTalk}
            color={'#A172D2'}
          />
          <RenderItemService
            link={APP_URL.HEALTH_SERVICE_GET_BLOOD}
            title={t('HOMEPAGE.SERVICE_OFFLINE.BLOOD_HOME')}
            subtitle={t('HOMEPAGE.SERVICE_OFFLINE.SUB_BLOOD_HOME')}
            Image={ImageInjection}
            color={'#F44067'}
          />
          <RenderItemService
            link={APP_URL.HEALTH_SERVICE_PHYSICAL_THERAPY}
            title={t('HOMEPAGE.SERVICE_OFFLINE.THERAPY')}
            subtitle={t('HOMEPAGE.SERVICE_OFFLINE.SUB_THERAPY')}
            Image={ImageShoulder}
            color={'#F5B11E'}
          />
        </Row>
      </Col>
    </Row>
  )
}

export const RenderItemService = ({
  Image,
  title,
  subtitle,
  color,
  link,
}: any) => {
  return (
    <Col
      xs={24}
      sm={24}
      md={12}
      lg={12}
      xl={5}
      xxl={5}
      className={classes['item']}
    >
      <Link to={link}>
        <Row justify={'center'} className={classes['img']}>
          <LazyLoad once height={200}>
            <img src={Image} alt="" />
          </LazyLoad>

          <div style={{ background: color }} className={classes['overlay']} />
        </Row>
        <Row justify={'space-between'} wrap={false}>
          <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
            <Row>
              <Typography.Text
                className={classes['title']}
                style={{ color: color }}
              >
                {title}
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text className={classes['subtitle']}>
                {subtitle}
              </Typography.Text>
            </Row>
          </Col>
          <Col className={classes['button']}>
            <img src={IconChevronRight} alt="" />
          </Col>
        </Row>
      </Link>
    </Col>
  )
}
