import { Box, BoxProps } from '@mui/material'
import { FC } from 'react'

import classes from './LoadingCircle.module.less'

type LoadingCircleProps = BoxProps & {}

export const LoadingCircle: FC<LoadingCircleProps> = ({
  ...params
}: LoadingCircleProps) => {
  return (
    <Box {...params}>
      <img
        className={classes['loading']}
        src="/assets/shop-online/promotions/loading.png"
        alt="loading-promotions"
      />
    </Box>
  )
}
