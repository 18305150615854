import { APP_URL } from 'src/config'

export const getMenu = (t: any) => [
  {
    icon: '/assets/images/header/ic-person.png',
    label: 'Thông tin cá nhân',
    url: APP_URL.USER_PROFILE_PERSONAL_INFORMATION,
  },
  {
    icon: '/assets/images/header/ic-map.png',
    label: 'Danh sách địa chỉ',
    url: APP_URL.USER_PROFILE_ADDRESS_LIST,
  },
  {
    icon: '/assets/images/header/ic-wallet.png',
    label: 'Phương thức thanh toán',
    url: '#',
  },
  {
    icon: '/assets/images/header/ic-track.png',
    label: 'Theo dõi đơn hàng',
    url: APP_URL.USER_PROFILE_ORDER_TRACKING,
  },
]
