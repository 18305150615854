import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fNumber } from 'src/utils/formatNumber'
import { ProductByShop } from './ProductByShop'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckIcon from '@mui/icons-material/Check'
import { format } from 'date-fns'
import Image from 'src/components/image/Image'
import { paymentMethodDetail } from 'src/pages/shop-online/Cart/types/pay'
import { Close } from '@mui/icons-material'

interface TransactionDetailDialogProps {
  open: boolean
  onClose: VoidFunction
  transaction: any[]
  transactionId: string
  payed: boolean
  address: any
}

export const TransactionDetailDialog = ({
  open,
  onClose,
  transaction,
  transactionId,
  payed,
  address,
}: TransactionDetailDialogProps) => {
  const { t } = useTranslation()
  const [isCopy, setCopy] = useState(false)

  const order = transaction?.[0]
  const { price, transportPrice, totalPromotion } = useMemo(() => {
    let price = 0
    let transportPrice = 0
    let promotionPrice = 0
    let transportPromotionPrice = 0
    for (const order of transaction) {
      price += +order?.price || 0
      transportPrice += +order?.transportPrice || 0
      promotionPrice += +order?.promotionPrice || 0
      transportPromotionPrice += +order?.transportPromotionPrice || 0
    }
    return {
      price,
      transportPrice,
      totalPromotion: promotionPrice + transportPromotionPrice,
    }
  }, [transaction])

  const handleCopy = () => {
    navigator.clipboard.writeText(transactionId)
    setCopy(true)
    setTimeout(() => {
      setCopy(false)
    }, 1000)
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ py: 2 }}
      >
        <Typography variant="h5" color="primary.main">
          {t('cart.orderDetail')}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          <Stack spacing={1}>
            <Typography variant="subtitle2">{t('cart.orderInfo')}</Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="#798395">
                {t('cart.orderCode')}
              </Typography>
              <Box display="flex" gap="4px" alignItems="flex-end">
                <Typography
                  typography="subtitle2"
                  align="right"
                  color="#576071"
                  sx={{ textAlign: 'bottom' }}
                >
                  {transactionId?.slice(0, 8)}
                  {'...'}
                </Typography>
                {isCopy ? (
                  <Typography>
                    <CheckIcon sx={{ color: 'green', fontSize: '20px' }} />
                  </Typography>
                ) : (
                  <Typography
                    onClick={handleCopy}
                    sx={{ padding: 0, margin: 0, height: '100%' }}
                  >
                    <ContentCopyIcon
                      sx={{ fontSize: '20px', marginBottom: '4px' }}
                    />
                  </Typography>
                )}
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="#798395">
                {t('cart.orderDate')}
              </Typography>
              <Typography variant="subtitle2" color="#576071">
                {format(new Date(order?.created), 'dd/MM/yyyy HH:mm:ss')}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="#798395">
                {t('cart.orderReceiver')}
              </Typography>
              <Typography variant="subtitle2" color="#576071">
                {order?.accountName}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="#798395">
                {t('cart.address')}
              </Typography>
              <Typography
                variant="subtitle2"
                color="#576071"
                maxWidth={300}
                textAlign="right"
              >
                {address?.addressAccount}, {address?.wardsName},{' '}
                {address?.districtName}, {address?.provinceName}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="#798395">
                {t('cart.paymentMethod')}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Image
                  src={paymentMethodDetail?.[order?.typePay]?.icon}
                  alt=""
                  sx={{ width: 24 }}
                />
                <Typography variant="subtitle2" color="#576071" flexGrow={1}>
                  {t(paymentMethodDetail?.[order?.typePay]?.translateKey)}
                  {order?.typePay !== 'COD' ? ` (${t('cart.paySuccess')})` : ''}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="subtitle2">
              {t('Chi tiết sản phẩm')}
            </Typography>
            {transaction?.map((item, i) => (
              <ProductByShop order={item} key={i} />
            ))}
          </Stack>
          <Stack spacing={1}>
            <Typography variant="subtitle2">{t('cart.payDetail')}</Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="#798395">
                {t('cart.originalPrice')}
              </Typography>
              <Typography variant="subtitle2" color="#576071">
                {fNumber(price - transportPrice + totalPromotion)}₫
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="#798395">
                {t('cart.transportPrice')}
              </Typography>
              <Typography variant="subtitle2" color="#576071">
                {fNumber(transportPrice)}₫
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="#798395">
                {t('cart.promotion')}
              </Typography>
              <Typography variant="subtitle2" color="#576071">
                {fNumber(totalPromotion)}₫
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="#798395">
                {t('cart.total')}
              </Typography>
              <Typography variant="subtitle1" color="#F0224F">
                {fNumber(price)}₫
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
