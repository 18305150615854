import { Col, Row, Typography } from 'antd'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { AboutDoctorBackground, AboutDoctorMask } from 'src/assets'
import classes from './TopPartner.module.less'
import './TopPartner.less'

export const TopPartner = ({ data }: any) => {
  const { t } = useTranslation()

  if (isEmpty(data)) return null

  const slickSetting = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    className: 'top-partner-slider',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  const RenderDoctor = ({ DoctorImage, name, description }: any) => {
    return (
      <div className={classes['slide-item']}>
        <div className={classes['doctor-container']}>
          <Row justify={'center'} className={classes['img-container']}>
            <img src={DoctorImage} alt="" className={classes['img-avatar']} />
            <img
              src={AboutDoctorBackground}
              alt=""
              className={classes['img-background']}
            />
            <img src={AboutDoctorMask} alt="" className={classes['img-mask']} />
          </Row>
          <Row justify={'center'} className={classes['info']}>
            <Typography.Text className={classes['name']}>
              {name}
            </Typography.Text>
          </Row>
          <p className={classes['description']}>{description}</p>
        </div>
      </div>
    )
  }

  return (
    <Col xs={24} className={classes['top-partner-container']}>
      <div className={classes['header']}>
        <div className={classes['title']}>
          {t('HOMEPAGE.TOP_PARTNER.TITLE')}{' '}
          <span>{t('HOMEPAGE.TOP_PARTNER.AT_SOPHIE')}</span>
        </div>
        <div className={classes['subtitle']}>
          {t('HOMEPAGE.TOP_PARTNER.SUBTITLE')}
        </div>
      </div>
      <div className={classes['slide-container']}>
        <Slider {...slickSetting}>
          {(data || [])
            ?.filter((item: any) => !!item?.fullName)
            ?.map((item: any, i: number) => (
              <RenderDoctor
                DoctorImage={item?.avatar}
                name={item?.fullName}
                description={item?.specialist}
                key={i}
              />
            ))}
        </Slider>
      </div>
    </Col>
  )
}
