import { Typography, TypographyProps } from '@mui/material'
import { styled } from '@mui/material/styles'

interface TypographyLineClampProps extends TypographyProps {
  line?: number
}

export const TypographyLineClamp = styled(Typography)<TypographyLineClampProps>(
  (props: TypographyLineClampProps) => ({
    fontSize: 14,
    display: '-webkit-box',
    maxWidth: '300px',
    WebkitLineClamp: `${props?.line || 3}`,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  })
)
