export const CloseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M6.34319 4.92893C5.95267 4.53841 5.3195 4.53841 4.92898 4.92893C4.53845 5.31946 4.53845 5.95262 4.92898 6.34315L10.5858 12L4.92898 17.6569C4.53845 18.0474 4.53845 18.6805 4.92898 19.0711C5.3195 19.4616 5.95266 19.4616 6.34319 19.0711L12 13.4142L17.6569 19.0711C18.0474 19.4616 18.6806 19.4616 19.0711 19.0711C19.4616 18.6805 19.4616 18.0474 19.0711 17.6569L13.4143 12L19.0711 6.34314C19.4616 5.95262 19.4616 5.31946 19.0711 4.92893C18.6806 4.53841 18.0474 4.53841 17.6569 4.92893L12 10.5858L6.34319 4.92893Z"
          fill="#798395"
        />
      </g>
      <defs>
        <clipPath id="clip0_7975_73978">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
