import CryptoJS from 'crypto-js'
import { logDev } from 'src/utils'
import { appConfig } from '../../../config'
import { StorageService } from '../storage'

var LIST_URL_KEY_DEFAULT = [
  '/Sys/Handshake',
  '/Sys/IpClient',
  '/Sys/OperatingSystem',
]

/**
 * Encrypt data to save to Session
 * @param {*} objectJson : Json Object
 * @returns : Text
 */

export function encryptSession(objectJson: string) {
  try {
    var plainText = JSON.stringify(objectJson)
    if (plainText === undefined || plainText === '') return plainText
    var cipher = CryptoJS.AES.encrypt(
      plainText,
      CryptoJS.enc.Utf8.parse(appConfig.APP_DEFAULT_AES_SECRETKEY),
      {
        iv: CryptoJS.enc.Utf8.parse(appConfig.APP_DEFAULT_AES_IVKEY),
        mode: CryptoJS.mode.CBC,
      }
    )
    var encryptText = cipher.toString()
    return encryptText
  } catch {
    return undefined
  }
}

/**
 * Decrypt data from Session
 * @param {*} encryptText : Text
 * @returns : Json Object
 */
export function decryptSession(encryptText: string) {
  try {
    if (encryptText === undefined || encryptText === '') return encryptText
    var cipher = CryptoJS.AES.decrypt(
      encryptText,
      CryptoJS.enc.Utf8.parse(appConfig.APP_DEFAULT_AES_SECRETKEY),
      {
        iv: CryptoJS.enc.Utf8.parse(appConfig.APP_DEFAULT_AES_IVKEY),
        mode: CryptoJS.mode.CBC,
      }
    )
    var plainText = CryptoJS.enc.Utf8.stringify(cipher).toString()
    try {
      var objectJson = JSON.parse(plainText)
      return objectJson
    } catch {
      return undefined
    }
  } catch {
    return undefined
  }
}

/**
 * Decrypt data response from api
 * @param {*} encryptText : String
 * @param {*} url : String
 * @returns : Json Object
 */

export function encryptData(objectJson: string, url: string) {
  try {
    var plainText = JSON.stringify(objectJson)
    if (plainText === undefined || plainText === '') return plainText
    // 1. Use the Key from the handshake
    if (url !== undefined && LIST_URL_KEY_DEFAULT.indexOf(url) === -1) {
      var cipher = CryptoJS.AES.encrypt(
        plainText,
        CryptoJS.enc.Utf8.parse(StorageService.get('AES_KEY_USER')),
        {
          iv: CryptoJS.enc.Utf8.parse(StorageService.get('AES_IV_USER')),
          mode: CryptoJS.mode.CBC,
        }
      )
      var encryptText = cipher.toString()
      return encryptText
    } else {
      // 2. Use Default Key
      cipher = CryptoJS.AES.encrypt(
        plainText,
        CryptoJS.enc.Utf8.parse(appConfig.APP_DEFAULT_AES_SECRETKEY),
        {
          iv: CryptoJS.enc.Utf8.parse(appConfig.APP_DEFAULT_AES_IVKEY),
          mode: CryptoJS.mode.CBC,
        }
      )
      encryptText = cipher.toString()
      return encryptText
    }
  } catch {
    return undefined
  }
}

/**
 * Decrypt data from Session
 * @param {*} encryptText : Text
 * @returns : Json Object
 */
export interface IDecryptDataOptions {
  url?: string
  type?: 'partner' | 'user'
}

export function decryptData(
  encryptText: string,
  customOptions: IDecryptDataOptions = {}
) {
  const defaultOptions: IDecryptDataOptions = {
    url: '',
    type: 'user',
  }
  const options = Object.assign(defaultOptions, customOptions)
  const { url, type } = options
  const aesKey: string =
    type === 'partner'
      ? StorageService.get('AES_KEY_PARTNER')
      : StorageService.get('AES_KEY_USER')
  const aesIv: string =
    type === 'partner'
      ? StorageService.get('AES_IV_PARTNER')
      : StorageService.get('AES_IV_USER')

  // logDev('decryptData', type, aesKey, aesIv)

  try {
    if (encryptText === undefined || encryptText === '') return encryptText
    // 1. Use the Key from the handshake
    if (url !== undefined && LIST_URL_KEY_DEFAULT.indexOf(url) === -1) {
      var cipher = CryptoJS.AES.decrypt(
        encryptText,
        CryptoJS.enc.Utf8.parse(aesKey),
        {
          iv: CryptoJS.enc.Utf8.parse(aesIv),
          mode: CryptoJS.mode.CBC,
        }
      )
      var plainText = CryptoJS.enc.Utf8.stringify(cipher).toString()
      try {
        var objectJson = JSON.parse(plainText)
        return objectJson
      } catch {
        return undefined
      }
    } else {
      // 2. Use Default Key
      cipher = CryptoJS.AES.decrypt(
        encryptText,
        CryptoJS.enc.Utf8.parse(appConfig.APP_DEFAULT_AES_SECRETKEY),
        {
          iv: CryptoJS.enc.Utf8.parse(appConfig.APP_DEFAULT_AES_IVKEY),
          mode: CryptoJS.mode.CBC,
        }
      )
      plainText = CryptoJS.enc.Utf8.stringify(cipher).toString()
      try {
        objectJson = JSON.parse(plainText)
        return objectJson
      } catch {
        return undefined
      }
    }
  } catch (error) {
    logDev('decryptData err1', error)
    return undefined
  }
}
