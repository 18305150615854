import md5 from 'md5'
import { REST_API_ADMIN } from '../api_paths'
import { InstanceAxios } from '../config/axios/partner'
import { generateSignaturePartner, logDev } from 'src/utils'
import { TypeLogin } from 'src/sections/auth/enum'
import { getIpClient } from './system'

export const onLogin = async (requestModel: any): Promise<any> => {
  const route: string = REST_API_ADMIN.LOGIN.uri

  const body = {
    typeLogin: requestModel?.data?.typeLogin,
    username: '',
    phoneNumber:
      requestModel?.data?.userName === TypeLogin.PHONE
        ? requestModel?.data?.userName
        : requestModel?.data?.phoneNumber,
    email: requestModel?.data?.userName,
    password: md5(requestModel?.data?.password),
    code2FA: '',
  }

  return InstanceAxios.post(route, body)
}

export const onFacebookLogin = async (requestModel: any): Promise<any> => {
  const route: string = REST_API_ADMIN.LOGIN_WITH_FACEBOOK.uri

  const body = {
    typeLogin: 'Facebook',
    access_token: requestModel?.data?.accessToken,
    id: requestModel?.data?.id,
    name: requestModel?.data?.name,
    picture: requestModel?.data?.picture?.data?.url,
    mobile: '',
    profile: {
      name: requestModel?.data?.name,
      family_name: requestModel?.data?.familyName,
      given_name: requestModel?.data?.givenName,
      picture: requestModel?.data?.profileObj?.imageUrl,
    },
  }

  return InstanceAxios.post(route, body)
}

export const onGoogleLogin = async (requestModel: any): Promise<any> => {
  const route: string = REST_API_ADMIN.LOGIN_WITH_GOOGLE.uri

  const body = {
    typeLogin: 'Google',
    access_token: requestModel?.data?.accessToken,
    id_token: requestModel?.data?.tokenId,
    profile: {
      name: requestModel?.data?.profileObj?.name,
      profile: requestModel?.data?.profileObj,
      email: requestModel?.data?.profileObj.email,
      family_name: requestModel?.data?.profileObj?.familyName,
      given_name: requestModel?.data?.profileObj?.givenName,
      picture: requestModel?.data?.profileObj?.imageUrl,
    },
  }

  return InstanceAxios.post(route, body)
}

export const onGetProfile = async (): Promise<any> => {
  const route: string = REST_API_ADMIN.GET_PROFILE.uri

  return InstanceAxios.get(route)
}

export const onLogout = async (requestModel: any): Promise<any> => {
  const route: string = REST_API_ADMIN.LOGOUT.uri

  const body = {
    refreshToken: requestModel?.refreshToken,
  }

  return InstanceAxios.post(route, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const onSendOtp = async (requestModel: any): Promise<any> => {
  const route: string = REST_API_ADMIN.SEND_OTP.uri

  const body = {
    typeOtp: requestModel.data.typeOtp, // là enum truyền lên, Registry, ForgotPass (trong trường hợp này là ForgotPass)
    phoneNumber: requestModel.data.phoneNumber,
    email: requestModel.data.email,
  }

  const ipClientRes = await getIpClient()
  const timestamp = ipClientRes?.timestamp
  // const timestamp = new Date().getTime()

  const [signature] = generateSignaturePartner(body, timestamp)

  logDev('timestamp ', timestamp)

  return InstanceAxios.post(route, JSON.stringify(btoa(JSON.stringify(body))), {
    headers: {
      'x-request-timestamp': timestamp.toString(),
      'x-signature': signature,
    } as any,
  })
}

export const onVerifyOtp = async (requestModel: any): Promise<any> => {
  const route: string = REST_API_ADMIN.VERIFY_OTP.uri

  // Ở dưới view truyền lên 4 trường  typeOtp, phoneNumber, email, codeOtp
  const body = {
    typeOtp: requestModel.data?.typeOtp, // là enum truyền lên, Registry, ForgotPass (trong trường hợp này là ForgotPass)
    phoneNumber: requestModel.data?.phoneNumber,
    codeOtp: requestModel.data?.codeOtp, // Mã OTP vừa nhận, mặc đình là 123456
  }

  const ipClientRes = await getIpClient()
  const timestamp = ipClientRes?.timestamp
  // const timestamp = new Date().getTime()
  const [signature] = generateSignaturePartner(body, timestamp)

  return InstanceAxios.post(route, JSON.stringify(btoa(JSON.stringify(body))), {
    headers: {
      'x-request-timestamp': timestamp.toString(),
      'x-signature': signature,
    } as any,
  })
}

export const onResetPass = async (requestModel: any): Promise<any> => {
  const route: string = REST_API_ADMIN.RESET_PASS.uri

  const body = {
    phoneNumber: requestModel.data.phoneNumber,
    newPassword: requestModel.data.newPassword,
    codeOtp: '123456', // otp default
  }

  return InstanceAxios.post(route, body)
}

export const onCheckAuthAccount = async (requestModel: any) => {
  const route: string = REST_API_ADMIN.CHECK_AUTH_ACCOUNT.uri

  const body = {
    phoneNumber: requestModel?.data?.phoneNumber,
  }

  const ipClientRes = await getIpClient()
  const timestamp = ipClientRes?.timestamp
  // const timestamp = new Date().getTime()
  const [signature] = generateSignaturePartner(body, timestamp)

  return InstanceAxios.post(
    route,
    JSON.stringify(btoa(JSON.stringify(body))),
    {}
  )
}

export const onCheckForAll = async (requestModel: any): Promise<any> => {
  const route: string = REST_API_ADMIN.CHECK_FOR_ALL.uri

  const body = {
    typeOtp: requestModel.data.typeOtp,
    PhoneNumber: requestModel.data.otpReceiver,
    Email: requestModel.data.otpReceiver,
  }

  const ipClientRes = await getIpClient()
  const timestamp = ipClientRes?.timestamp
  // const timestamp = new Date().getTime()
  const [signature] = generateSignaturePartner(body, timestamp)

  return InstanceAxios.post(route, JSON.stringify(btoa(JSON.stringify(body))), {
    headers: {
      'x-request-timestamp': timestamp.toString(),
      'x-signature': signature,
    } as any,
  })
}
