import { Button, Stack, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classes from './ProductInfo.module.less'

interface ProductDescriptionProps {
  product: any
}

export const ProductDescription = ({ product }: ProductDescriptionProps) => {
  const { t } = useTranslation()
  const productInfo: any[] = product?.productInfo || []
  const container = useRef<HTMLDivElement>()
  const [isShowReadMore, setShowReadMore] = useState(false)
  const [isExpand, setExpand] = useState(false)

  useEffect(() => {
    const height = container.current?.getBoundingClientRect().height || 0
    setShowReadMore(height > 150)
  }, [product])

  return (
    <>
      <div
        ref={container as any}
        className={
          `${classes['description']}` +
          ` ${isShowReadMore && !isExpand ? classes['collapse'] : ''}`
        }
      >
        <Stack spacing={3}>
          {productInfo?.map((item, i) => (
            <Stack key={i} spacing={1}>
              <Typography variant="h6">{item?.title}</Typography>
              <Typography variant="body1" whiteSpace="pre-line">
                {item?.content}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </div>
      {isShowReadMore && (
        <Button
          size="large"
          variant="contained"
          onClick={() => setExpand(!isExpand)}
          sx={{
            backgroundColor: 'primary.lighter',
            color: 'primary.main',
            textTransform: 'none',
            mt: 2,
            ':hover': {
              backgroundColor: 'primary.lighter',
            },
          }}
          fullWidth
        >
          {isExpand
            ? t('pharmacyOnline.product.collapse')
            : t('pharmacyOnline.product.readMore')}
        </Button>
      )}
    </>
  )
}
