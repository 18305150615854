import React, { useState } from 'react'
import classes from './DownloadBottom.module.less'
import { useTranslation } from 'react-i18next'
import {
  ArrowIcon,
  DownloadIcon,
  ImageAppStore,
  ImageGooglePlay,
} from 'src/assets'
import { LINK_DOWNLOAD_APP_DOCTOR_PLAY, LINK_DOWNLOAD_DOCTOR_STORE } from 'src/config'

export const DownloadBottom: React.FC<{}> = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  return (
    <div className={classes['root']}>
      <div onClick={() => setShow(!show)} className={classes['top-title']}>
        <img src={DownloadIcon} alt="" />
        <h2>Tải ứng dụng cho bác sĩ</h2>
        <div
          className={`${show ? classes['active-btn'] : ''} ${
            classes['btn-show']
          }`}
        >
          <img src={ArrowIcon} alt="" />
        </div>
      </div>
      <div
        className={`${show ? classes['active'] : ''} ${classes['wrap-layout']}`}
      >
        <a rel="noreferrer" target={'_blank'} href={LINK_DOWNLOAD_DOCTOR_STORE}>
          <img src={ImageAppStore} alt="" className={classes['img-social']} />
        </a>
        <a rel="noreferrer" target={'_blank'} href={LINK_DOWNLOAD_APP_DOCTOR_PLAY}>
          <img src={ImageGooglePlay} alt="" className={classes['img-social']} />
        </a>
      </div>
    </div>
  )
}
