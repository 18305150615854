import { ArrowDropDownRounded } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import Image from 'src/components/image/Image'

export const AccountInfoSection = () => {
  const { profile } = useAuthUserContext()

  return (
    <Stack direction="row" alignItems="center" spacing={1} width="180px">
      <Image
        src="/assets/shop-online/ic-avatar.png"
        alt=""
        sx={{ width: '40px' }}
      />
      <Stack flexGrow={1}>
        <Typography variant="caption" color="#576071">
          Tài khoản
        </Typography>
        <Typography variant="h6" color="primary.main">
          {profile?.lastname}
        </Typography>
      </Stack>
      <ArrowDropDownRounded />
    </Stack>
  )
}
