import { InstanceAxios } from '../config/axios/user'

export const getListStatusTreeTransportAccount = (body?: any) => {
  const path = '/api/account/transport/ListStatusTree'
  return InstanceAxios.post(path, body)
}

export const getListServiceTransportAccount = (body?: any) => {
  const path = '/api/account/transport/ListServices'
  return InstanceAxios.post(path, body)
}
