import { TRoute } from 'src/@types/common'
import { APP_URL } from 'src/config'
import PayResult from './PayResult.screen'

export const PayResultRoutes: TRoute[] = [
  {
    path: APP_URL.SHOP_ONLINE_PAY_RESULT,
    element: <PayResult />,
  },
]
