import { Add, Remove } from '@mui/icons-material'
import {
  Box,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import {
  useGetListPromotionPatient,
  useGetListTransportPromotionPatient,
} from 'src/api/hooks/patient.query'
import { ImageProductSale } from 'src/assets'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import { IconButtonAnimate } from 'src/components/animate'
import NumericFormatCustom from 'src/components/common/NumericFormatCustom'
import Image from 'src/components/image/Image'
import useResponsive from 'src/hooks/useResponsive'
import { fCurrency, fNumber } from 'src/utils/formatNumber'
import { useCartContext } from '../../context'
import { AddPromotionDialog } from './AddPromotionDialog'

export const Pricing = () => {
  const { t } = useTranslation()
  const isMd = useResponsive('up', 'md')
  const { isAuthenticated } = useAuthUserContext()
  const { order, isOrderLoading, cartData } = useCartContext()
  const listTransportPromotionRequest = useGetListTransportPromotionPatient()
  const listDiscountPromotionRequest = useGetListPromotionPatient()
  const [isOpenPromotionDialog, setOpenPromotionDialog] = useState(false)

  const transportPromotions = listTransportPromotionRequest?.data?.data || []
  const discountPromotions = listDiscountPromotionRequest?.data?.data || []

  const selectedTransportPromotions = useMemo(
    () =>
      transportPromotions?.find(
        (item: any) =>
          item?.transportPromotionId === order?.transportPromotionIds?.[0]
      ),
    [transportPromotions, order?.transportPromotionIds?.[0]]
  )

  const selectedDiscountPromotions = useMemo(
    () =>
      discountPromotions?.find(
        (item: any) => item?.promotionId === order?.promotionIds?.[0]
      ),
    [discountPromotions, order?.promotionIds?.[0]]
  )

  useEffect(() => {
    if (isAuthenticated && order?.listProduct?.length > 0) {
      listTransportPromotionRequest.mutateAsync({
        listProduct: order?.listProduct,
        typePay: order?.typePay || cartData?.typePay,
      })
    }
    if (isAuthenticated) {
      listDiscountPromotionRequest.mutateAsync({})
    }
  }, [
    isAuthenticated,
    order?.typePay,
    cartData?.typePay,
    JSON.stringify(order?.listProduct?.map(item => item?.productId) || {}),
  ])

  const promotion =
    (order?.promotionPrice || 0) + (order?.transportPromotionPrice || 0)

  return (
    <Paper>
      <Box p={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitle2" color="#576071">
            {t('cart.pay')}
          </Typography>
          {!selectedTransportPromotions && (
            <Typography
              variant="subtitle2"
              color="#1A9CC6"
              sx={{ cursor: 'pointer' }}
              onClick={() => setOpenPromotionDialog(true)}
            >
              {t('cart.addPromotion')}
            </Typography>
          )}
        </Stack>
        <Stack spacing={1} mt={2}>
          {(!!selectedTransportPromotions || !!selectedDiscountPromotions) && (
            <Box p={1.5} bgcolor="#F4F8FB" borderRadius={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" color="#798395">
                  {t('cart.promotion')}:
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="#1A9CC6"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setOpenPromotionDialog(true)}
                >
                  {t('cart.change')}
                </Typography>
              </Stack>
              <Typography variant="subtitle2" color="#576071">
                {selectedDiscountPromotions?.promotionName}
              </Typography>
              <Typography variant="subtitle2" color="#576071">
                {selectedTransportPromotions?.transportPromotionName}
              </Typography>
            </Box>
          )}
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" color="#798395">
              {t('cart.originalPrice')}
            </Typography>
            {isOrderLoading ? (
              <Skeleton width={80} />
            ) : (
              <Typography variant="subtitle2" color="#576071">
                {fNumber(order?.price - order?.transportPrice + promotion)}₫
              </Typography>
            )}
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" color="#798395">
              {t('cart.transportPrice')}
            </Typography>
            {isOrderLoading ? (
              <Skeleton width={80} />
            ) : (
              <Typography variant="subtitle2" color="#576071">
                {fNumber(+order?.transportPrice || 0)}₫
              </Typography>
            )}
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" color="#798395">
              {t('cart.promotion')}
            </Typography>
            {isOrderLoading ? (
              <Skeleton width={80} />
            ) : (
              <Typography variant="subtitle2" color="#576071">
                {fNumber(promotion)}₫
              </Typography>
            )}
          </Stack>
        </Stack>
      </Box>
      <Divider />
      <Stack direction="row" justifyContent="space-between" p={2}>
        <Typography variant="body2" color="#798395">
          {t('cart.total')}
        </Typography>
        {isOrderLoading ? (
          <Skeleton width={100} height={30} />
        ) : (
          <Typography variant="h6" color="#F0224F">
            {fNumber(order?.price)}₫
          </Typography>
        )}
      </Stack>
      <AddPromotionDialog
        open={isOpenPromotionDialog}
        onClose={() => setOpenPromotionDialog(false)}
        transportPromotions={transportPromotions}
      />
    </Paper>
  )
}
