import React from 'react'
import classes from './AboutSophie.module.less'
import {
  AboutDoctor,
  AboutDoctor1,
  AboutDoctor2,
  AboutDoctor3,
  AboutDoctor4,
  AboutDoctor5,
  AboutDoctor6,
  AboutDoctor7,
  AboutDoctor8,
  AboutDoctorBackground,
  AboutDoctorBonds,
  AboutDoctorMask,
  AboutDoctorQuoteMark,
  BackgroundOrange,
  ImageIntroduceBackground,
} from 'src/assets'
import { Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

export type TAboutSophieProps = {}

export const AboutSophie: React.FC<
  TAboutSophieProps
> = ({}: TAboutSophieProps) => {
  const { t } = useTranslation()

  return (
    <Row
      justify={{ md: 'start', lg: 'center' }}
      className={classes['about-container']}
    >
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <img
        src={ImageIntroduceBackground}
        alt=""
        className={classes['about-overlay']}
      />
      <Col className={classes['about']}>
        <Col xs={24} sm={24} md={24} lg={24} className={classes['part-1']}>
          <Row justify={'center'} data-aos="zoom-out-down">
            <Typography.Text className={classes['title']}>
              {t('ABOUT_SOPHIE.PART1.TITLE')}
            </Typography.Text>
          </Row>
          <Row justify={'center'} data-aos="zoom-out-down">
            <Typography.Text className={classes['subtitle']}>
              {t('ABOUT_SOPHIE.PART1.SUBTITLE')}
            </Typography.Text>
          </Row>
          <Row justify={'center'}>
            <Typography.Text className={classes['paragraph']}>
              {t('ABOUT_SOPHIE.PART1.PARAGRAPH')}
            </Typography.Text>
          </Row>
          <Row justify={'center'} data-aos="zoom-out-left">
            <img
              src={AboutDoctor}
              alt=""
              className={classes['img-doctor-about']}
            />
          </Row>
        </Col>
        <Row
          align={'middle'}
          justify={'space-between'}
          className={classes['part-2']}
          gutter={[0, 40]}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <img
              src={AboutDoctorBonds}
              alt=""
              className={classes['img-doctor-about']}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10}>
            <Row justify={{ lg: 'center', xl: 'start' }}>
              <Typography.Text className={classes['title']}>
                {t('ABOUT_SOPHIE.PART2.TITLE')}
              </Typography.Text>
            </Row>
            <Row justify={{ lg: 'center', xl: 'start' }}>
              <Typography.Text className={classes['subtitle']}>
                {t('ABOUT_SOPHIE.PART2.TITLE2')}
              </Typography.Text>
            </Row>
            <Row justify={{ lg: 'center', xl: 'start' }}>
              <Typography.Text className={classes['subsubtitle']}>
                {t('ABOUT_SOPHIE.PART2.SUBTITLE')}
              </Typography.Text>
            </Row>
            <Row justify={{ lg: 'center', xl: 'start' }}>
              <Typography.Text className={classes['paragraph']}>
                {t('ABOUT_SOPHIE.PART2.PARAGRAPH')}
              </Typography.Text>
            </Row>
            <Row justify={{ lg: 'center', xl: 'start' }} align={'middle'}>
              <Col xs={4} sm={3} md={3} lg={2} xl={3}>
                <img
                  src={AboutDoctorQuoteMark}
                  alt=""
                  className={classes['about-quote-mark']}
                />
              </Col>
              <Col span={16}>
                <Typography.Text className={classes['paragraph-2']}>
                  {t('ABOUT_SOPHIE.PART2.PARAGRAPH2')}
                </Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Row className={classes['part-3']} gutter={[0, 20]}>
        <Row className={classes['overlay']}>
          <img src={BackgroundOrange} alt="" />
        </Row>
        <Row
          className={classes['operator-container']}
          justify={'space-between'}
          gutter={[0, 20]}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={11}>
            <Row>
              <Typography.Text className={classes['title']}>
                {t('ABOUT_SOPHIE.PART3.TITLE')}
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text className={classes['subtitle']}>
                {t('ABOUT_SOPHIE.PART3.SUBTITLE')}
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text className={classes['subsubtitle']}>
                {t('ABOUT_SOPHIE.PART3.SUBSUBTITLE')}
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text className={classes['paragraph']}>
                {t('ABOUT_SOPHIE.PART3.PARAGRAPH')}
              </Typography.Text>
            </Row>
          </Col>
          <RenderDoctor
            DoctorImage={AboutDoctor7}
            name={t('ABOUT_SOPHIE.PART3.DOCTOR1')}
            description={t('ABOUT_SOPHIE.PART3.DOCTOR1_DESCRIPTION')}
          />
          <RenderDoctor
            DoctorImage={AboutDoctor8}
            name={t('ABOUT_SOPHIE.PART3.DOCTOR2')}
            description={t('ABOUT_SOPHIE.PART3.DOCTOR2_DESCRIPTION')}
          />
        </Row>
        {/* <Row
          className={classes['operator-container']}
          justify={'space-between'}
          gutter={[0, 20]}
        >
          <RenderDoctor
            DoctorImage={AboutDoctor3}
            name={t('ABOUT_SOPHIE.PART3.DOCTOR3')}
            description={t('ABOUT_SOPHIE.PART3.DOCTOR3_DESCRIPTION')}
          />
          <RenderDoctor
            DoctorImage={AboutDoctor4}
            name={t('ABOUT_SOPHIE.PART3.DOCTOR4')}
            description={t('ABOUT_SOPHIE.PART3.DOCTOR4_DESCRIPTION')}
          />
          <RenderDoctor
            DoctorImage={AboutDoctor5}
            name={t('ABOUT_SOPHIE.PART3.DOCTOR5')}
            description={t('ABOUT_SOPHIE.PART3.DOCTOR5_DESCRIPTION')}
          />
          <RenderDoctor
            DoctorImage={AboutDoctor6}
            name={t('ABOUT_SOPHIE.PART3.DOCTOR6')}
            description={t('ABOUT_SOPHIE.PART3.DOCTOR6_DESCRIPTION')}
          />
        </Row> */}
      </Row>
    </Row>
  )
}

export const RenderDoctor = ({ DoctorImage, name, description }: any) => {
  return (
    <Col
      xs={18}
      sm={18}
      md={18}
      lg={18}
      xl={5}
      className={classes['doctor-container']}
    >
      <Row justify={'center'} style={{ position: 'relative' }}>
        <img src={DoctorImage} alt="" className={classes['doctor']} />
        <img
          src={AboutDoctorBackground}
          alt=""
          className={classes['doctor-behind']}
        />
        <img src={AboutDoctorMask} alt="" className={classes['mask']} />
      </Row>
      <Row justify={'center'}>
        <Typography.Text className={classes['name']}>{name}</Typography.Text>
      </Row>
      <Row justify={'center'}>
        <Typography.Text className={classes['description']}>
          {description}
        </Typography.Text>
      </Row>
    </Col>
  )
}

export default AboutSophie
