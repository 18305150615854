import { InstanceAxios } from '../config/axios/user'

export const getOrderHistoryPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/OrderPatient/OrderHistory'
  return InstanceAxios.post(path, payload, {
    params: { skip: payload?.skip, limit: payload?.limit },
  })
}

export const getOrderHistoryDetailPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/OrderPatient/OrderHistoryDetail'
  return InstanceAxios.post(path, payload)
}

export const cancelOrderPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/OrderPatient/CancelOrder'
  return InstanceAxios.post(path, payload)
}

export const getOrderByTransactionIdPatient = (
  transactionId?: string
): Promise<any> => {
  const path = '/api/patient/OrderPatient/getByTransactionId'
  return InstanceAxios.get(path, {
    params: { transactionId },
  })
}

export const getOrderByAccountIdPatient = (
  accountId?: string
): Promise<any> => {
  const path = '/api/patient/OrderPatient/getByAccountId'
  return InstanceAxios.get(path, {
    params: { accountId },
  })
}

export const createOrUpdateOrderPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/OrderPatient/createOrUpdateOrder'
  return InstanceAxios.post(path, payload)
}

export const saveOrderPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/OrderPatient/saveOrder'
  return InstanceAxios.post(path, payload)
}

export const checkPromotionByCodePatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/OrderPatient/checkByCode'
  return InstanceAxios.post(path, payload, {
    params: { typePay: payload?.typePay },
  })
}

export const validatePromotionPatient = (payload?: any): Promise<any> => {
  const path = '/api/patient/OrderPatient/validatePromotion'
  return InstanceAxios.post(path, payload, {
    params: { typePay: payload?.typePay },
  })
}

export const getListTransportPromotionPatient = (
  payload?: any
): Promise<any> => {
  const path = '/api/patient/OrderPatient/listTransportPromotion'
  return InstanceAxios.post(path, payload, {
    params: {
      skip: payload?.skip,
      limit: payload?.limit,
      typePay: payload?.typePay,
    },
  })
}
