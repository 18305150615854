import { Close, Search } from '@mui/icons-material'
import { InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { LogoSophie } from 'src/assets'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import { IconButtonAnimate } from 'src/components/animate'
import { APP_URL } from 'src/config'
import classes from './Header.module.less'
import { HeaderRight } from './HeaderRight'

export const Header: React.FC = () => {
  const { profile, isAuthenticated } = useAuthUserContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')

  const handleSearch = () => {
    let url = APP_URL?.SHOP_ONLINE_SEARCH
    if (search) {
      url += '?search=' + search
    }
    navigate(url)
  }

  const RenderSearch = () => {
    return (
      <TextField
        size="small"
        fullWidth
        placeholder={t('pharmacyOnline.headerSearchPlaceholder')}
        value={search}
        onChange={e => setSearch(e.target.value)}
        onKeyUp={e => e.key === 'Enter' && handleSearch()}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButtonAnimate size="small">
                <Search />
              </IconButtonAnimate>
            </InputAdornment>
          ),
          endAdornment:
            search?.length > 0 ? (
              <InputAdornment position="end">
                <IconButtonAnimate size="small" onClick={() => setSearch('')}>
                  <Close />
                </IconButtonAnimate>
              </InputAdornment>
            ) : null,
        }}
      />
    )
  }

  return (
    <div className={classes['header-container']}>
      <div className={classes['header']}>
        <div className={classes['header__logo']}>
          <Link to="/">
            <img src={LogoSophie} alt="" />
          </Link>
        </div>
        <div className={classes['header__search']}>{RenderSearch()}</div>
        <HeaderRight />
      </div>
      <div className={classes['header__search--mobile']}>{RenderSearch()}</div>
    </div>
  )
}
