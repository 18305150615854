export const sortOptions = [
  {
    value: 'Created',
    translateEnum: 'orderSection.sortByCreated',
  },
  {
    value: 'PurchasedNumber',
    translateEnum: 'orderSection.sortByPurchasedNumber',
  },
  {
    value: 'ProductPrice',
    translateEnum: 'orderSection.sortByProductPrice',
  },
]

export const SERVICE_AND_TRANSPORT = [
  { value: 'GDK', label: 'Đồng kiểm' },
  { value: 'HGC', label: 'Hàng giá trị cao' },
  { value: 'HLH', label: 'Hàng lạnh' },
  { value: 'HQK', label: 'Hàng quá khổ' },
  { value: 'HNK', label: 'Hàng nguyên khối' },
  { value: 'HCL', label: 'Chất lỏng' },
  { value: 'HPN', label: 'Hàng từ tính, pin' },
]

export const TYPE_TRANSPORT_STATUS = [
  // { value: 'Created', label: 'Đã tạo đơn hàng' },
  { value: 'Verified', label: 'Đã xác nhận đơn' },
  { value: 'WaitingForDelivery', label: 'Chờ lấy hàng' },
  // { value: 'Delivered', label: 'Đã giao cho đơn vị vận chuyển' },
  // { value: 'Transporting', label: 'Đang vận chuyển' },
  // { value: 'Delivering', label: 'Đang giao' },
  // { value: 'Success', label: 'Đã giao thành công' },
  // { value: 'Waiting', label: 'Chờ giao lại' },
  // { value: 'Refunding', label: 'Đang hoàn hàng' },
  // { value: 'Refunded', label: 'Đã hoàn hàng' },
  // { value: 'Cancel', label: 'Đã hủy đơn hàng' },
]

export const CREATED = 'Created'
export const VERIFIED = 'Verified'
export const WAITING_FOR_DELIVERY = 'WaitingForDelivery'
export const DELIVERED = 'Delivered'
export const TRANSPORTING = 'Transporting'
export const DELIVERING = 'Delivering'
export const SUCCESS = 'Success'
export const WAITING = 'Waiting'
export const REFUNDING = 'Refunding'
export const REFUNDED = 'Refunded'
export const CANCEL = 'Cancel'

export enum TransportStatus {
  Created = 'Created',
  Verified = 'Verified',
  WaitingForDelivery = 'WaitingForDelivery',
  Delivered = 'Delivered',
  Transporting = 'Transporting',
  Success = 'Success',
  Waiting = 'Waiting',
  Refunding = 'Refunding',
  Refunded = 'Refunded',
  Cancel = 'Cancel',
}
