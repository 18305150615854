import { Add, Remove } from '@mui/icons-material'
import { Button, Paper, Stack, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import { IconButtonAnimate } from 'src/components/animate'
import NumericFormatCustom from 'src/components/common/NumericFormatCustom'
import Image from 'src/components/image/Image'
import Label from 'src/components/label/Label'
import { APP_URL } from 'src/config'
import { useCartContext } from 'src/pages/shop-online/Cart/context'
import LoginUserDialog from 'src/sections/auth/login-user/LoginUserDialog'
import { fNumber } from 'src/utils/formatNumber'

interface ProductSummaryProps {
  product: any
}

export const ProductSummary = ({ product }: ProductSummaryProps) => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuthUserContext()
  const { order, addToCart } = useCartContext()
  const [quantity, setQuantity] = useState<string | number>(1)
  const [isOpenLoginDialog, setOpenLoginDialog] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [isExpand, setExpand] = useState(false)

  const inStock = product?.productNumber || 0
  const content = product?.productInfo?.[0]?.content || ''
  const isShowReadMore = content?.length > 300

  const handleAddToCart = () => {
    const quantityInCart =
      order?.listProduct?.find(item => item?.productId === product?.productId)
        ?.quantity || 0
    if (inStock < +quantity + +quantityInCart && !product?.sellOver) {
      return enqueueSnackbar(
        t('cart.productOutStock', { name: product.productName }),
        { variant: 'error' }
      )
    }
    if (isAuthenticated) {
      addToCart({ ...product, quantity: quantity })
    } else {
      setOpenLoginDialog(true)
    }
  }

  const handleBuyNow = () => {
    const quantityInCart =
      order?.listProduct?.find(item => item?.productId === product?.productId)
        ?.quantity || 0
    if (inStock < +quantity + +quantityInCart && !product?.sellOver) {
      return enqueueSnackbar(
        t('cart.productOutStock', { name: product.productName }),
        { variant: 'error' }
      )
    }
    if (isAuthenticated) {
      addToCart({ ...product, quantity: quantity })
      navigate(APP_URL.SHOP_ONLINE_CART)
    } else {
      setOpenLoginDialog(true)
    }
  }

  const adjustInputWidthByValue = (value: number) => {
    if (+value > 9999) {
      return '80px'
    }
    if (+value > 999) {
      return '70px'
    }
    if (+value > 99) {
      return '60px'
    }
    return '50px'
  }

  return (
    <div>
      <Typography variant="h4" color="#1A9CC6">
        {product?.productName}
      </Typography>
      {product?.purchasedNumber ? (
        <Stack direction="row" spacing={1} mt={2}>
          <Image src="/assets/shop-online/ic-shopping-cart.png" />
          <Typography variant="subtitle1" color="#00A57B">
            Đã bán {product?.purchasedNumber} sản phẩm
          </Typography>
        </Stack>
      ) : null}
      <Typography variant="body1" color="#798395" mt={2}>
        {!isShowReadMore || isExpand ? content : content?.slice(0, 300) + '...'}
      </Typography>
      {isShowReadMore && (
        <Button onClick={() => setExpand(!isExpand)}>
          {isExpand
            ? t('pharmacyOnline.product.collapse')
            : t('pharmacyOnline.product.readMore')}
        </Button>
      )}
      <Paper sx={{ p: 3, mt: 4 }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1}
          justifyContent="space-between"
        >
          <Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography component="span" variant="h4" color="#F0224F">
                {fNumber(product?.productPrice || 0)} ₫
              </Typography>
              {product?.productPrice !== product?.productRealPrice && (
                <Label
                  color="error"
                  sx={{
                    color: '#F0224F',
                    border: '1px solid #F0224F',
                    fontSize: '14px',
                  }}
                >
                  -{product?.productDiscounts}%
                </Label>
              )}
            </Stack>
            {product?.productPrice !== product?.productRealPrice ? (
              <Typography
                component="span"
                variant="body1"
                color="#798395"
                sx={{ textDecoration: 'line-through' }}
              >
                {fNumber(product?.productRealPrice || 0)} ₫
              </Typography>
            ) : null}
          </Stack>
          <Stack alignItems="flex-start">
            <Typography component="span" variant="body1" color="#798395">
              {t('pharmacyOnline.product.amount')}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButtonAnimate
                size="small"
                onClick={() =>
                  setQuantity(quantity => Math.max(1, +quantity - 1))
                }
                sx={{
                  borderRadius: '4px',
                  bgcolor: '#F4F6F8',
                }}
              >
                <Remove />
              </IconButtonAnimate>
              <TextField
                size="small"
                value={quantity}
                onChange={e => setQuantity(+e.target.value || '')}
                onBlur={e => setQuantity(+e.target.value || 1)}
                InputProps={{
                  components: NumericFormatCustom as any,
                }}
                sx={{
                  width: adjustInputWidthByValue(+quantity),
                  '.MuiInputBase-root': { height: '34px', borderRadius: '4px' },
                  input: {
                    textAlign: 'center',
                    color: 'primary.main',
                    fontWeight: 600,
                  },
                }}
              />
              <IconButtonAnimate
                size="small"
                onClick={() => setQuantity(quantity => +quantity + 1)}
                sx={{ borderRadius: '4px', bgcolor: '#F4F6F8' }}
              >
                <Add />
              </IconButtonAnimate>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction={{ xs: 'column-reverse', md: 'row' }}
          justifyContent="space-between"
          spacing={1}
          mt={2}
        >
          <Button
            size="large"
            variant="contained"
            onClick={handleBuyNow}
            sx={{
              backgroundColor: 'primary.lighter',
              color: 'primary.main',
              textTransform: 'none',
              ':hover': {
                backgroundColor: 'primary.lighter',
              },
            }}
            fullWidth
          >
            {t('pharmacyOnline.product.buyNow')}
          </Button>
          <Button
            size="large"
            variant="contained"
            fullWidth
            onClick={handleAddToCart}
            sx={{
              textTransform: 'none',
            }}
          >
            {t('pharmacyOnline.product.addToCart')}
          </Button>
        </Stack>
      </Paper>
      <LoginUserDialog
        open={isOpenLoginDialog}
        onClose={() => setOpenLoginDialog(false)}
      />
    </div>
  )
}
