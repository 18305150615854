import { createContext, useCallback, useState } from 'react'
import { ForgotPasswordStep } from '../types'

interface IForgotPasswordData {
  codeOtp?: string
  phoneNumber?: string
  accountType?: 'partner' | 'user'
}

interface IForgotPasswordContext {
  step: ForgotPasswordStep
  nextStep: VoidFunction
  backStep: VoidFunction
  goToStep: (step: ForgotPasswordStep) => void
  forgotPasswordData: IForgotPasswordData
  updateForgotPasswordData: (data: IForgotPasswordData) => void
  resetForgotPasswordData: (data?: IForgotPasswordData) => void
}

const initialState: any = {}

const ForgotPasswordContext =
  createContext<IForgotPasswordContext>(initialState)

function ForgotPasswordContextProvider({
  children,
}: {
  children: JSX.Element
}) {
  const [data, setData] = useState<IForgotPasswordData>({})
  const [step, setStep] = useState(ForgotPasswordStep.InputPhoneNumber)

  const handleNextStep = useCallback(() => {
    setStep(step => step + 1)
  }, [])

  const handleBackStep = useCallback(() => {
    setStep(step => step - 1)
  }, [])

  const handleGoToStep = useCallback((step: ForgotPasswordStep) => {
    setStep(step)
  }, [])

  const handleUpdateForgotPasswordData = useCallback(
    (newData: IForgotPasswordData) => {
      setData(state => ({ ...state, ...newData }))
    },
    []
  )

  const handleResetForgotPasswordData = useCallback(
    (data: IForgotPasswordData = {}) => {
      setData(data)
    },
    []
  )

  const value: IForgotPasswordContext = {
    step,
    nextStep: handleNextStep,
    backStep: handleBackStep,
    goToStep: handleGoToStep,
    forgotPasswordData: data,
    updateForgotPasswordData: handleUpdateForgotPasswordData,
    resetForgotPasswordData: handleResetForgotPasswordData,
  }

  return (
    <ForgotPasswordContext.Provider value={value}>
      {children}
    </ForgotPasswordContext.Provider>
  )
}

export { ForgotPasswordContextProvider, ForgotPasswordContext }
