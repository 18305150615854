import { Box } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export type TShopDescriptionProps = {
  shop: any
}

export const ShopDescription: FC<TShopDescriptionProps> = ({
  shop,
}: TShopDescriptionProps) => {
  const { t } = useTranslation()

  return (
    <Box p={2} bgcolor="white" borderRadius="0 0 8px 8px" color="#798395">
      <div dangerouslySetInnerHTML={{ __html: shop?.description || '' }}></div>
    </Box>
  )
}

export default ShopDescription
