import axios, { AxiosInstance } from 'axios'
import { appConfig } from '../../../config'
import { HttpRestService } from '../../index'

export const InstanceAxiosDefault: AxiosInstance = axios.create({
  baseURL: appConfig.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

InstanceAxiosDefault.interceptors.response.use(res => res?.data)

export const HttpServiceDefault = new HttpRestService(InstanceAxiosDefault)
