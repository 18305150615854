import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { borderRadius } from '@mui/system'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumericFormatCustom from 'src/components/common/NumericFormatCustom'
import { CustomSelect } from 'src/components/custom-input'
import { getPriceOptions } from 'src/pages/shop-online/common/constants'
import classes from './CategoryFilter.module.less'

export type TCategoryFilterMobileProps = {
  open: boolean
  onClose: VoidFunction
  category: any
  filter: any
  handleUpdateFilter: (newFilter: any) => void
}

export const CategoryFilterMobile: React.FC<TCategoryFilterMobileProps> = ({
  open,
  onClose,
  category,
  filter,
  handleUpdateFilter,
}: TCategoryFilterMobileProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [localFilter, setLocalFilter] = useState({
    subCategoryId: filter?.subCategoryId,
    minPrice: filter?.minPrice,
    maxPrice: filter?.maxPrice,
    priceSelected: '',
  })

  const handleUpdateLocalFilter = (newFilter: Record<string, any>) => {
    setLocalFilter((state: any) => ({ ...state, ...newFilter }))
  }

  const subCategories = category?.listSubCategory || []

  const priceOptions = getPriceOptions(t)

  const handleReset = () => {
    onClose()
    handleUpdateFilter({
      subCategoryId: '',
      minPrice: '',
      maxPrice: '',
      priceSelected: '',
    })
  }

  const handleApplyPrice = () => {
    const { minPrice, maxPrice } = localFilter
    if (minPrice?.length > 0 && maxPrice?.length > 0 && +minPrice > +maxPrice) {
      return enqueueSnackbar(t('pharmacyOnline.minPriceMustLessThanMaxPrice'), {
        variant: 'error',
      })
    }
    const priceSelected = priceOptions.find(
      item => item?.label === localFilter?.priceSelected
    )

    if (priceSelected) {
      handleUpdateFilter({
        subCategoryId: localFilter?.subCategoryId,
        minPrice: priceSelected?.minPrice,
        maxPrice: priceSelected?.maxPrice,
      })
    } else {
      handleUpdateFilter({
        subCategoryId: localFilter?.subCategoryId,
        minPrice: localFilter?.minPrice,
        maxPrice: localFilter?.maxPrice,
      })
    }
    onClose()
  }

  const handleSyncFilter = () => {
    setLocalFilter({
      ...localFilter,
      subCategoryId: filter?.subCategoryId,
      // minPrice: filter?.minPrice,
      // maxPrice: filter?.maxPrice,
      // priceSelected: localFilter?.priceSelected,
    })
  }

  useEffect(() => {
    if (open) {
      handleSyncFilter()
    }
  }, [filter, open])

  const minPriceValue = localFilter?.minPrice?.length
    ? +localFilter?.minPrice
    : ''
  const maxPriceValue = localFilter?.maxPrice?.length
    ? +localFilter?.maxPrice
    : ''

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      sx={{
        '>.MuiPaper-root': {
          p: 2,
          maxHeight: '95vh',
          borderRadius: '16px 16px 0 0',
        },
      }}
    >
      <Typography variant="subtitle1">
        {t('pharmacyOnline.category')} {category?.categoryName}
      </Typography>
      <CustomSelect
        size="small"
        select
        fullWidth
        value={localFilter?.subCategoryId || 'All'}
        onChange={e => {
          const value = e.target.value === 'All' ? '' : e.target.value
          handleUpdateLocalFilter({ subCategoryId: value })
        }}
        sx={{ mt: 1 }}
      >
        <MenuItem value="All">Tất cả</MenuItem>
        {subCategories.map((item: any, i: number) => (
          <MenuItem value={item?.subCategoryId} key={i}>
            {item?.subCategoryName}
          </MenuItem>
        ))}
      </CustomSelect>
      <Typography variant="subtitle1" mt={2}>
        {t('pharmacyOnline.price')}
      </Typography>
      <Box>
        <Stack spacing={1} mt={1}>
          {priceOptions?.map(({ label, ...item }, i) => (
            <div>
              <div
                className={`${classes['filter-price-chip']} ${
                  localFilter?.priceSelected === label ? classes['active'] : ''
                }`}
                key={i}
                onClick={() => {
                  if (label === localFilter?.priceSelected) {
                    handleUpdateLocalFilter({ priceSelected: '' })
                  } else {
                    handleUpdateLocalFilter({
                      priceSelected: label,
                      minPrice: '',
                      maxPrice: '',
                    })
                  }
                }}
              >
                {label}
              </div>
            </div>
          ))}
        </Stack>
        <Typography variant="caption" color="#576071" mt={2}>
          {t('pharmacyOnline.chooseRangePrice')}
        </Typography>

        <Stack direction="row" spacing={1}>
          <TextField
            size="small"
            placeholder="0₫"
            value={minPriceValue}
            onChange={e =>
              handleUpdateLocalFilter({
                minPrice: e.target.value,
                priceSelected: '',
              })
            }
            fullWidth
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
          />
          <TextField
            size="small"
            placeholder="0₫"
            value={maxPriceValue}
            onChange={e =>
              handleUpdateLocalFilter({
                maxPrice: e.target.value,
                priceSelected: '',
              })
            }
            fullWidth
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
          />
        </Stack>
      </Box>
      <Stack direction="row" spacing={2} mt={2}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleReset}
          sx={{
            backgroundColor: '#E0F3F7',
            color: 'primary.main',
            ':hover': {
              backgroundColor: '#E0F3F7',
            },
          }}
        >
          {t('pharmacyOnline.reset')}
        </Button>
        <Button variant="contained" fullWidth onClick={handleApplyPrice}>
          {t('pharmacyOnline.apply')}
        </Button>
      </Stack>
    </Drawer>
  )
}

export default CategoryFilterMobile
