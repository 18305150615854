import { logJson } from "src/utils"
import { StorageService } from "../storage"

// === Logout remove all sesstion
export const removeData = () => {
  StorageService.destroyData()
}

export const removeAll = () => {
  StorageService.destroyAll()
}

// === Token Auth User

export const getAuthTokenUser = () : string => {
  try {
    if (StorageService.has('AUTH_TOKEN_USER')) {
      return StorageService.get('AUTH_TOKEN_USER')
    }
    return ''
  } catch (e) {
    return ''
  }
}

export const setAuthTokenUser = (token: string) => {
  StorageService.set('AUTH_TOKEN_USER', token)
}

export const removeAuthTokenUser = () => {
  if (StorageService.has('AUTH_TOKEN_USER')) {
    StorageService.remove('AUTH_TOKEN_USER')
  }
}

// === Token Auth Partner

export const getAuthTokenPartner = () : string => {
  try {
    if (StorageService.has('AUTH_TOKEN_PARTNER')) {
      return StorageService.get('AUTH_TOKEN_PARTNER')
    }
    return ''
  } catch (e) {
    return ''
  }
}

export const setAuthTokenPartner = (token: string) => {
  StorageService.set('AUTH_TOKEN_PARTNER', token)
}

export const removeAuthTokenPartner = () => {
  if (StorageService.has('AUTH_TOKEN_PARTNER')) {
    StorageService.remove('AUTH_TOKEN_PARTNER')
  }
}

// === RefreshToken User
export const getRefreshTokenUser = () => {
  if (StorageService.has('REFRESH_TOKEN_USER')) {
    return StorageService.get('REFRESH_TOKEN_USER')
  }
  return undefined
}
export const saveRefreshTokenUser = (refreshToken: string) => {
  StorageService.set('REFRESH_TOKEN_USER', refreshToken)
}
export const removeRefreshTokenUser = () => {
  if (StorageService.has('REFRESH_TOKEN_USER')) {
    StorageService.remove('REFRESH_TOKEN_USER')
  }
}

// === RefreshToken Partner
export const getRefreshTokenPartner = () => {
  if (StorageService.has('REFRESH_TOKEN_PARTNER')) {
    return StorageService.get('REFRESH_TOKEN_PARTNER')
  }
  return undefined
}
export const saveRefreshTokenPartner = (refreshToken: string) => {
  StorageService.set('REFRESH_TOKEN_PARTNER', refreshToken)
}
export const removeRefreshTokenPartner = () => {
  if (StorageService.has('REFRESH_TOKEN_PARTNER')) {
    StorageService.remove('REFRESH_TOKEN_PARTNER')
  }
}

// ===  Token Handshake
export const getCodeHandshakeUser = () : string => {
  if (StorageService.has('TOKEN_HANDSHAKE_USER')) {
    return StorageService.get('TOKEN_HANDSHAKE_USER')
  }
  return ''
}
export const saveCodeHandshakeUser = (tokenHandshake: string) => {
  StorageService.set('TOKEN_HANDSHAKE_USER', tokenHandshake)
  var date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  StorageService.set('DATE_HANDSHAKE_LASTED_USER', date)
}
export const removeCodeHandshakeUser = () => {
  if (StorageService.has('TOKEN_HANDSHAKE_USER')) {
    StorageService.remove('TOKEN_HANDSHAKE_USER')
  }
  if (StorageService.has('DATE_HANDSHAKE_LASTED')) {
    StorageService.remove('DATE_HANDSHAKE_LASTED')
  }
}

// ===  Token Handshake Partner
export const getCodeHandshakePartner = () : string => {
  if (StorageService.has('TOKEN_HANDSHAKE_PARTNER')) {
    return StorageService.get('TOKEN_HANDSHAKE_PARTNER')
  }
  return ''
}
export const saveCodeHandshakePartner = (tokenHandshake: string) => {
  StorageService.set('TOKEN_HANDSHAKE_PARTNER', tokenHandshake)
  var date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  StorageService.set('DATE_HANDSHAKE_LASTED_PARTNER', date)
}
export const removeCodeHandshakePartner = () => {
  if (StorageService.has('TOKEN_HANDSHAKE_PARTNER')) {
    StorageService.remove('TOKEN_HANDSHAKE_PARTNER')
  }
  if (StorageService.has('DATE_HANDSHAKE_LASTED_PARTNER')) {
    StorageService.remove('DATE_HANDSHAKE_LASTED_PARTNER')
  }
}

// === TokenFCM
export const getTokenFCM = () => {
  if (StorageService.has('TOKEN_FCM')) {
    return StorageService.get('TOKEN_FCM')
  }
  return undefined
}
export const saveTokenFCM = (tokenFcm: string) => {
  StorageService.set('TOKEN_FCM', tokenFcm)
}
export const removeTokenFCM = () => {
  if (StorageService.has('TOKEN_FCM')) {
    StorageService.remove('TOKEN_FCM')
  }
}
