import { IForgotPasswordDialogProps } from '../ForgotPasswordDialog'
import { ForgotPasswordContextProvider } from './ForgotPasswordContext'

const withForgotPasswordContext = (Component: any) => {
  function NewComponent(props: IForgotPasswordDialogProps) {
    return (
      <ForgotPasswordContextProvider>
        <Component {...props} />
      </ForgotPasswordContextProvider>
    )
  }
  return NewComponent
}
export default withForgotPasswordContext
