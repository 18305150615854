import { Stack } from '@mui/material'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useGetListProductPatient } from 'src/api/hooks/patient.query'
import { LoadingCircle } from 'src/components/loading-circle/LoadingCircle'
import { ProductList } from 'src/sections/user/ProductList'
import { toSlug } from 'src/utils/string'
import classes from './ProductByCategory.module.less'

interface ProductByCategoryProps {
  categories: any[]
}

export const ProductByCategory = ({ categories }: ProductByCategoryProps) => {
  const listProductRequest = useGetListProductPatient()

  const [productByCategory, setProductByCategory] = useState<any[]>([])

  const fetchMoreData = async () => {
    const categoriesToGetProduct = categories?.filter(
      (item, i) =>
        i >= productByCategory?.length && i < productByCategory?.length + 3
    )
    if (!categoriesToGetProduct?.length) {
      return
    }
    const newProductByCategory = await Promise.all(
      categoriesToGetProduct?.map(async item => {
        const filter = {
          categoryId: item?.categoryId,
          minPrice: 0,
          maxPrice: 0,
          isSale: null,
          isRelated: true,
          sortName: 'Created',
          sort: 'desc',
          skip: 0,
          limit: 6,
        }
        const res = await listProductRequest.mutateAsync({ ...filter })
        return {
          title: item?.categoryName,
          url: `/shop-online/category/${item?.categoryId}-${toSlug(
            item?.categoryName
          )}`,
          products: res?.data || [],
        }
      })
    )
    setTimeout(() => {
      setProductByCategory(state => [...state, ...newProductByCategory])
    }, 300)
  }

  return (
    <>
      <InfiniteScroll
        dataLength={productByCategory?.length}
        next={fetchMoreData}
        hasMore={productByCategory?.length < categories?.length}
        loader={<LoadingCircle mt={2} />}
        className={classes['infinite-scroll-container']}
      >
        <Stack spacing={2}>
          {productByCategory
            ?.filter(item => item?.products?.length > 0)
            ?.map((item, i) => (
              <ProductList
                title={item?.title}
                products={item?.products}
                seeMoreUrl={item?.url}
                key={i}
              />
            ))}
        </Stack>
      </InfiniteScroll>
    </>
  )
}

export default ProductByCategory
