import { Popover } from '@mui/material'
import LoginPopoverContent from 'src/sections/auth/login-user/LoginUserContent'
import classes from './LoginUser.module.less'

interface LoginPopoverProps {
  anchorEl: any
  onClose: VoidFunction
  onSuccess?: VoidFunction
}

const LoginPopover = ({ anchorEl, onClose, onSuccess }: LoginPopoverProps) => {
  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '.MuiPaper-root': {
          mt: 2,
        },
      }}
    >
      <div className={classes['login-dialog']}>
        <LoginPopoverContent onClose={onClose} onSuccess={onSuccess} />
      </div>
    </Popover>
  )
}

export default LoginPopover
