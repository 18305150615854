import { yupResolver } from '@hookform/resolvers/yup'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { format, isValid } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useUpdateProfilePatient } from 'src/api/hooks/patient.query'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import FormProvider from 'src/components/hook-form'
import { useLocales } from 'src/locales'
import * as Yup from 'yup'

export interface IPUpdateBirthdayDialogProps {
  open: boolean
  onClose: VoidFunction
}

export const UpdateBirthdayDialog = ({
  open,
  onClose,
}: IPUpdateBirthdayDialogProps) => {
  const updateProfileRequest = useUpdateProfilePatient()
  const { profile, updateProfile } = useAuthUserContext()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const formattedBirthDay = format(
    profile?.birthdate ? new Date(profile?.birthdate) : new Date(),
    'dd/MMM/yyyy'
  )

  const fieldRequiredMessage = t('alertSection.fieldIsRequired') || ''
  const fieldIsDate = t('alertSection.fieldIsDate') || ''
  const LoginSchema = Yup.object().shape({
    birthdate: Yup.date()
      .required(fieldRequiredMessage)
      .typeError(fieldIsDate)
      .test('check-valid-date', fieldIsDate, value => {
        if (value && value.getTime() > Date.now()) {
          return false
        }
        if (value && value.getFullYear() < 1000) {
          return false
        }
        return true
      }),
  })

  const defaultValues = {
    birthdate: '' as any,
  }
  const methods = useForm<typeof defaultValues>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, errors },
  } = methods

  const values = watch()

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      data.birthdate = format(new Date(data.birthdate), "yyyy-MM-dd'T'hh:mm:ss")
      await updateProfileRequest.mutateAsync({ ...profile, ...data })
      await updateProfile()
      enqueueSnackbar(t('userProfile.personalInformation.updateSuccess'))
      onClose()
    } catch (error) {}
  }

  useEffect(() => {
    if (open) {
      reset({ birthdate: formattedBirthDay })
    }
  }, [formattedBirthDay, open])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          component="div"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h5" color="primary.main">
            {t('userProfile.personalInformation.updateInformation')}
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <Typography variant="subtitle2" color="#798395">
              {t('userProfile.personalInformation.birthday')}
            </Typography>
            <DatePicker
              value={values.birthdate || null}
              inputFormat="dd/MM/yyyy"
              maxDate={Date.now()}
              onChange={value =>
                setValue('birthdate', value, { shouldValidate: true })
              }
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{ mt: 1 }}
                  placeholder={t('registerPage.birthdayPlaceholder') || ''}
                  error={!!errors?.birthdate?.message}
                  helperText={errors?.birthdate?.message as string}
                  onBlur={() => {
                    const date = values?.birthdate
                    if (isValid(date) && date.getFullYear() < 1000) {
                      date.setFullYear(1000)
                      setValue('birthdate', date, {
                        shouldValidate: true,
                      })
                    }
                  }}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('userProfile.saveChange')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
