import React from 'react'
import classes from './Policy.module.less'
import { WrapPolicy } from './Components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

export const PolicySecurity = () => {
  const { t } = useTranslation()
  return (
    <WrapPolicy>
      <Helmet>
        <title>Policy | Sophie</title>
      </Helmet>
      <div className={classes['root']}>
        <div className={classes['terms-text']}>
          <h1 className={classes['title-policy']}>
            {t('POLICY.SECURITY_PAGE.TITLE')}
          </h1>
          <div className="mt-3">
            <div className="terms-text">
              <p>{t('POLICY.SECURITY_PAGE.NOTICE')}</p>
            </div>
            <div className="terms-text">
              <h3>{t('POLICY.SECURITY_PAGE.INFO')}</h3>
              <p>{t('POLICY.SECURITY_PAGE.WEB')}</p>
              <ul className={classes['list-security']}>
                <li>{t('POLICY.SECURITY_PAGE.SUPPORT')}</li>
                <li>{t('POLICY.SECURITY_PAGE.CUS')}</li>
                <li>{t('POLICY.SECURITY_PAGE.PROVIDER')}</li>
                <li>{t('POLICY.SECURITY_PAGE.JUDGE')}</li>
                <li>{t('POLICY.SECURITY_PAGE.MAKETING')}</li>
              </ul>
              <p>{t('POLICY.SECURITY_PAGE.SERVICE')}</p>
              <p>{t('POLICY.SECURITY_PAGE.CONNECT')}</p>
            </div>

            <div className="terms-text">
              <h3>{t('POLICY.SECURITY_PAGE.MAP')}</h3>
              <p>{t('POLICY.SECURITY_PAGE.ACCEPT2')}</p>
              <p>{t('POLICY.SECURITY_PAGE.NEED')}</p>
              <p>{t('POLICY.SECURITY_PAGE.EXCEPT')}</p>
              <p>{t('POLICY.SECURITY_PAGE.CASE')}</p>
              <p>{t('POLICY.SECURITY_PAGE.LAW')}</p>
            </div>

            <div className="terms-text">
              <h3>{t('POLICY.SECURITY_PAGE.TIME')}</h3>
              <p>{t('POLICY.SECURITY_PAGE.SAVE')}</p>
            </div>
            <div className="terms-text">
              <h3>{t('POLICY.SECURITY_PAGE.ADDRESS')}</h3>
              <p>{t('POLICY.SECURITY_PAGE.NAME')} </p>

              <p> {t('POLICY.SECURITY_PAGE.PHONE')}</p>
              <p>{t('POLICY.SECURITY_PAGE.VN')}</p>
              <p>{t('POLICY.SECURITY_PAGE.WHENEVER')}</p>
            </div>
            <div className="terms-text">
              <h3>{t('POLICY.SECURITY_PAGE.COMMIT')}</h3>
              <p>{t('POLICY.SECURITY_PAGE.TOAST')}</p>
              <p>{t('POLICY.SECURITY_PAGE.BECAUSE')}</p>
            </div>
          </div>
        </div>
      </div>
    </WrapPolicy>
  )
}

export default PolicySecurity
