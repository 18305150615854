import { TRoute } from 'src/@types/common'
import { APP_URL } from 'src/config'
import HomePage from './HomePage.screen'

// const HomePage = Loadable(lazy(() => import('./HomePage.screen')))

export const HomePageRoutes: TRoute[] = [
  {
    path: APP_URL.HOME,
    element: <HomePage />,
  },
]
