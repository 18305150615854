import { TRoute } from 'src/@types/common'
import { APP_URL } from 'src/config'
import { PersonalInfo } from './PersonalInfo.screen'

export const PersonalInfoRoutes: TRoute[] = [
  {
    path: APP_URL.USER_PROFILE_PERSONAL_INFORMATION,
    element: <PersonalInfo />,
  },
]
