import React, { useState } from 'react'
import classes from './SearchBar.module.less'
import { Button, Row } from 'antd'
import { SearchIcon2 } from 'src/assets'
import { useNavigate } from 'react-router'
import { APP_URL } from 'src/config'

export type TSearchBarprop = {
  value?: string
  defaultValue?: string
  placeholder?: string
  onChange?: any
}

export const SearchBar: React.FC<TSearchBarprop> = ({
  value,
  defaultValue,
  onChange,
  placeholder,
}: TSearchBarprop) => {
  const [search, setSearch] = useState('')
  const navigate = useNavigate()

  const handleSearch = (e: any) => {
    e?.preventDefault()
    let url = APP_URL?.SHOP_ONLINE_SEARCH
    if (search) {
      url += '?search=' + search
    }
    navigate(url)
  }

  return (
    <form className={classes['searchbar']} onSubmit={handleSearch}>
      <Row className={classes['icon']}>
        <img src={SearchIcon2} alt="" />
        {/* <SearchIcon2 /> */}
      </Row>
      <input
        placeholder={placeholder}
        className={classes['input']}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      <Button htmlType="submit" type="primary" className={classes['button']}>
        Tìm kiếm
      </Button>
    </form>
  )
}
