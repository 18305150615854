import { Col, Menu, MenuProps, Row } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router'
import { IconChevronDown, ImageAppStore, ImageGooglePlay } from 'src/assets'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import {
  APP_URL,
  LINK_DOWNLOAD_APP_DOCTOR_PLAY,
  LINK_DOWNLOAD_DOCTOR_STORE,
} from 'src/config'
import './MainLayout.style.scss'
import classes from './NavMenu.module.less'

interface INavMenuProps {
  orientation?: 'horizontal' | 'vertical'
}

const NavMenu: FC<INavMenuProps> = ({
  orientation = 'horizontal',
}: INavMenuProps) => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuthUserContext()
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const [isOpenLoginDialog, setOpenLoginDialog] = useState<boolean>(false)

  const indentLabelMenuHorizontal = collapsed ? { margin: -20 } : {}

  const onChangeCollapsed = (boolean: boolean) => {
    if (boolean) {
      document.body.style.overflowY = 'scroll'
    } else {
      document.body.style.overflowY = 'hidden'
    }
    setCollapsed(boolean)
  }

  const RenderMenuLabel = (label: string, useDropdown?: boolean) => {
    return (
      <Row justify={'space-between'} className="header-menu-label" wrap={false}>
        <Col className="label">{label}</Col>
        {useDropdown && (
          <Col className={classes.icon}>
            <img src={IconChevronDown} alt="" />
          </Col>
        )}
      </Row>
    )
  }

  const childStyle = {
    borderRadius: '8px',
    margin: '8px 8px 0 8px',
  }

  const downloadStyle = {}

  const menuLists = (
    classItem: string,
    isHorizontal: boolean
  ): MenuProps['items'] => {
    const layoutDownload = (
      <div style={{ display: 'flex' }} className={classes['main-download']}>
        <div>
          <a
            className={classes['item-download']}
            target={'_blank'}
            href={LINK_DOWNLOAD_DOCTOR_STORE}
            rel="noreferrer"
          >
            <img src={ImageAppStore} alt="" className={classes['img-social']} />
          </a>
        </div>
        <div>
          <a
            className={classes['item-download']}
            target={'_blank'}
            href={LINK_DOWNLOAD_APP_DOCTOR_PLAY}
            rel="noreferrer"
          >
            <img
              src={ImageGooglePlay}
              alt=""
              className={classes['img-social']}
            />
          </a>
        </div>
      </div>
    )

    return [
      {
        key: Math.random(),
        label: RenderMenuLabel(t('MENU/ABOUTSOPHIE'), isHorizontal),
        children: [
          {
            label: t('MENU/ABOUTSOPHIE/INTRODUCE'),
            key: Math.random(),
            className: 'menu-item-custom',
            style: childStyle,
            onClick: () => {
              navigate(generatePath(APP_URL.ABOUT_SOPHIE))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/ABOUTSOPHIE/HR'),
            key: Math.random(),
            className: 'menu-item-custom',
            style: childStyle,
            onClick: () => {
              navigate(generatePath(APP_URL.SOPHIE_HIRING))
              onChangeCollapsed(true)
            },
          },
        ],
        className: classes[classItem],
        onClick: () => {},
        style: indentLabelMenuHorizontal,
      },
      {
        key: Math.random(),
        label: RenderMenuLabel(t('MENU/BOOKING_ONLINE'), isHorizontal),
        children: [
          {
            label: t('MENU/BOOKING_ONLINE/PHONE'),
            style: childStyle,
            key: Math.random(),
            className: 'menu-item-custom',
            onClick: () => {
              navigate(generatePath(APP_URL.ONLINE_MEDICAL_BOOKING_PHONE))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/BOOKING_ONLINE/HOME'),
            key: Math.random(),
            style: childStyle,
            className: 'menu-item-custom',
            onClick: () => {
              navigate(generatePath(APP_URL.ONLINE_MEDICAL_BOOKING_HOME))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/BOOKING_ONLINE/CLINIC'),
            key: Math.random(),
            style: childStyle,
            className: 'menu-item-custom',
            onClick: () => {
              navigate(generatePath(APP_URL.ONLINE_MEDICAL_BOOKING_CLINIC))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/BOOKING_ONLINE/FASTPHONE'),
            key: Math.random(),
            style: childStyle,
            className: 'menu-item-custom',
            onClick: () => {
              navigate(generatePath(APP_URL.ONLINE_MEDICAL_BOOKING_FAST_PHONE))
              onChangeCollapsed(true)
            },
          },
        ],
        className: classes[classItem],
        onClick: () => {},
        style: indentLabelMenuHorizontal,
      },
      {
        key: Math.random(),
        label: RenderMenuLabel(t('MENU/SERVICE_HEALTH_AT_HOME'), isHorizontal),
        children: [
          {
            label: t('MENU/SERVICE_HEALTH_AT_HOME/BABY'),
            key: Math.random(),
            className: 'menu-item-custom',
            style: childStyle,
            onClick: () => {
              navigate(generatePath(APP_URL.HEALTH_SERVICE_BABY_BATH))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/SERVICE_HEALTH_AT_HOME/SICK'),
            key: Math.random(),
            className: 'menu-item-custom',
            style: childStyle,
            onClick: () => {
              navigate(generatePath(APP_URL.HEALTH_SERVICE_HUMAN_SICK))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/SERVICE_HEALTH_AT_HOME/BLOOD_AT_HOME'),
            key: Math.random(),
            className: 'menu-item-custom',
            style: childStyle,
            onClick: () => {
              navigate(generatePath(APP_URL.HEALTH_SERVICE_GET_BLOOD))
              onChangeCollapsed(true)
            },
          },
          {
            label: t('MENU/SERVICE_HEALTH_AT_HOME/THERAPY'),
            key: Math.random(),
            className: 'menu-item-custom',
            style: childStyle,
            onClick: () => {
              navigate(generatePath(APP_URL.HEALTH_SERVICE_PHYSICAL_THERAPY))
              onChangeCollapsed(true)
            },
          },
        ],
        className: classes[classItem],
        onClick: () => {},
        style: indentLabelMenuHorizontal,
      },
      {
        key: Math.random(),
        label: RenderMenuLabel(t('MENU/PHARMARCY_ONLINE'), false),
        className: `${classes[classItem]} have-line`,
        onClick: () => {
          navigate(generatePath(APP_URL.SHOP_ONLINE))
          onChangeCollapsed(true)
        },
        style: indentLabelMenuHorizontal,
      },
      {
        key: Math.random(),

        label: RenderMenuLabel(t('MENU/BLOG_HEALTH'), false),
        className: `${classes[classItem]} have-line`,
        onClick: () => {
          onChangeCollapsed(true)
          navigate(generatePath(APP_URL.BLOG_HEAL_HOME))
        },
        style: indentLabelMenuHorizontal,
      },
      {
        className: `${classes[classItem]} not-background hide-at-desktop`,
        key: Math.random(),
        label: RenderMenuLabel(t('HOMEPAGE.SERVICE.DOWNLOAD'), isHorizontal),
        children: [
          {
            label: layoutDownload,
            key: Math.random(),
            style: downloadStyle,
            className: 'menu-item-custom',
          },
        ],
        style: indentLabelMenuHorizontal,
      },
    ]
  }

  return (
    <Menu
      mode={orientation === 'horizontal' ? 'horizontal' : 'inline'}
      theme="light"
      items={[
        ...(menuLists(
          orientation === 'horizontal'
            ? 'header-menu-item-horizontal'
            : 'header-menu-item-vertical',
          orientation === 'horizontal'
        ) || []),
      ]}
      className={
        orientation === 'horizontal'
          ? classes['header-menu-horizontal']
          : classes['header-menu-vertical']
      }
    />
  )
}

export default NavMenu
