import { ErrorCode, FileError, FileRejection } from 'react-dropzone'
// @mui
import { alpha } from '@mui/material/styles'
import { Box, Paper, Typography } from '@mui/material'
// utils
import { fData } from '../../../utils/formatNumber'
//
import { fileData } from '../../file-thumbnail'
import { useTranslation } from 'react-i18next'
import { toFileSizeReadable } from 'src/utils/number.helper'

// ----------------------------------------------------------------------

type Props = {
  fileRejections: FileRejection[]
}

export default function RejectionFiles({ fileRejections }: Props) {
  const { t } = useTranslation()
  if (!fileRejections.length) {
    return null
  }

  const renderErrorMessage = (error: FileError) => {
    switch (error.code) {
      case ErrorCode.FileTooLarge:
        return t('alertSection.fileSizeIsMax', {
          size: toFileSizeReadable(
            +(error.message?.match(/\d+/) as any)?.[0] || 0
          ),
        })

      case ErrorCode.FileInvalidType:
        return t('alertSection.fileMimetypeIsOnlyAllowed', {
          mimeTypes: error.message?.replace('File type must be ', ''),
        })

      default:
        return error.message
    }
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        bgcolor: theme => alpha(theme.palette.error.main, 0.08),
        borderColor: theme => alpha(theme.palette.error.main, 0.24),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = fileData(file)

        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {size ? fData(size) : ''}
            </Typography>

            {errors.map(error => (
              <Box
                key={error.code}
                component="span"
                sx={{ typography: 'caption' }}
              >
                - {renderErrorMessage(error)}
              </Box>
            ))}
          </Box>
        )
      })}
    </Paper>
  )
}
