import { useMutation } from 'react-query'
import {
  addAddressPatient,
  deleteAddressPatient,
  findAddressPatient,
  getListAddressPatient,
  getTransportListServices,
  updateAddressPatient,
} from '../api_functions/patientAddress'
import {
  checkPhoneNumberPatient,
  loginPatient,
  logoutPatient,
  onFacebookLogin,
  onGoogleLogin,
  registerPatient,
  sendOtpPatient,
  verifyOtpPatient,
} from '../api_functions/patientAuth'
import {
  cancelOrderPatient,
  checkPromotionByCodePatient,
  createOrUpdateOrderPatient,
  getListTransportPromotionPatient,
  getOrderByAccountIdPatient,
  getOrderByTransactionIdPatient,
  getOrderHistoryDetailPatient,
  getOrderHistoryPatient,
  saveOrderPatient,
  validatePromotionPatient,
} from '../api_functions/patientOrder'
import {
  checkMomoPayShopPatient,
  checkZaloPayShopPatient,
  createCodPayShopPatient,
  createMomoPayShopPatient,
  createZaloPayShopPatient,
} from '../api_functions/patientPay'
import {
  changePasswordPatient,
  getProfilePatient,
  resetPasswordPatient,
  updateProfilePatient,
} from '../api_functions/patientProfile'
import {
  getDetailProductPatient,
  getListProductPatient,
  getListPromotionPatient,
  getPromotionDetailPatient,
  getShopDetailByProductPatient,
  getSimilarProductPatient,
  getListSpecialProduct,
  getListCategoryPatient,
  getListBannerPatient,
} from '../api_functions/patientShop'
import { updateImageProfile } from '../api_functions/profile'

/*=================================== AUTH ================================ */

export function useRegisterPatient() {
  return useMutation(registerPatient)
}

export function useLoginPatient() {
  return useMutation(loginPatient)
}

export function useFacebookLogin() {
  return useMutation(onFacebookLogin)
}

export function useGoogleLogin() {
  return useMutation(onGoogleLogin)
}

export function useLogoutPatient() {
  return useMutation(logoutPatient)
}

export function useSendOtpPatient() {
  return useMutation(sendOtpPatient)
}

export function useVerifyOtpPatient() {
  return useMutation(verifyOtpPatient)
}

export function useCheckPhoneNumberPatient() {
  return useMutation(checkPhoneNumberPatient)
}

/*=================================== Profile ================================ */

export function useGetProfilePatient() {
  return useMutation(getProfilePatient)
}

export function useUpdateProfilePatient() {
  return useMutation(updateProfilePatient)
}

export function useResetPasswordPatient() {
  return useMutation(resetPasswordPatient)
}

export function useChangePasswordPatient() {
  return useMutation(changePasswordPatient)
}

/*=================================== SHOP ================================ */

export function useGetListCategoryPatient() {
  return useMutation<any, unknown, any>(getListCategoryPatient)
}

export function useGetListProductPatient() {
  return useMutation<any, unknown, any>(getListProductPatient)
}

export function useGetSimilarProductPatient() {
  return useMutation(getSimilarProductPatient)
}

export function useGetSListSpecialProductPatient() {
  return useMutation(getListSpecialProduct)
}

export function useGetDetailProductPatient() {
  return useMutation(getDetailProductPatient)
}

export function useGetShopDetailByProductPatient() {
  return useMutation(getShopDetailByProductPatient)
}

export function useGetListPromotionPatient() {
  return useMutation<any, unknown, any>(getListPromotionPatient)
}

export function useGetPromitionDetailPatient() {
  return useMutation(getPromotionDetailPatient)
}

export function useGetListBannerPatient() {
  return useMutation(getListBannerPatient)
}

/*=================================== ORDER ================================ */

export function useGetOrderHistoryPatient() {
  return useMutation(getOrderHistoryPatient)
}

export function useGetOrderHistoryDetailPatient() {
  return useMutation(getOrderHistoryDetailPatient)
}

export function useCancelOrderPatient() {
  return useMutation(cancelOrderPatient)
}

export function useGetOrderByTransactionIdPatient() {
  return useMutation(getOrderByTransactionIdPatient)
}

export function useGetOrderByAccountIdPatient() {
  return useMutation(getOrderByAccountIdPatient)
}

export function useCreateOrUpdateOrderPatient() {
  return useMutation(createOrUpdateOrderPatient)
}

export function useSaveOrderPatient() {
  return useMutation(saveOrderPatient)
}

export function useCheckPromotionByCodePatient() {
  return useMutation(checkPromotionByCodePatient)
}

export function useValidatePromotionPatient() {
  return useMutation(validatePromotionPatient)
}

export function useGetListTransportPromotionPatient() {
  return useMutation(getListTransportPromotionPatient)
}

/*=================================== PAY ================================ */

export function useCreateMomoPayShopPatient() {
  return useMutation(createMomoPayShopPatient)
}

export function useCheckMomoPayShopPatient() {
  return useMutation(checkMomoPayShopPatient)
}

export function useCreateZaloPayShopPatient() {
  return useMutation(createZaloPayShopPatient)
}

export function useCheckZaloPayShopPatient() {
  return useMutation(checkZaloPayShopPatient)
}

export function useCreateCodPayShopPatient() {
  return useMutation(createCodPayShopPatient)
}

/*=================================== Address ================================ */

export function useGetListAddressPatient() {
  return useMutation(getListAddressPatient)
}

export function useFindAddressPatient() {
  return useMutation(findAddressPatient)
}

export function useAddAddressPatient() {
  return useMutation(addAddressPatient)
}

export function useUpdateAddressPatient() {
  return useMutation(updateAddressPatient)
}

export function useDeleteAddressPatient() {
  return useMutation(deleteAddressPatient)
}

export function useGetTransportListServices() {
  return useMutation(getTransportListServices)
}
