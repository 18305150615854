import { Button, Divider, Grid, Paper, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import { ProfileUserThemeProvider } from '../theme'
import { UpdateBirthdayDialog } from './components/UpdateContact/UpdateBirthdayDialog'
import { UpdateEmailDialog } from './components/UpdateContact/UpdateEmailDialog'
import { UpdateFirstNameDialog } from './components/UpdateContact/UpdateFirstNameDialog'
import { UpdateGenderDialog } from './components/UpdateContact/UpdateGenderDialog'
import { UpdateLastNameDialog } from './components/UpdateContact/UpdateLastNameDialog'
import { UpdatePassWorDialog } from './components/UpdatePassWord'
import './PersonalInfo.module.less'
import classes from './PersonalInfo.module.less'

export const PersonalInfo = () => {
  const { profile, updateProfile } = useAuthUserContext()

  const { t } = useTranslation()
  const [isUpdatePassword, setUpdatePassword] = useState(false)
  const [isUpdateFirstName, setUpdateFirstName] = useState(false)
  const [isUpdateLastName, setUpdateLastName] = useState(false)
  const [isUpdateBirthday, setUpdateBirthday] = useState(false)
  const [isUpdateEmail, setUpdateEmail] = useState(false)
  const [isUpdateGender, setUpdateGender] = useState(false)

  return (
    <div className={classes['contact-personal']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <div>
        <Typography variant="h4" fontWeight={700}>
          {t('userProfile.personalInformation.title')}
        </Typography>
      </div>
      <Paper sx={{ px: 2, mt: 4 }}>
        <Grid container spacing={3} alignItems="center" py={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="body1" color="#798395">
              {t('userProfile.personalInformation.phoneNumber')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={5}>
            <Typography variant="subtitle1" color="#576071" display="flex">
              {profile?.phoneNumber}
              <img
                src="/assets/shop-online/Profile/radio_button_checked.png"
                alt="successfully"
              />
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#f0f3f8' }} />
        <Grid container spacing={3} alignItems="center" py={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="body1" color="#798395">
              {t('userProfile.personalInformation.password')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={5}>
            <Typography variant="subtitle1" color="#576071">
              ••••••••
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Button
              variant="contained"
              className={classes['change-password']}
              onClick={() => setUpdatePassword(true)}
              sx={{
                backgroundColor: 'primary.lighter',
                color: 'primary.main',
                ':hover': {
                  backgroundColor: 'primary.lighter',
                },
              }}
            >
              {t('userProfile.personalInformation.changePassword')}
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#f0f3f8' }} />
        <Grid container spacing={3} alignItems="center" py={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="body1" color="#798395">
              {t('userProfile.personalInformation.firstName')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={5}>
            <Typography variant="subtitle1" color="#576071">
              {profile?.firstname}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'primary.lighter',
                color: 'primary.main',
                ':hover': {
                  backgroundColor: 'primary.lighter',
                },
              }}
              onClick={() => setUpdateFirstName(true)}
            >
              {t('update')}
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#f0f3f8' }} />
        <Grid container spacing={3} alignItems="center" py={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="body1" color="#798395">
              {t('userProfile.personalInformation.lastName')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={5}>
            <Typography variant="subtitle1" color="#576071">
              {profile?.lastname}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              variant="contained"
              onClick={() => setUpdateLastName(true)}
              sx={{
                backgroundColor: 'primary.lighter',
                color: 'primary.main',
                ':hover': {
                  backgroundColor: 'primary.lighter',
                },
              }}
            >
              {t('update')}
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#f0f3f8' }} />
        <Grid container spacing={3} alignItems="center" py={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="body1" color="#798395">
              {t('userProfile.personalInformation.birthday')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={5}>
            <Typography variant="subtitle1" color="#576071">
              {profile?.birthdate &&
                format(new Date(profile?.birthdate), 'dd/MM/yyyy')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              variant="contained"
              onClick={() => setUpdateBirthday(true)}
              sx={{
                backgroundColor: 'primary.lighter',
                color: 'primary.main',
                ':hover': {
                  backgroundColor: 'primary.lighter',
                },
              }}
            >
              {t('update')}
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#f0f3f8' }} />
        <Grid container spacing={3} alignItems="center" py={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="body1" color="#798395">
              {t('userProfile.personalInformation.email')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={5}>
            <Typography variant="subtitle1" color="#576071">
              {profile?.email}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#f0f3f8' }} />
        <Grid container spacing={3} alignItems="center" py={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="body1" color="#798395">
              {t('userProfile.personalInformation.gender')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={5}>
            <Typography variant="subtitle1" color="#576071">
              {t(profile?.gender?.toLowerCase())}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              variant="contained"
              onClick={() => setUpdateGender(true)}
              sx={{
                backgroundColor: 'primary.lighter',
                color: 'primary.main',
                ':hover': {
                  backgroundColor: 'primary.lighter',
                },
              }}
            >
              {t('update')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <UpdatePassWorDialog
        open={isUpdatePassword}
        onClose={() => setUpdatePassword(false)}
      />
      <UpdateFirstNameDialog
        open={isUpdateFirstName}
        onClose={() => setUpdateFirstName(false)}
      />
      <UpdateBirthdayDialog
        open={isUpdateBirthday}
        onClose={() => setUpdateBirthday(false)}
      />
      <UpdateLastNameDialog
        open={isUpdateLastName}
        onClose={() => setUpdateLastName(false)}
      />
      <UpdateEmailDialog
        open={isUpdateEmail}
        onClose={() => setUpdateEmail(false)}
      />
      <UpdateGenderDialog
        open={isUpdateGender}
        onClose={() => setUpdateGender(false)}
      />
    </div>
  )
}
