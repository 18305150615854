import { format } from 'date-fns'

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function uuidV4() {
  const pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  const hexDigits = '0123456789abcdef'
  const allowY = '89ab'
  const uuid = pattern.replace(/[xy]/g, char => {
    if (char === 'y') {
      return allowY.charAt(Math.floor(Math.random() * allowY.length))
    }
    return hexDigits.charAt(Math.floor(Math.random() * hexDigits.length))
  })
  return uuid
}

export function makePromotionCode() {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const length = 8
  let charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export function toSlug(str: string) {
  if (!str) {
    return ''
  }
  str = str.toLowerCase()
  str = str.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a')
  str = str.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e')
  str = str.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i')
  str = str.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o')
  str = str.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u')
  str = str.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y')
  str = str.replace(/đ/gi, 'd')
  str = str.replace(/[\W]/gi, ' ')
  str = str.trim()
  str = str.replace(/\s+/gi, '-')
  return str
}

export function isPhoneNumber(phone: string) {
  const pattern = /^(\+84|84|0)(1\d{9}|3\d{8}|5\d{8}|7\d{8}|8\d{8}|9\d{8})$/g
  return phone.match(pattern) ? true : false
}

export function isEmail(email: string) {
  const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
  return email.match(pattern) ? true : false
}

export function isDateString(date: string) {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/g.test(date)
}

export function toDateString(value: any) {
  return format(new Date(value), "yyyy-MM-dd'T'hh:mm:ss")
}

export const stripHtml = (html: string | any) => {
  let tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}
