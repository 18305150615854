export enum DialogNames {
  FORGOT_PASSWORD = 'forgot password',
  INPUT_CODE = 'input code',
  CREATE_NEW_PASSWORD = 'create new password',
}

export enum Title {
  FORGOT_PASSWORD = 'Bạn quên mật khẩu',
  INPUT_CODE = 'input code',
  CREATE_NEW_PASSWORD = 'create new password',
}

export enum TypeLogin {
  PHONE = 'Phone',
  EMAIL = 'Email',
}

export const REGEX_PHONE_NUMBER_WITHOUT_COUNTRY_CODE =
  /^(\+84|0)?(1\d{9}|3\d{8}|5\d{8}|7\d{8}|8\d{8}|9\d{8})$/
export const REGEX_PHONE_NUMBER =
  /^(\+84|0)(1\d{9}|3\d{8}|5\d{8}|7\d{8}|8\d{8}|9\d{8})$/
export const REGEX_NUMBER = /^\d+$/
