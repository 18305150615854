import {
  Dialog,
  Button,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import classes from './PromotionDetail.module.less'
import './PromotionDetail.module.less'
import Slider from 'react-slick'
import { useState } from 'react'
import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Check,
  CopyAllRounded,
} from '@mui/icons-material'
import { format } from 'date-fns'

export interface IPromotionDetailDiaLogProps {
  item: any
  open: boolean
  onclose: VoidFunction
}

export const PromotionDetailDialog = ({
  item,
  open,
  onclose,
}: IPromotionDetailDiaLogProps) => {
  const [slickRef, setSlickRef] = useState<Slider>()
  const [isCopy, setCopy] = useState(false)

  const slickSetting: any = {
    ref: setSlickRef,
    arrows: false,
    dots: false,
    navigator: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(item?.promotionCode)
    setCopy(true)
    setTimeout(() => {
      setCopy(false)
    }, 1000)
  }

  return (
    <Dialog open={open} onClose={onclose} fullWidth maxWidth="sm" scroll="body">
      <div className={classes['image']}>
        <img
          onClick={onclose}
          src="/assets/shop-online/promotions/icclose.png"
          alt="icon-close"
          className={classes['ic-close']}
        />
        <Stack direction="row" spacing={1} className={classes['image-control']}>
          <IconButton
            disableRipple
            className={classes['button-prev']}
            onClick={() => slickRef?.slickPrev()}
          >
            <ArrowBackIosNewRounded />
          </IconButton>
          <IconButton
            disableRipple
            className={classes['button-next']}
            onClick={() => slickRef?.slickNext()}
          >
            <ArrowForwardIosRounded />
          </IconButton>
        </Stack>
        <div className={classes['slider-container']}>
          <Slider {...slickSetting}>
            {(item?.banner || []).map((datum: any, i: number) => (
              <img
                key={i}
                src={datum}
                alt="image-content"
                className={classes['img-content']}
              />
            ))}
          </Slider>
        </div>
      </div>
      <DialogContent sx={{ py: 2 }} className={classes['promotion-detail']}>
        <div>
          <Typography variant="h5" color="#1A9CC6">
            {item?.title}
          </Typography>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="subtitle2" color="#576071">
              {item?.promotionCode}
            </Typography>
            <IconButton size="small" onClick={handleCopy}>
              {isCopy ? <Check /> : <CopyAllRounded />}
            </IconButton>
          </Stack>
          <div className={classes['promotion-share']}>
            <div className={classes['date-time']}>
              <img
                src="/assets/shop-online/promotions/icondatetime.png"
                alt="date-time-promotions"
              />
              {item?.created && (
                <p className="date-time">
                  {format(new Date(item?.created), 'dd MMM, yyyy')}
                </p>
              )}
            </div>

            <Button
              sx={{ backgroundColor: '#E0F3F7' }}
              className={classes['share']}
            >
              <img
                src="/assets/shop-online/promotions/sharelink.png"
                alt="share-link"
              />
              <p>Chia sẻ</p>
            </Button>
          </div>
          <div className={classes['content-description']}>
            <p
              className={classes['promotion-description']}
              dangerouslySetInnerHTML={{ __html: item?.content }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
