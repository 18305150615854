import { AxiosError } from 'axios'
import { REST_API_ADMIN } from 'src/api/api_paths'
import { logDev, logJson } from 'src/utils'
import Snackbar from 'src/utils/snackbar'
import { doHandShakeAgain, IHandleResponseOptions } from './helper'

export class BaseResponse {
  code: number
  data: any
  message: String
  result: boolean

  constructor(code: number, data: any, message: String, result: boolean) {
    this.code = code
    this.data = data
    this.message = message
    this.result = result
  }
}

export const HandleResponseError = (
  error: AxiosError<any>,
  options: IHandleResponseOptions = {}
): Promise<any> => {
  logDev('Response Error: ', error?.response)
  const res = error?.response
  if (res) {
    var statusCode = res?.status
    switch (statusCode) {
      case 400:
        if (!!res?.data?.detail) {
          Snackbar.error(res?.data?.detail)
        }
        break
      case 401:
        if (res?.config?.url !== REST_API_ADMIN.GET_PROFILE.uri) {
          if (res?.data?.detail) {
            Snackbar.error(res?.data?.detail)
          } else {
            //Snackbar.error('Unauthorized')
          }
        }
        break
      case 403:
        return doHandShakeAgain(error, options)
      case 404:
        if (!!res?.data?.detail) {
          Snackbar.error(res?.data?.detail)
        }
        break
      case 500:
        if (!!res?.data?.detail) {
          Snackbar.error(res?.data?.detail)
        }
        break
      case 503:
        return doHandShakeAgain(error, options)
      default:
        Snackbar.error('Error')
    }
  } else if (error.request) {
    logDev('No response received from server')
  } else {
    logDev('Error', error.message)
  }
  return Promise.reject(error)
}
