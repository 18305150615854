import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { useGetSListSpecialProductPatient } from 'src/api/hooks/patient.query'
import { ProductCard } from 'src/sections/user'
import classes from './ProductPromotion.module.less'

interface ProductPromotionProps {
  categoryId?: string
}

export const ProductPromotion = ({ categoryId }: ProductPromotionProps) => {
  const [slickRef, setSlickRef] = useState<Slider>()
  const { t } = useTranslation()
  const listSpecialProductRequest = useGetSListSpecialProductPatient()

  const products: any[] = listSpecialProductRequest?.data?.data || []

  useEffect(() => {
    if (!!categoryId) {
      listSpecialProductRequest.mutate({ categoryId })
    }
  }, [categoryId])

  const slickSetting: any = {
    ref: setSlickRef,
    arrows: false,
    dots: false,
    navigator: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  if (!products?.length) {
    return <></>
  }

  return (
    <div className={classes['product-promotion']}>
      <Typography variant="h4" mt={2} mb={1}>
        {t('pharmacyOnline.promotionProductInDay')}
      </Typography>
      <div className={classes['slider-container']}>
        <IconButton
          disableRipple
          className={classes['button-prev']}
          onClick={() => slickRef?.slickPrev()}
        >
          <ArrowBackIosNewRounded />
        </IconButton>
        <IconButton
          disableRipple
          className={classes['button-next']}
          onClick={() => slickRef?.slickNext()}
        >
          <ArrowForwardIosRounded />
        </IconButton>
        <Slider {...slickSetting}>
          {products.map((item, i) => (
            <div className={classes['slider-item']}>
              <ProductCard item={item} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
