import { forwardRef } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  value: any
  thousandSeparator: boolean
  allowLeadingZeros: boolean
  allowNegative: boolean
  decimalScale: number
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const {
      onChange,
      value,
      thousandSeparator = true,
      decimalScale = 0,
      allowNegative = false,
      allowLeadingZeros = false,
      ...other
    } = props

    return (
      <NumericFormat
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        decimalScale={decimalScale}
        allowLeadingZeros={allowLeadingZeros}
        allowNegative={allowNegative}
        thousandSeparator={thousandSeparator}
        value={value === '' ? '' : value}
        {...other}
      />
    )
  }
)

export default NumericFormatCustom
