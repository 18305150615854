import { Row } from 'antd'
import React, { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  useGetLatestTopNews,
  useGetListCategoryNews,
} from 'src/api/hooks/blog.query'
import { useGetListNewsParallel } from 'src/api/hooks/guestDashboard.query'
import classes from './BlogHeal.module.less'
import BlogTopNews from './Components/BlogTopNews'
import ItemCategory from './Components/ItemCategory'

export const BlogHealHome: React.FC<{}> = () => {
  const { t } = useTranslation()

  const [payload, setPayload] = useState({
    version: 'v1.0',
    subjectType: 'Sức khoẻ hàng ngày',
  })

  const TopNews = useGetLatestTopNews(payload, { skip: 0, limit: 3 })
  const categoryNews = useGetListCategoryNews(payload)

  const listTopNews = useMemo(() => {
    if (TopNews.isSuccess) {
      return TopNews.data
    }
  }, [TopNews.isSuccess])

  const listCategoryNews = useMemo(() => {
    if (categoryNews.isSuccess) {
      return categoryNews.data
    }
  }, [categoryNews.isSuccess])

  const newsByCategory = useGetListNewsParallel(
    listCategoryNews || [],
    'v1.0',
    {
      skip: 0,
      limit: 6,
    }
  )

  const listNewsByCategory = useMemo(() => {
    return !!newsByCategory && newsByCategory.map((item: any) => item.data)
  }, [newsByCategory])

  return (
    <Row className={classes['layout-width']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <div data-aos="fade-up" className={classes['top-news']}>
        {listTopNews?.data.map((item: any, index: number) => (
          <BlogTopNews
            key={item.newsId}
            itemData={item}
            reponsive={{ index: index }}
          />
        ))}
      </div>
      <div className={classes['list-category']}>
        {listCategoryNews?.map((item: string, index: number) => (
          <ItemCategory
            listNews={listNewsByCategory[index]}
            key={item}
            itemData={item}
          />
        ))}
      </div>
    </Row>
  )
}

export default BlogHealHome
