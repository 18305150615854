import {
  Card,
  CardHeader,
  Divider,
  Grid,
  Link,
  Typography,
} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import RoomIcon from '@mui/icons-material/Room'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'

import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import WcIcon from '@mui/icons-material/Wc'
import PeopleIcon from '@mui/icons-material/People'
import WorkIcon from '@mui/icons-material/Work'
import CakeIcon from '@mui/icons-material/Cake'
import InfoIcon from '@mui/icons-material/Info'

//
import { styled } from '@mui/material/styles'
import { Stack } from '@mui/system'
import { useSnackbar } from 'notistack'
import Iconify from 'src/components/iconify/Iconify'
import { useLocales } from 'src/locales'
import { fNumber } from 'src/utils/formatNumber'
import { format } from 'date-fns'
import { useAuthContext } from 'src/auth/useAuthContext'
import _ from 'lodash'

// ----------------------------------------------------------------------

type Props = {
  profile: any
}

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}))

export default function Profile({ profile }: Props) {
  const { role } = useAuthContext()
  const { translate } = useLocales()
  const { enqueueSnackbar } = useSnackbar()
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Stack spacing={3}>
          <Card sx={{ py: 3 }}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Stack width={1} textAlign="center">
                <Typography variant="h4">
                  {fNumber(+profile?.exp || 0)}
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {translate('userDetail.numOfExp')}
                </Typography>
              </Stack>

              <Stack width={1} textAlign="center">
                <Typography variant="h4">
                  {fNumber(+profile?.rate || 0)}
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {translate('userDetail.rate')}
                </Typography>
              </Stack>
            </Stack>
          </Card>

          <Card>
            <CardHeader title={translate('information')} />

            <Stack spacing={2} sx={{ p: 3 }}>
              {!!profile?.data?.address && (
                <Stack direction="row">
                  <StyledIcon icon="eva:pin-fill" />

                  <Typography variant="body2">
                    {translate('userDetail.addressAt')} &nbsp;
                    <Link
                      component="span"
                      variant="subtitle2"
                      color="text.primary"
                    >
                      {profile?.data?.address}, {profile?.data?.wardsName},{' '}
                      {profile?.data?.districtName},{' '}
                      {profile?.data?.provinceName}
                    </Link>
                  </Typography>
                </Stack>
              )}

              {profile?.email && (
                <Stack columnGap="12px" direction="row">
                  <EmailIcon />
                  <Typography variant="body2">{profile?.email}</Typography>
                </Stack>
              )}

              {profile?.phoneNumber && (
                <Stack columnGap="12px" direction="row">
                  <PhoneAndroidIcon />
                  <Typography variant="body2">
                    {profile?.phoneNumber}
                  </Typography>
                </Stack>
              )}

              {profile?.race && (
                <Stack columnGap="12px" direction="row">
                  <PeopleIcon />
                  <Typography variant="body2">{profile?.race}</Typography>
                </Stack>
              )}

              {profile?.gender && (
                <Stack columnGap="12px" direction="row">
                  <WcIcon />
                  <Typography variant="body2">
                    {translate('gender')}{' '}
                    <Link
                      component="span"
                      variant="subtitle2"
                      color="text.primary"
                    >
                      {translate(profile?.gender?.toLowerCase())}
                    </Link>
                  </Typography>
                </Stack>
              )}

              {profile?.birthdate && (
                <Stack columnGap="12px" direction="row">
                  <CakeIcon />
                  <Typography variant="body2">
                    {format(new Date(profile?.birthdate), 'dd/MM/yyyy')}
                  </Typography>
                </Stack>
              )}

              {profile?.degree && (
                <Stack direction="row">
                  <StyledIcon
                    icon="ic:round-business-center"
                    sx={{ marginLeft: '2px' }}
                  />

                  <Typography variant="body2">
                    <Link
                      component="span"
                      variant="subtitle2"
                      color="text.primary"
                    >
                      {profile?.degree}
                    </Link>
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Card>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack spacing={3}>
          <Card>
            <CardHeader title={translate('userDetail.accountInformation')} />

            <Stack spacing={2} sx={{ p: 3 }}>
              <Stack columnGap="11px" direction="row">
                <EmailIcon />
                <Typography variant="body2">{profile?.email}</Typography>
              </Stack>
              {profile?.['name' + role] && (
                <Stack columnGap="11px" direction="row">
                  <EmailIcon />
                  <Typography variant="body2">
                    {profile?.['name' + role]}
                  </Typography>
                </Stack>
              )}

              {profile?.specialist && (
                <Stack columnGap="11px" direction="row">
                  <WorkIcon />
                  <Typography variant="body2">
                    {Array.isArray(profile?.specialist)
                      ? _.uniq(profile?.specialist).join(', ')
                      : profile?.specialist}
                  </Typography>
                </Stack>
              )}

              {!!profile?.homePhone && (
                <Stack columnGap="11px" direction="row">
                  <PhoneInTalkIcon />
                  <Typography variant="body2">{profile?.homePhone}</Typography>
                </Stack>
              )}

              {!!profile?.info && <Typography>{profile?.info}</Typography>}
            </Stack>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  )
}
