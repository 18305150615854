import * as Yup from 'yup'
// form
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
// @mui
import { Box, Stack, Typography } from '@mui/material'
// themes
import { useTheme } from '@mui/material/styles'
// components
import FormProvider, { RHFTextField } from 'src/components/hook-form'

import { LoadingButton } from '@mui/lab'
import { useEffect, useRef, useState } from 'react'
import { useSendOtp, useVerifyOtp } from 'src/api/hooks/admin.query'
import {
  useSendOtpPatient,
  useVerifyOtpPatient,
} from 'src/api/hooks/patient.query'
import { useLocales } from 'src/locales'
import { useForgotPasswordContext } from '../context'
import NumericFormatCustom from 'src/components/common/NumericFormatCustom'
import useResponsive from 'src/hooks/useResponsive'

export default function AuthInputCode() {
  const theme = useTheme()
  const sendOtpRequest = useSendOtp()
  const sendOtpPatientRequest = useSendOtpPatient()
  const verifyOtpRequest = useVerifyOtp()
  const verifyOtpPatientRequest = useVerifyOtpPatient()
  const { forgotPasswordData, updateForgotPasswordData, nextStep } =
    useForgotPasswordContext()
  const { translate } = useLocales()
  const [countdown, setCountDown] = useState(60)
  const countDownInterval = useRef<any>(null)
  const sm = useResponsive('up', 'sm')

  const codeRequiredMessage = translate('forgotPasswordPage.please_input_otp')

  const LoginSchema = Yup.object().shape({
    codeOtp: Yup.string().required(codeRequiredMessage),
  })

  const defaultValues = {
    codeOtp: '',
    typeOtp: 'ForgotPass',
    phoneNumber: forgotPasswordData?.phoneNumber,
  }

  const methods = useForm<typeof defaultValues>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      if (forgotPasswordData?.accountType === 'partner') {
        await verifyOtpRequest.mutateAsync({ data })
      } else {
        await verifyOtpPatientRequest.mutateAsync(data as any)
      }
      updateForgotPasswordData({
        codeOtp: data?.codeOtp,
      })
      nextStep()
    } catch (error) {}
  }

  const handleResendOtpCode = async () => {
    setCountDown(60)
    const data = {
      typeOtp: 'ForgotPass',
      phoneNumber: forgotPasswordData?.phoneNumber,
    }

    if (forgotPasswordData?.accountType === 'partner') {
      await sendOtpRequest.mutateAsync({ data })
    } else {
      await sendOtpPatientRequest.mutateAsync(data as any)
    }
  }

  useEffect(() => {
    countDownInterval.current = setInterval(() => {
      setCountDown(countdown => Math.max(0, countdown - 1))
    }, 1000)
    return () => {
      clearInterval(countDownInterval.current)
    }
  }, [])

  const RenderResendOtp = (
    <>
      <Typography
        variant="body1"
        color="#798395"
        textAlign="center"
        sx={{
          marginTop: '16px',
        }}
      >
        {translate('forgotPasswordPage.youDontReceiveCode')}
      </Typography>
      <Typography
        variant="body1"
        color="#B6BDCB"
        textAlign="center"
        sx={{
          marginBottom: '24px',
          fontWeight: theme.typography.fontWeightBold,
        }}
      >
        {countdown !== 0 ? (
          translate('forgotPasswordPage.resendOtpCodeAfter', { countdown })
        ) : (
          <LoadingButton disableRipple={true} onClick={handleResendOtpCode}>
            {translate('forgotPasswordPage.resendOtpCode')}
          </LoadingButton>
        )}
      </Typography>
    </>
  )

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body2">
        {translate('forgotPasswordPage.sub_title')}
        {!forgotPasswordData?.phoneNumber?.includes('@')
          ? translate('phoneNumber') + ' '
          : 'email '}
        <strong>{forgotPasswordData?.phoneNumber}</strong>
        {translate('forgotPasswordPage.mine')}
      </Typography>
      <Stack spacing={1} mt={1}>
        <Typography variant="subtitle2" color="#798395">
          {translate('forgotPasswordPage.verifyCode')}
        </Typography>
        <RHFTextField
          fullWidth
          name="codeOtp"
          placeholder={translate('forgotPasswordPage.verifyCode')}
          inputProps={{ maxLength: 6, thousandSeparator: false }}
          InputProps={{
            inputComponent: NumericFormatCustom as any,
          }}
        />
      </Stack>

      {!sm && forgotPasswordData?.accountType === 'user' && RenderResendOtp}
      <Box flexGrow={1}></Box>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{
          marginTop: { xs: '16px', sm: '16px' },
        }}
      >
        {translate('continue')}
      </LoadingButton>
      {sm && RenderResendOtp}
    </FormProvider>
  )
}
