import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
// utils
import localStorageAvailable from '../utils/localStorageAvailable'
import {
  ActionMapType,
  AuthUserType,
  IAuthUserContext,
  IAuthUserState,
} from './types'

import {
  getAuthTokenUser,
  getCodeHandshakeUser,
  getRefreshTokenUser,
  removeAuthTokenUser,
  removeRefreshTokenUser,
  saveRefreshTokenUser,
  setAuthTokenUser,
} from 'src/api/config/token'

import { useNavigate } from 'react-router'
import { STORAGE_KEY } from 'src/api'
import {
  useFacebookLogin,
  useGetProfilePatient,
  useGoogleLogin,
  useLoginPatient,
  useLogoutPatient,
} from 'src/api/hooks/patient.query'
import { APP_URL } from 'src/config'

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGIN_WITH_GOOGLE = 'LOGIN_WITH_GOOGLE',
  LOGIN_WITH_FACEBOOK = 'LOGIN_WITH_FACEBOOK',
  SEND_OTP = 'SEND_OTP',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  SET_ERROR = 'SET_ERROR',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean
    profile: AuthUserType
  }
  [Types.SET_ERROR]: {
    isError: boolean
  }
  [Types.LOGIN]: {
    profile: AuthUserType
  }
  [Types.LOGIN_WITH_GOOGLE]: {
    profile: AuthUserType
  }
  [Types.LOGIN_WITH_FACEBOOK]: {
    user: AuthUserType
  }
  [Types.SEND_OTP]: {
    user: AuthUserType
  }
  [Types.REGISTER]: {
    user: AuthUserType
    profile: AuthUserType
  }
  [Types.LOGOUT]: undefined
}

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>]

const initialState: IAuthUserState = {
  isInitialized: false,
  isAuthenticated: false,
  profile: null,
}

const reducer = (state: IAuthUserState, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      profile: action.payload.profile,
    }
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      profile: action.payload.profile,
    }
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      profile: null,
    }
  }
  return state
}

const AuthUserContext = createContext<IAuthUserContext | null>(null)

type AuthUserProviderProps = {
  children: React.ReactNode
}

export function AuthUserProvider({ children }: AuthUserProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const loginRequest = useLoginPatient()
  const googleLoginRequest = useGoogleLogin()
  const facebookLoginRequest = useFacebookLogin()
  const getProfileRequest = useGetProfilePatient()
  const logoutRequest = useLogoutPatient()
  const navigate = useNavigate()

  const storageAvailable = localStorageAvailable()

  const initialize = useCallback(async () => {
    const tokenAuth = getAuthTokenUser()
    var xHandshake = getCodeHandshakeUser()
    if (tokenAuth != '' && xHandshake != '') {
      await handleUpdateProfile()
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          profile: null,
        },
      })
    }
  }, [storageAvailable])

  useEffect(() => {
    initialize()
  }, [initialize])

  const handleUpdateProfile = useCallback(async () => {
    try {
      const res: any = await getProfileRequest.mutateAsync()
      const data = { ...res }

      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: true,
          profile: data?.profile,
        },
      })
    } catch (error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          profile: null,
        },
      })
    }
  }, [dispatch, getProfileRequest])

  const handleLogin = useCallback(
    async (data: any) => {
      const res = await loginRequest.mutateAsync({ ...data })
      setAuthTokenUser(res?.accessToken)
      saveRefreshTokenUser(res?.refreshToken)
      handleUpdateProfile()
    },
    [loginRequest]
  )

  // LOGIN WITH FACEBOOK
  const loginWithFacebook = useCallback(async (data: any) => {
    facebookLoginRequest.mutate({
      data,
    })
  }, [facebookLoginRequest])

  // LOGIN WITH GOOGLE
  const loginWithGoogle = useCallback(async (data: any) => {
    googleLoginRequest.mutate({
      data,
    })
  }, [googleLoginRequest])

  // LOGOUT
  const logout = useCallback(async () => {
    const refreshToken = getRefreshTokenUser()
    await logoutRequest.mutateAsync({ refreshToken })
    removeAuthTokenUser()
    removeRefreshTokenUser()
    navigate('/')
    dispatch({
      type: Types.LOGOUT,
    })
  }, [])

  useEffect(() => {
    if (facebookLoginRequest.isSuccess && facebookLoginRequest.data != null) {
      const data = facebookLoginRequest.data
      setAuthTokenUser(data.accessToken)
      saveRefreshTokenUser(data.refreshToken)
      handleUpdateProfile()
    }
  }, [facebookLoginRequest.isSuccess])

  useEffect(() => {
    if (googleLoginRequest.isSuccess && googleLoginRequest.data != null) {
      const data = googleLoginRequest.data
      setAuthTokenUser(data.accessToken)
      saveRefreshTokenUser(data.refreshToken)
      handleUpdateProfile()
    }
  }, [googleLoginRequest.isSuccess])

  useEffect(() => {
    const handleUpdateProfileIfChangeStorage = (e: StorageEvent) => {
      if (e.key === STORAGE_KEY.SESSION_TOKEN_USER) {
        if (getRefreshTokenUser() && getAuthTokenUser()) {
          handleUpdateProfile()
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: false,
              profile: null,
            },
          })
          navigate(APP_URL.HOME)
        }
      }
    }
    window.addEventListener('storage', handleUpdateProfileIfChangeStorage)
    return () => {
      window.removeEventListener('storage', handleUpdateProfileIfChangeStorage)
    }
  }, [])

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      profile: state.profile,
      method: 'jwt',
      login: handleLogin,
      loginWithFacebook,
      loginWithGoogle,
      logout,
      updateProfile: handleUpdateProfile,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.profile,
      handleLogin,
      loginWithFacebook,
      loginWithGoogle,
      logout,
      handleUpdateProfile,
    ]
  )

  return (
    <AuthUserContext.Provider value={memoizedValue}>
      {children}
    </AuthUserContext.Provider>
  )
}

export const useAuthUserContext = () => {
  const context = useContext(AuthUserContext)

  if (!context)
    throw new Error(
      'useAuthUserContext context must be use inside AuthUserProvider'
    )

  return context
}
