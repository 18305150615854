import { alpha, Theme } from '@mui/material/styles'
import { RootState, useSelector } from '../../redux/store'

export default function Backdrop(theme: Theme) {
  const { isSetBackground } = useSelector((state: RootState) => state.dialog)

  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // backgroundColor: alpha(theme.palette.grey[800], 0.8),
          backgroundColor: isSetBackground
            ? '#004E73'
            : alpha(theme.palette.grey[800], 0.8),
          opacity: isSetBackground ? '0.8 !important' : 1,
        },
        invisible: {
          background: 'transparent',
        },
      },
    },
  }
}
