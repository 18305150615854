import {
  Box,
  Grid,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useGetListCategoryPatient,
  useGetListProductPatient,
} from 'src/api/hooks/patient.query'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import Image from 'src/components/image/Image'
import { ProductCard } from 'src/sections/user'
import ProductFilterDesktop from './ProductFilterDesktop'
import ProductFilterSort from './ProductFilterSort'

export type TProductFilterProps = {
  shop: any
  search?: string
}

export const ProductFilter: React.FC<TProductFilterProps> = ({
  shop,
  search = '',
}: TProductFilterProps) => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuthUserContext()

  const categoryRequest = useGetListCategoryPatient()
  const listProductRequest = useGetListProductPatient()

  const [filter, setFilter] = useState<any>({
    shopId: shop?.shopId,
    categoryId: '',
    subCategoryId: '',
    minPrice: '',
    maxPrice: '',
    isSale: null,
    isRelated: true,
    sortName: 'PurchasedNumber',
    sort: 'desc',
    search: search,
    skip: 0,
    limit: 30,
  })

  const handleUpdateFilter = (newFilter: Record<string, any>) => {
    setFilter((state: any) => ({ ...state, ...newFilter }))
  }

  const products: any[] = listProductRequest?.data?.data || []
  const total: number = listProductRequest?.data?.total || 0
  const categories: any[] = categoryRequest?.data || []

  useEffect(() => {
    if (shop?.shopId) {
      listProductRequest.mutate({ ...filter, search, shopId: shop?.shopId })
    }
  }, [filter, shop?.shopId, search])

  useEffect(() => {
    if (isAuthenticated) {
      categoryRequest.mutate({})
    }
  }, [isAuthenticated])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={9}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems={{ md: 'center' }}
          spacing={2}
          mt={3}
        >
          {!listProductRequest?.isSuccess ? (
            <Skeleton width={200} height={30} />
          ) : null}
          {listProductRequest?.isSuccess && products?.length ? (
            <Typography variant="body2" color="#798395">
              {t('pharmacyOnline.searchResultFound', { number: total })}
            </Typography>
          ) : null}
          {listProductRequest?.isSuccess && !products?.length ? (
            <Typography variant="body2" color="#798395">
              {t('pharmacyOnline.noSearchResult')}
            </Typography>
          ) : null}
          <ProductFilterSort
            categories={categories}
            filter={filter}
            handleUpdateFilter={handleUpdateFilter}
          />
        </Stack>
        {!listProductRequest?.isSuccess ? (
          <Grid container columns={60} spacing={2} pt={2}>
            {[...new Array(30)]?.map((item, i) => (
              <Grid item xs={30} sm={20} md={15} lg={12} key={i}>
                <Skeleton height={200} />
              </Grid>
            ))}
          </Grid>
        ) : null}
        {listProductRequest?.isSuccess && products?.length ? (
          <Grid container columns={60} spacing={2} pt={2}>
            {products?.map((item, i) => (
              <Grid item xs={30} sm={20} md={15} lg={12} key={i}>
                <ProductCard item={item} />
              </Grid>
            ))}
          </Grid>
        ) : null}
        {listProductRequest?.isSuccess && !products?.length ? (
          <Stack spacing={1} textAlign="center" alignItems="center" py={8}>
            <Image src="/assets/shop-online/paper-1.png" alt="" />
            <Typography variant="subtitle1" color="#B6BDCB">
              Không tìm thấy kết quả phù hợp
            </Typography>
            <Typography variant="body2" color="#B6BDCB">
              Vui lòng thử với từ khoá tìm kiếm khác.
            </Typography>
          </Stack>
        ) : null}
        {listProductRequest?.isSuccess && products?.length ? (
          <Box display="flex" justifyContent="center" pt={3}>
            <Pagination
              count={Math.ceil(total / (filter?.limit || 10))}
              page={filter?.skip / filter?.limit + 1}
              onChange={(e, page) =>
                page && handleUpdateFilter({ skip: (page - 1) * filter?.limit })
              }
            />
          </Box>
        ) : null}
      </Grid>
      <Grid item xs={12} md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
        <ProductFilterDesktop
          categories={categories}
          filter={filter}
          handleUpdateFilter={handleUpdateFilter}
        />
      </Grid>
    </Grid>
  )
}

export default ProductFilter
