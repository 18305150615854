import { FC, RefAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { LabelProps } from 'src/components/label'
import Label from 'src/components/label/Label'
import {
  getTransportStatusLabel,
  transportStatusColor,
  transportStatusTextColor,
} from './../../constants'

enum TabValue {
  All,
  WaitingForDelivery,
  Transporting,
  Success,
}

type TransportStatusLabelProps = Omit<LabelProps, 'ref'> &
  RefAttributes<HTMLSpanElement> & {
    transportStatus: string
  }

export const TransportStatusLabel: FC<TransportStatusLabelProps> = ({
  transportStatus,
  ...other
}) => {
  const { t } = useTranslation()

  const transportStatusLabel = getTransportStatusLabel(t)

  return (
    <Label
      {...other}
      variant="soft"
      color={transportStatusColor[transportStatus]}
      sx={{
        borderTopLeftRadius: '9999px',
        borderTopRightRadius: '9999px',
        borderBottomLeftRadius: '9999px',
        borderBottomRightRadius: '9999px',
        color: transportStatusTextColor[transportStatus],
      }}
    >
      {transportStatusLabel[transportStatus]}
    </Label>
  )
}
