import { APP_URL } from 'src/config'
import { TRoute } from 'src/@types/common'
import { Loadable } from 'src/routes/elements'
import { lazy } from 'react'
import BlogHealHome from './BlogHealHome.screen'
import BlogListNewsItem from './BlogListNewsItem'
import BlogHealSearch from './BlogHealSearch.screen'
import BlogHeadDetail from './BlogHealDetail.screen'

// const BlogHealHome = Loadable(lazy(() => import('./BlogHealHome.screen')))
// const BlogListNewsItem = Loadable(lazy(() => import('./BlogListNewsItem')))
// const BlogHealSearch = Loadable(lazy(() => import('./BlogHealSearch.screen')))
// const BlogHeadDetail = Loadable(lazy(() => import('./BlogHealDetail.screen')))

export const BlogHealHomeRoutes: TRoute[] = [
  {
    path: APP_URL.BLOG_HEAL_HOME,
    element: <BlogHealHome />,
  },
  {
    path: APP_URL.BLOG_HEAL_SEE_ALL_NEWS_ITEM,
    element: <BlogListNewsItem />,
  },
  {
    path: APP_URL.BLOG_HEAL_SEARCH,
    element: <BlogHealSearch />,
  },
  {
    path: APP_URL.BLOG_HEAL_DETAIL,
    element: <BlogHeadDetail />,
  },
]
