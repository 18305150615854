import { useMutation } from 'react-query'
import { checkPhoneNumber, registerDoctor } from '../api_functions/doctorAuth'

export function useRegisterDoctor() {
  return useMutation(registerDoctor)
}

export function useCheckPhoneNumber() {
  return useMutation(checkPhoneNumber)
}
