import {
  Box,
  Breadcrumbs,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import { APP_URL } from 'src/config'
import LoginUserDialog from 'src/sections/auth/login-user/LoginUserDialog'
import {
  CartDetail,
  PayButton,
  PaymentMethod,
  Pricing,
  ShippingAddress,
} from './components'
import { NoticeHasSellOverDialog } from './components/PayButton/NoticeHasSellOverDialog'
import { useCartContext } from './context'
import classes from './Cart.module.less'
import { Helmet } from 'react-helmet-async'
import { ShippingUnitDialog } from './components/ShippingUnit/ShippingUnitDialog'
import { ShippingUnit } from './components/ShippingUnit'

export type TCartProps = {}

export const Cart: React.FC<TCartProps> = ({}: TCartProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isAuthenticated, isInitialized } = useAuthUserContext()
  const { order, isCartInitialized } = useCartContext()
  const [isOpenNoticeDialog, setOpenNoticeDialog] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      for (const product of order?.listProduct) {
        if (product?.productNumber <= 0 && !product?.sellOver) {
          setOpenNoticeDialog(true)
          break
        }
      }
    }
  }, [isAuthenticated])

  return (
    <div className={classes['cart-container']}>
      <Helmet>
        <title>Cart | Sophie</title>
      </Helmet>
      <Breadcrumbs>
        <Link color="red" to={APP_URL.SHOP_ONLINE}>
          <Typography variant="body2" color="primary">
            {t('pharmacyOnline.pharmacyOnline')}
          </Typography>
        </Link>
        <Typography color="#798395">{t('cart.yourCart')}</Typography>
      </Breadcrumbs>
      <Typography variant="h3" mt={1}>
        {t('cart.yourCart')}
      </Typography>
      <Grid container spacing={3} pt={2}>
        <Grid item xs={12} md={9}>
          {isInitialized ? <CartDetail /> : <Skeleton height={500} />}
        </Grid>
        <Grid item xs={12} md={3}>
          {isInitialized ? (
            <Stack spacing={1}>
              <ShippingAddress />
              <ShippingUnit />
              <Pricing />
              <PaymentMethod />
            </Stack>
          ) : (
            <Stack spacing={1}>
              <Skeleton height={200} />
              <Skeleton height={200} />
              <Skeleton height={200} />
            </Stack>
          )}
        </Grid>
      </Grid>
      <PayButton />
      <NoticeHasSellOverDialog
        open={isAuthenticated && isOpenNoticeDialog}
        onClose={() => setOpenNoticeDialog(false)}
      />
      <LoginUserDialog
        open={!isAuthenticated && isInitialized}
        onClose={() => navigate('/')}
      />
    </div>
  )
}

export default Cart
