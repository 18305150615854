import { Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useGetListCategoryPatient } from 'src/api/hooks/patient.query'
import {
  Banner,
  Category,
  ProductByCategory,
  ProductPromotion,
  Promotion,
} from './components'
import classes from './HomePage.module.less'

export type THomePageProps = {}

export const HomePage: React.FC<THomePageProps> = ({}: THomePageProps) => {
  const categoryRequest = useGetListCategoryPatient()
  const categories = categoryRequest?.data || []

  useEffect(() => {
    categoryRequest.mutateAsync({})
  }, [])

  return (
    <div className={classes['home-page']}>
      <Helmet>
        <title>Sophie</title>
      </Helmet>
      <Stack spacing={4} pb={6}>
        <Banner />
        <Category categories={categories} />
        <ProductPromotion />
        <Promotion />
        <ProductByCategory categories={categories} />
      </Stack>
    </div>
  )
}

export default HomePage
