import { Add, Remove } from '@mui/icons-material'
import {
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
import { ImageProductSale } from 'src/assets'
import { IconButtonAnimate } from 'src/components/animate'
import NumericFormatCustom from 'src/components/common/NumericFormatCustom'
import { TypographyLineClamp } from 'src/components/common/TypographyLineClamp'
import Image from 'src/components/image/Image'
import { APP_URL } from 'src/config'
import useResponsive from 'src/hooks/useResponsive'
import { fNumber } from 'src/utils/formatNumber'
import { toSlug } from 'src/utils/string'
import { useCartContext } from '../../context'
import classes from './CartDetail.module.less'
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog'

export const CartDetail = () => {
  const { t } = useTranslation()
  const isMd = useResponsive('up', 'md')
  const { isOrderLoading } = useCartContext()
  const {
    order,
    increaseProductAmountById,
    decreaseProductAmountById,
    setProductAmountById,
    removeProductById,
  } = useCartContext()
  const [productIdToDelete, setProductIdToDelete] = useState('')
  const [shopIdToDelete, setShopIdToDelete] = useState('')

  const { enqueueSnackbar } = useSnackbar()

  const handleDeleteProduct = () => {
    removeProductById(productIdToDelete, shopIdToDelete)
    enqueueSnackbar(t('cart.deletedProductFormCart'))
    setProductIdToDelete('')
    setShopIdToDelete('')
  }

  const adjustInputWidthByValue = (value: number) => {
    if (+value > 9999) {
      return '80px'
    }
    if (+value > 999) {
      return '70px'
    }
    if (+value > 99) {
      return '60px'
    }
    return '50px'
  }

  const productAmount = (orderItem: any) => {
    var productAmount = 0
    productAmount =
      (orderItem.price ?? 0) -
      ((orderItem.promotionPrice ?? 0) +
        (orderItem.transportPromotionPrice ?? 0) +
        (orderItem.transportPrice ?? 0))
    return productAmount
  }

  const countProduct = (listProduct: any) => {
    var count = 0
    if (listProduct != null && listProduct.length > 0) {
      for (var i = 0; i < listProduct.length; i++) {
        count = count + (listProduct[i].quantity ?? 0)
      }
    }
    return count
  }

  return (
    <Stack spacing={1}>
      {isMd && (
        <Paper sx={{ px: 2, py: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography variant="subtitle2" color="#576071">
                {t('cart.product')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" color="#576071">
                {t('cart.quantity')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" color="#576071">
                {t('cart.toPrice')}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="subtitle2" color="#576071" align="right">
                {t('cart.delete')}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
      {!order?.listOrder?.length && (
        <Stack spacing={1} textAlign="center" alignItems="center" py={8}>
          <Image src="/assets/shop-online/paper-1.png" alt="" />
          <Typography variant="subtitle1" color="#B6BDCB">
            {t('cart.youNeedAddProductToCart')}
          </Typography>
        </Stack>
      )}
      {order?.listOrder?.length > 0 &&
        order?.listOrder?.map((orderItem: any, indexOrder: number) => {
          var listProduct = order?.listOrder[indexOrder]?.listProduct ?? []
          return (
            <Paper sx={{ px: 2, pb: 2, maxHeight: 450, overflowY: 'auto' }}>
              <Grid spacing={1} key={indexOrder} pt={2}>
                <Grid item xs={12} md={12} pb={1}>
                  <Link
                    to={`/shop-online/shop/${
                      listProduct.length > 0 ? listProduct[0].productId : ''
                    }-${toSlug(orderItem?.shopName)}`}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Image
                        src="/assets/shop-online/store.svg"
                        alt=""
                        sx={{ width: 24 }}
                      />
                      <Typography variant="subtitle2" color="#576071">
                        {orderItem?.shopName}
                      </Typography>
                    </Stack>
                  </Link>
                </Grid>
                <Divider />
                {listProduct.length > 0 &&
                  listProduct.map((productItem: any, indexProduct: number) => {
                    return (
                      <Grid
                        container
                        spacing={1}
                        key={indexProduct}
                        pt={2}
                        sx={{ px: 2, pb: 2, maxHeight: 450, overflowY: 'auto' }}
                      >
                        <Grid item xs={12} md={5}>
                          <Link
                            to={APP_URL.SHOP_ONLINE_PRODUCT_DETAIL?.replace(
                              ':slug',
                              `${productItem?.productId}-${toSlug(
                                productItem?.productName
                              )}`
                            )}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <div className={classes['product-image']}>
                                {productItem?.productPrice !==
                                productItem?.productRealPrice ? (
                                  <div className={classes['overlay-sale']}>
                                    <LazyLoad once height={40}>
                                      <img src={ImageProductSale} alt="" />
                                    </LazyLoad>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {productItem?.productDiscounts > 0 ? (
                                  <div className={classes['overlay-sale-text']}>
                                    <Typography color="white">
                                      <>-{productItem?.productDiscounts}%</>
                                    </Typography>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <Image
                                  src={
                                    productItem?.productThumbImages?.[0] +
                                    '?timestamp=' +
                                    Date.now()
                                  }
                                  alt=""
                                  className={classes['img']}
                                  sx={{
                                    backgroundImage:
                                      'url(/assets/shop-online/medicine-placeholder.png) !important',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                  }}
                                />
                              </div>
                              <Stack>
                                <TypographyLineClamp
                                  variant="body1"
                                  color="#576071"
                                  line={2}
                                >
                                  {productItem?.productName}
                                </TypographyLineClamp>
                                <Typography variant="subtitle2">
                                  {fNumber(+productItem?.productPrice || 0)}
                                  ₫&nbsp;
                                  {productItem?.productPrice !==
                                    productItem?.productRealPrice && (
                                    <Typography
                                      variant="caption"
                                      component="span"
                                      color="#798395"
                                      sx={{ textDecoration: 'line-through' }}
                                    >
                                      {fNumber(
                                        +productItem?.productRealPrice || 0
                                      )}
                                      ₫
                                    </Typography>
                                  )}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Link>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            height="100%"
                            spacing={0.5}
                          >
                            <IconButtonAnimate
                              size="small"
                              sx={{
                                borderRadius: '8px',
                                bgcolor: '#F4F6F8',
                              }}
                              onClick={() => {
                                if (productItem?.quantity > 1) {
                                  decreaseProductAmountById(
                                    productItem?.productId,
                                    orderItem?.shopId
                                  )
                                } else {
                                  setProductIdToDelete(productItem?.productId)
                                  setShopIdToDelete(orderItem?.shopId)
                                }
                              }}
                              disabled={isOrderLoading}
                            >
                              <Remove />
                            </IconButtonAnimate>
                            <TextField
                              size="small"
                              value={+productItem?.quantity || ''}
                              onChange={e =>
                                setProductAmountById(
                                  productItem?.productId,
                                  +e.target.value || ''
                                )
                              }
                              onBlur={e =>
                                setProductAmountById(
                                  productItem?.productId,
                                  Math.max(+e.target.value, 1)
                                )
                              }
                              InputProps={{
                                components: NumericFormatCustom as any,
                              }}
                              sx={{
                                width: adjustInputWidthByValue(
                                  +productItem?.quantity
                                ),
                                '.MuiInputBase-root': {
                                  height: '34px',
                                  borderRadius: '8px',
                                },
                                input: {
                                  textAlign: 'center',
                                  color: 'primary.main',
                                  fontWeight: 600,
                                },
                              }}
                              disabled={isOrderLoading}
                            />
                            <IconButtonAnimate
                              size="small"
                              sx={{ borderRadius: '8px', bgcolor: '#F4F6F8' }}
                              onClick={() =>
                                increaseProductAmountById(
                                  productItem?.productId,
                                  orderItem?.shopId
                                )
                              }
                              disabled={isOrderLoading}
                            >
                              <Add />
                            </IconButtonAnimate>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          md={3}
                          display="flex"
                          alignItems="center"
                          justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
                        >
                          {productItem?.productNumber <= 0 ? (
                            <Typography fontWeight={700} color="#B6BDCB">
                              {t('cart.outStock')}
                            </Typography>
                          ) : (
                            <Typography fontWeight={700} color="#F0224F">
                              {fNumber(
                                +productItem?.productPrice *
                                  productItem?.quantity || 0
                              )}
                              ₫
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={0}
                          md={1}
                          display={{ xs: 'none', md: 'flex' }}
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <IconButtonAnimate
                            size="small"
                            onClick={() => {
                              setProductIdToDelete(productItem?.productId)
                              setShopIdToDelete(orderItem?.shopId)
                            }}
                            disabled={isOrderLoading}
                          >
                            <Image
                              src="/assets/shop-online/ic-trash.svg"
                              alt=""
                            />
                          </IconButtonAnimate>
                        </Grid>
                      </Grid>
                    )
                  })}
                <Grid item xs={12} md={6} py={1}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="#798395">
                      {t('cart.transportPrice')}
                    </Typography>
                    {isOrderLoading ? (
                      <Skeleton width={80} />
                    ) : (
                      <Typography variant="subtitle2" color="#576071">
                        {fNumber(+orderItem?.transportPrice || 0)}₫
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} py={1}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="#798395">
                      {'Tổng số tiền (' +
                        countProduct(listProduct) +
                        ' sản phẩm)'}
                    </Typography>
                    {isOrderLoading ? (
                      <Skeleton width={80} />
                    ) : (
                      <Typography variant="subtitle2" color="#576071">
                        {fNumber(productAmount(orderItem) || 0)}₫
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          )
        })}
      <ConfirmDeleteDialog
        open={!!productIdToDelete}
        onClose={() => setProductIdToDelete('')}
        onConfirm={handleDeleteProduct}
      />
    </Stack>
  )
}
