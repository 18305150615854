import md5 from 'md5'
import { ENVIRONMENT, appConfig } from '../../config'
import { InstanceAxiosDefault } from '../config/axios/default'
import { InstanceAxios } from '../config/axios/partner'
import { REST_API_SYS } from '../api_paths'
import {  generateSignatureUser, logJson } from '../../utils'

export const checkExpiredHandshake = async (): Promise<any> => {
  const route: string = REST_API_SYS.POST_EXPIRED_HANDSHAKE.uri

  const params = {
    operatingSystem: 'WEB',
    deviceLanguage: 'VI',
  }
  return InstanceAxios.patch(route, params)
}

export const checkOperatingSystem = async (): Promise<any> => {
  const route: string = REST_API_SYS.POST_OPERATING_SYSTEM.uri
  return InstanceAxiosDefault.post(route, {})
}

export const getIpClient = async (): Promise<any> => {
  const route: string = REST_API_SYS.GET_IP_ADDRESS_CLIENT.uri

  return InstanceAxiosDefault.get(route)
}

export const doHandShake = async (responseIp: any): Promise<any> => {
  const route: string = REST_API_SYS.HANDSHAKE_FOR_CLIENT.uri

  const operatingSystem = responseIp?.type === 'partner' ? 'PARTNER' : 'WEB'
  const clientId =
    responseIp?.type === 'partner'
      ? appConfig.PARTNER_CLIENT_ID
      : appConfig.CLIENT_ID
  const clientSecret =
    responseIp?.type === 'partner'
      ? appConfig.PARTNER_CLIENT_SECRET
      : appConfig.CLIENT_SECRET

  const params = {
    clientId: md5(clientId),
    clientSecret: md5(clientSecret),
    xForwardedFor: responseIp.ipClient ?? '',
    xDateTime: responseIp.dateTime ?? new Date().toISOString().slice(0, 23),
    deviceId: 'Chrome.101.0.4951.64.501015753736101049516453736',
    deviceName: 'Google Chrome',
    deviceLocation: 'unknown, Vietnam',
    deviceLanguage: 'VI',
    operatingSystem: operatingSystem,
  }

  // console.log('clientId: ', clientId)
  // console.log('clientSecret: ', clientSecret)
  // console.log('params Request: ', params)

  switch (appConfig.APP_ENV) {
    case ENVIRONMENT.STAGING:
      params.xForwardedFor = appConfig.XFORWARDEDFOR
      break
    case ENVIRONMENT.PRODUCTION:
      params.xForwardedFor = appConfig.XFORWARDEDFOR
      break
      case ENVIRONMENT.DEVELOPMENT:
      if (appConfig.XFORWARDEDFOR != '') {
        params.xForwardedFor = appConfig.XFORWARDEDFOR
      };
      break
  }

  var timestamp = Number(responseIp.timestamp) ?? 0
  const [signature] = generateSignatureUser(params, timestamp)

  return InstanceAxiosDefault.patch(
    route,
    JSON.stringify(btoa(JSON.stringify(params))),
    {
      headers: {
        'x-request-timestamp': timestamp.toString(),
        'x-signature': signature,
      } as any,
    }
  )
}
function AxiosRequestConfig() {
  throw new Error('Function not implemented.')
}
