import { REST_API_AUTH_DOCTOR } from '../api_paths'
import { InstanceAxios } from '../config/axios/partner'

export const registerDoctor = (body?: any) => {
  const path = REST_API_AUTH_DOCTOR.REGISTER.uri
  return InstanceAxios.post(path, body)
}

export const checkPhoneNumber = (phoneNumber?: any) => {
  const path = REST_API_AUTH_DOCTOR.CHECK_PHONE_NUMBER.uri
  return InstanceAxios.post(
    path,
    {
      phoneNumber,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
}
