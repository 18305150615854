import { CaretRightOutlined } from '@ant-design/icons'
import { Grid, Stack, Typography } from '@mui/material'
import { Col, Row, Typography as TypographyAntd } from 'antd'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
import { APP_URL } from 'src/config'
import { toSlug } from 'src/utils/string'
import classes from './Promotion.module.less'

export const ProductCategory = ({ data }: any) => {
  const { t } = useTranslation()

  return (
    <div className={classes['product-category-container']}>
      <Stack
        width="100%"
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'center', md: 'space-between' }}
        alignItems="center"
      >
        <Typography className={classes['title']}>
          {t('HOMEPAGE.PRODUCT_CATEGORY.TITLE')}
        </Typography>
        <Link to={APP_URL?.SHOP_ONLINE}>
          <TypographyAntd.Text className={classes['see-all']}>
            {t('HOMEPAGE.PRODUCT_CATEGORY.SEE_ALL')} <CaretRightOutlined />
          </TypographyAntd.Text>
        </Link>
      </Stack>
      <Grid
        container
        columns={24}
        spacing={{ xs: 1, md: 2 }}
        justifyContent="center"
        className={classes['category-list-container']}
      >
        {data.map((item: any, index: number) => (
          <Grid item xs={8} md={6} lg={3} key={index}>
            <Link
              to={`/shop-online/category/${item?.categoryId}-${toSlug(
                item?.categoryName
              )}`}
            >
              <Col className={classes['item']} key={index}>
                <div className={classes['wrap-inside']}>
                  <LazyLoad once height={300}>
                    <Row justify={'center'}>
                      <img src={item?.categoryIcon || ''} alt="" />
                    </Row>
                  </LazyLoad>
                  <Row justify={'center'}>{item?.categoryName}</Row>
                </div>
              </Col>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
