import { createSlice } from '@reduxjs/toolkit'
import { IDialogType } from '../../@types/dialog'
import { dispatch } from '../store'

const initialState: IDialogType = {
  isOpenDialog: false,
  dialogNameStore: 'forgot password',
  isSetBackground: false,
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsOpenDialog: (state, action) => {
      state.isOpenDialog = action.payload
    },
    setDialogName: (state, action) => {
      state.dialogNameStore = action.payload
    },
    setBackground: (state, action) => {
      state.isSetBackground = action.payload
    },
  },
})

// Reducer
export default slice.reducer

export function setIsOpenDialog(payload: any) {
  dispatch(slice.actions.setIsOpenDialog(payload))
}

export function setDialogNameStore(payload: any) {
  dispatch(slice.actions.setDialogName(payload))
}

export function setBackground(payload: any) {
  dispatch(slice.actions.setBackground(payload))
}
