// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import {
  Autocomplete,
  AutocompleteProps,
  FormHelperText,
  TextField,
} from '@mui/material'

// ----------------------------------------------------------------------

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string
  label?: string
  helperText?: React.ReactNode
}

export default function RHFAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  name,
  label,
  helperText,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control, setValue } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) =>
            setValue(name, newValue, { shouldValidate: true })
          }
          renderInput={params => (
            <TextField
              label={label}
              error={!!error}
              helperText={
                (!!error || helperText) && (
                  <FormHelperText
                    error={!!error}
                    sx={{
                      marginTop: '8px !important',
                    }}
                    style={{ color: '#F0224F' }}
                  >
                    {error ? error?.message : helperText}
                  </FormHelperText>
                )
              }
              placeholder={other?.placeholder}
              //   helperText={error ? error?.message : helperText}
              {...params}
            />
          )}
          {...other}
        />
      )}
    />
  )
}
