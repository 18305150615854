import React from 'react'
import classes from './SearchBar.module.less'
import { Button, Col, Row } from 'antd'
import { SearchIcon } from 'src/assets'

export type TSearchBar2prop = {
  value?: string
  defaultValue?: string
  placeholder?: string
  onChange?: any
}

export const SearchBar: React.FC<TSearchBar2prop> = ({
  value,
  defaultValue,
  onChange,
  placeholder,
}: TSearchBar2prop) => {
  return (
    <Row
      align={'middle'}
      justify={'space-between'}
      className={classes['searchbar2']}
      wrap={true}
      gutter={[0, 10]}
    >
      <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
        <Row className={classes['icon']}>
          <img src={SearchIcon} alt="" />
          {/* <SearchIcon /> */}
        </Row>
        <input
          placeholder={placeholder}
          className={classes['input']}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
        <Row justify={'end'}>
          <Button type="primary" className={classes['button']}>
            Tìm kiếm
          </Button>
        </Row>
      </Col>
    </Row>
  )
}
