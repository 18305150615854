import { InstanceAxios } from '../config/axios/user'

export const getProfilePatient = (): Promise<any> => {
  const path = '/api/patient/ProfilePatient/GetProfile'
  return InstanceAxios.get(path)
}

export const resetPasswordPatient = (body?: any): Promise<any> => {
  const path = '/api/patient/ProfilePatient/ResetPass'
  return InstanceAxios.post(path, body)
}

export const updateProfilePatient = (item?: any): Promise<any> => {
  const path = '/api/patient/ProfilePatient/updateprofile'
  return InstanceAxios.post(path, item)
}

export const changePasswordPatient = (item?: any): Promise<any> => {
  const path = '/api/patient/profilepatient/changepass'
  return InstanceAxios.post(path, item)
}
