import { useMutation } from 'react-query'
import { getListServiceTransportAccount, getListStatusTreeTransportAccount } from '../api_functions/accountTransport'

/*=================================== TRANSPORT ================================ */

export function useGetListStatusTreeTransportAccount() {
  return useMutation(getListStatusTreeTransportAccount)
}

export function useGetListServiceTransportAccount() {
  return useMutation(getListServiceTransportAccount)
}
