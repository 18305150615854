import { Close } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import { useEffect } from 'react'
import { LogoSophie } from 'src/assets'
import { IconButtonAnimate } from 'src/components/animate'
import { CloseIcon } from 'src/components/icon/CloseIcon'
import useResponsive from 'src/hooks/useResponsive'
import { useLocales } from 'src/locales'
import AuthCreateNewPassword from './components/AuthCreateNewPassword'
import AuthForgotUserPassword from './components/AuthForgotUserPassword'
import AuthInputCode from './components/AuthInputCode'
import { useForgotPasswordContext, withForgotPasswordContext } from './context'
import { ForgotPasswordStep } from './types'

export interface IForgotPasswordUserDialogProps {
  open: boolean
  onClose: VoidFunction
}

function ForgotPasswordUserDialog({
  open,
  onClose,
}: IForgotPasswordUserDialogProps) {
  const { translate } = useLocales()
  const { step, goToStep, resetForgotPasswordData } = useForgotPasswordContext()
  const isSm = useResponsive('up', 'sm')

  let component = <></>
  let title = ''

  switch (step) {
    case ForgotPasswordStep.InputPhoneNumber:
      component = <AuthForgotUserPassword hasSpaceInMobile />
      title = translate('forgotPasswordPage.you_forgot_password')
      break
    case ForgotPasswordStep.InputOtpCode:
      component = <AuthInputCode />
      title = translate('forgotPasswordPage.input_otp')
      break
    case ForgotPasswordStep.InputNewPassword:
      component = <AuthCreateNewPassword onSuccess={onClose} />
      title = translate('forgotPasswordPage.create_new_password')
      break
    default:
      break
  }

  useEffect(() => {
    if (open) {
      goToStep(ForgotPasswordStep.InputPhoneNumber)
      resetForgotPasswordData({ accountType: 'user' })
    }
  }, [open])

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => {}}
        sx={{
          backgroundColor: '#004E7399',
          '.MuiPaper-root': {
            margin: 0,
          },
        }}
        fullScreen={!isSm}
      >
        {isSm && (
          <DialogTitle
            sx={{
              padding: '8px 16px 8px 24px ',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: '#0F8FB9',
            }}
          >
            {title}
            <IconButton
              onClick={onClose}
              sx={{
                '&:hover': {
                  background: 'none',
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        )}
        {isSm && (
          <DialogContent sx={{ typography: 'body2', mb: 3 }}>
            {component}
          </DialogContent>
        )}
        {!isSm && (
          <Stack
            spacing={3}
            p={2}
            sx={{
              height: '100%',
              form: {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <img src={LogoSophie} alt="" />
              <IconButtonAnimate size="small" onClick={onClose}>
                <Close color="primary" />
              </IconButtonAnimate>
            </Stack>

            <Typography variant="h5" color="primary.main">
              {title}
            </Typography>

            {component}
          </Stack>
        )}
      </Dialog>
    </>
  )
}

export default withForgotPasswordContext(ForgotPasswordUserDialog)
