import { Close } from '@mui/icons-material'
import { Box, Button, Divider, Drawer, List, Stack } from '@mui/material'
import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { LogoSophie } from 'src/assets'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import { IconButtonAnimate } from 'src/components/animate'
import { APP_URL } from 'src/config'
import { RegisterLoginButton } from 'src/layouts/shop-online/components'
import { ConfirmLogoutDialog } from 'src/layouts/shop-online/components/ConfirmLogoutDialog'
import { MenuItem } from './MenuItem'

interface MenuDrawerProps {
  open: boolean
  onClose: VoidFunction
}

export const MenuDrawer: FC<MenuDrawerProps> = ({
  open,
  onClose,
}: MenuDrawerProps) => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuthUserContext()
  const location = useLocation()
  const [isOpenConfirmLogoutModal, setOpenConfirmLogoutModal] = useState(false)

  const screenHeight = useMemo(() => {
    if (!window) {
      return 0
    }
    return window.visualViewport?.height || window.innerHeight
  }, [window])

  useEffect(() => {
    onClose()
  }, [location.pathname])

  const MENU = getMenus(t).filter(item => {
    if (item?.role === 'auth') {
      return isAuthenticated
    }
    return true
  })

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="left"
      sx={{ '.MuiPaper-root': { width: '100%', height: '100vh' } }}
    >
      <Stack spacing={2} p={2} sx={{ height: screenHeight + 'px' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <img src={LogoSophie} alt="" />
          <IconButtonAnimate size="small" onClick={onClose}>
            <Close color="primary" />
          </IconButtonAnimate>
        </Stack>
        <Box flexGrow={1} overflow="auto">
          <List>
            {MENU?.map((item, i) => (
              <Fragment key={i}>
                <MenuItem item={item} onClose={onClose} />
                <Divider />
              </Fragment>
            ))}
          </List>
        </Box>
        {!isAuthenticated ? (
          <RegisterLoginButton />
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              setOpenConfirmLogoutModal(true)
            }}
            sx={{
              backgroundColor: '#E0F3F7',
              color: 'primary.main',
              whiteSpace: 'nowrap',
              ':hover': {
                backgroundColor: '#E0F3F7',
              },
            }}
          >
            Đăng xuất
          </Button>
        )}
      </Stack>
      <ConfirmLogoutDialog
        open={isOpenConfirmLogoutModal}
        onClose={() => setOpenConfirmLogoutModal(false)}
        onSuccess={onClose}
      />
    </Drawer>
  )
}

const getMenus = (t: any) => [
  {
    label: 'Thông tin cá nhân',
    role: 'auth',
    children: [
      {
        icon: '/assets/images/header/ic-person.png',
        label: t('userProfile.menu.personalInformation'),
        path: APP_URL.USER_PROFILE_PERSONAL_INFORMATION,
      },
      {
        icon: '/assets/images/header/ic-map.png',
        label: t('userProfile.menu.addressList'),
        path: APP_URL.USER_PROFILE_ADDRESS_LIST,
      },
      {
        icon: '/assets/images/header/ic-wallet.png',
        label: t('userProfile.menu.paymentMethod'),
        path: '#',
      },
      {
        icon: '/assets/images/header/ic-track.png',
        label: t('userProfile.menu.orderTracking'),
        path: '#',
      },
    ],
  },
  {
    label: t('MENU/ABOUTSOPHIE'),
    children: [
      {
        label: t('MENU/ABOUTSOPHIE/INTRODUCE'),
        path: APP_URL.ABOUT_SOPHIE,
      },
      {
        label: t('MENU/ABOUTSOPHIE/HR'),
        path: APP_URL.SOPHIE_HIRING,
      },
    ],
  },
  {
    label: t('MENU/BOOKING_ONLINE'),
    children: [
      {
        label: t('MENU/BOOKING_ONLINE/PHONE'),
        path: APP_URL.ONLINE_MEDICAL_BOOKING_PHONE,
      },
      {
        label: t('MENU/BOOKING_ONLINE/HOME'),
        path: APP_URL.ONLINE_MEDICAL_BOOKING_HOME,
      },
      {
        label: t('MENU/BOOKING_ONLINE/CLINIC'),
        path: APP_URL.ONLINE_MEDICAL_BOOKING_CLINIC,
      },
      {
        label: t('MENU/BOOKING_ONLINE/FASTPHONE'),
        path: APP_URL.ONLINE_MEDICAL_BOOKING_FAST_PHONE,
      },
    ],
  },
  {
    label: t('MENU/SERVICE_HEALTH_AT_HOME'),
    children: [
      {
        label: t('MENU/SERVICE_HEALTH_AT_HOME/BABY'),
        path: APP_URL.HEALTH_SERVICE_BABY_BATH,
      },
      {
        label: t('MENU/SERVICE_HEALTH_AT_HOME/SICK'),
        path: APP_URL.HEALTH_SERVICE_HUMAN_SICK,
      },
      {
        label: t('MENU/SERVICE_HEALTH_AT_HOME/BLOOD_AT_HOME'),
        path: APP_URL.HEALTH_SERVICE_GET_BLOOD,
      },
      {
        label: t('MENU/SERVICE_HEALTH_AT_HOME/THERAPY'),
        path: APP_URL.HEALTH_SERVICE_PHYSICAL_THERAPY,
      },
    ],
  },
  {
    label: t('MENU/PHARMARCY_ONLINE'),
    path: APP_URL.SHOP_ONLINE,
  },
  {
    label: t('MENU/BLOG_HEALTH'),
    path: APP_URL.BLOG_HEAL_HOME,
  },
  {
    label: t('HOMEPAGE.SERVICE.DOWNLOAD'),
    children: [
      {
        render: 'download',
      },
    ],
  },
]
