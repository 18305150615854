import React from 'react'
import classes from './Introduce.module.less'
import { Col, Row, Typography } from 'antd'
import {
  ImageAppStore,
  ImageGooglePlay,
  ImageIntroduceBackground,
  ImageUngDungSophie,
  MobileBooking,
} from 'src/assets'
import {
  LINK_DOWNLOAD_APP_PATIENT_PLAY,
  LINK_DOWNLOAD_PATIENT_STORE,
} from 'src/config/constant'
import { SearchBar } from '../SearchBar'
import { Helmet } from 'react-helmet-async'
import LazyLoad from 'react-lazyload'

export type TIntroduceProps = {}

export const Introduce: React.FC<TIntroduceProps> = ({}: TIntroduceProps) => {
  return (
    <Row
      justify={{ md: 'start', lg: 'center' }}
      className={classes['introduce-container']}
    >
      <img
        src={ImageIntroduceBackground}
        alt=""
        className={classes['introduce-overlay']}
      />
      <Col span={24}>
        <Row
          justify={{ md: 'start', lg: 'space-around' }}
          className={classes['introduce']}
          gutter={[0, 40]}
        >
          <Col xs={24} sm={24} md={24} lg={12} className={classes['left']}>
            <Row
              justify={{
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
              }}
            >
              <img
                src={ImageUngDungSophie}
                alt=""
                className={classes['img-sophie-application']}
              />
            </Row>
            <Row
              justify={{
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
              }}
            >
              <Typography.Text className={classes['title']}>
                Bác sĩ, nhà thuốc, <br />
                <span>
                  dịch vụ y tế <br />
                  sức khoẻ
                </span>
                <span> trực tuyến</span>
              </Typography.Text>
            </Row>
            <Row
              justify={{
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
              }}
            >
              <Typography.Text className={classes['subtitle']}>
                Thăm khám qua điện thoại, hay khám tại nhà, hoặc đặt lịch tại
                phòng khám tiện lợi.
              </Typography.Text>
            </Row>
            <Row
              justify={{
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
              }}
            >
              <Typography.Text className={classes['sub-subtitle']}>
                Ứng dụng có mặt trên
              </Typography.Text>
            </Row>
            <Row
              justify={{
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
              }}
              className={classes['social']}
            >
              <Col>
                <a
                  rel="noreferrer"
                  href={LINK_DOWNLOAD_PATIENT_STORE}
                  target={'_blank'}
                >
                  <img
                    src={ImageAppStore}
                    alt=""
                    className={classes['img-social']}
                  />
                </a>
              </Col>
              <Col>
                <a
                  rel="noreferrer"
                  href={LINK_DOWNLOAD_APP_PATIENT_PLAY}
                  target={'_blank'}
                >
                  <img
                    src={ImageGooglePlay}
                    alt=""
                    className={classes['img-social']}
                  />
                </a>
              </Col>
            </Row>
            <Row
              justify={{
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
              }}
              className={classes['search-bar']}
            >
              <Col xs={24} sm={24} md={24} lg={22}>
                <SearchBar placeholder="Tìm thuốc, thực phẩm chức năng, dụng cụ y tế" />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} className={classes['right']}>
            <LazyLoad once height={100}>
              <img
                src={MobileBooking}
                alt=""
                className={classes['img-mobile-booking']}
              />
            </LazyLoad>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
