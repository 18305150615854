export function getExtension(filename: string) {
  if (typeof filename !== 'string') {
    return ''
  }
  const parts = (filename || '')?.split('.')
  const ext = parts?.[parts?.length - 1]
  if (ext?.length > 5) {
    const parts_2 = (filename || '')?.split('_')
    return parts_2?.[parts_2?.length - 1]
  }
  return ext
}

export function isImage(filename: string) {
  const ext = getExtension(filename)
  const imageExts = ['png', 'jpg', 'jpeg', 'jfif', 'webp', 'gif', 'bmp', 'tiff']
  return imageExts.includes(ext.toLowerCase())
}

export function isVideo(filename: string) {
  const ext = getExtension(filename)
  const videoExts = [
    'mp4',
    'avi',
    'flv',
    'm4v',
    'mpg',
    'wmv',
    'mov',
    'avchd',
    'webm',
    'mkv',
  ]
  return videoExts.includes(ext.toLowerCase())
}

export function isDocument(filename: string) {
  const ext = getExtension(filename)
  const type = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf']
  return type?.includes(ext)
}
