import { yupResolver } from '@hookform/resolvers/yup'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { LogoSophie } from 'src/assets'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import { IconButtonAnimate } from 'src/components/animate'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import Iconify from 'src/components/iconify'
import useResponsive from 'src/hooks/useResponsive'
import { useLocales } from 'src/locales'
import { logDev } from 'src/utils'
import * as Yup from 'yup'
import { REGEX_PHONE_NUMBER_WITHOUT_COUNTRY_CODE, TypeLogin } from '../enum'
import ForgotPasswordUserDialog from '../forgot-password/ForgotPasswordUserDialog'
import AuthWithSocial from '../AuthWithSocial'

interface LoginPopoverContentProps {
  onClose?: VoidFunction
  onSuccess?: VoidFunction
  className?: string
}

export default function LoginPopoverContent({
  onClose,
  onSuccess,
  className,
}: LoginPopoverContentProps) {
  const { login } = useAuthUserContext()
  const { translate } = useLocales()
  const [showPassword, setShowPassword] = useState(false)
  const [isOpenForgotPasswordDialog, setOpenForgotPasswordDialog] =
    useState(false)
  const isMd = useResponsive('up', 'md')

  const LoginSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required(translate('loginPage.phoneNumberRequired') + '')
      .matches(
        REGEX_PHONE_NUMBER_WITHOUT_COUNTRY_CODE,
        translate('loginPage.phoneNumberIsWrongFormat') + ''
      ),
    password: Yup.string().required(
      translate('loginPage.passwordRequired') + ''
    ),
  })

  const defaultValues = {
    phoneNumber: '',
    password: '',
  }

  const methods = useForm<typeof defaultValues>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      const password = data?.password

      const phoneNumber =
        '+84' +
        (data?.phoneNumber?.[0] === '0'
          ? data?.phoneNumber?.replace(data?.phoneNumber?.[0], '')
          : data?.phoneNumber)

      const object = {
        typeLogin: TypeLogin.PHONE,
        phoneNumber: phoneNumber,
        password: password,
      }

      await login(object)
      onSuccess && onSuccess()
      onClose && onClose()
    } catch (error) {
      logDev('LoginPopoverContent', error)
    }
  }

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack
          p={2}
          spacing={3}
          height={{ xs: '100vh', md: 'auto' }}
          className={className}
        >
          {!isMd && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <img src={LogoSophie} alt="" />
              <IconButtonAnimate
                size="small"
                onClick={() => onClose && onClose()}
              >
                <Close color="primary" />
              </IconButtonAnimate>
            </Stack>
          )}
          <Typography variant="h5" color="primary.main">
            {translate('loginPage.login_account')}
          </Typography>
          <Stack spacing={1}>
            <Typography
              variant="subtitle2"
              color="#798395"
              sx={{
                marginBottom: { xs: '8px', md: 0 },
              }}
            >
              {translate('loginPage.phoneNumberRegister')}
            </Typography>
            <RHFTextField
              size="small"
              name="phoneNumber"
              autoComplete="off"
              placeholder={translate('loginPage.phoneNumberRegister') + ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: '#576071' }}>
                    <Box
                      component="img"
                      src={`/assets/icons/flags/ic_flag_vn.svg`}
                      sx={{ width: 26, height: 36 }}
                    />
                    <Typography sx={{ marginLeft: 1, marginRight: 3 }}>
                      +84
                    </Typography>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                autocomplete: 'off',
              }}
            />
          </Stack>
          <Stack spacing={1}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <Typography variant="subtitle2" color="#798395">
                {translate('password')}
              </Typography>
              <Stack>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => setOpenForgotPasswordDialog(true)}
                  sx={{
                    '&:hover': {
                      background: 'none',
                    },
                  }}
                >
                  <Typography variant="body2" color="#1A9CC6">
                    {translate('forgot_password')}
                  </Typography>
                </Button>
              </Stack>
            </Box>
            <RHFTextField
              size="small"
              name="password"
              autoComplete="off"
              placeholder={translate('loginPage.input_password') + ''}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          {!isMd && (
            <Typography variant="body2" color="#798395" align="center">
              {translate('loginPage.you_dont_have_account')}{' '}
              <Link to="/register">
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="primary.main"
                  onClick={onClose}
                >
                  {translate('loginPage.register_now')}
                </Typography>
              </Link>
            </Typography>
          )}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {translate('login')}
          </LoadingButton>

          <Typography
            variant="body1"
            color="#798395"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
              marginBottom: '16px',
            }}
          >
            {translate('loginPage.or_login_by')}
          </Typography>
          <AuthWithSocial />

          {isMd && (
            <Typography variant="body2" color="#798395" align="center">
              {translate('loginPage.you_dont_have_account')}{' '}
              <Link to="/register" onClick={onClose}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="primary.main"
                >
                  {translate('loginPage.register_now')}
                </Typography>
              </Link>
            </Typography>
          )}
        </Stack>
      </FormProvider>
      <ForgotPasswordUserDialog
        open={isOpenForgotPasswordDialog}
        onClose={() => setOpenForgotPasswordDialog(false)}
      />
    </>
  )
}
