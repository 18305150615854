import { doHandShake, getIpClient, checkOperatingSystem, checkExpiredHandshake } from '../api_functions/system'
import { useMutation } from 'react-query'

export function useCheckExpiredHandshake() {
  return useMutation(checkExpiredHandshake)
}

export function useCheckOperatingSystem() {
  return useMutation(checkOperatingSystem)
}

export function useGetIpClient() {
  return useMutation(getIpClient)
}

export function useHandShake() {
  return useMutation(doHandShake)
}


