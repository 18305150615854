import { CartContextProvider } from './CartContext'

const withCartContext = (Component: any) => {
  function NewComponent(props: any) {
    return (
      <CartContextProvider>
        <Component {...props} />
      </CartContextProvider>
    )
  }
  return NewComponent
}
export default withCartContext
