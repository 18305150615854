// @mui
import { Card, Dialog, DialogContent } from '@mui/material'
// routes
// auth
import { useAuthContext } from 'src/auth/useAuthContext'
// sections
import { useLocales } from 'src/locales'
import { ProfileCover, ProfileInformation } from './components'

// ----------------------------------------------------------------------

interface UserProfileDialogProps {
  open: boolean
  onClose: VoidFunction
}

export default function UserProfileDialog({
  open,
  onClose,
}: UserProfileDialogProps) {
  const { profile } = useAuthContext()
  const { translate } = useLocales()

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        scroll="body"
      >
        <DialogContent sx={{ p: 2 }}>
          <Card
            sx={{
              mb: 3,
              height: 280,
              position: 'relative',
            }}
          >
            <ProfileCover profile={profile} />
          </Card>

          <ProfileInformation profile={profile} />
        </DialogContent>
      </Dialog>
    </>
  )
}
