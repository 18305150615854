import { Box, Breadcrumbs, Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { matchRoutes, Outlet, useLocation, useMatch } from 'react-router'
import { Link } from 'react-router-dom'
import { APP_URL } from 'src/config'
import { Header } from '../shop-online/components'
import { AppFooter } from '../user/components'
import { MenuItem } from './component/Menu-item/MenuItem.screen'
import './ShopOnlineProfileLayout.module.less'
import classes from './ShopOnlineProfileLayout.module.less'

const ShopOnlineProfileLayout: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const breadcrumbTitleByPath = {
    [APP_URL.USER_PROFILE_PERSONAL_INFORMATION]: t(
      'userProfile.personalInformation.title'
    ),
    [APP_URL.USER_PROFILE_ADDRESS_LIST]: t('userProfile.address.listAddress'),
    [APP_URL.USER_PROFILE_ADDRESS_CREATE]: t('userProfile.address.addAddress'),
    [APP_URL.USER_PROFILE_ADDRESS_UPDATE]: t(
      'userProfile.address.updateAddress'
    ),
    [APP_URL.USER_PROFILE_ORDER_TRACKING]: t(
      'Theo dõi đơn hàng'
    ),
    [APP_URL.USER_PROFILE_ORDER_TRACKING_DETAIL]: t(
      'Chi tiết đơn hàng'
    ),
  }

  const [{ route }] = matchRoutes(
    Object.keys(breadcrumbTitleByPath).map(path => ({ path })),
    location
  ) as any

  return (
    <div className={classes['shop-online']}>
      <Header />
      <div className={classes['shop-online__content']}>
        <div className={classes['shop-online-profile']}>
          <div className={classes['container']}>
            <Box py={2}>
              <Breadcrumbs>
                <Link color="red" to={APP_URL.HOME}>
                  <Typography variant="body2" color="primary">
                    {t('userProfile.homepage')}
                  </Typography>
                </Link>
                <Typography color="#798395">
                  {breadcrumbTitleByPath[route?.path] || ''}
                </Typography>
              </Breadcrumbs>
            </Box>
          </div>
          <div className={classes['container']}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4} lg={3}>
                <MenuItem />
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <Outlet />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  )
}

export default ShopOnlineProfileLayout
