import React from 'react'
import classes from './RightNav.module.less'
import { Col, Row, Typography } from 'antd'
import { SearchIcon } from 'src/assets'
import { useTranslation } from 'react-i18next'

export type TRightNavProps = {}

export const RightNav: React.FC<TRightNavProps> = ({}: TRightNavProps) => {
  const { t } = useTranslation()

  return (
    <Row className={classes['right-nav-container']}>
      <Col span={24}>
        <Row className={classes['right-nav']} data-aos="fade-down-left">
          <Col span={24}>
            <Row>
              <Typography.Text className={classes['title']}>
                {t('NAV_RIGHT.TITLE')}
              </Typography.Text>
            </Row>
            <Row className={classes['search']}>
              <input
                placeholder={t('NAV_RIGHT.PLACEHOLDER') || ''}
                className={classes['input']}
              />
              <Row className={classes['icon']}>
                <img src={SearchIcon} alt="" />
                {/* <SearchIcon /> */}
              </Row>
            </Row>
          </Col>
        </Row>
        <Row className={classes['right-nav']} data-aos="fade-down-left">
          <Col span={24}>
            <Row>
              <Typography.Text className={classes['title']}>
                {t('NAV_RIGHT.SUBTITLE')}
              </Typography.Text>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Typography.Text className={classes['label']}>
                    {t('NAV_RIGHT.LABEL')}
                  </Typography.Text>
                </Row>
                <Row gutter={[4, 4]}>
                  <Col>
                    <Typography.Text className={classes['description']}>
                      Hồ Chí Minh
                    </Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text className={classes['description']}>
                      2 vị trí
                    </Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text className={classes['description']}>
                      12 Th4, 2022
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
