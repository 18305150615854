import { Grid } from '@mui/material'
import { Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import {
  ImageAppStore,
  ImageBuilding,
  ImageBuildingMask,
  ImageGooglePlay,
} from 'src/assets'
import { LINK_DOWNLOAD_APP_PATIENT_PLAY, LINK_DOWNLOAD_PATIENT_STORE } from 'src/config'
import classes from './SophieConfident.module.less'

export const SophieConfident = ({ data }: any) => {
  const { t } = useTranslation()

  const RenderItem = ({ title, description }: any) => {
    return (
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xl={6}
        xxl={6}
        className={classes['number-container']}
      >
        <Row justify={'center'} className={classes['number']}>
          {title}
        </Row>
        <Row justify={'center'} className={classes['description']}>
          {description}
        </Row>
      </Col>
    )
  }

  return (
    <LazyLoad once height={700} className={classes['confident-container']}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }}>
          <div data-aos="fade-up" className={classes['left']}>
            <Row
              justify={{
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
              }}
            >
              <Col className={classes['img-building']}>
                <img src={ImageBuilding} alt="" />
                <div className={classes['img-building-mask']}>
                  <img src={ImageBuildingMask} alt="" />
                </div>
              </Col>
              <Col
                className={classes['title']}
                dangerouslySetInnerHTML={{
                  __html: t('HOMEPAGE.SOPHIE_CONFIDENT.TITLE'),
                }}
              ></Col>
            </Row>
            <Row
              justify={{
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
              }}
            >
              <div
                className={classes['subtitle']}
                dangerouslySetInnerHTML={{
                  __html: t('HOMEPAGE.SOPHIE_CONFIDENT.SUBTITLE'),
                }}
              ></div>
            </Row>
            <Row
              justify={{
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
              }}
            >
              <Typography.Text className={classes['sub-subtitle']}>
                {t('HOMEPAGE.SOPHIE_CONFIDENT.SUB_SUBTITLE')}
              </Typography.Text>
            </Row>
            <Row
              justify={{
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
              }}
              className={classes['social']}
              gutter={[20, 20]}
            >
              <Col>
                <a
                  rel="noreferrer"
                  target={'_blank'}
                  href={LINK_DOWNLOAD_PATIENT_STORE}
                >
                  <img
                    src={ImageAppStore}
                    alt=""
                    className={classes['img-social']}
                  />
                </a>
              </Col>
              <Col>
                <a
                  rel="noreferrer"
                  target={'_blank'}
                  href={LINK_DOWNLOAD_APP_PATIENT_PLAY}
                >
                  <img
                    src={ImageGooglePlay}
                    alt=""
                    className={classes['img-social']}
                  />
                </a>
              </Col>
            </Row>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }}>
          <div data-aos="fade-up" className={classes['right']}>
            <img src={ImageBuilding} alt="" />
            <div className={classes['right-mask-overlay']}>
              <img src={ImageBuildingMask} alt="" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} order={3}>
          <Row
            align={'top'}
            justify={'space-around'}
            className={classes['number-of-user']}
            data-aos="fade-up"
            gutter={[0, 20]}
          >
            <RenderItem
              title={`${data?.countHospitals || 0}+`}
              description={t('HOMEPAGE.SOPHIE_CONFIDENT.COUNT_HOSPITAL')}
            />
            <RenderItem
              title={`${data?.countDoctors || 0}+`}
              description={t('HOMEPAGE.SOPHIE_CONFIDENT.COUNT_DOCTOR')}
            />
            <RenderItem
              title={`${data?.countPatients || 0}+`}
              description={t('HOMEPAGE.SOPHIE_CONFIDENT.COUNT_PATIENT')}
            />
            <RenderItem
              title={`${data?.countAppointment || 0}+`}
              description={t('HOMEPAGE.SOPHIE_CONFIDENT.COUNT_APPOINTMENT')}
            />
          </Row>
        </Grid>
      </Grid>
    </LazyLoad>
  )
}
